import React, { useEffect, useState } from "react";
import { Route, HashRouter as Router, Routes, Switch, useNavigate } from "react-router-dom";

/* Group of Components */
import ProviderHeader from "./../components/ProviderHeader";
import Search from "./../components/Search";
import ProviderSidebar from "./../components/ProviderSidebar";

import PageNotFound from "./../pages/404Page";
import ProviderProfile from "../pages/Provider/ProviderProfile";
import ProviderSettings from "../pages/Provider/ProviderSettings";

import PatientList from "../pages/Provider/PatientListU2";
import PatientView from "../pages/Provider/PatientViewU2";

/*Pathology Module*/
import ClinicalList from "../pages/Provider/ClinicalListU2";
import ClinicalView from "../pages/Provider/ClinicalViewU2";

function ProviderRoute() {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const UserType = sessionStorage.getItem("user_type");

    useEffect(() => {
        if (UserType !== "2") {
            navigate("/Provider/Login");
        }
    }, [navigate, UserType]);

    if (UserType == 2) {
        return (
            <div className={`app align-content-stretch d-flex flex-wrap ${isOpen ? "sidebar-hidden" : ""}`}>
                <ProviderSidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

                <div className="app-container">
                    <Search />
                    <ProviderHeader isOpen={isOpen} toggleSidebar={toggleSidebar} />

                    <Routes>
                        <Route path="/*" element={<PageNotFound />} />
                        <Route path="/Profile" element={<ProviderProfile />} />
                        <Route path="/Settings" element={<ProviderSettings />} />

                        <Route path="/PatientList" element={<PatientList />} />
                        <Route path="/PatientView" element={<PatientView />} />

                        {/* <Route path="/ClinicalList" element={<ClinicalList />} /> */}
                        {/* <Route path="/ClinicalView" element={<ClinicalView />} /> */}
                    </Routes>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default ProviderRoute;
