// import * as React from "react";
import React, { useState, useRef, useEffect } from "react";

import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid";

import axios from "axios";
import { security_key, BASE_URL, options_city } from "../global";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";

import Moment from "moment";
import "moment-timezone";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

// const currentDate = new Date();
// const dbFormattedDateTime = currentDate.toISOString().slice(0, 19).replace("T", " ");
// const dbFormattedDate = currentDate.toISOString().split("T")[0];
// const dbFormattedTime = currentDate.toTimeString().split(" ")[0];

function EditToolbar(props) {
    // const { setRows, setRowModesModel } = props;

    const { rows, setRows, setRowModesModel } = props;

    const handleClick = () => {
        // const id = 1;
        const maxId = Math.max(...rows.map((row) => row.id)); // Find the maximum ID from existing rows
        const id = isNaN(maxId) || maxId === -Infinity ? 1 : maxId + 1; // Increment it by 1

        setRows((oldRows) => [...oldRows, { id, service_name: "", service_cost: "", isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "service_name" },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add service
            </Button>
        </GridToolbarContainer>
    );
}

function ServiceCrudGrid({ providerId }) {
    // alert(providerId);

    const [loading, setLoading] = useState(true);

    const [rows, setRows] = React.useState([]);

    const location = useLocation();

    useEffect(() => {
        fetchData();
    }, [location]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getServiceByFk",
                provider_id: providerId,
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return { ...row, id: row.pk_service_id };
                });
                setRows(modifiedOutput);
                // console.log(modifiedOutput);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => async () => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it",
            });

            if (result.isConfirmed) {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "deleteService",
                    service_id: id,
                });

                const output = response.data;

                if (output === 1) {
                    await Swal.fire("Deleted!", "Service row has been deleted.", "success");
                    setRows(rows.filter((row) => row.id !== id));
                    fetchData();
                }
            }
        } catch (error) {
            // Handle any errors here
            console.error("An error occurred: ", error);
        }
    };

    const handleCancelClick = (id) => () => {
        // console.log(id);

        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    // const processRowUpdate = async (newRow) => {
    //     if (newRow["service_name"] == "" || newRow["service_cost"] == "") {
    //         let id = newRow["id"];
    //         setRowModesModel({
    //             ...rowModesModel,
    //             [id]: { mode: GridRowModes.View, ignoreModifications: true },
    //         });
    //         return { isNew: false };
    //     }
    // };

    // const processRowUpdate = async (updatedRow, originalRow) => {
    //     let id = originalRow["id"];
    //     setRowModesModel({
    //         ...rowModesModel,
    //         [id]: { mode: GridRowModes.View, ignoreModifications: true },
    //     });

    //     // console.log(updatedRow);
    //     // console.log(originalRow);
    //     // return originalRow;
    //     // // Find the index of the row that was edited
    //     // const rowIndex = rows.findIndex((row) => row.id === updatedRow.id);
    //     // // Replace the old row with the updated row
    //     // const updatedRows = [...rows];
    //     // updatedRows[rowIndex] = updatedRow;
    //     // // Update the state with the new rows
    //     // setRows(updatedRows);
    //     // // Return the updated row to update the internal state of the DataGrid
    //     // return updatedRow;
    // };

    const processRowUpdate = async (newRow) => {
        toast.dismiss();

        if (!newRow["service_name"]) {
            toast.error("Service Name is required.", {
                autoClose: 3000,
            });
            return;
        }
        // else if (!/^[a-zA-Z0-9. ]{2,250}$/.test(newRow["service_name"])) {
        //     toast.error("Service Name must be 2 to 250 characters long and can only contain letters, numbers, dots, and spaces.", {
        //         autoClose: 3000,
        //     });
        //     return;
        // }
        else if (!newRow["service_cost"]) {
            toast.error("Service Cost is required.", {
                autoClose: 3000,
            });
            return;
        } else if (newRow["service_cost"] == 0) {
            toast.error("Service Cost  must be greater than zero.", {
                autoClose: 3000,
            });
            return;
        } else if (newRow["service_cost"] < 0) {
            toast.error("Service Cost not accept negative value.", {
                autoClose: 3000,
            });
            return;
        }

        try {
            if (newRow.hasOwnProperty("isNew") && newRow["isNew"] === true) {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "addService",
                    provider_id: providerId,
                    service_name: newRow["service_name"],
                    service_cost: parseInt(newRow["service_cost"]),
                    created_by: dbFormattedDateTime,
                    updated_by: dbFormattedDateTime,
                });

                const output = response.data;

                if (output && output > 0) {
                    fetchData();
                    toast.success("Added successfully!", {
                        autoClose: 3000,
                    });
                    const updatedRow = { ...newRow, isNew: false, pk_service_id: parseInt(output) };
                    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
                    return updatedRow;
                } else {
                    // Handle the case when the response is not as expected
                    throw new Error("Invalid output value after adding service");
                }
            } else {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "updateService",
                    provider_id: providerId,
                    service_id: newRow["pk_service_id"],
                    service_name: newRow["service_name"],
                    service_cost: parseInt(newRow["service_cost"]),
                    created_by: dbFormattedDateTime,
                    updated_by: dbFormattedDateTime,
                });

                const output = response.data;

                if (output && output > 0) {
                    fetchData();
                    toast.success("Updated successfully!", {
                        autoClose: 3000,
                    });
                    const updatedRow = { ...newRow, isNew: false };
                    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
                    return updatedRow;
                } else {
                    // Handle the case when the response is not as expected
                    throw new Error("Invalid output value after updating service");
                }
            }
        } catch (error) {
            console.error("Error occurred during row update:", error);
            // Handle the error as needed, e.g., show an error message or perform other actions
        }
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        {
            field: "service_name",
            headerName: "Service Name",
            headerClassName: "super-app-theme--header",
            // width: 200,
            flex: 1,
            editable: true,
        },
        {
            headerName: "Service Cost",
            field: "service_cost",
            headerClassName: "super-app-theme--header",
            type: "number",
            // width: 180,
            flex: 1,
            align: "left",
            headerAlign: "left",
            editable: true,
            renderCell: (cellValues) => {
                return `${parseInt(cellValues.row.service_cost)}$`;
            },
            // cellEditorParams: {
            //     numeric: true,
            //     valueSetter: function (params) {
            //         const newValue = parseInt(params.newValue);
            //         if (!isNaN(newValue)) {
            //             params.data.service_cost = newValue;
            //             return true; // value was set successfully
            //         }
            //         return false; // invalid value, value was not set
            //     },
            // },
        },

        {
            headerName: "Actions",
            headerClassName: "super-app-theme--header",
            field: "actions",
            type: "actions",
            // width: 100,
            flex: 1,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: "primary.main",
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
                    ];
                }

                return [
                    <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
                ];
            },
        },
    ];

    return (
        <>
            <ToastContainer position="top-center" />

            <Box
                sx={{
                    height: "fit-content",
                    width: "100%",
                    "& .actions": {
                        color: "text.secondary",
                    },
                    "& .textPrimary": {
                        color: "text.primary",
                    },
                    "& .super-app-theme--header": {
                        backgroundColor: "#e3e6ee",
                    },
                }}
                // style={{ height: 400, width: "100%" }}
                style={{ height: rows.length == 0 ? 400 : "fit-content", width: "100%" }}
            >
                <DataGrid
                    sx={{ border: "none", m: 2 }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    slotProps={{
                        toolbar: { rows, setRows, setRowModesModel },
                    }}
                />
            </Box>
        </>
    );
}

export default ServiceCrudGrid;
