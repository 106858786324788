import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { security_key, BASE_URL } from "../global";
import { useState, useCallback, useRef } from "react";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import logo from "./../assets/images/backgrounds/Provider.png";

// import { createBrowserHistory } from "history";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const basicSchema = yup.object().shape({
    email: yup.string().required("Email ID is required"),
    password: yup.string().required("Password is required"),
});

const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
    setValues({
        ...initialValues, // Reset select fields
    });
};

function LoginProvider() {
    // const history = createBrowserHistory();

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const location = useLocation();

    const getUrlParameter = (parameterName) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(parameterName);
    };

    const token = getUrlParameter("token");
    useEffect(() => {
        const checkToken = async (token) => {
            try {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "checkVerifyToken",
                    token: token,
                });

                const output = response.data;

                if (output["status"]) {
                    toast.success(`${output["info"]}`, {
                        autoClose: 1000,
                    });
                }
            } catch (error) {
                console.error("Error while checking token:", error);
            }
        };

        if (token) {
            checkToken(token);
        }
    }, [token]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const initialValues = {
        email: "",
        password: "",
    };

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
        initialValues,
        validationSchema: basicSchema,
        onSubmit: async (formValues) => {
            setIsSubmitting(true);
            toast.dismiss();

            try {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "checkProviderLog",
                    ...formValues,
                });

                const output = response.data;

                if (output.length > 0) {
                    if (output[0]["verify_status"] == 1) {
                        resetFormFields(resetForm, setValues, initialValues);

                        // history.push("/Dashboard");

                        if (output[0]["pk_prov_auth_id"] !== "") {
                            handleWpLogin();
                            sessionStorage.setItem("loger_id", output[0]["pk_prov_auth_id"]);
                            sessionStorage.setItem("pk_provider_id", output[0]["pk_provider_id"]);
                            sessionStorage.setItem("wp_user_id", output[0]["fk_wp_user_id"]);
                            sessionStorage.setItem("fk_practice_id", output[0]["fk_practice_id"]);
                            sessionStorage.setItem("name", output[0]["prov_name"]);
                            sessionStorage.setItem("image", output[0]["prov_image"]);
                            sessionStorage.setItem("email", output[0]["prov_email"]);
                            sessionStorage.setItem("user_type", 2);
                            sessionStorage.setItem("is_mail_send", output[0]["is_mail_send"]);
                            sessionStorage.setItem("is_sms_send", output[0]["is_sms_send"]);
                            sessionStorage.setItem("theme", output[0]["theme"]);
                            sessionStorage.setItem("landing_page", output[0]["landing_page"]);

                            sessionStorage.setItem("prov_email", output[0]["prov_email"]);
                            sessionStorage.setItem("prov_password", output[0]["prov_dir_password"]);

                            toast.success("You have successfully logged into your account.", {
                                autoClose: 1000,
                            });

                            setTimeout(function () {
                                document.body.className = `body-container ${output[0]["theme"]}`;
                                navigate(`/Provider/${output[0]["landing_page"]}`);
                            }, 1000);
                        } else {
                            toast.error("Something went wrong.", {
                                autoClose: 1000,
                            });

                            setIsSubmitting(false); // Re-enable the submit button
                        }
                    } else {
                        toast.error("Your Account Not verified!.", {
                            autoClose: 1000, // 3 seconds in this example
                        });
                    }
                } else {
                    toast.error("Invalid email or password.", {
                        autoClose: 1000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Invalid API. Please Contact Emstyle Team", {
                    autoClose: 1000, // 3 seconds in this example
                });
                setIsSubmitting(false); // Re-enable the submit button
            } finally {
                setIsSubmitting(false); // Re-enable the submit button
            }
        },
    });

    const handleWpLogin = async () => {
        try {
            const response = await axios.post(BASE_URL + "shop/wp-json/woocommerce/v1/auth/", {
                action: "login",
                username: values.email,
                password: values.password,
            });
        } catch (error) {
            // Handle errors from the API call
            console.error("Error logging out:", error);
        }
    };

    return (
        <>
            <ToastContainer position="top-center" />
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end" style={{ background: "#e7ebf8" }}>
                    <div className="app-auth-background provider-login"></div>
                    <div className="app-auth-container">
                        <div className="logo-dark text-center mb-5">
                            <a role="button" style={{ display: "inline-block" }} onClick={() => navigate("/Patient")}>
                                {/* <Image src={BASE_URL + "images/logo-dark.png"} width={150} className="img-fluid d-block" /> */}
                                <Image src={logo} width={170} className="img-fluid d-block" />
                            </a>
                        </div>

                        <div className="auth-credentials m-b-xxl">
                            <label htmlFor="signInEmail" className="form-label">
                                Email Address
                            </label>
                            {/* <input type="email" className="form-control m-b-md" id="signInEmail" aria-describedby="signInEmail" placeholder="example@gmail.com" /> */}
                            <Form.Control
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="email"
                                className={errors.email && touched.email ? "input-error" : ""}
                                placeholder="example@gmail.com"
                            />
                            {errors.email && touched.email && <p className="error">{errors.email}</p>}
                            <label htmlFor="signInPassword" className="form-label mt-3">
                                Password
                            </label>
                            <div style={{ position: "relative" }}>
                                <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="password"
                                    className={errors.password && touched.password ? "input-error" : ""}
                                    placeholder="●●●●●●●●"
                                />
                                {errors.password && touched.password && <p className="error">{errors.password}</p>}
                                <span style={{ position: "absolute", top: "10px", right: "10px" }}>
                                    <a role="button" onClick={toggleShowPassword}>
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="auth-submit">
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                Sign In
                            </button>
                            <a
                                role="button"
                                className="auth-forgot-password float-end"
                                onClick={() => {
                                    navigate("/Provider/ForgotPassword");
                                }}
                            >
                                Forgot password?
                            </a>
                        </div>
                        <div className="divider" />
                        {/* <div className="auth-alts">
                            <a href="#" className="auth-alts-google" />
                            <a href="#" className="auth-alts-facebook" />
                            <a href="#" className="auth-alts-twitter" />
                        </div> */}
                    </div>
                </div>
            </Form>
        </>
    );
}

export default LoginProvider;
