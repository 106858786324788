import React, { useEffect, useState } from "react";

// import { Route, HashRouter as Router, Routes, Redirect, useHistory, Navigate } from "react-router-dom";
// import { Route, HashRouter as Router, Routes, Switch, useNavigate } from "react-router-dom";
import { Route, BrowserRouter as Router, Routes, Switch, useNavigate } from "react-router-dom";

import AdminLogin from "./authentication/AdminLogin";
import ProviderLogin from "./authentication/ProviderLogin";
import PatientLogin from "./authentication/PatientLogin";
import PatientOTP from "./authentication/PatientOTP";
import ForgotPassword from "./authentication/ForgotPassword";
import ResetCode from "./authentication/ResetCode";
import NewPassword from "./authentication/NewPassword";
import PasswordChanged from "./authentication/PasswordChanged";
import ChangePassword from "./authentication/ChangePassword";

import AdminRoute from "./App/AdminApp";
import ProviderRoute from "./App/ProviderApp";
import PatientRoute from "./App/PatientApp";

import PageNotFound from "./pages/404Page";

import PatientLanding from "./pages/Patient/PatientLanding";
import PatientAdd from "./pages/Patient/PatientAddU3";

import PaypalSuccess from "./pages/Paypal/PaypalSuccess";
import PaypalFailed from "./pages/Paypal/PaypalFailed";
import PaypalPending from "./pages/Paypal/PaypalPending";

import PaypalRedirect from "./pages/Paypal/PaypalRedirect";

import LeafletMap from "./pages/Patient/LeafLetMap";

// import myMap from "./pages/myMap";
// import GoogleMap from "./pages/GoogleMap";

import "bootstrap/dist/css/bootstrap.min.css";
// import "./index.css";

import moment from "moment-timezone";
// moment.tz.setDefault("Asia/Calcutta");
moment.tz.setDefault("America/Los_Angeles");

function App() {
    return (
        <React.StrictMode>
            {/* <Router basename="/2023/emstyle"> */}
            <Router>
                <Routes>
                    {/* <Route path="*" component={PageNotFound} /> */}
                    {/* <Route component={PageNotFound} /> */}
                    <Route path="/" element={<PatientLanding />} />
                    <Route path="/patient/" element={<PatientLanding />} />
                    <Route path="/PatientAdd/" element={<PatientAdd />} />
                    <Route path="/Patient/Login/" element={<PatientLogin />} />
                    <Route path="/Patient/PatientOTP/" element={<PatientOTP />} />

                    <Route path="/LeafletMap/" element={<LeafletMap />} />

                    <Route path="/PaypalSuccess/" element={<PaypalSuccess />} />
                    <Route path="/PaypalFailed/" element={<PaypalFailed />} />
                    <Route path="/PaypalPending/" element={<PaypalPending />} />
                    {/* <Route path="/patient/" element={<GoogleMap />} /> */}
                    {/* <Route path="/patient/" element={<AmericaMap />} /> */}
                    <Route path="/Admin/" element={<AdminLogin />} />
                    <Route path="/Admin/Login" element={<AdminLogin />} />
                    <Route path="/Provider/" element={<ProviderLogin />} />
                    <Route path="/Provider/Login" element={<ProviderLogin />} />
                    <Route path="/Provider/ChangePassword" element={<ChangePassword />} />
                    <Route path="/Provider/ForgotPassword" element={<ForgotPassword />} />
                    <Route path="/Provider/ResetCode" element={<ResetCode />} />
                    <Route path="/Provider/NewPassword" element={<NewPassword />} />
                    <Route path="/Provider/PasswordChanged" element={<PasswordChanged />} />

                    <Route path="/Admin/*" Component={AdminRoute} />
                    <Route path="/Provider/*" Component={ProviderRoute} />
                    <Route path="/PatientApp/*" Component={PatientRoute} />

                    <Route path="/PaypalRedirect" element={<PaypalRedirect />} />

                    {/* <Route path="/Sample" element={<Sample />} />
                    <Route path="/YesNoAlert" element={<YesNoAlert />} />
                    <Route path="/AssginProvider" element={<AssginProvider />} />
                    <Route path="/MuiGrid" element={<MuiGrid />} /> */}

                    {/* <Route path="/Tabview" element={<Tabview />} /> */}
                    {/* <Route path="/Provider/AddTutor" element={<AddTutor />} /> */}
                    {/* <Route path="/myMap" element={<myMap />} /> */}
                </Routes>
            </Router>
        </React.StrictMode>
    );
}

export default App;
