// import * as React from "react";
import React, { useState, useRef, useEffect } from "react";

import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid";

import axios from "axios";
import { security_key, BASE_URL } from "../global";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const convertToEmbedLink = (link) => {
    // Check if the link is already an embed link
    if (link.includes("vimeo.com/video") || link.includes("youtube.com/embed")) {
        return link; // Return the original link
    } else if (link.includes("vimeo.com")) {
        // Vimeo link format: https://vimeo.com/videoID
        const videoID = link.split("/").pop();
        return `https://player.vimeo.com/video/${videoID}`;
    } else if (link.includes("youtube.com")) {
        // YouTube link format: https://www.youtube.com/watch?v=videoID
        const videoID = link.split("v=").pop().split("&")[0];
        return `https://www.youtube.com/embed/${videoID}`;
    } else if (link.includes("youtu.be")) {
        // Shortened YouTube link format: https://youtu.be/videoID
        const videoID = link.split("/").pop();
        return `https://www.youtube.com/embed/${videoID}`;
    } else {
        // If not a Vimeo or YouTube link, return the original link
        return link;
    }
};

function EditToolbar(props) {
    // const { setRows, setRowModesModel } = props;

    const { rows, setRows, setRowModesModel } = props;

    const handleClick = () => {
        // const id = 1;
        const maxId = Math.max(...rows.map((row) => row.id)); // Find the maximum ID from existing rows
        const id = isNaN(maxId) || maxId === -Infinity ? 1 : maxId + 1; // Increment it by 1

        setRows((oldRows) => [...oldRows, { id, injection_site: "", injection_video_title: "", injection_volume: "", injection_video_link: "", isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "injection_site" },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add Injection Site
            </Button>
        </GridToolbarContainer>
    );
}

function ServiceCrudGrid({ pathoId }) {
    // alert(pathoId);

    const [loading, setLoading] = useState(true);

    const [rows, setRows] = React.useState([]);

    const location = useLocation();

    useEffect(() => {
        fetchData();
    }, [location]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getInjectionSiteByFk",
                patho_id: pathoId,
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return { ...row, id: row.pk_inj_id, index: index + 1 };
                });
                setRows(modifiedOutput);
                // console.log(modifiedOutput);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => async () => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it",
            });

            if (result.isConfirmed) {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "deleteInjection",
                    inj_id: id,
                });

                const output = response.data;

                if (output === 1) {
                    await Swal.fire("Deleted!", "Injection site row has been deleted.", "success");
                    setRows(rows.filter((row) => row.id !== id));
                    fetchData();
                }
            }
        } catch (error) {
            // Handle any errors here
            console.error("An error occurred: ", error);
        }
    };

    const handleCancelClick = (id) => () => {
        // console.log(id);

        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    async function checkInjectionVideoLinkUniqueness(video_link, inj_id = 0) {
        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "checkInjectionVideoLinkUniqueness",
            patho_id: pathoId,
            inj_id: inj_id,
            video_link: video_link,
        });

        const output = response.data;

        return output;
    }

    const processRowUpdate = async (newRow) => {
        toast.dismiss();

        if (!newRow["injection_site"]) {
            toast.error("Injection Site is required.", {
                autoClose: 3000,
            });
            return;
        }
        // else if (!/^(ftp|http|https):\/\/[^ "]+$/.test(newRow["injection_site"])) {
        //     toast.error("Please enter a valid URL for Injection Site Link.", {
        //         autoClose: 3000,
        //     });
        //     return;
        // }
        // else if (newRow["injection_video_title"].trim() === "") {
        //     toast.error("Video Title is required.", {
        //         autoClose: 3000,
        //     });
        //     return;
        // } else if (!/^[a-zA-Z0-9. ]{2,250}$/.test(newRow["injection_video_title"])) {
        //     toast.error("Video Title must be 2 to 250 characters long and can only contain letters, numbers, dots, and spaces.", {
        //         autoClose: 3000,
        //     });
        //     return;
        // }
        /*
        else if (newRow["injection_volume"] == 0) {
            toast.error("Injection volume must be greater than zero.", {
                autoClose: 3000,
            });
            return;
        } 
        */
        else if (!newRow["injection_volume"]) {
            toast.error("Injection volume is required.", {
                autoClose: 3000,
            });
            return;
        }
        // else if (parseInt(newRow["injection_volume"]) <= 0) {
        //     toast.error("Please enter a positive value for injection volume.", {
        //         autoClose: 3000,
        //     });
        //     return;
        // }
        else if (newRow["injection_video_link"].trim() === "") {
            toast.error("Injection video link is required.", {
                autoClose: 3000,
            });
            return;
        } else if (!/^https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*$/.test(newRow["injection_video_link"])) {
            toast.error("Video link be a valid URL.", {
                autoClose: 3000,
            });
            return;
        } else if (!/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com|player\.vimeo\.com)/.test(newRow["injection_video_link"])) {
            toast.error("Video link must be a valid link from YouTube or Vimeo.", {
                autoClose: 3000,
            });
            return;
        }
        // else if (!/^https?:\/\/(?:www\.)?(youtube\.com\/embed\/|player\.vimeo\.com\/video\/)[^\s/$.?#].[^\s]*$/.test(newRow["injection_video_link"])) {
        //     toast.error("Video link must be a valid embedded video link from YouTube or Vimeo.", {
        //         autoClose: 3000,
        //     });
        //     return;
        // }
        else if (
            newRow.hasOwnProperty("isNew") &&
            newRow["isNew"] === true &&
            (await checkInjectionVideoLinkUniqueness(newRow["injection_video_link"])) == false
        ) {
            toast.error("Injection video link is already existing in the database.", {
                autoClose: 3000,
            });
            return;
        } else if (!newRow.hasOwnProperty("isNew") && (await checkInjectionVideoLinkUniqueness(newRow["injection_video_link"], newRow["id"])) == false) {
            toast.error("Injection video link is already existing in the database.", {
                autoClose: 3000,
            });
            return;
        }

        // console.log(newRow["injection_site"]);

        try {
            if (newRow.hasOwnProperty("isNew") && newRow["isNew"] === true) {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "addInjection",
                    patho_id: pathoId,
                    injection_site: newRow["injection_site"],
                    injection_volume: newRow["injection_volume"],
                    injection_video_title: newRow["injection_video_title"],
                    injection_video_link: convertToEmbedLink(newRow["injection_video_link"]),
                });

                const output = response.data;

                if (output && output > 0) {
                    toast.success("Added successfully!", {
                        autoClose: 3000,
                    });
                    const updatedRow = { ...newRow, isNew: false, pk_service_id: parseInt(output) };
                    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
                    fetchData();
                    return updatedRow;
                } else {
                    // Handle the case when the response is not as expected
                    throw new Error("Invalid output value after adding Injection");
                }
            } else {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "updateInjection",
                    inj_id: newRow["id"],
                    injection_site: newRow["injection_site"],
                    injection_volume: newRow["injection_volume"],
                    injection_video_title: newRow["injection_video_title"],
                    injection_video_link: convertToEmbedLink(newRow["injection_video_link"]),
                });

                const output = response.data;

                if (output && output > 0) {
                    toast.success("Updated successfully!", {
                        autoClose: 3000,
                    });
                    const updatedRow = { ...newRow, isNew: false };
                    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
                    return updatedRow;
                    fetchData();
                } else {
                    // Handle the case when the response is not as expected
                    throw new Error("Invalid output value after updating Injection");
                }
            }
        } catch (error) {
            console.error("Error occurred during row update:", error);
            // Handle the error as needed, e.g., show an error message or perform other actions
        }
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        {
            headerName: "#ID",
            field: "index",
            headerClassName: "super-app-theme--header",
            width: 50,
        },
        {
            headerName: "Site",
            field: "injection_site",
            headerClassName: "super-app-theme--header",
            flex: 1,
            editable: true,
            type: "text",
            headerAlign: "left",
            align: "left",
            // renderCell: (params) => (
            //     <a href={params.value} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
            //         {params.value}
            //     </a>
            // ),
        },
        {
            headerName: "Volume",
            field: "injection_volume",
            headerClassName: "super-app-theme--header",
            width: 150,
            editable: true,
            type: "text",
            headerAlign: "left",
            align: "left",
        },
        // {
        //     headerName: "Title",
        //     field: "injection_video_title",
        //     headerClassName: "super-app-theme--header",
        //     width: 150,
        //     editable: true,
        //     type: "text",
        //     headerAlign: "left",
        //     align: "left",
        // },

        {
            headerName: "Video",
            field: "injection_video_link",
            headerClassName: "super-app-theme--header",
            flex: 1,
            editable: true,
            type: "url",
            headerAlign: "left",
            align: "left",
            renderCell: (params) => (
                <a href={params.value} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                    {params.value}
                </a>
            ),
        },

        {
            headerName: "Actions",
            headerClassName: "super-app-theme--header actions",
            field: "actions",
            type: "actions",
            // width: 100,
            flex: 1,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: "primary.main",
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
                    ];
                }

                return [
                    <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
                ];
            },
        },
    ];

    return (
        <>
            <ToastContainer position="top-center" />

            <Box
                sx={{
                    height: "fit-content",
                    width: "100%",
                    "& .actions": {
                        color: "text.secondary",
                        outline: "none !important",
                    },
                    "& .textPrimary": {
                        color: "text.primary",
                    },
                    "& .super-app-theme--header": {
                        backgroundColor: "#e3e6ee",
                    },
                }}
                style={{ height: "max-content", width: "100%" }}
            >
                <DataGrid
                    autoHeight
                    sx={{ border: "none", m: 2, "--DataGrid-overlayHeight": "600px" }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    slotProps={{
                        toolbar: { rows, setRows, setRowModesModel },
                    }}
                />
            </Box>
        </>
    );
}

export default ServiceCrudGrid;
