import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { security_key, BASE_URL } from "../../global";
import axios from "axios";
import Loader from "../../components/Loader";

function PaypalSuccess() {
    const navigate = useNavigate();
    const location = useLocation();

    const [amount, setAmount] = useState(0);

    const [loading, setLoading] = useState(true);

    const getUrlParameter = (parameterName) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(parameterName);
    };

    const assignId = getUrlParameter("assignId");

    const payerId = getUrlParameter("PayerID");

    useEffect(() => {
        fetchData();
        setInterval(fetchData, 3000);
    }, [payerId]);

    const fetchData = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getPayAmount",
                assign_id: assignId,
                payer_id: payerId,
            });

            const output = response.data;

            if (output["amount"]) {
                setAmount(output["amount"]);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <>
            <div className="row justify-content-center success-card mt-5 mr-0">
                <div className="col-10 col-lg-6 my-auto">
                    {amount == 0 ? (
                        <div style={{ height: "100vh" }}>
                            <Loader />
                        </div>
                    ) : (
                        <div className="card text-center">
                            <div className="card-body">
                                <img src="images/emcyte/success.png" alt="Success" width="240px" />
                                <h2 className="pay-success">{parseInt(amount)}$</h2>
                                <p>Thank you for supporting with Emstyle.</p>
                                <h1>Your payment has been successful!</h1>
                                {/* <p className="my-3">
                                    <strong>
                                        Please contact{" "}
                                        <a href="tel:18007555775" class="link-underline-light">
                                            1800 755 5775
                                        </a>{" "}
                                    </strong>
                                    for further instructions.
                                </p> */}
                                <button onClick={() => navigate("/PatientApp/Profile")} className="btn btn-success my-4 text-white link">
                                    Back to Login
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default PaypalSuccess;
