import React, { useEffect, useState } from "react";

// import { Route, HashRouter as Router, Routes, Redirect, useHistory, Navigate } from "react-router-dom";
import { Route, HashRouter as Router, Routes, Switch, useNavigate } from "react-router-dom";
// import { Route, BrowserRouter as Router, Routes, Switch, useNavigate } from "react-router-dom";

// import "./AdminApp.css";
// import "./../index2.css";
// import "bootstrap/dist/css/bootstrap.min.css";

/* Group of Components */
import AdminHeader from "./../components/AdminHeader";
import Search from "./../components/Search";
import AdminSidebar from "./../components/AdminSidebar";

import PageNotFound from "./../pages/404Page";
import AdminProfile from "./../pages/Admin/AdminProfile";
import AdminSettings from "./../pages/Admin/AdminSettings";
import AdminDashboard from "./../pages/Admin/AdminDashboard";

/*Patient Module*/
import PatientAdd from "../pages/Admin/PatientAdd";
import PatientList from "../pages/Admin/PatientList";
import PatientView from "../pages/Admin/PatientView";
import PatientEdit from "../pages/Admin/PatientEdit";
import PatientAssign from "../pages/Admin/PatientAssign";
import PatientSelect from "../pages/Admin/PatientSelect";
import ProviderSelect from "../pages/Admin/ProviderSelect";
// import AssginProvider from "../pages/AssginProvider";

/*Provider Module*/
import ProviderAdd from "../pages/Admin/ProviderAdd";
import ProviderList from "../pages/Admin/ProviderList";
import ProviderView from "../pages/Admin/ProviderView";

/*Pathology Module*/
import ClinicalAdd from "../pages/Admin/ClinicalAdd";
import ClinicalList from "../pages/Admin/ClinicalList";
import ClinicalView from "../pages/Admin/ClinicalView";

/*Email Template Module*/
import EmailAdd from "../pages/Admin/EmailAdd";
import EmailList from "../pages/Admin/EmailList";
import EmailEdit from "../pages/Admin/EmailEdit";

function AdminRoute() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const UserType = sessionStorage.getItem("user_type");

    useEffect(() => {
        if (UserType !== "1") {
            navigate("/Admin/Login");
        }
    }, [navigate, UserType]);

    if (UserType == 1) {
        return (
            <div className={`app align-content-stretch d-flex flex-wrap ${isOpen ? "sidebar-hidden" : ""}`}>
                <AdminSidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

                <div className="app-container">
                    <Search />
                    <AdminHeader isOpen={isOpen} toggleSidebar={toggleSidebar} />

                    <Routes>
                        <Route path="/*" element={<PageNotFound />} />

                        <Route path="/Dashboard" element={<AdminDashboard />} />
                        <Route path="/Profile" element={<AdminProfile />} />
                        <Route path="/Settings" element={<AdminSettings />} />

                        <Route path="/PatientAdd" element={<PatientAdd />} />
                        <Route path="/PatientList" element={<PatientList />} />
                        <Route path="/PatientView" element={<PatientView />} />
                        <Route path="/PatientEdit" element={<PatientEdit />} />
                        <Route path="/PatientAssign" element={<PatientAssign />} />
                        <Route path="/PatientSelect" element={<PatientSelect />} />
                        <Route path="/ProviderSelect" element={<ProviderSelect />} />

                        {/* <Route path="/AssginProvider" element={<AssginProvider />} /> */}
                        <Route path="/ProviderAdd" element={<ProviderAdd />} />
                        <Route path="/ProviderList" element={<ProviderList />} />
                        <Route path="/ProviderView" element={<ProviderView />} />

                        <Route path="/ClinicalAdd" element={<ClinicalAdd />} />
                        <Route path="/ClinicalList" element={<ClinicalList />} />
                        <Route path="/ClinicalView" element={<ClinicalView />} />

                        <Route path="/EmailAdd" element={<EmailAdd />} />
                        <Route path="/EmailList" element={<EmailList />} />
                        <Route path="/EmailEdit" element={<EmailEdit />} />
                    </Routes>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default AdminRoute;
