import React, { useState } from "react";
import "./Sidebar.css";
import Image from "react-bootstrap/Image";

import { useLocation, NavLink, useNavigate } from "react-router-dom";

function AppSidebar({ isOpen, toggleSidebar }) {
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path ? "active-page" : "";
    };
    return (
        <div className={`app-sidebar ${isOpen ? "open" : ""}`}>
            <div className={`logo text-center ${isOpen ? "hidden-sidebar-logo" : ""}`}>
                {/* <img className="img-fluid" width={130} src="images/logo-light.png" alt="" /> */}
                <Image src="../images/logo-light.png" width={150} className="img-fluid mx-auto d-block" />
            </div>
            <button className="close-btn" onClick={toggleSidebar}>
                    &times;
                </button>
            <div className="app-menu">
                <ul className="accordion-menu">
                    <li className="sidebar-title">Menu</li>
                    <li className={isActive("/Admin/Dashboard")}>
                        <NavLink to="/Admin/Dashboard">
                            <i className="fa-solid fa-gauge" />
                            Dashboard
                        </NavLink>
                    </li>
                    <li
                        className={
                            isActive("/Admin/PatientAdd") ||
                            isActive("/Admin/PatientList") ||
                            isActive("/Admin/PatientEdit") ||
                            isActive("/Admin/PatientView") ||
                            isActive("/Admin/ProviderSelect")
                        }
                    >
                        <NavLink to="/Admin/PatientList">
                            <i className="fa-solid fa-user-group" />
                            Patients
                        </NavLink>
                    </li>
                    <li className={isActive("/Admin/ProviderAdd") || isActive("/Admin/ProviderList") || isActive("/Admin/ProviderView")}>
                        <NavLink to="/Admin/ProviderList">
                            <i className="fa-solid fa-window-restore" /> Providers
                        </NavLink>
                    </li>
                    <li className={isActive("/Admin/PatientAssign") || isActive("/Admin/PatientSelect")}>
                        <NavLink to="/Admin/PatientAssign">
                            <i className="fa-solid fa-person-circle-plus" /> Assign
                        </NavLink>
                    </li>
                    <li className={isActive("/Admin/ClinicalList") || isActive("/Admin/ClinicalAdd") || isActive("/Admin/ClinicalView")}>
                        <NavLink to="/Admin/ClinicalList">
                            <i className="fa-solid fa-house-chimney-medical" /> Clinical
                        </NavLink>
                    </li>
                    {/* <li className={isActive("/Admin/EmailList") || isActive("/Admin/EmailAdd") || isActive("/Admin/EmailEdit")}>
                        <NavLink to="/Admin/EmailList">
                            <i class="fa-solid fa-envelope"></i> Email Template
                        </NavLink>
                    </li> */}

                    <li className={isActive("/Admin/Settings")}>
                        <NavLink to="/Admin/Settings">
                            <i className="fa-solid fa-gear" /> Settings
                        </NavLink>
                    </li>
                    <li className={isActive("/Admin/Profile")}>
                        <NavLink to="/Admin/Profile">
                            <i className="fa-solid fa-user" /> Profile
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AppSidebar;
