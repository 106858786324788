import React, { useState, useCallback, useEffect, useRef } from "react";
import axios from "axios";
import { security_key, BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Row, Col } from "react-bootstrap";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import LinearProgress from "@mui/material/LinearProgress";

const basicSchema = Yup.object().shape({
    map_link: Yup.string().url("Invalid URL format.").required("Map URL is required."),
    height: Yup.number().positive("Height must be a positive number.").integer("Height must be an integer.").required("Map height is required."),
    width: Yup.number().positive("Width must be a positive number.").integer("Width must be an integer.").required("Map width is required."),
    max_zoom: Yup.number().min(0, "Max zoom must be at least 0.").required("Max zoom is required."),
    min_zoom: Yup.number().min(0, "Min zoom must be at least 0.").required("Min zoom is required."),
    scroll_wheel_zoom: Yup.number().oneOf([0, 1], "Scroll wheel zoom must be either 0 or 1.").required("Scroll wheel zoom is required."),
    theme_link: Yup.string()
        .matches(/^(https?:\/\/)?([\w-]+(\.[\w-]+)+|localhost)(:\d+)?(\/.*)?(\?.*)?(\{.*\})?$/, "Invalid URL format.")
        // .url("Invalid URL format.")
        .required("Map theme URL is required."),
});

const MapSetting = () => {
    const formRef = useRef(null);

    const [loading, setLoading] = useState(true);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    const [mapData, setMapData] = useState([]);

    const [mapId, setMapId] = useState(1);

    useEffect(() => {
        fetchData();
    }, [mapId]);

    const fetchData = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getMapCredential",
                map_id: mapId,
            });

            const output = response.data;

            if (output) {
                setMapData(output);
                setLoading(false);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const initialValues = {
        map_id: mapData && mapData.pk_map_id ? mapData.pk_map_id : "",
        map_link: mapData && mapData.map_link ? mapData.map_link : "",
        height: mapData && mapData.height ? mapData.height : "",
        width: mapData && mapData.width ? mapData.width : "",
        max_zoom: mapData && mapData.max_zoom ? mapData.max_zoom : "",
        min_zoom: mapData && mapData.min_zoom ? mapData.min_zoom : "",
        scroll_wheel_zoom: mapData && mapData.scroll_wheel_zoom ? mapData.scroll_wheel_zoom : 0,
        theme_link: mapData && mapData.theme_link ? mapData.theme_link : "",
    };

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        onSubmit: async (formValues) => {
            setIsSubmitting(true);

            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "updateMapCrdential",
                ...formValues,
            });
            const output = response.data;
            if (output == 1) {
                toast.success("Updated successfully!", {
                    autoClose: 3000,
                });
                fetchData();
                setIsEdit(false);
            } else {
                toast.error("Oops! Something went wrong.", {
                    autoClose: 3000,
                });
            }

            setTimeout(function () {
                setIsSubmitting(false);
            }, 3000);
        },
    });

    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;

    const landingPageResetMapData = {
        pk_map_id: 1,
        map_link: "https://emstylemsk.net/",
        height: 350,
        width: 100,
        max_zoom: 9,
        min_zoom: 3,
        scroll_wheel_zoom: 1,
        theme_link: "https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}",
    };

    const iframePageResetMapData = {
        pk_map_id: 2,
        map_link: "https://emstylemsk.net/LeafletMap",
        height: 500,
        width: 100,
        max_zoom: 9,
        min_zoom: 3,
        scroll_wheel_zoom: 1,
        theme_link: "https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}",
    };

    const handleLandingReset = () => {
        setMapData(landingPageResetMapData);
    };

    const handleIframeReset = () => {
        setMapData(iframePageResetMapData);
    };

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="d-flex justify-content-end align-items-center my-4">
                <div>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch defaultChecked />}
                            label={mapId === 1 ? "Landing" : "Iframe"}
                            onChange={(e) => {
                                const value = e.target.checked ? 1 : 2;
                                setMapId(value);
                            }}
                        />
                    </FormGroup>
                </div>
                <div>
                    {isEdit ? (
                        <Button variant="contained" size="small" onClick={() => setIsEdit(false)}>
                            View
                        </Button>
                    ) : (
                        <Button variant="contained" size="small" onClick={() => setIsEdit(true)}>
                            Edit
                        </Button>
                    )}
                </div>
            </div>
            <hr />

            {isEdit ? (
                <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map URL</span>
                        <div className="w-50">
                            <Form.Control type="url" name="map_link" value={values.map_link} onChange={handleChange} onBlur={handleBlur} />
                            {errors.map_link && touched.map_link && <p className="error">{errors.map_link}</p>}
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map height(px)</span>
                        <div className="w-50">
                            <Form.Control type="number" name="height" value={values.height} onChange={handleChange} onBlur={handleBlur} />
                            {errors.height && touched.height && <p className="error">{errors.height}</p>}
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map width(%)</span>
                        <div className="w-50">
                            <Form.Control type="number" name="width" value={values.width} onChange={handleChange} onBlur={handleBlur} />
                            {errors.width && touched.width && <p className="error">{errors.width}</p>}
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map max zoom</span>
                        <div className="w-50">
                            <Form.Control type="number" name="max_zoom" value={values.max_zoom} onChange={handleChange} onBlur={handleBlur} />
                            {errors.max_zoom && touched.max_zoom && <p className="error">{errors.max_zoom}</p>}
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map min zoom</span>
                        <div className="w-50">
                            <Form.Control type="number" name="min_zoom" value={values.min_zoom} onChange={handleChange} onBlur={handleBlur} />
                            {errors.min_zoom && touched.min_zoom && <p className="error">{errors.min_zoom}</p>}
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Scroll wheel zoom</span>
                        <div>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={values.scroll_wheel_zoom === 1}
                                            onChange={(e) => {
                                                const value = e.target.checked ? 1 : 0;
                                                handleChange({
                                                    target: {
                                                        name: "scroll_wheel_zoom",
                                                        value: value,
                                                    },
                                                });
                                            }}
                                            onBlur={handleBlur}
                                        />
                                    }
                                    label={values.scroll_wheel_zoom === 1 ? "ON" : "OFF"}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map theme URL</span>
                        <div className="w-50">
                            <Form.Control type="url" name="theme_link" value={values.theme_link} onChange={handleChange} onBlur={handleBlur} />
                            {errors.theme_link && touched.theme_link && <p className="error">{errors.theme_link}</p>}
                        </div>
                    </div>
                    <hr />

                    <div className="d-flex justify-content-end align-items-center my-4">
                        {mapId == 1 ? (
                            <Button variant="contained" onClick={() => handleLandingReset()} className="me-2">
                                Recommended
                            </Button>
                        ) : (
                            <Button variant="contained" onClick={() => handleIframeReset()} className="me-2">
                                Recommended
                            </Button>
                        )}
                        <Button variant="contained" type="submit" className="my-4" disabled={isSubmitting}>
                            <span className="px-4">Save</span>
                            {isSubmitting ? <LinearProgress /> : ""}
                        </Button>
                    </div>
                </Form>
            ) : (
                <>
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map URL</span>
                        <div>
                            <a href={mapData.map_link && mapData.map_link} target="_blank">
                                {mapData.map_link && mapData.map_link}
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map height(px)</span>
                        <div>{mapData.height && mapData.height + "px"}</div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map width(%)</span>
                        <div>{mapData.width && mapData.width + "%"}</div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map max zoom</span>
                        <div>{mapData.max_zoom && mapData.max_zoom}</div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Map min zoom</span>
                        <div>{mapData.min_zoom && mapData.min_zoom}</div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>Scroll wheel zoom</span>
                        <div>{mapData.scroll_wheel_zoom && mapData.scroll_wheel_zoom == 1 ? "ON" : "OFF"}</div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <span>
                            Map theme URL{" "}
                            <a href="https://leaflet-extras.github.io/leaflet-providers/preview/" target="_blank">
                                View sample themes
                            </a>
                        </span>
                        <div>
                            {mapData.theme_link && mapData.theme_link}
                            {/* <a href={mapData.theme_link && mapData.theme_link} target="_blank">
                            </a> */}
                        </div>
                    </div>
                    <hr />
                </>
            )}
        </>
    );
};

export default MapSetting;
