import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useHistory, NavLink } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useFormik } from "formik";
import * as Yup from "yup";

import Select from "react-select";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";

import { security_key, BASE_URL, options_city } from "../../global";
import "./AdminProfile.css";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";

import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import Moment from "moment";
import "moment-timezone";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

// const currentDate = new Date();
// const dbFormattedDateTime = currentDate.toISOString().slice(0, 19).replace("T", " ");

const readInputImg = (event, id) => {
    const input = event.target;
    const reader = new FileReader();

    reader.onload = function () {
        const imgElement = document.querySelector(id);
        if (imgElement) {
            imgElement.src = reader.result;
        }
    };

    if (input.files && input.files[0]) {
        reader.readAsDataURL(input.files[0]);
    }
};

// const FILE_SIZE_LIMIT = 1048576; // 1MB in bytes
// const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "image/gif"]; // Example supported image formats

const basicSchema = Yup.object().shape({
    // adm_name: Yup.string().min(3, "Minimum 3 characters required").required("Admin Name is required").strict(),
    // adm_image: Yup.mixed().required("Admin Image is required"),
    // .test("fileSize", "File is too large", (value) => value && value.size <= FILE_SIZE_LIMIT)
    // .test("fileFormat", "Unsupported file format", (value) => value && SUPPORTED_FORMATS.includes(value.type)),
    // adm_email: Yup.string().email("Invalid email").required("Admin Email is required").strict(),
    adm_dir_password: Yup.string()
        .required("Admin Password is required.")
        .matches(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,20}$/,
            "Password must contain at least one special character, one number, and be between 8 and 20 characters."
        )
        .strict(),
});

const Profile = () => {
    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(false);

    const [loading, setLoading] = useState(true);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);
    const [adminData, setAdminData] = useState();

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    //Get key value start
    const location = useLocation();
    useEffect(() => {
        fetchData();
    }, [location]);

    const fetchData = async () => {
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getAdmin",
                adm_id: sessionStorage.getItem("loger_id"),
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output) {
                setAdminData(output);
                setLoading(false);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const initialValues = {
        /*tbl_patient_information*/
        adm_id: adminData && adminData.pk_adm_auth_id ? adminData.pk_adm_auth_id : "",
        adm_name: adminData && adminData.adm_name ? adminData.adm_name : "",
        adm_image: adminData && adminData.adm_image ? adminData.adm_image : "",
        adm_email: adminData && adminData.adm_email ? adminData.adm_email : "",
        adm_dir_password: adminData && adminData.adm_dir_password ? adminData.adm_dir_password : "",
        updated_by: sessionStorage.getItem("loger_id"),
        updated_on: dbFormattedDateTime,
        visibility: "1",
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };

            fileReader.readAsDataURL(file);
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        onSubmit: async (formValues) => {
            toast.dismiss();

            // alert(123);
            setIsSubmitting(true);

            let base64Image = formValues["adm_image"];
            let fileExtension = null;
            let isImgUpdate = false;

            if (typeof formValues["adm_image"] === "object") {
                base64Image = await convertBase64(formValues["adm_image"]);
                let fileName = formValues["adm_image"]["name"];
                fileExtension = fileName.split(".").pop();
                isImgUpdate = true;
            }
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "updateAdmin",
                ...formValues,
                adm_image: base64Image,
                file_extension: fileExtension,
                img_update: isImgUpdate,
            });
            const output = response.data;
            if (output == 1) {
                fetchData();
                sessionStorage.setItem("image", `${values.adm_image}`);
                toast.success("Updated successful!", {
                    autoClose: 3000,
                });
                setTimeout(function () {
                    setIsEdit(false);
                }, 3000);
            } else {
                toast.error("Oops! Something went wrong.", {
                    autoClose: 3000,
                });
            }

            setTimeout(function () {
                setIsSubmitting(false);
            }, 3000);
        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;
    const setFieldValue = formik.setFieldValue;

    /****MUI Nav Tab****/

    const [value, setValue] = React.useState("1");

    // const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    //     setValue(newValue);
    // };
    const handleTabChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <ToastContainer position="top-center" />
            <div className="app-content" id="admin_profile">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="section-header d-flex align-items-center justify-content-between">
                            <div className="section-heading">
                                <h4 className="mb-1">Profile</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a role="button" onClick={() => navigate("/Admin/dashboard")}>
                                                Home
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Profile
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            {/* <div className="section-essentials d-flex align-items-center justify-content-between gap-2">
                                <a role="button" className="btn btn-light btn-md" onClick={() => navigate("/Admin/ListPatient")}>
                                    Back
                                </a>
                            </div> */}
                        </div>
                        <Card className="my-card px-3 py-3 bg-white">
                            <Card.Body className="card-padding-res">
                                <div className="container">
                                    <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                        <div className="row align-items-start justify-content-end">
                                            <div className="col-md-2 text-end">
                                                {isEdit ? (
                                                    <input
                                                        type="button"
                                                        className="profile-edit-btn w-100"
                                                        name="btnAddMore"
                                                        value="View Profile"
                                                        onClick={() => {
                                                            setIsEdit(false);
                                                            resetForm();
                                                        }}
                                                    />
                                                ) : (
                                                    <input
                                                        type="button"
                                                        className="profile-edit-btn w-100"
                                                        name="btnAddMore"
                                                        value="Edit Profile"
                                                        onClick={() => setIsEdit(true)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {isEdit ? (
                                                    <Box sx={{ width: "100%", typography: "body1" }}>
                                                        <TabContext value={value} className="p-0">
                                                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                                    <Tab label="About" value="1" />
                                                                </TabList>
                                                                {loading && <LinearProgress />}
                                                            </Box>
                                                            <TabPanel value="1" className="p-0">
                                                                <div className="d-flex justify-content-left mt-3">
                                                                    <div className="me-5 ">
                                                                        <h6 className="text-secondary">Profile</h6>
                                                                        <div style={{ position: "relative" }} className="border rounded">
                                                                            <img
                                                                                style={{ width: "150px" }}
                                                                                className="img-fluid rounded"
                                                                                // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"
                                                                                src={`https://emstylemsk.net/API/upload/image/${values.adm_image}`}
                                                                                id="user_img_output"
                                                                            />
                                                                            <label
                                                                                for="adm_image"
                                                                                style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
                                                                            >
                                                                                <EditIcon className="text-primary" />
                                                                            </label>

                                                                            <Form.Control
                                                                                type="file"
                                                                                id="adm_image"
                                                                                style={{ display: "none" }}
                                                                                name="adm_image"
                                                                                accept="image/*"
                                                                                onChange={(event) => {
                                                                                    setFieldValue("adm_image", event.currentTarget.files[0]);
                                                                                    readInputImg(event, "#user_img_output");
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                className={errors.adm_image && touched.adm_image ? "input-error" : ""}
                                                                            />
                                                                            {errors.adm_image && touched.adm_image && (
                                                                                <p className="error">{errors.adm_image}</p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Group controlId="adm_name" className="row align-items-center mb-3">
                                                                            <h6 className="text-secondary">Name</h6>
                                                                            <Col>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    id="adm_name"
                                                                                    className={`form-control-sm ${
                                                                                        errors.adm_name && touched.adm_name ? "input-error" : ""
                                                                                    }`}
                                                                                    value={values.adm_name}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                />

                                                                                {errors.adm_name && touched.adm_name && (
                                                                                    <p className="error">{errors.adm_name}</p>
                                                                                )}
                                                                            </Col>
                                                                        </Form.Group>

                                                                        <Form.Group controlId="adm_email" className="row align-items-center mb-3">
                                                                            <h6 className="text-secondary">Email</h6>
                                                                            <Col>
                                                                                <Form.Control
                                                                                    type="email"
                                                                                    value={values.adm_email}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.adm_email && touched.adm_email ? "input-error" : ""}
                                                                                />

                                                                                {errors.adm_email && touched.adm_email && (
                                                                                    <p className="error">{errors.adm_email}</p>
                                                                                )}
                                                                            </Col>
                                                                        </Form.Group>

                                                                        <Form.Group controlId="adm_dir_password" className="mb-3">
                                                                            <h6 className="text-secondary">Password</h6>

                                                                            <div style={{ position: "relative" }}>
                                                                                <Form.Control
                                                                                    type={showPassword ? "text" : "password"}
                                                                                    value={values.adm_dir_password}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={
                                                                                        errors.adm_dir_password && touched.adm_dir_password ? "input-error" : ""
                                                                                    }
                                                                                />

                                                                                {errors.adm_dir_password && touched.adm_dir_password && (
                                                                                    <p className="error">{errors.adm_dir_password}</p>
                                                                                )}

                                                                                <span style={{ position: "absolute", top: "10px", right: "10px" }}>
                                                                                    <a role="button" onClick={toggleShowPassword}>
                                                                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                                    </a>
                                                                                </span>
                                                                            </div>
                                                                        </Form.Group>

                                                                        <Button variant="primary" type="submit" className="my-3" disabled={isSubmitting}>
                                                                            <span className="px-4">Save</span>
                                                                            {isSubmitting ? <LinearProgress /> : ""}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                        </TabContext>
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ width: "100%", typography: "body1" }}>
                                                        <TabContext value={value}>
                                                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                                    <Tab className="p-0" label="About" value="1" />
                                                                </TabList>
                                                                {loading && <LinearProgress />}
                                                            </Box>
                                                            <TabPanel value="1" className="p-0">
                                                                {!loading && (
                                                                    <div className="d-flex justify-content-left mt-3">
                                                                        <div className="pe-5">
                                                                            <h6 className="text-secondary">Profile</h6>
                                                                            <img
                                                                                className="img-fluid rounded"
                                                                                style={{ width: "150px" }}
                                                                                src={`https://emstylemsk.net/API/upload/image/${values.adm_image}`}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <div className="mb-4">
                                                                                <h6 className="text-secondary">Name</h6>
                                                                                <p>{values.adm_name}</p>
                                                                            </div>
                                                                            <div className="mb-4">
                                                                                <h6 className="text-secondary">Email</h6>
                                                                                <p>{values.adm_email}</p>
                                                                            </div>
                                                                            <div className="mb-4">
                                                                                <h6 className="text-secondary">Password</h6>

                                                                                <div className="d-flex align-items-center">
                                                                                    <p className="me-4 mb-0">
                                                                                        {showPassword
                                                                                            ? values.adm_dir_password
                                                                                            : "*".repeat(values.adm_dir_password.length)}
                                                                                    </p>
                                                                                    <a role="button" onClick={toggleShowPassword}>
                                                                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </TabPanel>
                                                        </TabContext>
                                                    </Box>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-4">
                                                <div className="profile-work">
                                                    <p>WORK LINK</p>
                                                    <a href="">Website Link</a>
                                                    <br />
                                                    <a href="">Bootsnipp Profile</a>
                                                    <br />
                                                    <a href="">Bootply Profile</a>
                                                    <p>SKILLS</p>
                                                    <a href="">Web Designer</a>
                                                    <br />
                                                    <a href="">Web Developer</a>
                                                    <br />
                                                    <a href="">WordPress</a>
                                                    <br />
                                                    <a href="">WooCommerce</a>
                                                    <br />
                                                    <a href="">PHP, .Net</a>
                                                    <br />
                                                </div>
                                            </div> */}
                                            <div className="col-md-8 offset-md-4"></div>
                                        </div>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
