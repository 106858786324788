import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { security_key, BASE_URL } from "../global";
import { useState, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Image from "react-bootstrap/Image";

import logo from "./../assets/images/backgrounds/Admin.png";

const security_key = "86b432bb2871216fca5739395a276391";
const BASE_URL = "https://emstylemsk.net/";

const basicSchema = yup.object().shape({
    email: yup.string().required("Email ID is required"),
    password: yup.string().required("Password is required"),
});

const ShowToast = () => {
    toast.success("You have successfully logged into your account.", {
        autoClose: 1000,
    });
};

function LoginAdmin() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const [isActive, setIsActive] = useState(true);
    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
        setValues({
            ...initialValues, // Reset select fields
        });
    };

    const initialValues = {
        email: "",
        password: "",
    };

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
        initialValues,
        validationSchema: basicSchema,
        onSubmit: async (formValues) => {
            setIsSubmitting(true);
            toast.dismiss();

            try {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "checkAdmin",
                    ...formValues,
                });

                const output = response.data;

                if (output.length > 0) {
                    resetFormFields(resetForm, setValues, initialValues);

                    console.log(output[0]["adm_name"]);

                    if (output[0]["pk_adm_auth_id"] !== "") {
                        sessionStorage.setItem("loger_id", output[0]["pk_adm_auth_id"]);
                        sessionStorage.setItem("name", output[0]["adm_name"]);
                        sessionStorage.setItem("image", output[0]["adm_image"]);
                        sessionStorage.setItem("email", output[0]["adm_email"]);
                        sessionStorage.setItem("user_type", 1);
                        sessionStorage.setItem("theme", output[0]["theme"]);
                        sessionStorage.setItem("landing_page", output[0]["landing_page"]);

                        ShowToast();

                        setTimeout(function () {
                            document.body.className = `body-container ${output[0]["theme"]}`;
                            navigate(`/Admin/${output[0]["landing_page"]}`);
                        }, 1000);
                    } else {
                        toast.error("Something went wrong.", {
                            autoClose: 1000,
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }
                } else {
                    toast.error("Invalid email or password.", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Invalid API. Please Contact EMSTYLE Team", {
                    autoClose: 1000,
                });
                setIsSubmitting(false); // Re-enable the submit button
            } finally {
                setIsSubmitting(false); // Re-enable the submit button
            }
        },
    });

    return (
        <>
            <ToastContainer position="top-center" />
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end" style={{ background: "#e7ebf8" }}>
                    <div className="app-auth-background admin-login"></div>
                    <div className="app-auth-container">
                        <div className="log text-center mb-5">
                            <a role="button" style={{ display: "inline-block" }} onClick={() => navigate("/Patient")}>
                                {/* <Image src={BASE_URL + "images/logo-dark.png"} width={150} className="img-fluid d-block" /> */}
                                <Image src={logo} width={250} className="img-fluid d-block" />
                            </a>
                        </div>

                        <div className="auth-credentials m-b-xxl">
                            <label htmlFor="signInEmail" className="form-label">
                                Email Address
                            </label>
                            {/* <input type="email" className="form-control m-b-md" id="signInEmail" aria-describedby="signInEmail" placeholder="example@gmail.com" /> */}
                            <Form.Control
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="email"
                                className={errors.email && touched.email ? "input-error" : ""}
                                placeholder="example@gmail.com"
                            />
                            {errors.email && touched.email && <p className="error">{errors.email}</p>}
                            <label htmlFor="signInPassword" className="form-label mt-3">
                                Password
                            </label>
                            <div style={{ position: "relative" }}>
                                <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="password"
                                    className={errors.password && touched.password ? "input-error" : ""}
                                    placeholder="●●●●●●●●"
                                />
                                {errors.password && touched.password && <p className="error">{errors.password}</p>}

                                <span style={{ position: "absolute", top: "10px", right: "10px" }}>
                                    <a role="button" onClick={toggleShowPassword}>
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="auth-submit">
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                Sign In
                            </button>
                        </div>
                        <div className="divider" />
                        {/* <div className="auth-alts">
                            <a href="#" className="auth-alts-google" />
                            <a href="#" className="auth-alts-facebook" />
                            <a href="#" className="auth-alts-twitter" />
                        </div> */}
                    </div>
                </div>
            </Form>
        </>
    );
}

export default LoginAdmin;
