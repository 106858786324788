// import * as React from "react";
import React, { useState, useRef, useEffect } from "react";

import { useLocation } from "react-router-dom";

import { Box, ButtonGroup, Button, TablePagination } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import axios from "axios";
import { security_key, BASE_URL, options_city } from "../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Card, ListGroup } from "react-bootstrap";
import CustomNoRowsOverlay from "../components/NoRow";

function ServiceCrudGrid({ pathoId }) {
    // alert(pathoId);

    const location = useLocation();

    const [loading, setLoading] = useState(true);

    const [rows, setRows] = React.useState([]);

    const [view, setView] = useState(1);

    /****Grid Pagination functional****/

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    useEffect(() => {
        fetchData();
    }, [location]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getInjectionSiteByFk",
                patho_id: pathoId,
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return { ...row, id: row.pk_inj_id, index: index + 1 };
                });
                setRows(modifiedOutput);
                // console.log(modifiedOutput);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const columns = [
        {
            headerName: "#ID",
            field: "index",
            headerClassName: "super-app-theme--header",
            width: 50,
        },
        {
            headerName: "Site",
            field: "injection_site",
            headerClassName: "super-app-theme--header",
            flex: 1,
            headerAlign: "left",
            align: "left",
            // renderCell: (params) => (
            //     <a href={params.value} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
            //         {params.value}
            //     </a>
            // ),
        },
        {
            headerName: "Volume",
            field: "injection_volume",
            headerClassName: "super-app-theme--header",
            width: 150,
            headerAlign: "left",
            align: "left",
        },
        // {
        //     headerName: "Title",
        //     field: "injection_video_title",
        //     headerClassName: "super-app-theme--header",
        //     width: 150,
        //     headerAlign: "left",
        //     align: "left",
        // },
        // {
        //     headerName: "Video",
        //     field: "injection_video_link",
        //     headerClassName: "super-app-theme--header",
        //     flex: 1,
        //     headerAlign: "left",
        //     align: "left",
        //     renderCell: (params) => (
        //         <a href={params.value} target="_blank" rel="noopener noreferrer">
        //             {params.value}
        //         </a>
        //     ),
        // },
        {
            headerName: "Video",
            field: "injection_video_link",
            headerClassName: "super-app-theme--header",
            flex: 1,
            headerAlign: "left",
            align: "left",
            // height: 300,
            renderCell: (params) => {
                return (
                    <div style={{ height: "auto", overflow: "auto" }}>
                        <iframe
                            width="320"
                            height="180"
                            src={params.value}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                            // style={{ width: "100%", height: "300px" }}
                        ></iframe>
                    </div>
                );
            },
            // renderCell: (params) => {
            //     return (
            //         <iframe
            //             // width="320"
            //             // height="180"
            //             src="https://www.youtube.com/embed/60h6lpnSgck?si=jciGTlXpwD8Qwfbv"
            //             title="YouTube video player"
            //             frameborder="0"
            //             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            //             referrerpolicy="strict-origin-when-cross-origin"
            //             allowfullscreen
            //             style={{ width: "100%", height: "300px" }}
            //         ></iframe>
            //     );
            // },
        },
    ];

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="d-flex align-items-center m-3">
                <ButtonGroup aria-label="outlined primary button group">
                    <Button size="small" variant={view === 0 ? "contained" : "outlined"} onClick={() => setView(0)}>
                        <i class="material-icons icon-16pt me-2" style={{ fontSize: "22px" }}>
                            dehaze
                        </i>
                        List View
                    </Button>
                    <Button size="small" variant={view === 1 ? "contained" : "outlined"} onClick={() => setView(1)}>
                        <i className="material-icons icon-16pt me-2" style={{ fontSize: "22px" }}>
                            view_module
                        </i>
                        Grid View
                    </Button>
                </ButtonGroup>
            </div>

            {view === 0 ? (
                <Box
                    sx={{
                        height: "fit-content",
                        width: "100%",
                        overflowX: "scroll",
                        "& .actions": {
                            color: "text.secondary",
                        },
                        "& .textPrimary": {
                            color: "text.primary",
                        },
                        "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                        },
                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                            {
                                outline: "none !important",
                            },
                    }}
                    style={{ height: "max-content", width: "100%" }}
                >
                    <DataGrid
                        autoHeight
                        getRowHeight={() => "auto"}
                        slots={{
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        sx={{ border: "none", m: 2, "--DataGrid-overlayHeight": "600px" }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        loading={loading}
                        rows={rows}
                        columns={columns}
                    />
                </Box>
            ) : (
                <Box>
                    <Row className="mt-3 g-2">
                        {rows.length == 0 ? <CustomNoRowsOverlay /> : ""}
                        {/* {rows.map((row, index) => ( */}
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <Col sm={4} key={index} role="button" style={{ aspectRatio: 3 / 2 }}>
                                <div className="p-3 border rounded" style={{ background: "#f2f2f2" }}>
                                    <div className="mb-3">
                                        {/* <h6>
                                        <PersonIcon className="me-2" />
                                        Patient
                                    </h6> */}

                                        <iframe
                                            width="100%"
                                            src={row.injection_video_link}
                                            title={`Video`}
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerpolicy="strict-origin-when-cross-origin"
                                            allowfullscreen
                                            className="mb-2"
                                        />

                                        <div className="d-flex">
                                            <h6 className="text-secondary me-2">Injection Site:</h6>
                                            <span>{row["injection_site"]}</span>
                                        </div>
                                        <div className="d-flex">
                                            <h6 className="text-secondary me-2">Injection Volume:</h6>
                                            <span>{row["injection_volume"]}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <TablePagination
                        rowsPerPageOptions={[12, 24, 60]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            )}
        </>
    );
}

export default ServiceCrudGrid;
