import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import "./AddForm.css";

import { Form, Row, Col, Stack } from "react-bootstrap";
import { Button, LinearProgress } from "@mui/material";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import EditorToolbar, { modules, formats } from "./../components/EditorToolbar";
import { Config } from "../../components/EditorConfig";

import "jodit";
// import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";

import * as Yup from "yup";
import { useFormik } from "formik";

import axios from "axios";
import { security_key, BASE_URL } from "../../global";
// import { config } from "@fortawesome/fontawesome-svg-core";

const validationSchema = Yup.object().shape({
    mail_title: Yup.string()
        .required("Mail Title is required")
        .matches(/^[a-zA-Z0-9. ]*$/, "Mail Title can only contain letters, numbers, dots and spaces.")
        .min(2, "Mail Title must be at least 2 characters.")
        .max(250, "Mail Title must be at most 250 characters."),
    mail_subject: Yup.string()
        .required("Mail Subject is required")
        .matches(/^[a-zA-Z0-9. ]*$/, "Mail Subject can only contain letters, numbers, dots and spaces.")
        .min(2, "Mail Subject must be at least 2 characters.")
        .max(250, "Mail Subject must be at most 250 characters."),
    mail_content: Yup.string().required("Mail Content is required").min(3, "Mail Content must be at least 3 characters."),
    mail_footer: Yup.string().required("Mail Footer is required").min(3, "Mail Footer must be at least 3 characters."),
    mail_remarks: Yup.string()
        .required("Mail Description is required")
        .min(3, "Mail Description must be at least 3 characters.")
        .max(250, "Mail Description must be at most 250 characters."),
});

function EmailTemplateForm() {
    const navigate = useNavigate();
    const formRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const location = useLocation();
    const { mail_id } = location.state;
    const [emailData, setEmailData] = useState();

    const editor = useRef(null);

    useEffect(() => {
        if (mail_id) {
            fetchData(mail_id);
        }
    }, [location]);

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getEmailTemplate",
                mail_id: id,
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output) {
                setEmailData(output);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const initialValues = {
        mail_title: emailData && emailData.mail_title ? emailData.mail_title : "",
        mail_subject: emailData && emailData.mail_subject ? emailData.mail_subject : "",
        mail_content: emailData && emailData.mail_content ? emailData.mail_content : "",
        mail_footer: emailData && emailData.mail_footer ? emailData.mail_footer : "",
        mail_remarks: emailData && emailData.mail_remarks ? emailData.mail_remarks : "",
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (formValues) => {
            toast.dismiss();
            setIsSubmitting(true);

            try {
                toast.dismiss();
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "updateEmailTemplate",
                    ...formValues,
                    mail_id: mail_id,
                });
                const output = response.data;
                if (output == 1) {
                    setIsSubmitting(false);
                    toast.success("Updated successful!", {
                        autoClose: 3000,
                    });
                    setTimeout(function () {
                        navigate("/Admin/EmailList");
                    }, 3000);
                } else {
                    toast.error("Oops! Something went wrong.", {
                        autoClose: 3000,
                    });
                }
            } catch (error) {
                console.error("API call error:", error);
                toast.error("Oops! Something went wrong.", {
                    autoClose: 3000,
                });
            } finally {
            }
        },
    });

    // Render your form components here

    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;
    const setFieldValue = formik.setFieldValue;
    const injections = values.injections || [];

    // useEffect(() => {
    //     if (!formik.isSubmitting) return;

    //     let firstErrorField = Object.keys(formik.errors)[0];

    //     console.log(formik.errors.injections);

    //     if (firstErrorField == "injections") {
    //         let proError = formik.errors.injections;

    //         if (proError) {
    //             for (const [index, row] of Object.entries(proError)) {
    //                 if (row) {
    //                     firstErrorField = Object.keys(row)[0] + `${index}`;
    //                     console.log(row);
    //                     break;
    //                 }
    //             }
    //         }
    //     }

    //     if (firstErrorField) {
    //         const errorFieldElement = document.getElementById(firstErrorField);

    //         if (errorFieldElement) {
    //             const elementRect = errorFieldElement.getBoundingClientRect();
    //             const absoluteElementTop = elementRect.top + window.scrollY;
    //             const middleOfElement = absoluteElementTop - window.innerHeight / 2;

    //             window.scrollTo({
    //                 top: middleOfElement,
    //                 behavior: "smooth",
    //             });
    //         } else {
    //             console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
    //         }
    //     }
    // }, [formik.errors, formik.isSubmitting]);

    return (
        <div className="app-content">
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="section-header d-flex align-items-center justify-content-between">
                        <div className="section-heading">
                            <h4 className="mb-1">Edit Email Template</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                            Home
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a role="button" onClick={() => navigate("/Admin/EmailList")}>
                                            Email
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Edit Email Template
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="section-essentials d-flex align-items-center justify-content-between gap-2">
                            <Button className="btn-light btn-md" onClick={() => navigate("/Admin/EmailList")}>
                                Back
                            </Button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" />

                    <Row>
                        <Col md={12} className="mx-auto">
                            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                <Stack>
                                    <div className="my-card p-4 bg-white">
                                        <div className="section-heading border-bottom pb-3 mb-3">
                                            <h6 className="mb-0">Email Template</h6>
                                        </div>
                                        <Row className="mb-3">
                                            <Col md={6}>
                                                <Form.Group controlId="mail_title">
                                                    <Form.Label>
                                                        Mail Title<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="mail_title"
                                                        value={values.mail_title}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.mail_title && touched.mail_title ? "input-error" : ""}
                                                    />
                                                    {errors.mail_title && touched.mail_title && <p className="error">{errors.mail_title}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="mail_subject">
                                                    <Form.Label>
                                                        Mail Subject<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="mail_subject"
                                                        value={values.mail_subject}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.mail_subject && touched.mail_subject ? "input-error" : ""}
                                                    />
                                                    {errors.mail_subject && touched.mail_subject && <p className="error">{errors.mail_subject}</p>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Group controlId="mail_content">
                                                    <Form.Label>
                                                        Mail Content<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <JoditEditor
                                                        ref={editor}
                                                        // value={values.mail_content}
                                                        // onBlur={(value) => {
                                                        //     handleBlur({
                                                        //         target: {
                                                        //             name: "mail_content",
                                                        //             value: value,
                                                        //             type: "change",
                                                        //         },
                                                        //     });
                                                        // }}
                                                        config={Config}
                                                        value={formik.values.mail_content}
                                                        onChange={(value) => {
                                                            formik.setFieldValue("mail_content", value);
                                                        }}
                                                        onBlur={(value) => {
                                                            // if (value == "<p><br></p>") {
                                                            //     value = "";
                                                            // }
                                                            formik.setFieldValue("mail_content", value);
                                                        }}
                                                    />

                                                    {/* <EditorToolbar /> */}
                                                    {/* <ReactQuill
                                                        theme="snow"
                                                        value={formik.values.mail_content}
                                                        onChange={(value, delta, source, editor) => {
                                                            // alert(JSON.stringify(delta));
                                                            // if (!delta["ops"][0]["retain"]) {
                                                            //     formik.setFieldValue("mail_content", "");
                                                            // } else {
                                                            // }
                                                            formik.setFieldValue("mail_content", value);
                                                        }}
                                                        onBlur={(e) => {
                                                            if (e.index == 0) {
                                                                formik.setFieldValue("mail_content", "");
                                                            } else {
                                                                formik.setFieldTouched("mail_content", true);
                                                            }
                                                        }}
                                                        style={{ height: "400px", overflow: "auto" }}
                                                        modules={modules}
                                                        formats={formats}
                                                    /> */}
                                                    {formik.errors.mail_content && formik.touched.mail_content && (
                                                        <p className="error">{formik.errors.mail_content}</p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            {/* <Col md={6}>
                                                <Form.Group controlId="mail_footer">
                                                    <Form.Label>
                                                        Mail Footer<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <ReactQuill
                                                        theme="snow"
                                                        value={formik.values.mail_footer}
                                                        onChange={(value, delta, source, editor) => {
                                                            formik.setFieldValue("mail_footer", value);
                                                        }}
                                                        onBlur={(e) => {
                                                            if (e.index == 0) {
                                                                formik.setFieldValue("mail_footer", "");
                                                            } else {
                                                                formik.setFieldTouched("mail_footer", true);
                                                            }
                                                        }}
                                                        style={{ height: "200px", overflow: "auto" }}
                                                    />
                                                    {errors.mail_footer && touched.mail_footer && <p className="error">{errors.mail_footer}</p>}
                                                </Form.Group>
                                            </Col> */}
                                            <Col md={6}>
                                                <Form.Group controlId="mail_remarks">
                                                    <Form.Label>
                                                        Description <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        style={{ height: "100px" }}
                                                        rows={3}
                                                        name="mail_remarks"
                                                        value={values.mail_remarks}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.mail_remarks && touched.mail_remarks && <p className="error">{errors.mail_remarks}</p>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                </Stack>

                                <Stack>
                                    <Row className="my-4">
                                        <Col sm={12} className="text-end">
                                            <Button variant="contained" type="submit" disabled={isSubmitting}>
                                                <span className="px-4">Submit</span>
                                                {isSubmitting ? <LinearProgress /> : ""}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Stack>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default EmailTemplateForm;
