import React, { useEffect, useState } from "react";

import { Route, HashRouter as Router, Routes, Switch, useNavigate } from "react-router-dom";

/* Group of Components */
import PatientHeader from "./../components/PatientHeader";
import Search from "./../components/Search";
import PatientSidebar from "./../components/PatientSidebar";
import PageNotFound from "./../pages/404Page";

import Profile from "../pages/Patient/PatientProfile";
import Notification from "../pages/Patient/PatientNotification";
import Dashboard from "../pages/Patient/PatientDasboard";

function PatientRoute() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const UserType = sessionStorage.getItem("user_type");

    useEffect(() => {
        if (UserType !== "3") {
            navigate("/Patient/Login");
        }
    }, [navigate, UserType]);

    if (UserType == 3) {
        return (
            <div className={`app align-content-stretch d-flex flex-wrap ${isOpen ? "sidebar-hidden" : ""}`}>
                <PatientSidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

                <div className="app-container">
                    <Search />
                    <PatientHeader isOpen={isOpen} toggleSidebar={toggleSidebar} />

                    <Routes>
                        <Route path="/*" element={<PageNotFound />} />

                        <Route path="/Dashboard" element={<Dashboard />} />
                        <Route path="/Notification" element={<Notification />} />
                        <Route path="/Profile" element={<Profile />} />
                        {/* <Route path="/Profile" element={<AdminProfile />} />
                        <Route path="/Settings" element={<AdminSettings />} /> */}

                        {/* <Route path="/ProviderList" element={<ProviderList />} /> */}
                    </Routes>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default PatientRoute;
