import React, { useState, useRef } from "react";
import "./ForgotPassword.css";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import axios from "axios";
import { security_key, BASE_URL } from "../global";
import LinearProgress from "@mui/material/LinearProgress";
import Image from "react-bootstrap/Image";
import logo from "./../assets/images/backgrounds/Provider.png";

const validationSchema = Yup.object().shape({
    provider_password: Yup.string()
        .required("Password is required")
        .matches(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,20}$/,
            "Password must contain at least one special character, one number, and be between 8 and 20 characters"
        )
        .strict(),
    // provider_cpassword: Yup.string().required("Confirm Password is required").strict(),
    provider_cpassword: Yup.string()
        .oneOf([Yup.ref("provider_password"), null], "Password and confirm password not matching")
        .required("Confirm password is required"),
});

function ForgotPassword() {
    const navigate = useNavigate();

    const formRef = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const initialValues = {
        provider_password: "",
        provider_cpassword: "",
    };

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (formValues) => {
            toast.dismiss();
            setIsSubmitting(true);

            try {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "changePassword",
                    provider_email: sessionStorage.getItem("email"),
                    ...formValues,
                });

                const output = response.data;

                // console.log("Response Data:", output);

                if (output == 1) {
                    navigate("/Provider/PasswordChanged");
                } else {
                    toast.error("Oops! Something went wrong.", {
                        autoClose: 3000,
                    });
                }
                setIsSubmitting(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Invalid API. Please Contact Emstyle Team", {
                    autoClose: 1000,
                });
            }
        },
    });

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="forgot_wrap">
                <div className="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end" style={{ background: "#e7ebf8" }}>
                    <div className="app-auth-background provider-login"></div>
                    <div className="app-auth-container">
                        <div className="logo-dark mb-3 text-center">
                            <a role="button" style={{ display: "inline-block" }} onClick={() => navigate("/Patient")}>
                                {/* <Image src="../images/logo-dark.png" width={150} className="img-fluid d-block" /> */}
                                <Image src={logo} width={170} className="img-fluid d-block" />
                            </a>
                        </div>

                        <div className="auth-credentials card border-0 shadow-lg p-5 m-b-xxl">
                            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                <h4 className="text-center mb-4">New Password</h4>

                                <div className="single-form">
                                    <Form.Label>
                                        Enter New Password<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="provider_password"
                                        value={values.provider_password}
                                        // placeholder="Enter new password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.provider_password && touched.provider_password ? "input-error" : ""}
                                    />
                                    {errors.provider_password && touched.provider_password && <p className="error">{errors.provider_password}</p>}
                                </div>

                                <div className="single-form mt-3">
                                    <Form.Label>
                                        Confirm New Password<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="provider_cpassword"
                                        value={values.provider_cpassword}
                                        // placeholder="Confirm your password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.provider_cpassword && touched.provider_cpassword ? "input-error" : ""}
                                    />
                                    {errors.provider_cpassword && touched.provider_cpassword && <p className="error">{errors.provider_cpassword}</p>}
                                </div>

                                <div className="single-form mt-3">
                                    {/* <button type="submit" className="btn btn-primary w-100">
                                        Change
                                    </button> */}
                                    <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                        Change
                                        {isSubmitting ? <LinearProgress /> : ""}
                                    </button>
                                </div>
                            </Form>
                        </div>
                        {/* <div className="auth-alts">
                            <a href="#" className="auth-alts-google" />
                            <a href="#" className="auth-alts-facebook" />
                            <a href="#" className="auth-alts-twitter" />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
