// import * as React from "react";
import React, { useState, useRef, useEffect } from "react";

import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, gridClasses } from "@mui/x-data-grid";

import axios from "axios";
import { security_key, BASE_URL, options_city } from "../global";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";

function ServiceCrudGrid({ providerId }) {
    // alert(providerId);

    const [loading, setLoading] = useState(true);

    const [rows, setRows] = React.useState([]);

    const location = useLocation();

    useEffect(() => {
        fetchData();
    }, [location]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getServiceByFk",
                provider_id: providerId,
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return { ...row, id: row.pk_service_id };
                });
                setRows(modifiedOutput);
                // console.log(modifiedOutput);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const columns = [
        {
            headerName: "Service Name",
            field: "service_name",
            headerClassName: "super-app-theme--header",
            flex: 1,
        },
        {
            headerName: "Service Cost",
            field: "service_cost",
            headerClassName: "super-app-theme--header",
            flex: 1,
            renderCell: (cellValues) => {
                return `${parseInt(cellValues.row.service_cost)}$`;
            },
        },
    ];

    return (
        <>
            <ToastContainer position="top-center" />

            <Box
                sx={{
                    height: "fit-content",
                    width: "100%",
                    "& .actions": {
                        color: "text.secondary",
                    },
                    "& .textPrimary": {
                        color: "text.primary",
                    },
                    "& .super-app-theme--header": {
                        backgroundColor: "#e3e6ee",
                    },

                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                        outline: "none",
                    },
                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                        outline: "none",
                    },
                }}
                style={{ height: rows.length == 0 ? 400 : "fit-content", width: "100%" }}
            >
                <DataGrid
                    sx={{ border: "none", m: 2 }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    loading={loading}
                    rows={rows}
                    columns={columns}
                />
            </Box>
        </>
    );
}

export default ServiceCrudGrid;
