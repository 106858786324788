/*
Purpose: list details of student with use datatable
Developer: BY PKS
Date:26-OCT-2023 
Project: EMSTYLE
*/

// import { useState, useCallback, useEffect } from "react";
import React, { useState, useCallback, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { Dialog, Box, TabContext, TabList, Tab, TabPanel } from '@mui/material';

import axios from "axios";
import { security_key, BASE_URL, options_patient } from "../../global";

import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Swal from "sweetalert2";

import CustomNoRowsOverlay from "../../components/NoRow";

const ClinicalList = () => {
    const navigate = useNavigate();

    const handleRowClick = (row) => {
        navigate("/Admin/ClinicalView", { state: { patho_id: row.id } });
    };

    /****Clinical List Functional****/

    const [loading, setLoading] = useState(true);

    const [rows, setRows] = React.useState([]);

    const [records, setRecords] = useState([]);

    const [search, setSearch] = useState("");

    const [boxHeight, setBoxHeight] = useState(600);

    // const [limit, setLimit] = useState(10);

    // const [length, setLength] = useState(10);

    // const [start, setStart] = useState(10);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const handlePageChange = (params) => {
        const { pageSize, page, sortModel } = params;
    };

    useEffect(() => {
        // document.title = "Your Custom Title";
        fetchData();
    }, [search]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getPathology",
                search: search,
            });
            const output = response.data;

            // console.log("Response Data:", response.data);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return {
                        ...row,
                        id: row.pk_patho_id,
                        // provider_name: `${row.provider_first_name} ${row.provider_last_name}`,
                        // image_url: `https://emstylemsk.net/API/upload/image/${row.provider_image}`,
                        index: index + 1,
                        // mobile_number: formatPhoneNumber(row.scheduling_phone),
                    };
                });

                setRows(modifiedOutput);
                setRecords(modifiedOutput);
                setLoading(false);
            } else {
                setRows([]);
                setRecords([]);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    /****Delete Clinical row Functional****/

    const deleteRow = async (id) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it",
            });

            if (result.isConfirmed) {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "deletePathology",
                    patho_id: id,
                });

                const output = response.data;

                if (output === 1) {
                    await Swal.fire("Deleted!", "The clinical record has been successfully removed.", "success");
                    fetchData();
                }
            }
        } catch (error) {
            // Handle any errors here
            console.error("An error occurred: ", error);
        }
    };

    const columns = [
        {
            headerName: "#ID",
            field: "index",
            headerClassName: "super-app-theme--header",
            width: 50,
        },

        {
            headerName: "Title",
            field: "title_of_pathology",
            headerClassName: "super-app-theme--header",
            minWidth: 250,
            flex: 1,
        },
        {
            headerName: "Description",
            field: "description_of_pathology",
            headerClassName: "super-app-theme--header",
            minWidth: 350,
            flex: 1,
        },
        {
            headerName: "Grade of Pathology",
            field: "grade_of_injury",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        // {
        //     headerName: "Rehabilitation",
        //     field: "rehabilitation",
        //     headerClassName: "super-app-theme--header",
        //     flex: 1,
        // },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            headerClassName: "super-app-theme--header",
            minWidth: 50,
            cellClassName: "actions",
            getActions: ({ id, row }) => {
                return [
                    <div>
                        <Tooltip title="Delete">
                            <GridActionsCellItem icon={<DeleteIcon />} label="Edit" className="textPrimary" onClick={() => deleteRow(id)} color="inherit" />
                        </Tooltip>
                    </div>,
                ];
            },
        },

        // {
        //     field: "actions",
        //     type: "actions",
        //     headerName: "Actions",
        //     headerClassName: "super-app-theme--header",
        //     width: 150,
        //     cellClassName: "actions",
        //     getActions: ({ id, row }) => {
        //         return [
        //             <div>
        //                 <Tooltip title="View Details">
        //                     <GridActionsCellItem
        //                         icon={<VisibilityIcon />}
        //                         label="View"
        //                         className="textPrimary"
        //                         onClick={() => navigate("/Admin/ClinicalView", { state: { patho_id: id } })}
        //                         color="inherit"
        //                     />
        //                 </Tooltip>
        //             </div>,
        //         ];
        //     },
        // },
    ];

    /*
    const handleFilterModelChange = (model) => {
        if (model && model.items && model.items.length > 0) {
            const filteredRowsCount = rows.filter((row) => {
                return model.items.every((filterItem) => {
                    const { field, operator, value } = filterItem;
                    if (operator === "contains") {
                        return row[field].toString().includes(value.toString());
                    }
                    return true;
                });
            }).length;

            setBoxHeight(filteredRowsCount === 0 ? 600 : "max-content");
        } else {
            setBoxHeight(600);
        }
    };
    */

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="app-content">
                <div className="content-wrapper">
                    <div className="container-fluid g-0">
                        <Row className="gx-0 gx-md-4">
                            <Col xs="12">
                                <Row className=" g-0 gx-md-4  justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="section-header d-flex align-items-center justify-content-between">
                                            <div className="section-heading">
                                                <h4 className="mb-1">Clinical</h4>
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                                                Home
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            Clinical
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                            <Button variant="contained" style={{ width: "140px" }} onClick={() => navigate("/Admin/ClinicalAdd")}>
                                                Add Clinical
                                            </Button>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 mb-3">
                                            <input
                                                type="search"
                                                className="form-control m-0"
                                                placeholder="Search"
                                                style={{ height: "39px", width: "200px" }}
                                                onChange={handleSearch}
                                            />
                                        </div>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <Card className="my-card p-1 bg-white">
                                            <Card.Body className="card-padding-res">
                                                <Box
                                                    sx={{
                                                        height: "fit-content",
                                                        width: "100%",
                                                        "& .actions": {
                                                            color: "text.secondary",
                                                        },
                                                        "& .textPrimary": {
                                                            color: "text.primary",
                                                        },
                                                        "& .super-app-theme--header": {
                                                            backgroundColor: "#e3e6ee",
                                                        },
                                                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                                            {
                                                                outline: "none !important",
                                                            },
                                                    }}
                                                    // style={{ height: rows.length == 0 ? 600 : "max-content", width: "100%" }}
                                                    // style={{ height: boxHeight, width: "100%" }}
                                                    style={{ height: "max-content", width: "100%" }}
                                                >
                                                    <DataGrid
                                                        autoHeight
                                                        sx={{ border: "none", mx: 2, "--DataGrid-overlayHeight": "600px" }}
                                                        initialState={{
                                                            pagination: { paginationModel: { pageSize: 10 } },
                                                        }}
                                                        pageSizeOptions={[10, 25, 50]}
                                                        loading={loading}
                                                        slots={{
                                                            loadingOverlay: LinearProgress,
                                                            noRowsOverlay: CustomNoRowsOverlay,
                                                            toolbar: GridToolbar,
                                                            // toolbar: EditToolbar,
                                                        }}
                                                        slotProps={{
                                                            toolbar: {
                                                                csvOptions: { fileName: "ClinicalList" },
                                                                printOptions: {
                                                                    disableToolbarButton: true,
                                                                    hideFooter: true,
                                                                    hideToolbar: true,
                                                                },
                                                                //printOptions: { fileName: "ClinicalList" },
                                                                rows,
                                                                setRows,
                                                            },
                                                        }}
                                                        // rows={[]}
                                                        rows={rows}
                                                        columns={columns}
                                                        // initialState={{ pinnedColumns: { right: ["actions"] } }}
                                                        rowHeight={80}
                                                        editMode="row"
                                                        onRowClick={(params) => handleRowClick(params.row)}
                                                        getRowClassName={() => "cursor-pointer"}
                                                        onPageChange={handlePageChange}
                                                        // onFilterModelChange={handleFilterModelChange}
                                                    />
                                                </Box>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClinicalList;
