/*
Purpose: list details of student with use datatable
Developer: BY PKS
Date:26-OCT-2023 
Project: EMSTYLE
*/

// import { useState, useCallback, useEffect } from "react";
import React, { useState, useCallback, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Select from "react-select";

import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { security_key, BASE_URL, options_patient } from "../../global";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import TablePagination from "@mui/material/TablePagination";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PersonIcon from "@mui/icons-material/Person";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";

import CustomNoRowsOverlay from "../../components/NoRow";
import Swal from "sweetalert2";

const formatPhoneNumber = (rawPhoneNumber) => {
    const phoneNumber = String(rawPhoneNumber);
    const countryCode = phoneNumber.substring(0, 1);
    const restOfNumber = phoneNumber.substring(1);

    // Format the phone number as +1 (XXX) XXX-XXXX
    const formattedPhoneNumber = `+${countryCode} (${restOfNumber.substring(0, 3)}) ${restOfNumber.substring(3, 6)}-${restOfNumber.substring(6)}`;

    return formattedPhoneNumber;
};

const options_field = [
    { value: "provider_first_name", label: "Provider First Name" },
    { value: "provider_last_name", label: "Provider Last Name" },
    { value: "provider_email", label: "Provider Email" },
    { value: "provider_mobile_number", label: "Provider Phone" },
    { value: "practice_name", label: "Practice Name" },
    { value: "practice_phone", label: "Practice Phone" },

    { value: "practice_city", label: "Practice City" },
    { value: "practice_state", label: "Practice State" },
    { value: "practice_zip", label: "Practice Zip" },
];

const ProviderList = () => {
    const navigate = useNavigate();

    const handleRowClick = (row, event = false) => {
        if (event && event.target.tagName === "A") {
            return;
        }
        navigate("/Admin/ProviderView", {
            state: {
                provider_id: row.pk_provider_id,
                practice_id: row.fk_practice_id,
            },
        });
    };

    const [tabNo, setTabNo] = React.useState(1);
    const handleTabNavClick = (value) => {
        setTabNo(value);
    };

    /****Grid Pagination functional****/

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    /****Delete provider row Functional****/

    const deleteRow = async (id, file_name) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it",
            });

            if (result.isConfirmed) {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "deleteProvider",
                    provider_id: id,
                    file_name: file_name,
                });

                const output = response.data;

                if (output === 1) {
                    await Swal.fire("Deleted!", "The provider record has been successfully removed.", "success");
                    fetchData();
                }
            }
        } catch (error) {
            // Handle any errors here
            console.error("An error occurred: ", error);
        }
    };

    /****Provider List Functional****/

    const [loading, setLoading] = useState(true);

    const [rows, setRows] = React.useState([]);

    const [records, setRecords] = useState([]);

    const [field, setField] = useState("");

    const [search, setSearch] = useState("");

    const [view, setView] = useState(0);

    useEffect(() => {
        fetchData();
    }, []);

    // useEffect(() => {
    //     fetchData();
    // }, [search]);

    const handleReset = () => {
        setField("");
        setSearch("");
        fetchData();
    };

    const handleSearch = () => {
        if (field == "") {
            toast.info("Please select a field!", {
                autoClose: 3000,
            });
        } else if (search == "") {
            toast.info("Please enter a search value!", {
                autoClose: 3000,
            });
        } else {
            fetchData(field, search);
        }
    };

    const fetchData = async (field = "", search = "") => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getAllProvider",
                field: field,
                search: search,
            });
            const output = response.data;

            // console.log("Response Data:", response.data);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return {
                        ...row,
                        id: row.pk_provider_id,
                        provider_name: `${row.provider_first_name} ${row.provider_last_name}`,
                        image_url: `https://emstylemsk.net/API/upload/image/${row.provider_image}`,
                        index: index + 1,
                        mobile_number1: formatPhoneNumber(row.provider_mobile_number),
                        mobile_number2: formatPhoneNumber(row.practice_phone),
                    };
                });

                setRows(modifiedOutput);
                setRecords(modifiedOutput);
                setLoading(false);
                // console.log(data);
            } else {
                setRows([]);
                setRecords([]);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [rowModesModel, setRowModesModel] = React.useState({});

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        // {
        //     headerName: "#ID",
        //     field: "index",
        //     headerClassName: "super-app-theme--header",
        //     minWidth: 50,
        //     // flex: 1,
        // },
        // {
        //     headerName: "Provider Name",
        //     field: "provider_name",
        //     headerClassName: "super-app-theme--header",
        //     flex: 1,
        //     renderCell: (params) => {
        //         const { provider_first_name, provider_last_name } = params.row;

        //         const fullName = `${provider_first_name} ${provider_last_name}`;

        //         return <span>{fullName}</span>;
        //     },
        // },
        // {
        //     headerName: "Provider Image",
        //     field: "image_url",
        //     headerClassName: "super-app-theme--header",
        //     flex: 1,
        //     // width: "100x",

        //     renderCell: (params) => {
        //         const imageUrl = "https://emstylemsk.net/API/upload/image/" + params.row.provider_image; // Assuming the URL of the image is stored in provider_image field

        //         return imageUrl ? (
        //             <img src={imageUrl} alt="Provider Image" style={{ width: "100px", height: "auto", aspectRatio: "1/1" }} />
        //         ) : (
        //             <span>No Image Available</span>
        //         );
        //     },
        // },

        {
            headerName: "Provider Title",
            field: "provider_title",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider First Name",
            field: "provider_first_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider Last Name",
            field: "provider_last_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider Email",
            field: "provider_email",
            headerClassName: "super-app-theme--header",
            minWidth: 210,
            flex: 1,
        },
        {
            headerName: "Provider Phone",
            field: "mobile_number1",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider Specialty",
            field: "provider_specialty",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider Guidance",
            field: "provider_guidance",
            headerClassName: "super-app-theme--header",
            minWidth: 200,
            flex: 1,
        },
        {
            headerName: "Provider Services",
            field: "provider_services",
            headerClassName: "super-app-theme--header",
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                var services = params.value.split(", ");

                var html = "";

                services.forEach((service) => {
                    html += `${service}<br/>`; // Newline for separating services, or use <br> for line breaks
                });

                return <div style={{ height: "auto", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: html }}></div>;
            },
        },

        {
            headerName: "Practice Name",
            field: "practice_name",
            headerClassName: "super-app-theme--header",
            minWidth: 120,
            flex: 1,
        },
        {
            headerName: "Practice Phone",
            field: "mobile_number2",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },

        {
            headerName: "Practice City",
            field: "practice_city",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Practice State",
            field: "practice_state",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Practice Address 1",
            field: "practice_address_1",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Practice Zip",
            field: "practice_zip",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "is verified",
            field: "verify_status",
            headerClassName: "super-app-theme--header",
            minWidth: 70,
            flex: 1,
            renderCell: (params) => {
                if (params.value == 1) {
                    return <CheckIcon style={{ color: "green" }} />;
                } else {
                    return <ClearIcon style={{ color: "red" }} />;
                }
            },
        },
        {
            headerName: "Actions",
            field: "actions",
            type: "actions",
            headerClassName: "super-app-theme--header",
            minWidth: 50,
            cellClassName: "actions",
            getActions: ({ id, row }) => {
                return [
                    <div>
                        {/* <Tooltip title="Assign Patient">
                            <GridActionsCellItem
                                icon={<PersonAddAlt1SharpIcon />}
                                label="View"
                                className="textPrimary"
                                onClick={() => navigate("/Admin/PatientAssign", { state: { provider_id: id } })}
                                color="inherit"
                            />
                        </Tooltip> */}
                        {/* <Tooltip title="View Details">
                            <GridActionsCellItem
                                icon={<VisibilityIcon />}
                                label="View"
                                className="textPrimary"
                                onClick={() => navigate("/Admin/ProviderView", { state: { provider_id: id } })}
                                color="inherit"
                            />
                        </Tooltip> */}
                        <Tooltip title="Delete">
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Edit"
                                className="textPrimary"
                                onClick={() => deleteRow(id, row.provider_image)}
                                color="inherit"
                            />
                        </Tooltip>
                    </div>,
                ];
            },
        },
    ];

    // const columns = [
    //     {
    //         name: "Provider Name",
    //         selector: (row) => row.provider_first_name + " " + row.provider_last_name,
    //         sortable: true,
    //     },
    //     {
    //         name: "Upload Provider Image",
    //         cell: (row) => <img src={"https://emstylemsk.net/API/upload/image/" + row.provider_image} alt="img" width={100} height={100} />,
    //     },
    //     {
    //         name: "Practice Name",
    //         selector: (row) => row.practice_name,
    //         sortable: true,
    //     },

    //     // {
    //     //     name: "Provider Last Name",
    //     //     selector: (row) => row.provider_last_name,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Provider Title",
    //     //     selector: (row) => row.provider_title,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Provider Specialty",
    //     //     selector: (row) => row.provider_specialty,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Provider Description of Expertise",
    //     //     selector: (row) => row.provider_description_of_expertise,
    //     //     sortable: true,
    //     // },
    //     {
    //         name: "Practice Address 1",
    //         selector: (row) => row.practice_address_1,
    //         sortable: true,
    //     },
    //     // {
    //     //     name: "Practice Address 2",
    //     //     selector: (row) => (row.practice_address_2 ? row.practice_address_2 : "N/A"),
    //     //     sortable: true,
    //     // },
    //     {
    //         name: "Practice City",
    //         selector: (row) => row.practice_city,
    //         sortable: true,
    //     },
    //     {
    //         name: "Practice State",
    //         selector: (row) => row.practice_state,
    //         sortable: true,
    //     },
    //     // {
    //     //     name: "Practice Zip",
    //     //     selector: (row) => row.practice_zip,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Practice Phone",
    //     //     selector: (row) => row.practice_phone,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Provider Mobile Number",
    //     //     selector: (row) => row.provider_mobile_number,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Scheduling Phone",
    //     //     selector: (row) => row.scheduling_phone,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Provider Email",
    //     //     selector: (row) => row.provider_email,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Scheduling Email",
    //     //     selector: (row) => row.scheduling_email,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Scheduling Contact First Name",
    //     //     selector: (row) => row.scheduling_contact_first_name,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Scheduling Contact Last Name",
    //     //     selector: (row) => row.scheduling_contact_last_name,
    //     //     sortable: true,
    //     // },
    //     // {
    //     //     name: "Hours of Operation",
    //     //     selector: (row) => row.hours_of_operation,
    //     //     sortable: true,
    //     // },
    //     {
    //         name: "Action",
    //         cell: (row) => (
    //             <>
    //                 <a
    //                     role="button"
    //                     onClick={() => {
    //                         handleOpen();
    //                         setAssignProvider(row.pk_provider_id);
    //                         setAssignProviderName(row.provider_first_name + " " + row.provider_last_name);
    //                     }}
    //                     className="btn btn-outline-primary btn-sm me-1"
    //                 >
    //                     Assign Patient
    //                 </a>
    //                 <a
    //                     role="button"
    //                     onClick={() => navigate("/Admin/ViewProvider", { state: { provider_id: row.pk_provider_id } })}
    //                     className="btn btn-primary btn-sm"
    //                 >
    //                     View
    //                 </a>
    //             </>
    //         ),
    //         width: "200px", // Set the desired width
    //     },
    // ];

    function handleFilter(event) {
        const inputValue = event.target.value.trim().toLowerCase();
        const newData = records.filter((row) => {
            return (
                row.pk_provider_id.toString().includes(inputValue) ||
                row.start_date.includes(inputValue) ||
                row.start_time.includes(inputValue) ||
                row.provider_image.toLowerCase().includes(inputValue) ||
                row.practice_name.toLowerCase().includes(inputValue) ||
                (row.provider_first_name + " " + row.provider_last_name).toLowerCase().includes(inputValue) ||
                row.provider_title.toLowerCase().includes(inputValue) ||
                row.provider_specialty.toLowerCase().includes(inputValue) ||
                row.provider_description_of_expertise.toLowerCase().includes(inputValue) ||
                row.practice_address_1.toLowerCase().includes(inputValue) ||
                row.practice_address_2.toLowerCase().includes(inputValue) ||
                row.practice_city.toLowerCase().includes(inputValue) ||
                row.practice_state.toLowerCase().includes(inputValue) ||
                row.practice_zip.toLowerCase().includes(inputValue) ||
                row.practice_phone.toLowerCase().includes(inputValue) ||
                row.provider_mobile_number.toLowerCase().includes(inputValue) ||
                row.scheduling_phone.toLowerCase().includes(inputValue) ||
                row.provider_email.toLowerCase().includes(inputValue) ||
                row.scheduling_email.toLowerCase().includes(inputValue) ||
                (row.scheduling_contact_first_name + " " + row.scheduling_contact_last_name).toLowerCase().includes(inputValue) ||
                row.hours_of_operation.toLowerCase().includes(inputValue)
            );
        });
        setRows(newData);
    }
    const customCsvFileName = "customFileName"; // Replace this with your desired custom file name

    const csvOptions = {
        fileName: customCsvFileName,
        delimiter: ";",
        utf8WithBom: true,
    };

    const modalStyle = {
        minWidth: "80%",
    };

    const themes = sessionStorage.getItem("theme");

    const commonStyles = {
        react_select: "",
        react_tel: "",
    };

    const darkThemeStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "#343541",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "white",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                    // color: "black",
                },
            }),
        },
        react_tel: "",
    };

    const colourStyles = themes == "dark" ? darkThemeStyles : commonStyles;

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="app-content">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Row className="gx-0 gx-md-4">
                            <Col xs="12">
                                <Row className="gx-0 gx-md-4 justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="section-header d-flex align-items-center justify-content-between">
                                            <div className="section-heading">
                                                <h4 className="mb-1">Providers</h4>
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                                                Home
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            Providers
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                            <div>
                                                <Button variant="contained" onClick={() => navigate("/Admin/ProviderAdd")} className="me-2">
                                                    Add Provider
                                                </Button>
                                                <Button variant="outlined" color="error" onClick={handleReset}>
                                                    Reset
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="section-essentials d-flex align-items-center mb-3 gap-2">
                                            {/* <div className="search-section position-relative">
                                                    <input
                                                        type="search"
                                                        placeholder="Search"
                                                        onChange={(e) => {
                                                            setSearch(e.target.value);
                                                            //handleFilter(e);
                                                        }}
                                                        onKeyUp={(e) => {
                                                            setSearch(e.target.value);
                                                        }}
                                                    />

                                                    <div className="icon">
                                                        <i className="fa-solid fa-magnifying-glass" />
                                                    </div>
                                                </div> */}

                                            <Select
                                                options={options_field}
                                                className="auto"
                                                value={
                                                    field &&
                                                    options_field.find((option) => {
                                                        return option.value && option.value.toString() == field.toString();
                                                    })
                                                }
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: "field",
                                                            value: selectedOption["value"],
                                                        },
                                                    };

                                                    setField(event.target.value);
                                                }}
                                            />

                                            <input
                                                type="search"
                                                className="form-control m-0 w-auto"
                                                placeholder="Search"
                                                style={{ height: "39px" }}
                                                value={search}
                                                onChange={(event) => {
                                                    setSearch(event.target.value);
                                                }}
                                            />

                                            <Button className="me-2" variant="contained" onClick={() => handleSearch()}>
                                                Search
                                            </Button>
                                        </div>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <Card className="my-card p-1 bg-white">
                                            <Card.Body className="card-padding-res">
                                                {/* {view == 0 ? (
                                                    <span
                                                        role="button"
                                                        style={{ width: "fit-content" }}
                                                        onClick={() => {
                                                            setView(1);
                                                        }}
                                                        className="d-flex align-items-center text-dark border border-4 fw-bold p-2 mb-0 fw-bold"
                                                    >
                                                        <i className="material-icons icon-16pt">view_module</i>
                                                        <span className="view">GRID VIEW</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        role="button"
                                                        style={{ width: "fit-content" }}
                                                        onClick={() => {
                                                            setView(0);
                                                        }}
                                                        class="d-flex align-items-center text-dark border border-4 fw-bold p-2 mb-0 fw-bold"
                                                    >
                                                        <i class="material-icons icon-16pt">dehaze</i>
                                                        <span class="view">List VIEW</span>
                                                    </span>
                                                )} */}

                                                <div className="d-flex align-items-center mx-3">
                                                    <ButtonGroup aria-label="outlined primary button group">
                                                        <Button size="small" variant={view === 0 ? "contained" : "outlined"} onClick={() => setView(0)}>
                                                            <i class="material-icons icon-16pt me-2" style={{ fontSize: "22px" }}>
                                                                dehaze
                                                            </i>
                                                            List View
                                                        </Button>
                                                        <Button size="small" variant={view === 1 ? "contained" : "outlined"} onClick={() => setView(1)}>
                                                            <i className="material-icons icon-16pt me-2" style={{ fontSize: "22px" }}>
                                                                view_module
                                                            </i>
                                                            Grid View
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>

                                                <Box
                                                    sx={{
                                                        height: "fit-content",
                                                        width: "100%",
                                                        "& .actions": {
                                                            color: "text.secondary",
                                                        },
                                                        "& .textPrimary": {
                                                            color: "text.primary",
                                                        },
                                                        "& .super-app-theme--header": {
                                                            backgroundColor: "#e3e6ee",
                                                        },
                                                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                                            {
                                                                outline: "none !important",
                                                            },
                                                    }}
                                                    // style={{ height: rows.length == 0 ? 600 : "fit-content", width: "100%" }}
                                                    style={{
                                                        height: "max-content",
                                                        width: "100%",
                                                        display: view === 1 ? "none" : "block",
                                                    }}
                                                >
                                                    <DataGrid
                                                        autoHeight
                                                        sx={{
                                                            border: "none",
                                                            m: 2,
                                                            "--DataGrid-overlayHeight": "600px",
                                                        }}
                                                        initialState={{
                                                            pagination: { paginationModel: { pageSize: 10 } },
                                                        }}
                                                        pageSizeOptions={[10, 25, 50]}
                                                        loading={loading}
                                                        slots={{
                                                            loadingOverlay: LinearProgress,
                                                            noRowsOverlay: CustomNoRowsOverlay,
                                                            toolbar: GridToolbar,
                                                            // toolbar: EditToolbar,
                                                        }}
                                                        slotProps={{
                                                            toolbar: {
                                                                csvOptions: { fileName: "ProviderList" },
                                                                printOptions: {
                                                                    disableToolbarButton: true,
                                                                    hideFooter: true,
                                                                    hideToolbar: true,
                                                                },
                                                                // printOptions: { fileName: "ProviderList" },
                                                                rows,
                                                                setRows,
                                                                setRowModesModel,
                                                            },
                                                        }}
                                                        // rows={[]}
                                                        rows={rows}
                                                        columns={columns}
                                                        rowHeight={80}
                                                        editMode="row"
                                                        rowModesModel={rowModesModel}
                                                        onRowModesModelChange={handleRowModesModelChange}
                                                        processRowUpdate={processRowUpdate}
                                                        onRowClick={(params) => handleRowClick(params.row)}
                                                        getRowClassName={() => "cursor-pointer"}
                                                    />
                                                </Box>

                                                <Box style={{ display: view === 0 ? "none" : "block" }}>
                                                    <Row className="mt-3 g-2">
                                                        {/* {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                            <Col
                                                                sm={4}
                                                                key={index}
                                                                role="button"
                                                                onClick={(event) => handleRowClick(row, event)}
                                                                style={{
                                                                    aspectRatio: 3 / 3,
                                                                }}
                                                            >
                                                                <div
                                                                    className="p-3 border rounded h-100"
                                                                    style={{
                                                                        aspectRatio: 3 / 3,
                                                                        background: "#f2f2f2",
                                                                        overflowY: "hidden",
                                                                        // overflowY: "scroll",
                                                                        // scrollbarWidth: "thin",
                                                                        // scrollbarColor: "red",
                                                                        // WebkitScrollbarWidth: "thin",
                                                                        // WebkitScrollbarColor: "blue",
                                                                    }}
                                                                > */}
                                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                            <Col
                                                                sm={4}
                                                                key={index}
                                                                role="button"
                                                                onClick={(event) => handleRowClick(row, event)}
                                                                style={{ aspectRatio: 3 / 2.5 }}
                                                            >
                                                                <div
                                                                    className="p-3 border rounded h-100"
                                                                    style={{
                                                                        background: "#f2f2f2",
                                                                        overflowY: "scroll",
                                                                        // scrollbarWidth: "none",
                                                                        // WebkitScrollbarWidth: "none",
                                                                    }}
                                                                >
                                                                    <div className="d-flex my-3">
                                                                        <a
                                                                            className={`text-decoration-none ${tabNo === 1 ? "text-black" : ""}`}
                                                                            onClick={() => handleTabNavClick(1)}
                                                                        >
                                                                            <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                                            Provider
                                                                        </a>
                                                                        <span className="px-2">/</span>
                                                                        <a
                                                                            className={`text-decoration-none ${tabNo === 2 ? "text-black" : ""}`}
                                                                            onClick={() => handleTabNavClick(2)}
                                                                        >
                                                                            <MedicalServicesIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                                            Services
                                                                        </a>
                                                                        <span className="px-2">/</span>
                                                                        <a
                                                                            className={`text-decoration-none ${tabNo === 3 ? "text-black" : ""}`}
                                                                            onClick={() => handleTabNavClick(3)}
                                                                        >
                                                                            <i class="fa-solid fa-hospital me-2" style={{ fontSize: "12px" }}></i>
                                                                            Practice
                                                                        </a>
                                                                    </div>
                                                                    <div
                                                                        className="mb-3"
                                                                        style={{
                                                                            display: tabNo === 1 ? "block" : "none",
                                                                        }}
                                                                    >
                                                                        {/* <h6>
                                                                            <PersonIcon className="me-2" />
                                                                            Provider
                                                                        </h6> */}
                                                                        <div>Title: {row["provider_title"]}</div>
                                                                        <div>First Name: {row["provider_first_name"]}</div>
                                                                        <div>Last Name: {row["provider_last_name"]}</div>
                                                                        <div>
                                                                            Email:{" "}
                                                                            <a href={`mailto:${row["provider_email"]}`} className="text-decoration-none">
                                                                                {row["provider_email"]}
                                                                            </a>
                                                                        </div>
                                                                        <div>
                                                                            Phone:{" "}
                                                                            <a href={`tel:${row["mobile_number1"]}`} className="text-decoration-none">
                                                                                {row["mobile_number1"]}
                                                                            </a>
                                                                        </div>
                                                                        <div>Specialty: {row["provider_specialty"]}</div>
                                                                        <div>Guidance: {row["provider_guidance"]}</div>
                                                                        <div>
                                                                            is verified:{" "}
                                                                            {row["verify_status"] == 1 ? (
                                                                                <CheckIcon style={{ color: "green" }} />
                                                                            ) : (
                                                                                <ClearIcon style={{ color: "red" }} />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="mb-3"
                                                                        style={{
                                                                            display: tabNo === 2 ? "block" : "none",
                                                                        }}
                                                                    >
                                                                        {/* <h6>
                                                                            <MedicalServicesIcon className="me-2" fontSize="small" />
                                                                            Services
                                                                        </h6> */}

                                                                        {row["provider_services"].split(", ").map((service, index) => (
                                                                            <span key={index}>
                                                                                {service}
                                                                                <br />
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                    <div
                                                                        className="mb-3"
                                                                        style={{
                                                                            display: tabNo === 3 ? "block" : "none",
                                                                        }}
                                                                    >
                                                                        {/* <h6>
                                                                            <i class="fa-solid fa-hospital me-2"></i>
                                                                            Practice
                                                                        </h6> */}
                                                                        <div>Name: {row["practice_name"]}</div>
                                                                        <div>
                                                                            Phone:{" "}
                                                                            <a href={`tel:${row["mobile_number2"]}`} className="text-decoration-none">
                                                                                {row["mobile_number2"]}
                                                                            </a>
                                                                        </div>
                                                                        <div>City: {row["practice_city"]}</div>
                                                                        <div>State: {row["practice_state"]}</div>
                                                                        <div>Address 1: {row["practice_address_1"]}</div>
                                                                        <div>Zip: {row["practice_zip"]}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    <TablePagination
                                                        rowsPerPageOptions={[12, 24, 60]}
                                                        component="div"
                                                        count={rows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        className="mt-3"
                                                    />
                                                </Box>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProviderList;
