import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { CircularProgress, LinearProgress } from "@mui/material";
import { Box, Button } from "@mui/material";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { security_key, BASE_URL } from "../global";
import { ToastContainer, toast } from "react-toastify";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Moment from "moment";
import "moment-timezone";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

const basicSchema = Yup.object().shape({
    patient_first_name: Yup.string()
        .min(3, "First Name must be at least 3 characters.")
        .max(250, "First Name must be at most 250 characters.")
        .matches(/^[a-zA-Z]*$/, "First Name can only contain letters.")
        .required("First Name is required.")
        .strict(),
});

function FeesModal({ clickRow, showModal, handleCloseModal }) {
    const [loading, setLoading] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [referEdit, setReferEdit] = useState(false);

    const [rows, setRows] = React.useState([]);

    const [checkedService, setCheckedService] = useState([]);

    const [totalCost, setTotalCost] = useState(0);

    const handleToggle = (row) => () => {
        const pk_service_id = parseInt(row["pk_service_id"]);
        const isChecked = checkedService.some((item) => item.pk_service_id === pk_service_id);

        let updatedCheckedService = [];
        if (isChecked) {
            updatedCheckedService = checkedService.filter((item) => item.pk_service_id !== pk_service_id);
        } else {
            updatedCheckedService = [...checkedService, row];
        }

        setCheckedService(updatedCheckedService);

        const totalCost = updatedCheckedService.reduce((sum, item) => sum + parseInt(item["service_cost"]), 0);
        setTotalCost(totalCost);
    };

    const resetCheckBox = () => {
        setCheckedService([]);
        setTotalCost(0);
    };

    useEffect(() => {
        if (showModal) {
            resetCheckBox();
            fetchData();
        }
    }, [showModal, referEdit]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getServiceByFk",
                provider_id: clickRow["fk_provider_id"],
            });

            const output = response.data;

            if (output.length > 0) {
                setRows(output);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [serviceCost, setServiceCost] = useState(100);

    const handleInputChange = (event) => {
        const { value } = event.target;
        if (/^\d*$/.test(value) && value !== "") {
            setServiceCost(value);
        }
    };

    const handleSend = async () => {
        toast.dismiss();

        if (totalCost != 0) {
            setIsSubmitting(true);

            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "pushPaymentNotification",
                assign_id: clickRow["pk_assign_id"],
                patient_id: clickRow["pk_patient_id"],
                provider_id: clickRow["fk_provider_id"],
                patient_name: `${clickRow["patient_first_name"]} ${clickRow["patient_last_name"]}`,
                checked_service: checkedService,
                total_cost: totalCost,
            });
            const output = response.data;

            if (output == 1) {
                resetCheckBox();
                handleCloseModal();

                toast.success("Success! Notification has been sent", {
                    autoClose: 3000,
                });
            } else if (output && output.includes("Fatal error")) {
                toast.error("Server returned an error.", {
                    autoClose: 3000,
                });
            } else {
                toast.error("Oops! Something went wrong.", {
                    autoClose: 3000,
                });
            }

            setIsSubmitting(false);
        } else {
            toast.error("Please select a service.", {
                autoClose: 3000,
            });
        }
    };

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Payment Notify</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <List sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}>
                            <ListItem key="referral" disablePadding>
                                {referEdit ? (
                                    <>
                                        <TextField
                                            label="Referral Amount"
                                            className="me-5 w-50"
                                            id="outlined-size-small"
                                            // defaultValue="100"
                                            size="small"
                                            value={serviceCost}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                pattern: "[0-9]*",
                                            }}
                                            onChange={handleInputChange}
                                        />
                                        <ClearIcon role="button" className="text-danger me-2" onClick={() => setReferEdit(false)} />
                                        <CheckIcon
                                            role="button"
                                            className="text-success me-2"
                                            onClick={() => {
                                                setReferEdit(false);
                                                handleToggle({ pk_service_id: 0, service_name: "Referral Amount", service_cost: serviceCost });
                                            }}
                                        />
                                    </>
                                ) : (
                                    <ListItemButton
                                        role={undefined}
                                        onClick={handleToggle({ pk_service_id: 0, service_name: "Referral Amount", service_cost: serviceCost })}
                                        dense
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checkedService.findIndex((item) => item && item.pk_service_id === 0) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ "aria-labelledby": "checkbox-list-label-0" }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="Referral Amount" />
                                        <ListItemText primary={parseInt(serviceCost) + "$"} />
                                        <EditIcon onClick={() => setReferEdit(true)} />
                                    </ListItemButton>
                                )}
                            </ListItem>
                            <hr />
                            {rows.map((row, index) => {
                                const labelId = `checkbox-list-label-${row["pk_service_id"]}`;

                                return (
                                    <ListItem key={index} disablePadding>
                                        <ListItemButton role={undefined} onClick={handleToggle(row)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checkedService.findIndex((item) => item && item.pk_service_id === row["pk_service_id"]) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={row["service_name"]} />
                                            <ListItemText id={labelId} primary={parseInt(row["service_cost"]) + "$"} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                            <hr />
                            <ListItem key="total" disablePadding>
                                <ListItemButton role={undefined} dense>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary="Total Cost" />
                                    <ListItemText primary={totalCost + "$"} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outlined" onClick={handleCloseModal} className="me-2">
                        Close
                    </Button>

                    {isSubmitting ? (
                        <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                            Send
                        </LoadingButton>
                    ) : (
                        <Button variant="contained" type="submit" disabled={isSubmitting} onClick={handleSend}>
                            Send
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FeesModal;
