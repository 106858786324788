// import * as React from "react";
import React, { useState, useRef, useEffect } from "react";

import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";

import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { security_key, BASE_URL, options_city } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import dayjs from "dayjs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import LinearProgress from "@mui/material/LinearProgress";
import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { Form } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";
import RestoreIcon from "@mui/icons-material/Restore";
import CustomNoRowsOverlay from "../../components/NoRow";

function Notification() {
    const [loading, setLoading] = useState(false);

    const [loadingBtn2, setLoadingBtn2] = useState(false);
    const [loadingBtn3, setLoadingBtn3] = useState(false);

    const [rows, setRows] = React.useState([]);

    const [amount, setAmount] = React.useState(0);
    const [assignId, setAssignId] = React.useState(0);
    const [pnmId, setPnmId] = React.useState(0);
    const [description, setDescription] = React.useState("NA");

    const location = useLocation();

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const handleRecover = async () => {
        // console.log("From Date:", fromDate);
        // console.log("To Date:", toDate);

        toast.dismiss();

        if (!fromDate) {
            toast.error("Please select a from date", {
                autoClose: 3000,
            });
            return;
        }
        if (!toDate) {
            toast.error("Please select a to date", {
                autoClose: 3000,
            });
            return;
        }
        if (fromDate <= toDate) {
            setLoadingBtn2(true);

            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "recoverPatientNotify",
                patient_id: sessionStorage.getItem("loger_id"),
                from_date: fromDate,
                to_date: toDate,
            });

            const output = response.data;

            if (output == 1) {
                fetchData();
            }
            setLoadingBtn2(false);
        } else {
            toast.error("From date cannot be greater than To date", {
                autoClose: 3000,
            });
        }
    };
    const handleClearAll = async () => {
        toast.dismiss();
        setLoadingBtn3(true);

        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "clearAllPatientNotify",
            patient_id: sessionStorage.getItem("loger_id"),
        });

        const output = response.data;

        if (output == 1) {
            fetchData();
        }
        setLoadingBtn3(false);
    };

    useEffect(() => {
        fetchData();
    }, [location]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getPaymentNotification",
                patient_id: sessionStorage.getItem("loger_id"),
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return { ...row, id: row.pk_pnm_id, index: index + 1 };
                });
                setRows(modifiedOutput);
                // console.log(modifiedOutput);
            } else {
                setRows([]);
                // Handle unsuccessful response, e.g., show an alert to the user.
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handlePay = (row) => {
        setAssignId(row["fk_assign_id"]);
        setPnmId(row["pk_pnm_id"]);
        setAmount(parseInt(row["amount"]));
        setDescription(row["description"]);
        // const form = document.getElementById("referPayment");
        // form.submit();

        // console.log(amount);
        // console.log(assignId);
    };
    useEffect(() => {
        if (pnmId != 0 && assignId != 0 && amount != 0 && description != "NA") {
            const form = document.getElementById("referPayment");
            if (form) {
                form.submit();
            }
        }
    }, [pnmId, assignId, amount, description]);

    const columns = [
        {
            headerName: "#ID",
            field: "index",
            headerClassName: "super-app-theme--header",
            width: 50,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            headerClassName: "super-app-theme--header",
            width: 150,
            cellClassName: "actions",
            getActions: ({ id, row }) => {
                return [
                    <div key={id}>
                        {row["payment_status"] == "Completed" ? (
                            <Button variant="contained" disabled size="small" onClick={() => handlePay(row)}>
                                Paid
                            </Button>
                        ) : (
                            <Button variant="contained" size="small" onClick={() => handlePay(row)}>
                                Pay
                            </Button>
                        )}
                    </div>,
                ];
            },
        },
        {
            headerName: "Notification",
            field: "description",
            headerClassName: "super-app-theme--header",
            // width: "auto",
            flex: 1,
            height: 500,
            headerAlign: "left",
            align: "left",
            renderCell: (params) => <div style={{ height: "auto", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: params.value }}></div>,
        },

        {
            headerName: "Total Amount",
            field: "amount",
            headerClassName: "super-app-theme--header",
            width: 100,
            headerAlign: "left",
            align: "left",
            renderCell: (params) => <span>{params.value}$</span>,
        },
        {
            headerName: "Notify Date",
            field: "formatted_date",
            headerClassName: "super-app-theme--header",
            width: 100,
            headerAlign: "left",
            align: "left",
        },
        {
            headerName: "Notify Time",
            field: "formatted_time",
            headerClassName: "super-app-theme--header",
            width: 100,
            headerAlign: "left",
            align: "left",
        },

        // {
        //     headerName: "Date Of Payment",
        //     field: "paid_date",
        //     headerClassName: "super-app-theme--header",
        //     flex: 1,
        //     headerAlign: "left",
        //     align: "left",
        // },
    ];

    return (
        <>
            <ToastContainer position="top-center" />

            <form action="https://www.sandbox.paypal.com/cgi-bin/webscr" method="post" target="_top" id="referPayment" name="referPayment">
                <input type="hidden" name="item_name" value="Referral Payment" />
                <input type="hidden" name="amount" id="total_amount" value={amount} />
                <input type="hidden" name="currency_code" value="USD" />
                <input type="hidden" name="business" value="cdsphp1@gmail.com" />
                <input type="hidden" name="cmd" value="_xclick" />
                <input type="hidden" name="USER" value="cdsphp1-facilitator-1_api1.gmail.com" />
                <input type="hidden" name="PWD" value="N6FL93U3CVE5ASN7" />
                <input type="hidden" name="SIGNATURE" value="Ahs3vWVNjhi-4QscmZnANk3Md3OCAF4lcicq5cpDq9Sibz3bK9FVLkiE" />
                <input type="hidden" name="return" value="https://emstylemsk.net/#/PaypalSuccess" />
                {/* <input type="hidden" name="cancel_return" value="https://emstylemsk.net/#/PaypalPending" /> */}
                <input type="hidden" name="cancel_return" value="https://emstylemsk.net/#/PatientApp/Profile" />
                <input type="hidden" name="notify_url" value="https://emstylemsk.net/API/pay_notify.php" />
                <input type="hidden" name="custom" id="custom" value={`${sessionStorage.getItem("loger_id")}|${assignId}|${pnmId}`} />
                <input type="hidden" name="bn" value="YourCustomText" />
                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>

            <div className="app-content">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Row>
                            <Col xs="12">
                                <Row className="justify-content-center">
                                    <Col sm={12}>
                                        <div className="section-header d-flex align-items-center justify-content-between mb-3">
                                            <div className="section-heading">
                                                <h4 className="mb-1">Notification</h4>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col sm={12}>
                                        <Card className="my-card p-1 bg-white">
                                            <Card.Body>
                                                <div className="d-flex mt-3 mb-5 justify-content-end">
                                                    <Form.Label className="me-2">FROM:</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        value={fromDate}
                                                        onChange={(e) => setFromDate(e.target.value)}
                                                        style={{
                                                            width: "15%",
                                                            height: "calc(1.5em + 0.5rem + 2px)",
                                                            padding: ".25rem .5rem",
                                                            fontSize: ".875rem",
                                                            lineHeight: "1.5",
                                                            borderRadius: ".2rem",
                                                        }}
                                                        className="me-2"
                                                    />
                                                    <Form.Label className="me-2">TO:</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        min={fromDate}
                                                        value={toDate}
                                                        onChange={(e) => setToDate(e.target.value)}
                                                        style={{
                                                            width: "15%",
                                                            height: "calc(1.5em + 0.5rem + 2px)",
                                                            padding: ".25rem .5rem",
                                                            fontSize: ".875rem",
                                                            lineHeight: "1.5",
                                                            borderRadius: ".2rem",
                                                        }}
                                                        className="me-2"
                                                    />

                                                    <LoadingButton
                                                        loading={loadingBtn2}
                                                        loadingPosition="start"
                                                        startIcon={<RestoreIcon />}
                                                        variant="contained"
                                                        size="small"
                                                        className="me-2"
                                                        onClick={handleRecover}
                                                    >
                                                        Recover
                                                    </LoadingButton>
                                                    <LoadingButton
                                                        loading={loadingBtn3}
                                                        loadingPosition="start"
                                                        startIcon={<ClearIcon />}
                                                        variant="contained"
                                                        size="small"
                                                        className="me-2"
                                                        onClick={handleClearAll}
                                                    >
                                                        <span>Clear All</span>
                                                    </LoadingButton>
                                                    {/* <ButtonGroup disableElevation variant="contained" aria-label="Disabled button group">
                                                <Button size="small">Recover Last 10 Days</Button>
                                                <Button size="small">Clear All</Button>
                                            </ButtonGroup> */}
                                                </div>
                                                <Box
                                                    sx={{
                                                        height: "fit-content",
                                                        width: "100%",
                                                        "& .actions": {
                                                            color: "text.secondary",
                                                        },
                                                        "& .textPrimary": {
                                                            color: "text.primary",
                                                        },
                                                        "& .super-app-theme--header": {
                                                            backgroundColor: "#e3e6ee",
                                                        },
                                                        "& .hot": {
                                                            backgroundColor: "#ff943975",
                                                            color: "#1a3e72",
                                                        },
                                                        "& .row-muted": {
                                                            //backgroundColor: "#d4cad4",
                                                            color: "#1a3e72",
                                                            filter: "blur(1px)",
                                                        },
                                                        //"& .row-muted:hover": {
                                                        //     backgroundColor: "#d4cad4",
                                                        //     color: "#1a3e72",
                                                        // },

                                                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                                            {
                                                                outline: "none !important",
                                                            },
                                                    }}
                                                    style={{ height: "max-content", width: "100%" }}
                                                    // style={{ height: rows.length == 0 ? 600 : "fit-content", width: "100%" }}
                                                >
                                                    <DataGrid
                                                        autoHeight
                                                        sx={{ border: "none", m: 2, "--DataGrid-overlayHeight": "600px" }}
                                                        // minHeight={700}
                                                        initialState={{
                                                            pagination: { paginationModel: { pageSize: 10 } },
                                                        }}
                                                        getRowHeight={() => "auto"}
                                                        pageSizeOptions={[10, 25, 50]}
                                                        loading={loading}
                                                        slots={{
                                                            loadingOverlay: LinearProgress,
                                                            noRowsOverlay: CustomNoRowsOverlay,
                                                        }}
                                                        slotProps={{
                                                            toolbar: {
                                                                csvOptions: { fileName: "PatientList" },
                                                                printOptions: {
                                                                    disableToolbarButton: true,
                                                                    hideFooter: true,
                                                                    hideToolbar: true,
                                                                },
                                                                // printOptions: { fileName: "PatientList" },
                                                                rows,
                                                                setRows,
                                                            },
                                                        }}
                                                        // rows={[]}
                                                        rows={rows}
                                                        columns={columns}
                                                        editMode="row"
                                                        getRowClassName={(params) => {
                                                            // console.log("params:", params.row.assign_visibility);
                                                            if (params.row.assign_visibility == 0) {
                                                                return "row-muted";
                                                            }

                                                            return "";
                                                        }}
                                                    />
                                                </Box>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notification;
