import React, { useEffect, useState, useRef } from "react";
import "./ForgotPassword.css";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import axios from "axios";
import { security_key, BASE_URL } from "../global";
import LinearProgress from "@mui/material/LinearProgress";
import Image from "react-bootstrap/Image";

const validationSchema = Yup.object().shape({
    patient_otp: Yup.string().required("OTP is required").strict().min(4, "OTP must be at least 4 characters").max(6, "OTP must not exceed 6 characters"),
});

function SetOTP() {
    const navigate = useNavigate();

    const formRef = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [count, setCount] = useState(60);

    localStorage.setItem("count", 60);

    useEffect(() => {
        const storedCount = localStorage.getItem("count");
        if (storedCount) {
            // setCount(parseInt(storedCount, 10));
            setCount(parseInt(storedCount));
        }

        startTimer();
    }, []);

    const startTimer = function () {
        const interval = setInterval(() => {
            setCount((prevCount) => {
                const newCount = prevCount - 1;
                if (newCount <= 0) {
                    stopTimer();
                    clearInterval(interval);
                    localStorage.setItem("count", 0);
                    return 0;
                } else {
                    localStorage.setItem("count", newCount);
                    return newCount;
                }
            });
        }, 1000);

        return () => clearInterval(interval); // Clear interval on component unmount
    };

    const stopTimer = async () => {
        toast.dismiss();
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "clearPatientOTP",
                patient_email: sessionStorage.getItem("email"),
            });

            const output = response.data;

            if (output === 1) {
                toast.error("OTP expired. Please request a new one", {
                    autoClose: 3000,
                });
            }
        } catch (error) {
            console.error("Error occurred:", error);
            toast.error("An error occurred while stopping the timer.", {
                autoClose: 3000,
            });
        }
    };

    const resendOtp = async () => {
        toast.dismiss();
        setIsSubmitting(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "checkPatientEmail",
                patient_email: sessionStorage.getItem("email"),
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output && output["status"] === 1) {
                toast.success(output["message"], {
                    autoClose: 3000,
                });

                // Assuming setCount and localStorage are defined somewhere
                setCount(60);
                localStorage.setItem("count", 60);
                startTimer();
            } else {
                toast.error(output && output["message"], {
                    autoClose: 3000,
                });
            }
            setIsSubmitting(false);
        } catch (error) {
            console.error("Error occurred:", error);
            toast.error("An error occurred. Please try again.", {
                autoClose: 3000,
            });
        }
    };

    const initialValues = {
        patient_otp: "",
    };

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (formValues) => {
            setIsSubmitting(true);

            try {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "checkPatientOTP",
                    patient_email: sessionStorage.getItem("email"),
                    ...formValues,
                });

                const output = response.data;

                // console.log("Response Data:", output);

                if (output.length > 0) {
                    sessionStorage.setItem("loger_id", output[0]["pk_patient_id"]);
                    sessionStorage.setItem("name", `${output[0]["patient_first_name"]} ${output[0]["patient_last_name"]}`);
                    sessionStorage.setItem("email", output[0]["patient_email"]);
                    sessionStorage.setItem("user_type", 3);

                    navigate("/PatientApp/Dashboard");
                } else {
                    toast.error("You've entered incorrect code!", {
                        autoClose: 3000,
                    });
                }
                setIsSubmitting(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Invalid API. Please Contact Emstyle Team", {
                    autoClose: 1000,
                });
            }
        },
    });

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="forgot_wrap">
                <div className="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end" style={{ background: "#e7ebf8" }}>
                    <div className="app-auth-background"></div>
                    <div className="app-auth-container">
                        <div className="logo-dark mb-3 text-center">
                            <a role="button" style={{ display: "inline-block" }} onClick={() => navigate("/Patient")}>
                                <Image src="../images/logo-dark.png" width={150} className="img-fluid d-block" />
                            </a>
                        </div>

                        <div className="auth-credentials card border-0 shadow-lg p-5 m-b-xxl">
                            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                <h4 className="text-center mb-4">Code Verification</h4>

                                <div className="row">
                                    <div className="col-12">
                                        {/* <div className="alert alert-danger text-center"></div> */}

                                        <div className="form-group mb-3">
                                            <Form.Label>
                                                Enter OTP<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="patient_otp"
                                                value={values.patient_otp}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.patient_otp && touched.patient_otp ? "input-error" : ""}
                                            />
                                            {errors.patient_otp && touched.patient_otp && <p className="error">{errors.patient_otp}</p>}
                                        </div>
                                        <div className="form-group text-center">
                                            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                                Submit
                                                {isSubmitting ? <LinearProgress /> : ""}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 text-center">
                                        {count == 0 ? (
                                            <a role="button" onClick={resendOtp} style={{ textDecoration: "none" }}>
                                                Resend OTP
                                            </a>
                                        ) : (
                                            <div id="timer">Time remaining: {count} seconds</div>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SetOTP;
