import React, { useState, useEffect, useRef } from "react";
import { MapContainer, useMap, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import toast, { Toaster } from "react-hot-toast";

import { security_key, BASE_URL } from "../../global";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import "./PatientLanding.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// import "./AmericaMap.css";
import "./red.css";

import Moment from "moment";
import "moment-timezone";
import { colors } from "@mui/material";
import { renderToString } from "react-dom/server";

import LeafletMap from "./LeafLetMap";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

console.log(dbFormattedDateTime);

const center = [37.0902, -95.7129]; // Coordinates for the center of the United States

const CustomZoomControl = () => {
    const map = useMap();

    const handleWheel = (e) => {
        e.preventDefault(); // Prevent the default browser zoom behavior

        if (e.ctrlKey) {
            // Zoom only when the Ctrl key is pressed
            const delta = e.deltaY;
            const zoomStep = 1;

            if (delta > 0) {
                // Scroll down
                map.setZoom(map.getZoom() - zoomStep);
            } else if (delta < 0) {
                // Scroll up
                map.setZoom(map.getZoom() + zoomStep);
            }
        }
    };

    // Attach the wheel event to the map
    map.getContainer().addEventListener("wheel", handleWheel);

    return null; // This component doesn't render anything
};

function PatientLanding() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    /****Map functional****/

    const [selectedCity, setSelectedCity] = React.useState(null);

    const handleCityClick = (data) => {
        setSelectedCity(data.name);
        navigate("/PatientAdd", { state: { city_id: data.pk_city_id, city_name: data.name, city_state: data.state } });
    };

    const customIcon = new L.Icon({
        // iconUrl: iconToUrl(<RoomIcon style={{ color: "red" }} />),
        iconSize: [32, 42],
        iconAnchor: [16, 32],
        iconUrl: "./images/marker-icon.png",
        // iconSize: [22, 22],
    });

    const zoom = selectedCity ? 12 : 4; // Adjust the zoom level as needed

    const [cities, setCities] = useState([]); // State to hold cities data

    const [allState, setAllState] = useState([]);

    useEffect(() => {
        fetchData();
        getMapCredential();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getAllProviderCity",
            });

            const output = response.data;

            if (output.length > 0) {
                const formattedOptions = output.map((option) => ({
                    pk_city_id: option.pk_city_id,
                    state: option.State,
                    name: option.City,
                    coordinates: [option.Latitude, option.Longitude],
                }));

                setCities(formattedOptions); // Update state with fetched data

                var stateArr = output.map((row) => row.State);
                setAllState(stateArr);
            } else {
                // Handle the case when output is empty
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [mapData, setMapData] = useState(false);

    const getMapCredential = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getMapCredential",
                map_id: 1,
            });

            const output = response.data;

            if (output) {
                setMapData(output);
                setLoading(false);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const mapContainerRef = useRef(null);

    const scrollToMapContainer = () => {
        if (mapContainerRef.current) {
            const mapContainer = mapContainerRef.current;
            const { offsetTop, clientHeight } = mapContainer;
            const scrollTarget = offsetTop + clientHeight / 2;

            // Current scroll position
            const start = window.pageYOffset;
            // Distance to scroll
            const distance = scrollTarget - start;
            // Duration of the scroll animation
            const duration = 1000; // Adjust as needed, 1000ms = 1 second
            // Timestamp at the beginning of the scroll
            const startTime = performance.now();

            // Animation function
            const animateScroll = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                // Calculate the position to scroll to
                const position = easeInOut(elapsedTime, start, distance, duration);
                // Scroll to that position
                window.scrollTo(0, position);
                // Continue the animation if not finished
                if (elapsedTime < duration) {
                    requestAnimationFrame(animateScroll);
                }
            };

            // Easing function for smooth animation (you can use different easing functions)
            const easeInOut = (t, b, c, d) => {
                t /= d / 2;
                if (t < 1) return (c / 2) * t * t + b;
                t--;
                return (-c / 2) * (t * (t - 2) - 1) + b;
            };

            // Start the animation
            requestAnimationFrame(animateScroll);
        }
    };

    return (
        <>
            <Toaster />
            <div className="patient-container">
                <div className="preloader" />
                <nav
                    className="navbar navbar-default navbar-fixed-top"
                    style={{
                        border: "1px solid #e7e7e7",
                    }}
                >
                    <div className="container d-block d-sm-flex">
                        <div className="navbar-header mobile-center">
                            <div className="navbar-brand p-0 m-0">
                                <img src="images/logo-dark.png" alt="Emstyle" width="150px" />{" "}
                            </div>
                        </div>
                        <div className="click-to-call hidden-xs py-3">
                            {/* <a href="tel:18007555775" target="_blank"> */}
                            <div>
                                <span className="phone-icon">
                                    <i className="fa fa-phone" />
                                </span>{" "}
                                <span>1800 755 5775</span>
                            </div>
                        </div>
                    </div>
                </nav>

                <header id="top" className="header header_menu_padding medical_bg">
                    <div className="container">
                        <div className="header-content">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="hero-area">
                                        <h3 className="headline-support fw-normal">Your Health, Your Time, Your Choice </h3>
                                        <h1 className="headline">
                                            Effortless <span className="text-highlight"> Medical Appointment </span> Scheduling{" "}
                                        </h1>
                                        <div className="click-to-call visible-xs">
                                            <a href="tel:18006577000" target="_blank">
                                                <span className="phone-icon">
                                                    <i className="fa fa-phone" />
                                                </span>{" "}
                                                <span>1800 657 7000</span>
                                            </a>
                                        </div>
                                        {/* <p className="vertical-space">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> */}
                                        <p className="vertical-space">Take control of your health with our easy-to-use scheduling platform.</p>
                                        <button
                                            className="btn btn-success mb-20"
                                            onClick={() => {
                                                // scrollToMapContainer();
                                                navigate("/PatientAdd", { state: { allState: allState } });
                                            }}
                                            data-toggle="modal"
                                            data-target="#myModal"
                                        >
                                            Schedule an Appointment
                                        </button>
                                    </div>
                                    <div className="benefits benefits-vertical mt-2">
                                        <div className="benefit-item">
                                            <div className="benefit-icon">
                                                <i className="fa fa-stethoscope"> </i>
                                            </div>
                                            <h6 className="benefit-title">Easy Appointment Booking</h6>
                                            {/* <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                                            </p> */}
                                            <p>
                                                Effortlessly find and book appointments with qualified healthcare professionals on our user-friendly platform
                                                for a seamless experience.
                                            </p>
                                        </div>
                                        <div className="benefit-item">
                                            <div className="benefit-icon">
                                                <i className="fa fa-heartbeat"> </i>
                                            </div>
                                            <h6 className="benefit-title">Seamless Rescheduling</h6>
                                            {/* <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                                            </p> */}
                                            <p>
                                                Effortlessly reschedule appointments for ultimate convenience. Seamlessly adjust your schedule, taking control
                                                of your healthcare journey
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* // end .col */}
                                <div className="col-md-6 position-static-md">
                                    <div className="image-align-bottom">
                                        {" "}
                                        <img src="images/emcyte/doctor-header.png" alt="Doctor" className="center-block img-responsive" />
                                    </div>
                                </div>
                                {/* // end .col */}
                            </div>
                            {/* // end .row */}
                        </div>
                        {/* // end .header-content */}
                    </div>
                </header>

                <section className="call-to-action highlight" ref={mapContainerRef} id="map_container">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-9">
                                <div className="pt-5 shadow my-5">
                                    <div className="section-title text-center mt-0">
                                        <h2>
                                            Book an <span className="text-highlight">Appointment </span> Now
                                        </h2>
                                        <h3 className="subline">
                                            Join us today and discover a new level of convenience and control in managing your medical appointments.{" "}
                                        </h3>
                                        <hr />
                                    </div>
                                    <div className="cta-button text-center vertical-space">
                                        <h6 className="vertical-space">
                                            Call : <a href="tel:800123456789">1800 755 5775</a>
                                        </h6>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <p>Book your appointment now by choosing a location on the map.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="row ">
                        <div className="col-12 text-center">
                            {mapData && (
                                <MapContainer
                                    className="custom-map card"
                                    style={{ height: mapData.height + "px", width: mapData.width + "%", display: "inline-block" }}
                                    center={center}
                                    zoom={3.5}
                                    scrollWheelZoom={mapData.scroll_wheel_zoom == 1}
                                    maxZoom={mapData.max_zoom}
                                    minZoom={mapData.min_zoom}
                                >
                                    <TileLayer
                                        noWrap={true}
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        // attribution={false}
                                        url={mapData.theme_link}
                                        // url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                                        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                                        // url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
                                        // url="https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png"
                                        // url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.png"
                                        // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                        // url="https://api.mapbox.com/styles/mapbox/navigation-night-v1"
                                        // url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                    />

                                    {cities.map((city, index) => (
                                        <Marker
                                            key={index}
                                            position={city.coordinates}
                                            icon={customIcon}
                                            rotation3d={[1, 0, 0, 320]}
                                            rotationAngle={320}
                                            eventHandlers={{ click: () => handleCityClick(city) }}
                                        >
                                            <Tooltip autoClose={false} direction="top" offset={[0, -33]}>
                                                {city.name}
                                            </Tooltip>
                                        </Marker>
                                    ))}

                                    <CustomZoomControl />
                                </MapContainer>
                            )}
                        </div>
                    </div>
                </section>

                {/* Appointment Section====================================== */}
                <section className="appointment">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-12">
                                <div className="section-title text-center">
                                    <h2>
                                        Welcome to the Evolution of <span className="text-highlight">Healthcare</span> Scheduling
                                    </h2>
                                    <hr />
                                </div>

                                <p className="text-center">
                                    Embark on a journey into a new era of healthcare scheduling excellence. Our innovative platform is designed to redefine your
                                    healthcare experience. Discover the possibilities that await you.
                                </p>
                                <ul className="list-unstyled checklist text-left ms-5">
                                    <li>
                                        <p className="d-flex">
                                            {" "}
                                            <i className="fa mt-1 fa-check" /> Simplify appointment scheduling effortlessly
                                        </p>
                                    </li>
                                    <li>
                                        <p className="d-flex">
                                            {" "}
                                            <i className="fa mt-1  fa-check" /> Access healthcare services on your terms
                                        </p>
                                    </li>
                                    <li>
                                        <p className="d-flex">
                                            {" "}
                                            <i className="fa mt-1  fa-check" /> Enhance patient experience with advanced features
                                        </p>
                                    </li>
                                    <li>
                                        <p className="d-flex">
                                            {" "}
                                            <i className="fa mt-1  fa-check" /> Streamlined communication for better care coordination
                                        </p>
                                    </li>
                                    <li>
                                        <p className="d-flex">
                                            {" "}
                                            <i className="fa mt-1  fa-check" /> Personalized healthcare solutions for every individual
                                        </p>
                                    </li>
                                    <li>
                                        <p className="d-flex">
                                            {" "}
                                            <i className="fa mt-1  fa-check" /> Empowerment through easy access to medical information
                                        </p>
                                    </li>
                                    <li>
                                        <p className="d-flex">
                                            {" "}
                                            <i className="fa mt-1  fa-check" /> Collaborative approach towards your well-being
                                        </p>
                                    </li>
                                    <li>
                                        <p className="d-flex">
                                            {" "}
                                            <i className="fa mt-1  fa-check" /> Seamless integration with your lifestyle
                                        </p>
                                    </li>
                                    <li>
                                        <p className="d-flex">
                                            {" "}
                                            <i className="fa mt-1  fa-check" /> Future-proofing your healthcare experience
                                        </p>
                                    </li>
                                    <li>
                                        <p className="d-flex">
                                            {" "}
                                            <i className="fa mt-1  fa-check" /> Elevate your health management journey
                                        </p>
                                    </li>
                                </ul>

                                <img src="images/emcyte/why-us.jpg" className="img-responsive" alt="Appintment Image" />
                            </div>
                        </div>
                        {/* // end .row */}
                    </div>
                    {/* end .container */}
                </section>
                {/* end section.appointment */}

                <section className="highlight top-space-xl">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>
                                Elevate Your Experience with <span className="text-highlight">Premium Services</span>!
                            </h2>
                            <hr />
                        </div>
                        <div className="row g-3">
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="thumbnail h-100">
                                    <img src="images/emcyte/service3.jpg" alt="Service Image 1" />
                                    <div className="caption" style={{ padding: "9px" }}>
                                        <h6 className="caption-title mt-3 fw-bold">Intuitive Interface</h6>
                                        <p className="caption-text">
                                            Explore a user-friendly interface for an enhanced experience in navigating our platform effortlessly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="thumbnail h-100">
                                    <img src="images/emcyte/service4.jpg" alt="Service Image 2" />
                                    <div className="caption" style={{ padding: "9px" }}>
                                        <h6 className="caption-title mt-3  fw-bold">Automated Reminders</h6>
                                        <p className="caption-text">
                                            Receive timely automated reminders, ensuring you stay informed about upcoming appointments with ease.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix visible-sm" />
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="thumbnail h-100">
                                    <img src="images/emcyte/service2.jpg" alt="Service Image 3" />
                                    <div className="caption" style={{ padding: "9px" }}>
                                        <h6 className="caption-title mt-3  fw-bold">Electronic Health Records</h6>
                                        <p className="caption-text">
                                            Securely manage electronic health records, providing a comprehensive and personalized healthcare experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="thumbnail h-100">
                                    <img src="images/emcyte/service1.jpg" alt="Service Image 4" />
                                    <div className="caption" style={{ padding: "9px" }}>
                                        <h6 className="caption-title mt-3  fw-bold">Multi-Provider Scheduling</h6>
                                        <p className="caption-text">
                                            Simplify life with multi-provider scheduling, effortlessly managing appointments for your overall well-being.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="testimonials">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>
                                Discover the Voices of Our
                                <span className="text-highlight"> Valued Patients</span>
                            </h2>
                            <h3 className="subline">Don’t take our word. Hear what our patients have to say about their experiences.</h3>
                            <hr />
                        </div>
                        <div className="row">
                            <div className="col-sm-9">
                                <blockquote className="bq-right">
                                    "I had an incredible experience with the healthcare team. Their dedication to patient care is unparalleled. Thank you for
                                    making a difference!"
                                </blockquote>
                                <p className="bq-author">
                                    <strong>
                                        <span className="text-highlight">Sarah L</span>
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="vertical-space-lg">
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-9">
                                <blockquote className="bq-left">
                                    "I'm impressed by the personalized care and attention to detail. The healthcare professionals here truly prioritize patient
                                    well-being."
                                </blockquote>
                                <p className="text-center text-md-end bq-author">
                                    <strong>
                                        {" "}
                                        <span className="text-highlight">John M</span>
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div className="vertical-space-lg"></div>
                    </div>
                </section>

                <div className="highlight contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6">
                                <p>
                                    <span className="text-highlight">
                                        <strong>Our Address</strong>
                                    </span>
                                    <br />
                                    4331 Veronica
                                    <br /> S. Shoemaker Blvd. Fort Myers,
                                    <br /> FL 33916
                                </p>
                            </div>
                            {/* // end .col */}
                            <div className="col-sm-6">
                                <div className="d-flex justify-content-center justify-content-md-end">
                                    <p>
                                        <span className="text-highlight">
                                            <strong>Contact Us</strong>
                                        </span>
                                        <br /> 239-481-7725
                                        <br /> 239-666-8015 <br />{" "}
                                        <a href="mailto:support@emstyle.com">
                                            <span style={{ color: "#1d68ae" }}>support@emstyle.com</span>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            {/* // end .col */}
                        </div>
                        {/* // end .row */}
                    </div>
                </div>
                {/* end section.footer-action */}
                <footer>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/emcyte" target="_blank">
                            <i className="fa fa-facebook text-white" style={{ background: "#385494" }} />{" "}
                        </a>
                        <a href="https://www.instagram.com/emcytecorp/" target="_blank">
                            <i className="fa fa-instagram text-white" style={{ background: "#f72777" }} />{" "}
                        </a>
                        <a href="https://www.linkedin.com/company/emcyte-corp" target="_blank">
                            <i className="fa fa-linkedin text-white" style={{ background: "#365ca7" }} />{" "}
                        </a>
                    </div>
                    <p>
                        <small className="">
                            © 2023.{" "}
                            <a href="#" style={{ color: "#1d68ae" }}>
                                Emstyle.
                            </a>{" "}
                            All rights reserved. Designed by{" "}
                            <a href="https://capdigisoft.com/" target="_blank" style={{ color: "#1d68ae" }}>
                                CAP Digisoft.
                            </a>{" "}
                        </small>
                    </p>
                </footer>
                <a href="#top" className="back_to_top">
                    <img src="images/back_to_top.png" alt="back to top" />
                </a>
            </div>
        </>
    );
}

export default PatientLanding;
