import React, { useState } from "react";

import { Nav, Navbar, NavDropdown, Dropdown, ButtonGroup, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GreetingComponent from "./Greeting";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// sessionStorage.setItem("name", "naveen");
import AdminNotify from "./AdminNotification";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function AppHeader({ isOpen, toggleSidebar }) {
    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear();
        navigate("/Admin/Login");
    };

    const [totalPatient, setTotalPatient] = useState(0);

    const [isOpenDrop, setIsOpenDrop] = useState(false);

    const toggleDropDown = () => {
        setIsOpenDrop(!isOpenDrop); // Toggle the current value
    };

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const [anchorElNotify, setAnchorElNotify] = React.useState(null);

    const handleClick = (event) => {
        setAnchorElNotify(event.currentTarget);
    };

    return (
        <div className="app-header">
            <div className="app-header-one">
                <nav className="navbar navbar-light navbar-expand-lg">
                    <div className="container-fluid">
                        <div className="w-100 d-flex flex-row justify-content-between align-items-center overflow-hidden">
                            <div className="navbar-nav flex-row align-items-center" id="navbarNav">
                                <ul className="navbar-nav" onClick={toggleSidebar}>
                                    <li className="nav-item">
                                        <a className="nav-link hide-sidebar-toggle-button" role="button">
                                            {isOpen ? <i className="material-icons">last_page</i> : <i className="material-icons">first_page</i>}
                                        </a>
                                    </li>
                                </ul>
                                {/* <h5 className="mt-3 ms-3">Hi, Good Afternoon !</h5> */}
                                <GreetingComponent />
                            </div>
                            <div className="d-flex">
                                <ul className="navbar-nav">
                                    {/* <li className="nav-item">
                                        <a className="nav-link toggle-search" role="button">
                                            <i className="material-icons">search</i>
                                        </a>
                                    </li> */}
                                    <li className="nav-item hidden-on-mobile d-flex align-items-center gap-1">
                                        <Badge badgeContent={totalPatient} color="primary" className="mt-2 me-4" role="button" onClick={handleClick}>
                                            {/* <NotificationsIcon color="primary" fontSize="medium" /> */}
                                            <NotificationsNoneIcon color="primary" fontSize="large" />
                                        </Badge>

                                        <Box sx={{ flexGrow: 0 }}>
                                            <Tooltip title="">
                                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                    <Avatar
                                                        alt="Remy Sharp"
                                                        src={`https://emstylemsk.net/API/upload/image/${sessionStorage.getItem("image")}`}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                sx={{ mt: "45px" }}
                                                id="menu-appbar"
                                                anchorEl={anchorElUser}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                open={Boolean(anchorElUser)}
                                                onClose={handleCloseUserMenu}
                                            >
                                                <MenuItem
                                                    key="profile"
                                                    onClick={() => {
                                                        handleCloseUserMenu();
                                                        navigate("/Admin/Profile");
                                                    }}
                                                >
                                                    <Typography textAlign="center">Profile</Typography>
                                                </MenuItem>
                                                <MenuItem
                                                    key="Settings"
                                                    onClick={() => {
                                                        handleCloseUserMenu();
                                                        navigate("/Admin/Settings");
                                                    }}
                                                >
                                                    <Typography textAlign="center">Settings</Typography>
                                                </MenuItem>
                                                <MenuItem
                                                    key="Logout"
                                                    onClick={() => {
                                                        handleCloseUserMenu();
                                                        handleLogout();
                                                    }}
                                                >
                                                    <Typography textAlign="center">Logout</Typography>
                                                </MenuItem>
                                            </Menu>
                                            <AdminNotify
                                                anchorElNotify={anchorElNotify}
                                                setAnchorElNotify={setAnchorElNotify}
                                                setTotalPatient={setTotalPatient}
                                            />
                                        </Box>
                                        <div>
                                            <h6 className="mb-0">{sessionStorage.getItem("name")}</h6>
                                            <small>{sessionStorage.getItem("email")}</small>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div className=" app-header-two">
                <nav className="navbar navbar-light navbar-expand-lg app-header-two">
                    <div className="container-fluid">
                        <div className="w-100 header-main overflow-hidden">
                            <div className="navbar-nav flex-row align-items-center justify-content-between" id="navbarNav">
                                <Image src="../images/logo-light.png" width={132} className="img-fluid  d-block" />
                                <div className="d-flex align-items-center">
                                    <Badge badgeContent={totalPatient} color="primary" className=" " role="button" onClick={handleClick}>
                                        {/* <NotificationsIcon color="primary" fontSize="medium" /> */}
                                        <NotificationsNoneIcon className="text-light" color="white" fontSize="large" />
                                    </Badge>
                                    <ul className="navbar-nav" onClick={toggleSidebar}>
                                        <li className="nav-item d-flex align-items-center ">
                                            <a className="nav-link hide-sidebar-toggle-button" role="button">
                                                {isOpen ? (
                                                    <MenuOpenIcon className="text-light f-30"></MenuOpenIcon>
                                                ) : (
                                                    <MenuIcon className="text-light f-30"></MenuIcon>
                                                )}
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                {/* <h5 className="mt-3 ms-3">Hi, Good Afternoon !</h5> */}
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="mt-4 app-header-two">
                    <div className="mb-3">
                        {" "}
                        <GreetingComponent />
                    </div>

                    <div className="d-flex">
                        <ul className="navbar-nav">
                            {/* <li className="nav-item">
                                        <a className="nav-link toggle-search" role="button">
                                            <i className="material-icons">search</i>
                                        </a>
                                    </li> */}
                            <li className="nav-item hidden-on-mobile d-flex align-items-center gap-1">
                                <Box sx={{ flexGrow: 0 }}>
                                    <Tooltip title="">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt="Remy Sharp" src={`https://emstylemsk.net/API/upload/image/${sessionStorage.getItem("image")}`} />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: "45px" }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        <MenuItem
                                            key="profile"
                                            onClick={() => {
                                                handleCloseUserMenu();
                                                navigate("/Admin/Profile");
                                            }}
                                        >
                                            <Typography textAlign="center">Profile</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            key="Settings"
                                            onClick={() => {
                                                handleCloseUserMenu();
                                                navigate("/Admin/Settings");
                                            }}
                                        >
                                            <Typography textAlign="center">Settings</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            key="Logout"
                                            onClick={() => {
                                                handleCloseUserMenu();
                                                handleLogout();
                                            }}
                                        >
                                            <Typography textAlign="center">Logout</Typography>
                                        </MenuItem>
                                    </Menu>
                                    <AdminNotify anchorElNotify={anchorElNotify} setAnchorElNotify={setAnchorElNotify} setTotalPatient={setTotalPatient} />
                                </Box>
                                <div>
                                    <h6 className="mb-0">{sessionStorage.getItem("name")}</h6>
                                    <small>{sessionStorage.getItem("email")}</small>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppHeader;
