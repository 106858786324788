import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useHistory, NavLink } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useFormik } from "formik";
import * as Yup from "yup";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Avatar from "@mui/material/Avatar";
import VaccinesIcon from "@mui/icons-material/Vaccines";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { security_key, BASE_URL, options_city } from "../../global";
import "./AdminProfile.css";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import InjectionSiteListMode from "../InjectionSiteListMode";
import InjectionSiteEditMode from "../InjectionSiteEditMode";

import Moment from "moment";
import "moment-timezone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVialVirus, faDna, faSyringe } from "@fortawesome/free-solid-svg-icons";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

console.log(dbFormattedTime);

const ClinicalView = () => {
    const location = useLocation();

    const navigate = useNavigate();

    const formRef = useRef(null);

    const [loading, setLoading] = useState(true);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /****MUI Nav Tab****/

    const [value, setValue] = React.useState("1");

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const [isEdit, setIsEdit] = useState(false);

    /****Provider List****/

    const [pathologyData, setPatholodyData] = useState();

    useEffect(() => {
        fetchData();
    }, [location]);

    const fetchData = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getPathologyById",
                patho_id: location.state.patho_id,
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output) {
                setPatholodyData(output);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    /****Provider Edit Functional****/

    const initialValues = {
        /**Pathology*/
        ultrasound_video_link: pathologyData && pathologyData.ultrasound_video_link ? pathologyData.ultrasound_video_link : "",
        title_of_pathology: pathologyData && pathologyData.title_of_pathology ? pathologyData.title_of_pathology : "",
        description_of_pathology: pathologyData && pathologyData.description_of_pathology ? pathologyData.description_of_pathology : "",
        grade_of_injury: pathologyData && pathologyData.grade_of_injury ? pathologyData.grade_of_injury : "",

        /**Autologous Biologic */
        bmc_platelet_dose: pathologyData && pathologyData.bmc_platelet_dose ? pathologyData.bmc_platelet_dose : "",
        bmc_wpc_dose: pathologyData && pathologyData.bmc_wpc_dose ? pathologyData.bmc_wpc_dose : "",
        prp_type: pathologyData && pathologyData.prp_type ? pathologyData.prp_type.split(", ") : [],
        prp_platelet_dose: pathologyData && pathologyData.prp_platelet_dose ? pathologyData.prp_platelet_dose : "",
        leukocyte_type: pathologyData && pathologyData.leukocyte_type ? pathologyData.leukocyte_type.split(", ") : [],

        /**Removed Fields */
        rehabilitation: pathologyData && pathologyData.rehabilitation ? pathologyData.rehabilitation : "",
        adipose_concentrate_dose: pathologyData && pathologyData.adipose_concentrate_dose ? pathologyData.adipose_concentrate_dose : "",
        adipose_concentrate: pathologyData && pathologyData.adipose_concentrate ? pathologyData.adipose_concentrate.split(", ") : [],
        ac_total_volume: pathologyData && pathologyData.ac_total_volume ? pathologyData.ac_total_volume : "",
        bone_marrow_concentrate_dose: pathologyData && pathologyData.bone_marrow_concentrate_dose ? pathologyData.bone_marrow_concentrate_dose : "",
        bone_marrow_concentrate: pathologyData && pathologyData.bone_marrow_concentrate ? pathologyData.bone_marrow_concentrate.split(", ") : [],
        bc_total_volume: pathologyData && pathologyData.bc_total_volume ? pathologyData.bc_total_volume : "",
        platelet_concentrate_dose: pathologyData && pathologyData.platelet_concentrate_dose ? pathologyData.platelet_concentrate_dose : "",
        platelet_concentrate: pathologyData && pathologyData.platelet_concentrate ? pathologyData.platelet_concentrate.split(", ") : [],
        pc_total_volume: pathologyData && pathologyData.pc_total_volume ? pathologyData.pc_total_volume : "",

        /**Anesthetics */
        anesthesia_type: pathologyData && pathologyData.anesthesia_type ? pathologyData.anesthesia_type : "",
        anesthetic_name: pathologyData && pathologyData.anesthetic_name ? pathologyData.anesthetic_name : "",
        anesthetic_dose: pathologyData && pathologyData.anesthetic_dose ? pathologyData.anesthetic_dose : "",
        injection_technique: pathologyData && pathologyData.injection_technique ? pathologyData.injection_technique : "",
        anesthetics_rationale: pathologyData && pathologyData.anesthetics_rationale ? pathologyData.anesthetics_rationale : "",

        created_on: dbFormattedDateTime,
        updated_on: dbFormattedDateTime,
        visibility: "1",
    };

    const validationSchema = Yup.object().shape({
        ultrasound_video_link: Yup.string()
            .required("Ultrasound Video Link is required")
            .matches(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com|player\.vimeo\.com)/, "Please enter a valid video link from YouTube or Vimeo"),

        // .test("is-embedded-video-link", "Please enter a valid embedded video link from YouTube or Vimeo", (value) => {
        //     if (!value) return true; // Allow empty value

        //     // Regular expressions to match embedded video links from YouTube and Vimeo domains
        //     const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/embed\/)([^"&?\/\s]{11})(\?si=[^"&?\/\s]+)?$/;
        //     const vimeoRegex = /^(https?:\/\/)?(www\.)?(player\.vimeo\.com\/video\/)([0-9]+)(\?[^"&?\/\s]+)?$/;

        //     // Test if the value matches the patterns of YouTube or Vimeo embedded video links
        //     return youtubeRegex.test(value) || vimeoRegex.test(value);
        // }),

        title_of_pathology: Yup.string()
            .required("Pathology Title is required")
            .matches(/^[a-zA-Z0-9. ]*$/, "Pathology Title can only contain letters, numbers, dots and spaces.")
            .min(2, "Pathology Title must be at least 2 characters.")
            .max(250, "Pathology Title must be at most 250 characters."),

        description_of_pathology: Yup.string()
            .required("Pathology Description is required")
            .min(3, "Pathology Description must be at least 3 characters.")
            .max(250, "Pathology Description must be at most 250 characters."),

        // rehabilitation: Yup.string()
        //     .required("Rehabilitation is required")
        //     .min(3, "Rehabilitation must be at least 3 characters.")
        //     .max(250, "Rehabilitation must be at most 250 characters."),

        grade_of_injury: Yup.string()
            .required("Grade of Pathology is required")
            .matches(/^[a-zA-Z. ]*$/, "Grade of Pathology can only contain letters, dots and spaces.")
            .min(2, "Grade of Pathology must be at least 2 characters.")
            .max(250, "Grade of Pathology must be at most 250 characters."),

        /*
        adipose_concentrate_dose: Yup.string()
            .required("Adipose Concentrate Dose is required")
            .matches(/^[a-zA-Z0-9. ]*$/, "Adipose Concentrate Dose can only contain letters, numbers, dots and spaces.")
            .min(2, "Adipose Concentrate Dose must be at least 2 characters.")
            .max(250, "Adipose Concentrate Dose must be at most 250 characters."),

        adipose_concentrate: Yup.array().min(1, "At least one option should be selected"),

        ac_total_volume: Yup.string()
            .required("AC Total Volume is required")
            .test("is-positive", "AC Total Volume must be a positive number", (value) => {
                if (!value) return true; // Accept empty value
                const numericValue = parseFloat(value);
                if (numericValue < 0) {
                    return false;
                }
                return true;
            })
            .test("greater-than-zero", "AC Total Volume must be greater than zero", (value) => {
                if (!value) return false;
                const numericValue = parseFloat(value);
                return numericValue > 0;
            }),
       
        bone_marrow_concentrate: Yup.array().min(1, "At least one option should be selected"),

        bc_total_volume: Yup.string()
            .required("BC Total Volume is required")
            .test("is-positive", "BC Total Volume must be a positive number", (value) => {
                if (!value) return true;
                const numericValue = parseFloat(value);
                if (numericValue < 0) {
                    return false;
                }
                return true;
            })
            .test("greater-than-zero", "BC Total Volume must be greater than zero", (value) => {
                if (!value) return false;
                const numericValue = parseFloat(value);
                return numericValue > 0;
            }),
                  .max(250, "Platelet Concentrate Dose must be at most 250 characters."),

        platelet_concentrate: Yup.array().min(1, "At least one option should be selected"),

        pc_total_volume: Yup.string()
            .required("PC Total Volume is required")
            .test("is-positive", "PC Total Volume must be a positive number", (value) => {
                if (!value) return true;
                const numericValue = parseFloat(value);
                if (numericValue < 0) {
                    return false;
                }
                return true;
            })
            .test("greater-than-zero", "PC Total Volume must be greater than zero", (value) => {
                if (!value) return false;
                const numericValue = parseFloat(value);
                return numericValue > 0;
            }),
        */

        bmc_platelet_dose: Yup.string()
            //.required("BMC Platelet Dose is required")
            // .matches(/^[a-zA-Z0-9. ]*$/, "BMC Platelet Dose can only contain letters, numbers, dots and spaces.")
            .min(2, "BMC Platelet Dose must be at least 2 characters.")
            .max(250, "BMC Platelet Dose must be at most 250 characters."),

        bmc_wpc_dose: Yup.string()
            // .required("BMC WPC Dose is required")
            .min(3, "BMC WPC Dose must be at least 3 characters.")
            .max(250, "BMC WPC Dose must be at most 250 characters."),

        // prp_type: Yup.array().min(1, "At least one option should be selected"),

        prp_platelet_dose: Yup.string()
            // .required("PRP Platelet Dose is required")
            // .matches(/^[a-zA-Z0-9. ]*$/, "PRP Platelet Dose can only contain letters, numbers, dots and spaces.")
            .min(2, "PRP Platelet Dose must be at least 2 characters.")
            .max(250, "PRP Platelet Dose must be at most 250 characters."),

        //leukocyte_type: Yup.array().min(1, "At least one option should be selected"),

        anesthesia_type: Yup.string()
            .required("Anesthesia Type is required")
            .matches(/^[a-zA-Z0-9. ]*$/, "Anesthesia Type can only contain letters, numbers, dots and spaces.")
            .min(2, "Anesthesia Type must be at least 2 characters.")
            .max(100, "Anesthesia Type Dose must be at most 100 characters."),

        anesthetic_name: Yup.string()
            .required("Anesthetic Name is required")
            .matches(/^[a-zA-Z. ]*$/, "Anesthetics Name can only contain letters, dots and spaces.")
            .min(2, "Anesthetics Name must be at least 2 characters.")
            .max(100, "Anesthetics Name Dose must be at most 100 characters."),
        anesthetic_dose: Yup.string()
            .required("Anesthetic Dose is required")
            .matches(/^[a-zA-Z0-9. ]*$/, "Anesthetics Dose can only contain letters, numbers, dots and spaces.")
            .min(2, "Anesthetics Dose must be at least 2 characters.")
            .max(250, "Anesthetics Dose must be at most 250 characters."),
        injection_technique: Yup.string()
            .required("Injection Technique is required")
            .matches(/^[a-zA-Z0-9. ]*$/, "Injection Technique can only contain letters, numbers, dots and spaces.")
            .min(2, "Injection Technique must be at least 2 characters.")
            .max(100, "Injection Technique must be at most 100 characters."),
        anesthetics_rationale: Yup.string()
            .required("Anesthetics Rationale is required")
            .matches(/^[a-zA-Z. ]*$/, "Anesthetics Rationale can only contain letters, dots and spaces.")
            .min(2, "Anesthetics Rationale must be at least 2 characters.")
            .max(250, "Anesthetics Rationale must be at most 250 characters."),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            toast.dismiss();
            setIsSubmitting(true);

            const formValues = { ...values };
            formValues.ultrasound_video_link = convertToEmbedLink(formValues.ultrasound_video_link);
            formik.setValues(formValues);

            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "updatePathology",
                patho_id: location.state.patho_id,
                ...formValues,
            });
            const output = response.data;
            if (output == 1) {
                toast.success("Updated successfully!", {
                    autoClose: 3000,
                });
                fetchData();
                setTimeout(function () {
                    setIsEdit(false);
                }, 3000);
            } else {
                toast.error("Oops! Something went wrong.", {
                    autoClose: 3000,
                });
            }

            setTimeout(function () {
                setIsSubmitting(false);
            }, 3000);
        },
    });
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;
    const setFieldValue = formik.setFieldValue;

    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) {
            const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
            const correspondingTab = getTabForField(firstErrorField);
            setValue(correspondingTab);

            const errorFieldElement = document.getElementById(firstErrorField);
            if (errorFieldElement) {
                const elementRect = errorFieldElement.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const middleOfElement = absoluteElementTop - window.innerHeight / 2;

                window.scrollTo({
                    top: middleOfElement,
                    behavior: "smooth",
                });
            } else {
                // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
            //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
    }, [formik]);

    const tabOneFields = ["ultrasound_video_link", "title_of_pathology", "description_of_pathology", "grade_of_injury"];
    const tabTwoFields = ["bmc_platelet_dose", "bmc_wpc_dose", "prp_type", "prp_platelet_dose", "leukocyte_type"];
    const tabThreeFields = ["anesthesia_type", "anesthetic_name", "anesthetic_dose", "injection_technique", "anesthetics_rationale"];

    const getTabForField = (fieldName) => {
        if (tabOneFields.includes(fieldName)) {
            return "1";
        } else if (tabTwoFields.includes(fieldName)) {
            return "2";
        } else if (tabThreeFields.includes(fieldName)) {
            return "3";
        } else {
            return "1";
        }
    };

    const convertToEmbedLink = (link) => {
        // Check if the link is already an embed link
        if (link.includes("vimeo.com/video") || link.includes("youtube.com/embed")) {
            return link; // Return the original link
        } else if (link.includes("vimeo.com")) {
            // Vimeo link format: https://vimeo.com/videoID
            const videoID = link.split("/").pop();
            return `https://player.vimeo.com/video/${videoID}`;
        } else if (link.includes("youtube.com")) {
            // YouTube link format: https://www.youtube.com/watch?v=videoID
            const videoID = link.split("v=").pop().split("&")[0];
            return `https://www.youtube.com/embed/${videoID}`;
        } else if (link.includes("youtu.be")) {
            // Shortened YouTube link format: https://youtu.be/videoID
            const videoID = link.split("/").pop();
            return `https://www.youtube.com/embed/${videoID}`;
        } else {
            // If not a Vimeo or YouTube link, return the original link
            return link;
        }
    };

    return (
        <>
            <ToastContainer position="top-center" />
            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div className="app-content">
                    <div className="content-wrapper">
                        <div className="container-fluid">
                            <div className="section-header d-flex align-items-center justify-content-between">
                                <div className="section-heading">
                                    <h4 className="mb-1">Clinical</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <span role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                                    Home
                                                </span>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <span role="button" onClick={() => navigate("/Admin/ClinicalList")}>
                                                    Clinical
                                                </span>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                {isEdit ? "Edit Clinical" : "View Clinical"}
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="section-essentials d-flex align-items-center justify-content-between gap-2">
                                    <span role="button" className="btn btn-light btn-md border" onClick={() => navigate("/Admin/ClinicalList")}>
                                        Back
                                    </span>
                                </div>
                            </div>
                            <Card className="my-card px-3 py-3 bg-white">
                                <Card.Body className="card-padding-res pt-1">
                                    <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                        <div className="row">
                                            <div className="col-12 text-end">
                                                {isEdit ? (
                                                    <input
                                                        type="button"
                                                        className="btn btn-outline-secondary"
                                                        name="btnAddMore"
                                                        value="View Clinical"
                                                        onClick={() => setIsEdit(false)}
                                                    />
                                                ) : (
                                                    <input
                                                        type="button"
                                                        className="btn btn-outline-secondary"
                                                        name="btnAddMore"
                                                        value="Edit Clinical"
                                                        onClick={() => setIsEdit(true)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="" style={{ position: "relative" }}>
                                                <Avatar
                                                    sx={{
                                                        bgcolor: "#1869bf",
                                                        width: 72, // Adjust the width as needed
                                                        height: 72, // Adjust the height as needed
                                                    }}
                                                    className="me-2"
                                                >
                                                    <span style={{ fontSize: "24px" }}>
                                                        {pathologyData ? pathologyData.title_of_pathology.charAt(0).toUpperCase() : "N/A"}
                                                    </span>
                                                    {/* {pathologyData ? pathologyData.title_of_pathology.charAt(1).toUpperCase() : "N/A"} */}
                                                </Avatar>
                                            </div>
                                            <div>
                                                <h1>{pathologyData && pathologyData.title_of_pathology ? pathologyData.title_of_pathology : "N/A"}</h1>
                                                {/* <div>
                                                    <span className="me-3 text-secondary">Practice Name</span>
                                                    <span className="me-3">
                                                        {pathologyData && pathologyData.practice_name ? pathologyData.practice_name : "N/A"}
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {isEdit ? (
                                            <>
                                                <Box sx={{ width: "100%", typography: "body1" }}>
                                                    <TabContext value={value}>
                                                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                            <TabList
                                                                onChange={handleTabChange}
                                                                variant="scrollable"
                                                                scrollButtons="auto"
                                                                allowScrollButtonsMobile
                                                                aria-label="scrollable auto tabs example"
                                                            >
                                                                <Tab
                                                                    label="Pathology"
                                                                    value="1"
                                                                    icon={<FontAwesomeIcon icon={faVialVirus} />}
                                                                    iconPosition="start"
                                                                />
                                                                <Tab
                                                                    label="Autologous Biologic"
                                                                    value="2"
                                                                    icon={<FontAwesomeIcon icon={faDna} />}
                                                                    iconPosition="start"
                                                                />
                                                                <Tab label="Anesthetics" value="3" icon={<VaccinesIcon />} iconPosition="start" />
                                                                <Tab
                                                                    label="Injection Sites & Volume"
                                                                    value="4"
                                                                    icon={<FontAwesomeIcon icon={faSyringe} />}
                                                                    iconPosition="start"
                                                                />
                                                            </TabList>
                                                        </Box>
                                                        <TabPanel value="1">
                                                            <Form.Group controlId="ultrasound_video_link" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Ultrasound Video Link(Provide Embedded video Link)<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="url"
                                                                        name="ultrasound_video_link"
                                                                        value={values.ultrasound_video_link}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={
                                                                            errors.ultrasound_video_link && touched.ultrasound_video_link ? "input-error" : ""
                                                                        }
                                                                    />
                                                                    {errors.ultrasound_video_link && touched.ultrasound_video_link && (
                                                                        <p className="error">{errors.ultrasound_video_link}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="title_of_pathology" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Title<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="title_of_pathology"
                                                                        value={formik.values.title_of_pathology}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.title_of_pathology && touched.title_of_pathology ? "input-error" : ""}
                                                                    />
                                                                    {errors.title_of_pathology && touched.title_of_pathology && (
                                                                        <p className="error">{errors.title_of_pathology}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="description_of_pathology" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Description of Pathology<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        name="description_of_pathology"
                                                                        value={values.description_of_pathology}
                                                                        style={{ height: "100px" }}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={
                                                                            errors.description_of_pathology && touched.description_of_pathology
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors.description_of_pathology && touched.description_of_pathology && (
                                                                        <p className="error">{errors.description_of_pathology}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="grade_of_injury" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Grade of Pathology<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="grade_of_injury"
                                                                        value={values.grade_of_injury}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.grade_of_injury && touched.grade_of_injury ? "input-error" : ""}
                                                                    />
                                                                    {errors.grade_of_injury && touched.grade_of_injury && (
                                                                        <p className="error">{errors.grade_of_injury}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            {/* <Form.Group controlId="rehabilitation" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Rehabilitation<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        rows={4}
                                                                        name="rehabilitation"
                                                                        value={values.rehabilitation}
                                                                        style={{ height: "100px" }}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.rehabilitation && touched.rehabilitation ? "input-error" : ""}
                                                                    />
                                                                    {errors.rehabilitation && touched.rehabilitation && (
                                                                        <p className="error">{errors.rehabilitation}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group> */}
                                                            <Row>
                                                                <Col className="text-end">
                                                                    <Button variant="primary" type="submit" className="my-4" disabled={isSubmitting}>
                                                                        <span className="px-4">Save</span>
                                                                        {isSubmitting ? <LinearProgress /> : ""}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </TabPanel>
                                                        <TabPanel value="2">
                                                            {/* <Form.Group controlId="adipose_concentrate_dose" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">Adipose Concentrate Dose</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="adipose_concentrate_dose"
                                                                        value={values.adipose_concentrate_dose}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={
                                                                            errors.adipose_concentrate_dose && touched.adipose_concentrate_dose
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors.adipose_concentrate_dose && touched.adipose_concentrate_dose && (
                                                                        <p className="error">{errors.adipose_concentrate_dose}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="adipose_concentrate" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">Adipose Concentrate</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                name="adipose_concentrate"
                                                                                checked={formik.values.adipose_concentrate.includes("Adipose concentrate")}
                                                                                onChange={(event) => {
                                                                                    const isChecked = event.target.checked;
                                                                                    formik.setFieldValue(
                                                                                        "adipose_concentrate",
                                                                                        isChecked
                                                                                            ? [...formik.values.adipose_concentrate, "Adipose concentrate"]
                                                                                            : formik.values.adipose_concentrate.filter(
                                                                                                  (type) => type !== "Adipose concentrate"
                                                                                              )
                                                                                    );
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        }
                                                                        label="Adipose concentrate"
                                                                    />
                                                                    {formik.errors.adipose_concentrate && formik.touched.adipose_concentrate && (
                                                                        <p className="error">{formik.errors.adipose_concentrate}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="ac_total_volume" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">AC Total Volume</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        name="ac_total_volume"
                                                                        value={values.ac_total_volume}
                                                                        min={0}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.ac_total_volume && touched.ac_total_volume ? "input-error" : ""}
                                                                    />
                                                                    {errors.ac_total_volume && touched.ac_total_volume && (
                                                                        <p className="error">{errors.ac_total_volume}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="bc_total_volume" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">BC Total Volume</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        name="bc_total_volume"
                                                                        value={values.bc_total_volume}
                                                                        min={0}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.bc_total_volume && touched.bc_total_volume ? "input-error" : ""}
                                                                    />
                                                                    {errors.bc_total_volume && touched.bc_total_volume && (
                                                                        <p className="error">{errors.bc_total_volume}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="pc_total_volume" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">PC Total Volume</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        name="pc_total_volume"
                                                                        value={values.pc_total_volume}
                                                                        min={0}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.pc_total_volume && touched.pc_total_volume ? "input-error" : ""}
                                                                    />
                                                                    {errors.pc_total_volume && touched.pc_total_volume && (
                                                                        <p className="error">{errors.pc_total_volume}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group> 
                                                            <Form.Group controlId="platelet_concentrate" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">Platelet Concentrate</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                name="platelet_concentrate"
                                                                                checked={formik.values.platelet_concentrate.includes("Platelet concentrate")}
                                                                                onChange={(event) => {
                                                                                    const isChecked = event.target.checked;
                                                                                    formik.setFieldValue(
                                                                                        "platelet_concentrate",
                                                                                        isChecked
                                                                                            ? [...formik.values.platelet_concentrate, "Platelet concentrate"]
                                                                                            : formik.values.platelet_concentrate.filter(
                                                                                                  (type) => type !== "Platelet concentrate"
                                                                                              )
                                                                                    );
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        }
                                                                        label="Platelet concentrate"
                                                                    />

                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                name="platelet_concentrate"
                                                                                checked={formik.values.platelet_concentrate.includes(
                                                                                    "Protein rich platelet concentrate"
                                                                                )}
                                                                                onChange={(event) => {
                                                                                    const isChecked = event.target.checked;
                                                                                    formik.setFieldValue(
                                                                                        "platelet_concentrate",
                                                                                        isChecked
                                                                                            ? [
                                                                                                  ...formik.values.platelet_concentrate,
                                                                                                  "Protein rich platelet concentrate",
                                                                                              ]
                                                                                            : formik.values.platelet_concentrate.filter(
                                                                                                  (type) => type !== "Protein rich platelet concentrate"
                                                                                              )
                                                                                    );
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        }
                                                                        label="Protein rich platelet concentrate"
                                                                    />

                                                                    {formik.errors.platelet_concentrate && formik.touched.platelet_concentrate && (
                                                                        <p className="error">{formik.errors.platelet_concentrate}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>*/}
                                                            <Form.Group controlId="bmc_platelet_dose " className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">BMC Platelet Dose</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="bmc_platelet_dose"
                                                                        value={values.bmc_platelet_dose}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.bmc_platelet_dose && touched.bmc_platelet_dose ? "input-error" : ""}
                                                                    />
                                                                    {errors.bmc_platelet_dose && touched.bmc_platelet_dose && (
                                                                        <p className="error">{errors.bmc_platelet_dose}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="bmc_wpc_dose" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">BMC WBC Dose</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="bmc_wpc_dose"
                                                                        value={values.bmc_wpc_dose}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                    {errors.bmc_wpc_dose && touched.bmc_wpc_dose && (
                                                                        <p className="error">{errors.bmc_wpc_dose}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="prp_type" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">PRP Type</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                name="prp_type"
                                                                                checked={formik.values.prp_type.includes("Platelet Rich Plasma")}
                                                                                onChange={(event) => {
                                                                                    const isChecked = event.target.checked;
                                                                                    formik.setFieldValue(
                                                                                        "prp_type",
                                                                                        isChecked
                                                                                            ? [...formik.values.prp_type, "Platelet Rich Plasma"]
                                                                                            : formik.values.prp_type.filter(
                                                                                                  (type) => type !== "Platelet Rich Plasma"
                                                                                              )
                                                                                    );
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        }
                                                                        label="Platelet Rich Plasma"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                name="prp_type"
                                                                                checked={formik.values.prp_type.includes("Protein Rich PRP")}
                                                                                onChange={(event) => {
                                                                                    const isChecked = event.target.checked;
                                                                                    formik.setFieldValue(
                                                                                        "prp_type",
                                                                                        isChecked
                                                                                            ? [...formik.values.prp_type, "Protein Rich PRP"]
                                                                                            : formik.values.prp_type.filter(
                                                                                                  (type) => type !== "Protein Rich PRP"
                                                                                              )
                                                                                    );
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        }
                                                                        label="Protein Rich PRP"
                                                                    />

                                                                    {formik.errors.prp_type && formik.touched.prp_type && (
                                                                        <p className="error">{formik.errors.prp_type}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="prp_platelet_dose" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">PRP Platelet Dose</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="prp_platelet_dose"
                                                                        value={values.prp_platelet_dose}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.prp_platelet_dose && touched.prp_platelet_dose ? "input-error" : ""}
                                                                    />
                                                                    {errors.prp_platelet_dose && touched.prp_platelet_dose && (
                                                                        <p className="error">{errors.prp_platelet_dose}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="leukocyte_type" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">Leukocyte</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={formik.values.leukocyte_type.includes("Leukocyte rich")}
                                                                                onChange={(event) => {
                                                                                    const isChecked = event.target.checked;
                                                                                    formik.setFieldValue(
                                                                                        "leukocyte_type",
                                                                                        isChecked
                                                                                            ? [...formik.values.leukocyte_type, "Leukocyte rich"]
                                                                                            : formik.values.leukocyte_type.filter(
                                                                                                  (type) => type !== "Leukocyte rich"
                                                                                              )
                                                                                    );
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                                name="leukocyte_type"
                                                                            />
                                                                        }
                                                                        label="Leukocyte rich"
                                                                    />

                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={formik.values.leukocyte_type.includes("Leukocyte poor")}
                                                                                onChange={(event) => {
                                                                                    const isChecked = event.target.checked;
                                                                                    formik.setFieldValue(
                                                                                        "leukocyte_type",
                                                                                        isChecked
                                                                                            ? [...formik.values.leukocyte_type, "Leukocyte poor"]
                                                                                            : formik.values.leukocyte_type.filter(
                                                                                                  (type) => type !== "Leukocyte poor"
                                                                                              )
                                                                                    );
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                                name="leukocyte_type"
                                                                            />
                                                                        }
                                                                        label="Leukocyte poor"
                                                                    />

                                                                    {formik.errors.leukocyte_type && formik.touched.leukocyte_type && (
                                                                        <p className="error">{formik.errors.leukocyte_type}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Row>
                                                                <Col className="text-end">
                                                                    <Button variant="primary" type="submit" className="my-4" disabled={isSubmitting}>
                                                                        <span className="px-4">Save</span>
                                                                        {isSubmitting ? <LinearProgress /> : ""}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </TabPanel>
                                                        <TabPanel value="3">
                                                            <Form.Group controlId="anesthesia_type" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Type of Anesthesia<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="anesthesia_type"
                                                                        value={values.anesthesia_type}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.anesthesia_type && touched.anesthesia_type ? "input-error" : ""}
                                                                    />
                                                                    {errors.anesthesia_type && touched.anesthesia_type && (
                                                                        <p className="error">{errors.anesthesia_type}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="anesthetic_name" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Name of Anesthetic<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="anesthetic_name"
                                                                        value={values.anesthetic_name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.anesthetic_name && touched.anesthetic_name ? "input-error" : ""}
                                                                    />
                                                                    {errors.anesthetic_name && touched.anesthetic_name && (
                                                                        <p className="error">{errors.anesthetic_name}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="anesthetic_dose" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Dose of Anesthetic<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="anesthetic_dose"
                                                                        value={values.anesthetic_dose}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.anesthetic_dose && touched.anesthetic_dose ? "input-error" : ""}
                                                                    />
                                                                    {errors.anesthetic_dose && touched.anesthetic_dose && (
                                                                        <p className="error">{errors.anesthetic_dose}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="injection_technique" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Injection Technique<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="injection_technique"
                                                                        value={values.injection_technique}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={
                                                                            errors.injection_technique && touched.injection_technique ? "input-error" : ""
                                                                        }
                                                                    />
                                                                    {errors.injection_technique && touched.injection_technique && (
                                                                        <p className="error">{errors.injection_technique}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="anesthetics_rationale" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Anesthetics Rationale<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="anesthetics_rationale"
                                                                        value={values.anesthetics_rationale}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={
                                                                            errors.anesthetics_rationale && touched.anesthetics_rationale ? "input-error" : ""
                                                                        }
                                                                    />
                                                                    {errors.anesthetics_rationale && touched.anesthetics_rationale && (
                                                                        <p className="error">{errors.anesthetics_rationale}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Row>
                                                                <Col className="text-end">
                                                                    <Button variant="primary" type="submit" className="my-4" disabled={isSubmitting}>
                                                                        <span className="px-4">Save</span>
                                                                        {isSubmitting ? <LinearProgress /> : ""}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </TabPanel>
                                                        <TabPanel value="4">
                                                            <InjectionSiteEditMode pathoId={location.state.patho_id} />
                                                        </TabPanel>
                                                    </TabContext>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box sx={{ width: "100%", typography: "body1" }}>
                                                <TabContext value={value}>
                                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                        <TabList
                                                            onChange={handleTabChange}
                                                            variant="scrollable"
                                                            scrollButtons="auto"
                                                            allowScrollButtonsMobile
                                                            aria-label="scrollable auto tabs example"
                                                        >
                                                            <Tab
                                                                label="Pathology"
                                                                value="1"
                                                                icon={<FontAwesomeIcon icon={faVialVirus} />}
                                                                iconPosition="start"
                                                            />
                                                            <Tab
                                                                label="Autologous Biologic"
                                                                value="2"
                                                                icon={<FontAwesomeIcon icon={faDna} />}
                                                                iconPosition="start"
                                                            />
                                                            <Tab label="Anesthetics" value="3" icon={<VaccinesIcon />} iconPosition="start" />
                                                            <Tab
                                                                label="Injection Sites & Volume"
                                                                value="4"
                                                                icon={<FontAwesomeIcon icon={faSyringe} />}
                                                                iconPosition="start"
                                                            />
                                                        </TabList>
                                                    </Box>
                                                    <TabPanel value="1">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Ultrasound Video Link</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <iframe
                                                                    style={{
                                                                        width: "100%",
                                                                        maxWidth: "300px",
                                                                        height: "180px",
                                                                    }}
                                                                    src={values.ultrasound_video_link ? values.ultrasound_video_link : ""}
                                                                    title="YouTube video player"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen
                                                                ></iframe>
                                                                {/* <iframe
                                                                    src="https://player.vimeo.com/video/913684794?h=af5c4a99f4"
                                                                    width="320"
                                                                    height="180"
                                                                    frameborder="0"
                                                                    allow="autoplay; fullscreen; picture-in-picture"
                                                                    allowfullscreen
                                                                ></iframe> */}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Title</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.title_of_pathology ? values.title_of_pathology : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Description of Pathology</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.description_of_pathology ? values.description_of_pathology : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Grade of Pathology</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.grade_of_injury ? values.grade_of_injury : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Rehabilitation</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.rehabilitation ? values.rehabilitation : "N/A"}</p>
                                                            </div>
                                                        </div> */}
                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                        {/* <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Adipose Concentrate Dose</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.adipose_concentrate_dose ? values.adipose_concentrate_dose : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Adipose Concentrate</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{pathologyData.adipose_concentrate ? pathologyData.adipose_concentrate : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">AC Total Volume</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.ac_total_volume ? values.ac_total_volume : "N/A"}</p>
                                                            </div>
                                                        </div> 
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">BC Total Volume</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.bc_total_volume ? values.bc_total_volume : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">PC Total Volume</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.pc_total_volume ? values.pc_total_volume : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                         <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Platelet Concentrate</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{pathologyData.platelet_concentrate ? pathologyData.platelet_concentrate : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        */}
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">BMC Platelet Dose</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.bmc_platelet_dose ? values.bmc_platelet_dose : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">BMC WBC Dose</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{pathologyData.bmc_wpc_dose ? pathologyData.bmc_wpc_dose : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">PRP Type</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{pathologyData.prp_type ? pathologyData.prp_type : "N/A"}</p>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">PRP Platelet Dose</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.prp_platelet_dose ? values.prp_platelet_dose : "N/A"}</p>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Leukocyte</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{pathologyData.leukocyte_type ? pathologyData.leukocyte_type : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="3">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Type of Anesthesia</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.anesthesia_type ? values.anesthesia_type : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Name of Anesthetic</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.anesthetic_name ? values.anesthetic_name : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Dose of Anesthetic</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.anesthetic_dose ? values.anesthetic_dose : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Injection Technique</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.injection_technique ? values.injection_technique : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Anesthetics Rationale</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{values.anesthetics_rationale ? values.anesthetics_rationale : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="4">
                                                        <InjectionSiteListMode pathoId={location.state.patho_id} />
                                                    </TabPanel>
                                                </TabContext>
                                            </Box>
                                        )}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ClinicalView;
