import React, { useState, useEffect } from "react";

const GreetingComponent = () => {
    const [greeting, setGreeting] = useState("");

    useEffect(() => {
        const currentHour = new Date().getHours();

        if (currentHour >= 5 && currentHour < 12) {
            setGreeting("Good Morning");
        } else if (currentHour >= 12 && currentHour < 18) {
            setGreeting("Good Afternoon");
        } else {
            setGreeting("Good Evening");
        }
    }, []); // The empty dependency array ensures that the effect runs only once after the initial render

    return <h5 className="mb-0">Hi, {greeting}!</h5>;
};

export default GreetingComponent;
