import React from "react";
import { useNavigate } from "react-router-dom";

function PaypalFailed() {
    const navigate = useNavigate();
    return (
        <div className="row justify-content-center success-card mt-5 mr-0">
            <div className="col-10 col-lg-6 my-auto">
                <div className="card text-center">
                    <div className="card-body">
                        <img src="images/emcyte/failed.png" alt="Failed" width="275px" />
                        <p>Thank you for registering at Emstyle.</p>
                        <h1>Your Payment has been failed</h1>
                        <p className="my-3">
                            <strong>
                                Please contact{" "}
                                <a href="tel:18007555775" class="link-underline-light">
                                    1800 755 5775
                                </a>{" "}
                            </strong>
                            for further instructions.
                        </p>
                        <button onClick={() => navigate("/Patient")} className="btn btn-danger my-2 text-white link">
                            Back To Site
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaypalFailed;
