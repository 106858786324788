import React, { useState, useEffect, useRef } from "react";
import { MapContainer, useMap, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { security_key, BASE_URL } from "../../global";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

function LeafletMap() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getMapCredential();
    }, []);

    const [mapData, setMapData] = useState([]);

    const getMapCredential = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getMapCredential",
                map_id: 2,
            });

            const output = response.data;

            if (output) {
                setMapData(output);
                setLoading(false);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const center = [37.0902, -95.7129]; // Coordinates for the center of the United States

    const customIcon = new L.Icon({
        // iconUrl: iconToUrl(<RoomIcon style={{ color: "red" }} />),
        iconSize: [32, 42],
        iconAnchor: [16, 32],
        iconUrl: "./images/marker-icon.png",
        // iconSize: [22, 22],
    });

    /****Map functional****/

    const handleCityClick = (data) => {
        navigate("/PatientAdd", { state: { city_id: data.pk_city_id, city_name: data.name, city_state: data.state } });
    };

    const [cities, setCities] = useState([]); // State to hold cities data

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getAllProviderCity",
            });

            const output = response.data;

            if (output.length > 0) {
                const formattedOptions = output.map((option) => ({
                    pk_city_id: option.pk_city_id,
                    state: option.State,
                    name: option.City,
                    coordinates: [option.Latitude, option.Longitude],
                }));

                setCities(formattedOptions); // Update state with fetched data
            } else {
                // Handle the case when output is empty
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const CustomZoomControl = () => {
        const map = useMap();

        const handleWheel = (e) => {
            e.preventDefault(); // Prevent the default browser zoom behavior

            if (e.ctrlKey) {
                // Zoom only when the Ctrl key is pressed
                const delta = e.deltaY;
                const zoomStep = 1;

                if (delta > 0) {
                    // Scroll down
                    map.setZoom(map.getZoom() - zoomStep);
                } else if (delta < 0) {
                    // Scroll up
                    map.setZoom(map.getZoom() + zoomStep);
                }
            }
        };

        // Attach the wheel event to the map
        map.getContainer().addEventListener("wheel", handleWheel);

        return null; // This component doesn't render anything
    };

    return (
        !loading && (
            <MapContainer
                className="custom-map"
                // style={{ height: "100vh", width: "100%" }}
                style={{ height: mapData.height + "px", width: mapData.width + "%" }}
                center={center}
                zoom={3.5}
                scrollWheelZoom={mapData.scroll_wheel_zoom == 1}
                maxZoom={mapData.max_zoom}
                minZoom={mapData.min_zoom}
            >
                <TileLayer
                    noWrap={true}
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url={mapData.theme_link}
                />

                {cities.map((city, index) => (
                    <Marker
                        key={index}
                        position={city.coordinates}
                        icon={customIcon}
                        rotation3d={[1, 0, 0, 320]}
                        rotationAngle={320}
                        eventHandlers={{ click: () => handleCityClick(city) }}
                    >
                        <Tooltip autoClose={false} direction="top" offset={[0, -33]}>
                            {city.name}
                        </Tooltip>
                    </Marker>
                ))}

                <CustomZoomControl />
            </MapContainer>
        )
    );
}

export default LeafletMap;
