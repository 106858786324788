import React, { useState, useRef, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";

import Select from "react-select";
import axios from "axios";
import { security_key, BASE_URL, options_city } from "../../global";

import "./AddForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
// import Button from "@mui/material/Button";

const basicSchema = Yup.object().shape({
    patient_first_name: Yup.string()
        .min(3, "Patient First Name must be at least 3 characters.")
        .max(250, "Patient First Name must be at most 250 characters.")
        .matches(/^[a-zA-Z]*$/, "Patient First Name can only contain letters.")
        .required("Patient First Name is required.")
        .strict(),
    patient_last_name: Yup.string()
        .min(3, "Patient Last Name must be at least 3 characters.")
        .max(250, "Patient Last Name must be at most 250 characters.")
        .matches(/^[a-zA-Z]*$/, "Patient Last Name can only contain letters.")
        .required("Patient Last Name is required.")
        .strict(),
    patient_mobile_phone: Yup.string()
        .required("Patient Mobile number is required.")
        .min(11, "Patient Mobile number must be at least 10 digits.")
        .max(16, "Patient Mobile number must not exceed 15 digits.")
        .test("unique-digits", "Invalid mobile number.", function (value) {
            const cleanedDigits = value.replace(/^\+\D*/, "");
            const digitsWithoutCountryCode = cleanedDigits.slice(1);
            const hasDifferentDigit = Array.from(new Set(digitsWithoutCountryCode)).length !== 1;
            return hasDifferentDigit || this.createError({ message: "Invalid mobile number." });
        })
        .strict(),
    patient_other_phone: Yup.string()
        // .min(11, "Patient Other Phone number must be at least 10 digits")
        // .max(16, "Patient Other Phone number must not exceed 15 digits")
        // .notRequired()
        .test("unique-digits.", "Invalid mobile number.", function (value) {
            if (value && value !== "1") {
                if (value.length < 11) {
                    return this.createError({ message: "Patient Alternate Phone number must be at least 10 digits." });
                }

                const cleanedDigits = value.replace(/^\+\D*/, "");
                const digitsWithoutCountryCode = cleanedDigits.slice(1);
                const hasDifferentDigit = new Set(digitsWithoutCountryCode).size > 1;

                return hasDifferentDigit || this.createError({ message: "Invalid mobile number." });
            } else {
                return true;
            }
        })
        .strict(),

    patient_email: Yup.string()
        .email("Invalid email.")
        .required("Patient Email is required.")
        .test("valid-email.", "Invalid email format.", function (value) {
            if (!value) return true; // Allow empty value (let required() handle this)

            // Check for invalid patterns
            const invalidPatterns = [
                /^\./, // Starts with a dot
                /\.$/, // Ends with a dot
                /\.{2,}/, // Multiple dots in sequence
                /\.@/, // Starts with a dot immediately followed by @
                // /\.com.com$/,
                /(\.com){2,}$/, // Ends with .com repeated one or more times
                // /@\.\w+$/,
            ];

            const validPatterns = [/@.*\.[^.].*$/];

            const isInvalid = invalidPatterns.some((pattern) => pattern.test(value));

            const isValid = validPatterns.some((pattern) => pattern.test(value));

            return isValid && !isInvalid;
        }),
    patient_city: Yup.string().required("City is required."),
    patient_state: Yup.string().strict(),

    patient_address_1: Yup.string()
        .required("Patient Address 1 is required.")
        .min(3, "Patient Address 1 must be at least 3 characters.")
        .max(250, "Patient Address 1 must be at most 250 characters."),

    patient_address_2: Yup.string().min(3, "Patient Address 2 must be at least 3 characters.").max(250, "Patient Address 2 must be at most 250 characters."),
    patient_zip: Yup.string()
        .min(5, "Patient Zip Code must be at least 5 digits.")
        .max(10, "Patient Zip Code must be at most 9 digits.")
        .required("Patient Zip Code is required.")
        // .matches(/^\d{5}$/, "Invalid ZIP code.")
        // .test("is-valid-zip.", "Invalid ZIP code.", (value) => Number(value) >= 1 && Number(value) <= 99950)
        .strict(),
    patient_schedule_date: Yup.string().required("Patient Schedule date is required."),

    description_of_condition: Yup.string()
        .min(8, "Description of condition must be at least 8 characters.")
        .max(250, "Description of condition must be at most 250 characters.")
        .strict(),
    area_of_concern: Yup.string()
        .required("Area of concern is required.")
        .min(8, "Area of concern must be at least 8 characters.")
        .max(250, "Area of concern must be at most 250 characters.")
        .strict(),
    urgency: Yup.string().required("Urgency is required.").strict(),
    preferred_contact_method: Yup.string().when("urgency", {
        is: "Other",
        then: () =>
            Yup.string()
                .required("Preferred Contact Method is required.")
                .matches(/^[a-zA-Z ]*$/, "Preferred Contact Method can only contain letters and spaces.")
                .min(3, "Preferred Contact Method must be at least 3 characters.")
                .max(250, "Preferred Contact Method must be at most 250 characters."),
    }),
    preferred_contact_time: Yup.string()
        .min(3, "Preferred Contact Time must be at least 3 characters.")
        .max(250, "Preferred Contact Time must be at most 250 characters.")
        .strict(),
    how_did_you_hear_about_us: Yup.string()
        .min(3, "How did you hear about us must be at least 3 characters.")
        .max(250, "How did you hear about us must be must be at most 250 characters.")
        .strict(),
});
const PatientForm = () => {
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);
    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm();
        setValues({
            ...initialValues,
        });
    };
    async function checkPatientEmailUniqueness(email) {
        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "checkPatientEmailUniqueness",
            patient_email: email,
        });

        const output = response.data;

        return output;
    }

    const initialValues = {
        /*tbl_patient_information*/
        patient_first_name: "",
        patient_last_name: "",
        patient_address_1: "",
        patient_address_2: "",
        patient_city: "",
        patient_state: "",
        patient_zip: "",
        patient_schedule_date: "",
        patient_email: "",
        patient_mobile_phone: "",
        patient_other_phone: "",
        description_of_condition: "",
        area_of_concern: "",
        urgency: "",
        preferred_contact_method: "",
        preferred_contact_time: "",
        how_did_you_hear_about_us: "",
        created_by: sessionStorage.getItem("loger_id"),
        // updated_on: formattedDate,
        visibility: "1",
    };

    // console.log(patientData.patient_city);

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        validate: async (values) => {
            const errors = {};

            // Check Patient email uniqueness
            const isPatientEmailUnique = await checkPatientEmailUniqueness(values.patient_email);
            if (!isPatientEmailUnique) {
                errors.patient_email = "Patient Email is already taken";
            }

            return errors;
        },
        onSubmit: async (formValues) => {
            toast.dismiss();

            setIsSubmitting(true);

            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "addPatientByAdmin",
                ...formValues,
            });
            const output = response.data;
            if (output == 1) {
                // resetFormFields(resetForm, setValues, initialValues);
                toast.success("Added successfully!", {
                    autoClose: 3000,
                });
                setTimeout(function () {
                    navigate("/Admin/PatientList");
                }, 3000);
            } else {
                toast.error("Oops! Something went wrong.", {
                    autoClose: 3000,
                });
            }
            setTimeout(function () {
                setIsSubmitting(false);
            }, 3000);
        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;

    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) {
            const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
            // console.log(firstErrorField);

            const errorFieldElement = document.getElementById(firstErrorField);

            if (errorFieldElement) {
                const elementRect = errorFieldElement.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const middleOfElement = absoluteElementTop - window.innerHeight / 2;

                window.scrollTo({
                    top: middleOfElement,
                    behavior: "smooth",
                });
            } else {
                // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
            //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
    }, [formik]);

    // const resetFormFields = (resetForm, setValues, initialValues) => {
    //     resetForm(); // Reset Formik-controlled fields
    // };

    // function handleClick(event) {
    //     event.preventDefault();
    //     console.info("You clicked a breadcrumb.");
    // }

    const themes = sessionStorage.getItem("theme");

    const commonStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "white",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "black",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                },
            }),
        },
        react_tel: "",
    };

    const darkThemeStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "#343541",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "white",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                    // color: "black",
                },
            }),
        },
        react_tel: "",
    };

    const colourStyles = themes == "dark" ? darkThemeStyles : commonStyles;

    return (
        <div className="app-content">
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="section-header d-flex align-items-center justify-content-between">
                        <div className="section-heading">
                            <h4 className="mb-1">Add Patient</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                            Home
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a role="button" onClick={() => navigate("/Admin/PatientList")}>
                                            Patients
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Add Patient
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div>
                            <button className="btn btn-light btn-md border" onClick={() => navigate("/Admin/PatientList")}>
                                Back
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" />
                    <Row>
                        <Col md={12} className="mx-auto">
                            <div className="card p-3 p-md-5">
                                <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="patient_first_name">
                                                <Form.Label>
                                                    Patient First Name<span className="text-danger">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="patient_first_name"
                                                    value={values.patient_first_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.patient_first_name && touched.patient_first_name ? "input-error" : ""}
                                                />
                                                {errors.patient_first_name && touched.patient_first_name && (
                                                    <p className="error">{errors.patient_first_name}</p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="patient_last_name">
                                                <Form.Label>
                                                    Patient Last Name<span className="text-danger">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="patient_last_name"
                                                    value={values.patient_last_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.patient_last_name && touched.patient_last_name ? "input-error" : ""}
                                                />
                                                {errors.patient_last_name && touched.patient_last_name && <p className="error">{errors.patient_last_name}</p>}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="patient_address_1">
                                                <Form.Label>
                                                    Patient Address 1<span className="text-danger">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: "100px" }}
                                                    value={values.patient_address_1}
                                                    onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    onBlur={(e) => {
                                                        const trimmedValue = e.target.value.trim();
                                                        const event = {
                                                            target: {
                                                                name: "patient_address_1",
                                                                value: trimmedValue,
                                                            },
                                                        };
                                                        handleChange(event);
                                                        handleBlur(event);
                                                    }}
                                                    className={errors.patient_address_1 && touched.patient_address_1 ? "input-error" : ""}
                                                />
                                                {errors.patient_address_1 && touched.patient_address_1 && <p className="error">{errors.patient_address_1}</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="patient_address_2">
                                                <Form.Label>Patient Address 2</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: "100px" }}
                                                    name="patient_address_2"
                                                    value={values.patient_address_2}
                                                    onChange={handleChange}
                                                    onBlur={(e) => {
                                                        const trimmedValue = e.target.value.trim();
                                                        const event = {
                                                            target: {
                                                                name: "patient_address_2",
                                                                value: trimmedValue,
                                                            },
                                                        };
                                                        handleChange(event);
                                                        handleBlur(event);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md="6" className="patient_city">
                                            <Form.Label>
                                                Patient City<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Select
                                                options={options_city}
                                                name="patient_city"
                                                id="patient_city"
                                                styles={colourStyles.react_select}
                                                value={
                                                    values &&
                                                    values.patient_city &&
                                                    options_city.find((option) => {
                                                        // alert(values.patient_city);
                                                        return option.value && option.value.toString() === values.patient_city.toString();
                                                    })
                                                }
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: "patient_city",
                                                            value: selectedOption.value,
                                                        },
                                                    };
                                                    handleChange(event);
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event);
                                                    setTouched({ ...touched, patient_city: true });
                                                }}
                                                filterOption={(option, inputValue) => {
                                                    return option.label.toLowerCase().includes(inputValue.toLowerCase());
                                                }}
                                                className={errors.patient_city && touched.patient_city ? "input-error" : ""}
                                            />

                                            {errors.patient_city && touched.patient_city && <p className="error">{errors.patient_city}</p>}
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="patient_state">
                                                <Form.Label>Patient State</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    readOnly={true}
                                                    name="patient_state"
                                                    value={values.patient_city.split(" || ")[2]}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.patient_state && touched.patient_state ? "input-error" : ""}
                                                />
                                                {errors.patient_state && touched.patient_state && <p className="error">{errors.patient_state}</p>}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {/* <Form.Group controlId="patient_city">
                                        <Form.Label>
                                            Patient City<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="patient_city"
                                            value={values.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={errors.patient_city && touched.patient_city ? "input-error" : ""}
                                        />
                                        {errors.patient_city && touched.patient_city && <p className="error">{errors.patient_city}</p>}
                                    </Form.Group> */}

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group controlId="patient_zip">
                                                <Form.Label>
                                                    Patient Zip Code<span className="text-danger">*</span>
                                                </Form.Label>
                                                {/* <Form.Control
                                                    type="text"
                                                    name="patient_zip"
                                                    value={values.patient_zip}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.patient_zip && touched.patient_zip ? "input-error" : ""}
                                                /> */}
                                                <InputMask
                                                    mask="99999-9999"
                                                    maskChar=""
                                                    type="text"
                                                    name="patient_zip"
                                                    value={values.patient_zip}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${errors.patient_zip && touched.patient_zip ? "input-error" : ""}`}
                                                />
                                                {errors.patient_zip && touched.patient_zip && <p className="error">{errors.patient_zip}</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="patient_schedule_date">
                                                <Form.Label>
                                                    Patient Schedule Date<span className="text-danger">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="patient_schedule_date"
                                                    value={values.patient_schedule_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.patient_schedule_date && touched.patient_schedule_date ? "input-error" : ""}
                                                />
                                                {errors.patient_schedule_date && touched.patient_schedule_date && (
                                                    <p className="error">{errors.patient_schedule_date}</p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col sm={6}>
                                            <Form.Group controlId="description_of_condition">
                                                <Form.Label>Description of Condition</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: "100px" }}
                                                    rows={3}
                                                    name="description_of_condition"
                                                    value={values.description_of_condition}
                                                    onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    onBlur={(e) => {
                                                        const trimmedValue = e.target.value.trim();
                                                        const event = {
                                                            target: {
                                                                name: "description_of_condition",
                                                                value: trimmedValue,
                                                            },
                                                        };
                                                        handleChange(event);
                                                        handleBlur(event);
                                                    }}
                                                    className={errors.description_of_condition && touched.description_of_condition ? "input-error" : ""}
                                                />
                                                {errors.description_of_condition && touched.description_of_condition && (
                                                    <p className="error">{errors.description_of_condition}</p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group controlId="area_of_concern">
                                                <Form.Label>
                                                    Area of Concern<span className="text-danger">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: "100px" }}
                                                    rows={3}
                                                    name="area_of_concern"
                                                    value={values.area_of_concern}
                                                    onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    onBlur={(e) => {
                                                        const trimmedValue = e.target.value.trim();
                                                        const event = {
                                                            target: {
                                                                name: "area_of_concern",
                                                                value: trimmedValue,
                                                            },
                                                        };
                                                        handleChange(event);
                                                        handleBlur(event);
                                                    }}
                                                    className={errors.area_of_concern && touched.area_of_concern ? "input-error" : ""}
                                                />
                                                {errors.area_of_concern && touched.area_of_concern && <p className="error">{errors.area_of_concern}</p>}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col sm={4}>
                                            <Form.Group controlId="patient_email">
                                                <Form.Label>
                                                    Patient Email<span className="text-danger">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    value={values.patient_email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.patient_email && touched.patient_email ? "input-error" : ""}
                                                />
                                                {errors.patient_email && touched.patient_email && <p className="error">{errors.patient_email}</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group controlId="patient_mobile_phone">
                                                <Form.Label>
                                                    Patient Mobile Phone<span className="text-danger">*</span>
                                                </Form.Label>
                                                <PhoneInput
                                                    name="patient_mobile_phone"
                                                    countryCodeEditable={false}
                                                    country="us"
                                                    value={values.patient_mobile_phone}
                                                    onlyCountries={["us"]}
                                                    onChange={(value) => {
                                                        const event = {
                                                            target: {
                                                                name: "patient_mobile_phone",
                                                                value: value,
                                                            },
                                                        };
                                                        handleChange(event);
                                                    }}
                                                    onBlur={(event) => {
                                                        handleBlur(event);
                                                        setTouched({ ...touched, patient_mobile_phone: true });
                                                    }}
                                                    inputClass={
                                                        errors.patient_mobile_phone && touched.patient_mobile_phone
                                                            ? "input-error form-control"
                                                            : "form-control"
                                                    }
                                                />
                                                {errors.patient_mobile_phone && touched.patient_mobile_phone && (
                                                    <p className="error">{errors.patient_mobile_phone}</p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group controlId="patient_other_phone">
                                                <Form.Label>Alternate Phone</Form.Label>
                                                <PhoneInput
                                                    name="patient_other_phone"
                                                    countryCodeEditable={false}
                                                    country="us"
                                                    value={values.patient_other_phone}
                                                    onlyCountries={["us"]}
                                                    onChange={(value) => {
                                                        console.log(value);

                                                        const event = {
                                                            target: {
                                                                name: "patient_other_phone",
                                                                value: value,
                                                            },
                                                        };
                                                        handleChange(event);
                                                    }}
                                                    onBlur={(event) => {
                                                        handleBlur(event);
                                                        setTouched({ ...touched, patient_other_phone: true });
                                                    }}
                                                    inputClass={
                                                        errors.patient_other_phone && touched.patient_other_phone ? "input-error form-control" : "form-control"
                                                    }
                                                />
                                                {errors.patient_other_phone && touched.patient_other_phone && (
                                                    <p className="error">{errors.patient_other_phone}</p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col sm={4}>
                                            <Form.Group controlId="urgency" className="mb-3">
                                                <Form.Label>
                                                    Urgency<span className="text-danger">*</span>
                                                </Form.Label>
                                                <div className="radio-group">
                                                    <Form.Check
                                                        type="radio"
                                                        id="urgencyASAP"
                                                        label="ASAP"
                                                        name="urgency"
                                                        value="ASAP"
                                                        checked={values.urgency === "ASAP"}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="urgencyOther"
                                                        label="Other"
                                                        name="urgency"
                                                        value="Other"
                                                        checked={values.urgency === "Other"}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                {errors.urgency && touched.urgency && <p className="error">{errors.urgency}</p>}
                                            </Form.Group>

                                            {values.urgency === "Other" && (
                                                <Form.Group controlId="preferred_contact_method">
                                                    <Form.Label>
                                                        Preferred Contact Method<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="preferred_contact_method"
                                                        value={values.preferred_contact_method}
                                                        onChange={handleChange}
                                                        // onBlur={handleBlur}
                                                        onBlur={(e) => {
                                                            const trimmedValue = e.target.value.trim();
                                                            const event = {
                                                                target: {
                                                                    name: "preferred_contact_method",
                                                                    value: trimmedValue,
                                                                },
                                                            };
                                                            handleChange(event);
                                                            handleBlur(event);
                                                        }}
                                                        className={errors.preferred_contact_method && touched.preferred_contact_method ? "input-error" : ""}
                                                    />
                                                    {errors.preferred_contact_method && touched.preferred_contact_method && (
                                                        <p className="error">{errors.preferred_contact_method}</p>
                                                    )}
                                                </Form.Group>
                                            )}
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group controlId="preferred_contact_time">
                                                <Form.Label>Preferred Contact Time</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="preferred_contact_time"
                                                    value={values.preferred_contact_time}
                                                    onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    onBlur={(e) => {
                                                        const trimmedValue = e.target.value.trim();
                                                        const event = {
                                                            target: {
                                                                name: "preferred_contact_time",
                                                                value: trimmedValue,
                                                            },
                                                        };
                                                        handleChange(event);
                                                        handleBlur(event);
                                                    }}
                                                    className={errors.preferred_contact_time && touched.preferred_contact_time ? "input-error" : ""}
                                                />
                                                {errors.preferred_contact_time && touched.preferred_contact_time && (
                                                    <p className="error">{errors.preferred_contact_time}</p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group controlId="how_did_you_hear_about_us">
                                                <Form.Label>How Did You Hear About Us</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    name="how_did_you_hear_about_us"
                                                    value={values.how_did_you_hear_about_us}
                                                    onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    onBlur={(e) => {
                                                        const trimmedValue = e.target.value.trim();
                                                        const event = {
                                                            target: {
                                                                name: "how_did_you_hear_about_us",
                                                                value: trimmedValue,
                                                            },
                                                        };
                                                        handleChange(event);
                                                        handleBlur(event);
                                                    }}
                                                    className={errors.how_did_you_hear_about_us && touched.how_did_you_hear_about_us ? "input-error" : ""}
                                                />
                                                {errors.how_did_you_hear_about_us && touched.how_did_you_hear_about_us && (
                                                    <p className="error">{errors.how_did_you_hear_about_us}</p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="my-4">
                                        <Col sm={12} className="text-end">
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                <span className="px-4">Save</span>
                                                {isSubmitting ? <LinearProgress /> : ""}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default PatientForm;
