/*
Purpose: list details of student with use datatable
Developer: BY PKS
Date:26-OCT-2023 
Project: EMSTYLE
*/

import React, { useState, useCallback, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { security_key, BASE_URL } from "../../global";
import LinearProgress from "@mui/material/LinearProgress";
import FeesModal from "../../components/FeesModal";

import Moment from "moment";
import "moment-timezone";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

const formatPhoneNumber = (rawPhoneNumber) => {
    const phoneNumber = String(rawPhoneNumber);
    const countryCode = phoneNumber.substring(0, 1);
    const restOfNumber = phoneNumber.substring(1);

    // Format the phone number as +1 (XXX) XXX-XXXX
    const formattedPhoneNumber = `+${countryCode} (${restOfNumber.substring(0, 3)}) ${restOfNumber.substring(3, 6)}-${restOfNumber.substring(6)}`;

    return formattedPhoneNumber;
};

const ProviderSelect = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const { provider_id } = location.state;
    const { provider_name } = location.state;
    const { practice_city } = location.state;
    const { practice_state } = location.state;
    const { assignData } = location.state;

    // console.log(assignData);

    /****Bootstrap Modal functional****/

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const [isLoading, setIsLoading] = useState(true);

    const [optionsPatient, setOptionsPatient] = useState([]);

    useEffect(() => {
        const fetchPatientOption = async () => {
            try {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "getAllPatient",
                });

                const output = response.data;

                // console.log("Response Data:", output);

                if (output.length > 0) {
                    const formattedOptions = output
                        .filter((option) => option.patient_state.trim() == practice_state.trim())
                        .map((option) => ({
                            value: option.pk_patient_id,
                            label: `${option.patient_first_name} ${option.patient_last_name}, ${formatPhoneNumber(option.patient_mobile_phone)}, ${
                                option.patient_email
                            }`,
                            service_ids: option["fk_service_ids"],
                        }));

                    setOptionsPatient(formattedOptions);
                    setIsLoading(false);
                } else {
                    // Handle error or show an alert message
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchPatientOption();
    }, []);

    /****MUI Nav Tab****/

    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [patientData, setPatientData] = useState({
        patient_first_name: "",
    });

    const getPatientByPk = async (id) => {
        setIsSubmitting(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getPatient",
                patient_id: id,
            });

            const output = response.data;

            // console.log(output);

            if (response.data) {
                setPatientData(response.data);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }

            setIsSubmitting(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    /****Assign Functional****/
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [assignPatient, setAssignPatient] = useState(0);
    const [assignProvider, setAssignProvider] = useState(provider_id);
    const [assignProviderName, setAssignProviderName] = useState(provider_name);
    const [preferredSerArr, setPreferredSerArr] = useState([]);

    const handleAssign = async () => {
        if (assignPatient != 0 && assignProvider != 0) {
            setShowModal(true);
        } else {
            toast.error("Please select patient.", {
                autoClose: 3000,
            });
        }
    };

    const handleAfterAssign = async () => {
        getPatientByPk(assignPatient);
    };

    const themes = sessionStorage.getItem("theme");

    const commonStyles = {
        react_select: "",
        react_tel: "",
    };

    const darkThemeStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "#343541",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "white",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                    // color: "black",
                },
            }),
        },
        react_tel: "",
    };

    const colourStyles = themes == "dark" ? darkThemeStyles : commonStyles;

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="app-content">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Row>
                            <Col xs="12">
                                <Row className="justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="section-header d-flex align-items-center justify-content-between">
                                            <div className="section-heading">
                                                <h4 className="mb-1">Patient Assign</h4>
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                                                Home
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Admin/PatientAssign")}>
                                                                Assign
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            Patient Assign
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                            <div className="section-essentials d-flex align-items-center justify-content-between gap-2">
                                                <a role="button" className="btn btn-light btn-md" onClick={() => navigate("/Admin/PatientAssign")}>
                                                    Back
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <Card className="my-card p-1 bg-white">
                                            <Card.Body>
                                                <Row className="mb-3">
                                                    <Col sm={12}>
                                                        <div className="mt-2">
                                                            <span>
                                                                Recipient (<Form.Label className="p-0">Provider</Form.Label>): {assignProviderName}
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center mt-3">
                                                            <span>
                                                                Assignee (<Form.Label className="p-0">Patient</Form.Label>):
                                                            </span>
                                                            <div className="ms-2 w-50">
                                                                <Select
                                                                    autoFocus={false}
                                                                    styles={colourStyles.react_select}
                                                                    options={optionsPatient}
                                                                    // value={
                                                                    //     patientId &&
                                                                    //     optionsPatient.find((option) => {
                                                                    //         console.log(patientId);
                                                                    //         console.log("pv:" + option.value);
                                                                    //         // return option.value && option.value.toString() === patientId.toString();
                                                                    //         return option.value && option.value.toString() == 25;
                                                                    //     })
                                                                    // }
                                                                    onChange={(selectedOption) => {
                                                                        getPatientByPk(selectedOption["value"]);
                                                                        setAssignPatient(selectedOption["value"]);
                                                                        setPreferredSerArr(selectedOption["service_ids"]);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    {assignPatient !== 0 && assignProvider !== 0 ? (
                                                        <Col sm="12" className="mt-3">
                                                            <TabContext value={value}>
                                                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                                    <TabList
                                                                        onChange={handleChange}
                                                                        aria-label="lab API tabs example"
                                                                        indicatorColor="secondary"
                                                                        textColor="inherit"
                                                                    >
                                                                        <Tab label="Patient" value="1" />
                                                                        <Tab label="Contact" value="2" />
                                                                        <Tab label="Other" value="3" />
                                                                    </TabList>
                                                                </Box>
                                                                <TabPanel value="1">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">First Name</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.patient_first_name
                                                                                    ? patientData.patient_first_name
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Last Name</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.patient_last_name
                                                                                    ? patientData.patient_last_name
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">City</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>{patientData && patientData.patient_city ? patientData.patient_city : "N/A"}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">State</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.patient_state ? patientData.patient_state : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Address 1</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.patient_address_1
                                                                                    ? patientData.patient_address_1
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Address 2</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.patient_address_2
                                                                                    ? patientData.patient_address_2
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Zip</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>{patientData && patientData.patient_zip ? patientData.patient_zip : "N/A"}</p>
                                                                        </div>
                                                                    </div>

                                                                    <hr />
                                                                </TabPanel>
                                                                <TabPanel value="2">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Email</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.patient_email ? patientData.patient_email : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Mobile Phone</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData &&
                                                                                patientData.patient_mobile_phone &&
                                                                                patientData.patient_mobile_phone != 1
                                                                                    ? formatPhoneNumber(patientData.patient_mobile_phone)
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Other Phone</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData &&
                                                                                patientData.patient_other_phone &&
                                                                                patientData.patient_other_phone != 1
                                                                                    ? formatPhoneNumber(patientData.patient_other_phone)
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Urgency</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>{patientData && patientData.urgency ? patientData.urgency : "N/A"}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Preferred Contact Method</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.preferred_contact_method
                                                                                    ? patientData.preferred_contact_method
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Preferred Contact Time</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.preferred_contact_time
                                                                                    ? patientData.preferred_contact_time
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <hr />
                                                                </TabPanel>
                                                                <TabPanel value="3">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Call Date</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.formatted_date ? patientData.formatted_date : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Call Time</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.formatted_time ? patientData.formatted_time : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">Description of Condition</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.description_of_condition
                                                                                    ? patientData.description_of_condition
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h6 className="text-secondary">How Did You Hear About Us</h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>
                                                                                {patientData && patientData.how_did_you_hear_about_us
                                                                                    ? patientData.how_did_you_hear_about_us
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </TabPanel>
                                                            </TabContext>
                                                        </Col>
                                                    ) : (
                                                        <div style={{ height: "240px" }}>
                                                            <Row className="justify-content-center align-items-center text-center h-100">
                                                                <Col sm={12}>
                                                                    <h6>Please select a patient</h6>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                </Row>
                                                <Row>
                                                    <Col sm={12} className="text-end">
                                                        {patientData &&
                                                        patientData.pk_assign_id !== null &&
                                                        patientData.pk_patient_id === assignPatient &&
                                                        patientData.fk_provider_id === assignProvider ? (
                                                            <Button type="submit" variant="primary" onClick={handleAssign} disabled={true}>
                                                                <span className="px-4">Assigned</span>
                                                                {isSubmitting ? <LinearProgress /> : ""}
                                                            </Button>
                                                        ) : patientData && patientData.pk_assign_id === null ? (
                                                            <Button type="submit" variant="primary" onClick={handleAssign} disabled={isSubmitting}>
                                                                {isSubmitting ? (
                                                                    <>
                                                                        <span className="px-4">Loading...</span>
                                                                        <LinearProgress />
                                                                    </>
                                                                ) : (
                                                                    <span className="px-4">Confirm Assign</span>
                                                                )}
                                                            </Button>
                                                        ) : assignPatient !== 0 && assignProvider !== 0 ? (
                                                            <Button type="submit" variant="primary" onClick={handleAssign} disabled={isSubmitting}>
                                                                {isSubmitting ? (
                                                                    <>
                                                                        <span className="px-4">Loading...</span>
                                                                        <LinearProgress />
                                                                    </>
                                                                ) : (
                                                                    <span className="px-4">Re Assign</span>
                                                                )}
                                                            </Button>
                                                        ) : (
                                                            <Button type="submit" variant="primary" disabled={true}>
                                                                <span className="px-4">Confirm Assign</span>
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <FeesModal
                patientId={assignPatient}
                providerId={assignProvider}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                handleAfterAssign={handleAfterAssign}
                // preferredSerArr={preferredSerArr}
            />
        </>
    );
};

export default ProviderSelect;
