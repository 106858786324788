import React, { useState, useRef } from "react";
import "./ForgotPassword.css";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import axios from "axios";
import { security_key, BASE_URL } from "../global";
import Image from "react-bootstrap/Image";
import logo from "./../assets/images/backgrounds/Provider.png";

function ForgotPassword() {
    const navigate = useNavigate();
    return (
        <>
            {/* <ToastContainer position="top-center" /> */}

            <div className="forgot_wrap">
                <div className="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end" style={{ background: "#e7ebf8" }}>
                    <div className="app-auth-background provider-login"></div>
                    <div className="app-auth-container">
                        <div className="logo-dark mb-3 text-center">
                            <a role="button" style={{ display: "inline-block" }} onClick={() => navigate("/Patient")}>
                                {/* <Image src="../images/logo-dark.png" width={150} className="img-fluid d-block" /> */}
                                <Image src={logo} width={170} className="img-fluid d-block" />
                            </a>
                        </div>

                        <div className="auth-credentials card border-0 shadow-lg p-5 m-b-xxl text-center">
                            <img src="../images/tick.png" alt="success" width={60} className="mx-auto d-block mb-2" />
                            <p className="widget-title text-center"> Success</p>
                            <p className="text-center mb-0">Your password has been changed</p>
                            <p className="text-center">Please login with your new password</p>
                            <input type="hidden" name="login-now" defaultValue="Login Now" />
                            <div className="single-form">
                                <button className="btn btn-primary" id="login-nowBTN" onClick={() => navigate("/Provider/Login")}>
                                    Login Now
                                </button>
                            </div>
                        </div>
                        {/* <div className="auth-alts">
                            <a href="#" className="auth-alts-google" />
                            <a href="#" className="auth-alts-facebook" />
                            <a href="#" className="auth-alts-twitter" />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
