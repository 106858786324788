import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import "./AddForm.css";

import { Form, Row, Col, Stack } from "react-bootstrap";
import { Button, LinearProgress } from "@mui/material";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

import * as Yup from "yup";
import { useFormik } from "formik";

import axios from "axios";
import { security_key, BASE_URL } from "../../global";

async function checkInjectionVideoLinkUniqueness(video_link, inj_id = 0) {
    const response = await axios.post(BASE_URL + "API/apicall", {
        security_key: security_key,
        mode: "checkInjectionVideoLinkUniqueness",
        patho_id: 0,
        inj_id: inj_id,
        video_link: video_link,
    });

    const output = response.data;

    return output;
}

const validationSchema = Yup.object().shape({
    ultrasound_video_link: Yup.string()
        .required("Ultrasound Video Link is required")
        .matches(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com|player\.vimeo\.com)/, "Please enter a valid video link from YouTube or Vimeo"),
    // .test("is-embedded-video-link", "Please enter a valid embedded video link from YouTube or Vimeo", (value) => {
    //     if (!value) return true; // Allow empty value

    //     // Regular expressions to match embedded video links from YouTube and Vimeo domains
    //     const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/embed\/)([^"&?\/\s]{11})(\?si=[^"&?\/\s]+)?$/;
    //     const vimeoRegex = /^(https?:\/\/)?(www\.)?(player\.vimeo\.com\/video\/)([0-9]+)(\?[^"&?\/\s]+)?$/;

    //     // Test if the value matches the patterns of YouTube or Vimeo embedded video links
    //     return youtubeRegex.test(value) || vimeoRegex.test(value);
    // }),

    title_of_pathology: Yup.string()
        .required("Pathology Title is required")
        .matches(/^[a-zA-Z0-9. ]*$/, "Pathology Title can only contain letters, numbers, dots and spaces.")
        .min(2, "Pathology Title must be at least 2 characters.")
        .max(250, "Pathology Title must be at most 250 characters."),
    description_of_pathology: Yup.string()
        .required("Pathology Description is required")
        .min(3, "Pathology Description must be at least 3 characters.")
        .max(250, "Pathology Description must be at most 250 characters."),
    // rehabilitation: Yup.string()
    //     .required("Rehabilitation is required")
    //     .min(3, "Rehabilitation must be at least 3 characters.")
    //     .max(250, "Rehabilitation must be at most 250 characters."),
    grade_of_injury: Yup.string()
        .required("Grade of Pathology is required")
        .matches(/^[a-zA-Z. ]*$/, "Grade of Pathology can only contain letters, dots and spaces.")
        .min(2, "Grade of Pathology must be at least 2 characters.")
        .max(250, "Grade of Pathology must be at most 250 characters."),
    /*
    adipose_concentrate_dose: Yup.string()
        .required("Adipose Concentrate Dose is required")
        .matches(/^[a-zA-Z0-9. ]*$/, "Adipose Concentrate Dose can only contain letters, numbers, dots and spaces.")
        .min(2, "Adipose Concentrate Dose must be at least 2 characters.")
        .max(250, "Adipose Concentrate Dose must be at most 250 characters."),
    adipose_concentrate: Yup.array().min(1, "At least one option should be selected"),
    ac_total_volume: Yup.string()
        .required("AC Total Volume is required")
        .test("is-positive", "AC Total Volume must be a positive number", (value) => {
            if (!value) return true; // Accept empty value
            const numericValue = parseFloat(value);
            if (numericValue < 0) {
                return false;
            }
            return true;
        })
        .test("greater-than-zero", "AC Total Volume must be greater than zero", (value) => {
            if (!value) return false;
            const numericValue = parseFloat(value);
            return numericValue > 0;
        }),
    bone_marrow_concentrate: Yup.array().min(1, "At least one option should be selected"),
    bc_total_volume: Yup.string()
        .required("BC Total Volume is required")
        .test("is-positive", "BC Total Volume must be a positive number", (value) => {
            if (!value) return true;
            const numericValue = parseFloat(value);
            if (numericValue < 0) {
                return false;
            }
            return true;
        })
        .test("greater-than-zero", "BC Total Volume must be greater than zero", (value) => {
            if (!value) return false;
            const numericValue = parseFloat(value);
            return numericValue > 0;
        }),
    platelet_concentrate: Yup.array().min(1, "At least one option should be selected"),
    pc_total_volume: Yup.string()
        .required("PC Total Volume is required")
        .test("is-positive", "PC Total Volume must be a positive number", (value) => {
            if (!value) return true;
            const numericValue = parseFloat(value);
            if (numericValue < 0) {
                return false;
            }
            return true;
        })
        .test("greater-than-zero", "PC Total Volume must be greater than zero", (value) => {
            if (!value) return false;
            const numericValue = parseFloat(value);
            return numericValue > 0;
        }),
    */

    bmc_platelet_dose: Yup.string()
        // .required("BMC Platelet Dose is required")
        // .matches(/^[a-zA-Z0-9. ]*$/, "BMC Platelet Dose can only contain letters, numbers, dots and spaces.")
        .min(2, "BMC Platelet Dose must be at least 2 characters.")
        .max(250, "BMC Platelet Dose must be at most 250 characters."),

    bmc_wpc_dose: Yup.string()
        // .required("BMC WPC Dose is required")
        .min(3, "BMC WPC Dose must be at least 3 characters.")
        .max(250, "BMC WPC Dose must be at most 250 characters."),

    // prp_type: Yup.array().min(1, "At least one option should be selected"),

    prp_platelet_dose: Yup.string()
        // .required("PRP Platelet Dose is required")
        // .matches(/^[a-zA-Z0-9. ]*$/, "PRP Platelet Dose can only contain letters, numbers, dots and spaces.")
        .min(2, "PRP Platelet Dose must be at least 2 characters.")
        .max(250, "PRP Platelet Dose must be at most 250 characters."),

    //leukocyte_type: Yup.array().min(1, "At least one option should be selected"),

    anesthesia_type: Yup.string()
        .required("Anesthesia Type is required")
        .matches(/^[a-zA-Z0-9. ]*$/, "Anesthesia Type can only contain letters, numbers, dots and spaces.")
        .min(2, "Anesthesia Type must be at least 2 characters.")
        .max(100, "Anesthesia Type Dose must be at most 100 characters."),
    anesthetic_name: Yup.string()
        .required("Anesthetic Name is required")
        .matches(/^[a-zA-Z. ]*$/, "Anesthetics Name can only contain letters, dots and spaces.")
        .min(2, "Anesthetics Name must be at least 2 characters.")
        .max(100, "Anesthetics Name Dose must be at most 100 characters."),
    anesthetic_dose: Yup.string()
        .required("Anesthetic Dose is required")
        .matches(/^[a-zA-Z0-9. ]*$/, "Anesthetics Dose can only contain letters, numbers, dots and spaces.")
        .min(2, "Anesthetics Dose must be at least 2 characters.")
        .max(250, "Anesthetics Dose must be at most 250 characters."),
    injection_technique: Yup.string()
        .required("Injection Technique is required")
        .matches(/^[a-zA-Z0-9. ]*$/, "Injection Technique can only contain letters, numbers, dots and spaces.")
        .min(2, "Injection Technique must be at least 2 characters.")
        .max(100, "Injection Technique must be at most 100 characters."),
    anesthetics_rationale: Yup.string()
        .required("Anesthetics Rationale is required")
        .matches(/^[a-zA-Z. ]*$/, "Anesthetics Rationale can only contain letters, dots and spaces.")
        .min(2, "Anesthetics Rationale must be at least 2 characters.")
        .max(250, "Anesthetics Rationale must be at most 250 characters."),
    injections: Yup.array().of(
        Yup.object().shape({
            injection_site: Yup.string().required("Site is required").min(2, "Site be at least 2 characters.").max(255, "Site at most 255 characters."),
            // .test("is-valid-url", "Please enter a valid Link.", function (value) {
            //     if (!value) return true; // If value is not provided, let required validation handle it
            //     return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
            // }),
            injection_volume: Yup.string().required("Volume is required").min(1, "Volume be at least 1 characters.").max(250, "Volume at most 250 characters."),
            // .test("is-positive", "Volume must be a positive number", (value) => {
            //     if (!value) return true;
            //     const numericValue = parseFloat(value);
            //     if (numericValue < 0) {
            //         return false;
            //     }
            //     return true;
            // })
            // .test("greater-than-zero", "Volume must be greater than zero", (value) => {
            //     if (!value) return false;
            //     const numericValue = parseFloat(value);
            //     return numericValue > 0;
            // }),
            /*
            injection_video_title: Yup.string()
                .required("Video Title is required")
                .matches(/^[a-zA-Z0-9. ]*$/, "Video Title can only contain letters, numbers, dots and spaces.")
                .min(2, "Video Title must be at least 2 characters.")
                .max(250, "Video Title must be at most 250 characters."),
            */

            injection_video_link: Yup.string()
                .required("Video Link is required")
                .max(250, "Video Link be at most 250 characters.")
                .matches(
                    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com|player\.vimeo\.com)/,
                    "Please enter a valid video link from YouTube or Vimeo"
                )
                // .test("is-embedded-video-link", "Please enter a valid embedded video link from YouTube or Vimeo", (value) => {
                //     if (!value) return true; // Allow empty value

                //     // Regular expressions to match embedded video links from YouTube and Vimeo domains
                //     const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/embed\/)([^"&?\/\s]{11})(\?si=[^"&?\/\s]+)?$/;
                //     const vimeoRegex = /^(https?:\/\/)?(www\.)?(player\.vimeo\.com\/video\/)([0-9]+)(\?[^"&?\/\s]+)?$/;

                //     // Test if the value matches the patterns of YouTube or Vimeo embedded video links
                //     return youtubeRegex.test(value) || vimeoRegex.test(value);
                // })

                .test("unique-link", "Video Link is already existing in the database", async function (value) {
                    if (!value) return true;
                    const isVideoLinkUnique = await checkInjectionVideoLinkUniqueness(value);
                    return isVideoLinkUnique;
                })
                .test("unique-link", "Video Link is already taken", function (value, { path }) {
                    const injections = this.options.context.injections || [];

                    const allLinks = injections.map((injection) => injection.injection_video_link);

                    const counts = {};

                    allLinks.forEach(function (x) {
                        counts[x] = (counts[x] || 0) + 1;
                    });

                    if (counts[`${value}`] > 1) {
                        return path.includes("[0]");
                    } else {
                        return true;
                    }
                }),
        })
    ),
});

const initialValues = {
    /**Pathology*/
    ultrasound_video_link: "",
    title_of_pathology: "",
    grade_of_injury: "",
    description_of_pathology: "",

    /**Autologous Biologic */
    bmc_platelet_dose: "",
    bmc_wpc_dose: "",
    prp_type: [],
    prp_platelet_dose: "",
    leukocyte_type: [],

    /**Removed Fields */
    rehabilitation: "",
    adipose_concentrate_dose: "",
    adipose_concentrate: [],
    ac_total_volume: "",
    bone_marrow_concentrate_dose: "",
    bone_marrow_concentrate: [],
    bc_total_volume: "",
    platelet_concentrate_dose: "",
    platelet_concentrate: [],
    pc_total_volume: "",

    /**Anesthetics */
    anesthesia_type: "",
    anesthetic_name: "",
    anesthetic_dose: "",
    injection_technique: "",
    anesthetics_rationale: "",
    injections: [
        {
            injection_site: "",
            injection_volume: "",
            injection_video_title: "",
            injection_video_link: "",
        },
    ],
};

function PathologyForm() {
    const navigate = useNavigate();
    const formRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const convertToEmbedLink = (link) => {
        // Check if the link is already an embed link
        if (link.includes("vimeo.com/video") || link.includes("youtube.com/embed")) {
            return link; // Return the original link
        } else if (link.includes("vimeo.com")) {
            // Vimeo link format: https://vimeo.com/videoID
            const videoID = link.split("/").pop();
            return `https://player.vimeo.com/video/${videoID}`;
        } else if (link.includes("youtube.com")) {
            // YouTube link format: https://www.youtube.com/watch?v=videoID
            const videoID = link.split("v=").pop().split("&")[0];
            return `https://www.youtube.com/embed/${videoID}`;
        } else if (link.includes("youtu.be")) {
            // Shortened YouTube link format: https://youtu.be/videoID
            const videoID = link.split("/").pop();
            return `https://www.youtube.com/embed/${videoID}`;
        } else {
            // If not a Vimeo or YouTube link, return the original link
            return link;
        }
    };

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm();
        setValues({
            ...initialValues,
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            toast.dismiss();
            setIsSubmitting(true);
            try {
                const formValues = { ...values };
                formValues.ultrasound_video_link = convertToEmbedLink(formValues.ultrasound_video_link);
                const updatedInjections = formValues.injections.map((injection) => ({
                    ...injection,
                    injection_video_link: convertToEmbedLink(injection.injection_video_link),
                }));
                const updatedFormValues = {
                    ...formValues,
                    injections: updatedInjections,
                };
                await formik.setValues(updatedFormValues);
                toast.dismiss();
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "addPathology",
                    ...updatedFormValues,
                });
                const output = response.data;
                if (output == 1) {
                    resetFormFields(resetForm, setValues, initialValues);
                    setIsSubmitting(false);
                    toast.success("Success! Your form was submitted", {
                        autoClose: 3000,
                    });
                    setTimeout(function () {
                        navigate("/Admin/ClinicalList");
                    }, 3000);
                } else {
                    toast.error("Oops! Something went wrong.", {
                        autoClose: 3000,
                    });
                }
            } catch (error) {
                console.error("API call error:", error);
                toast.error("Oops! Something went wrong.", {
                    autoClose: 3000,
                });
            } finally {
            }
        },
    });

    // Render your form components here

    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;
    const setFieldValue = formik.setFieldValue;
    const injections = values.injections || [];

    const handleAddInjection = () => {
        const newInjection = [
            ...formik.values.injections,
            {
                injection_site: "",
                injection_volume: "",
                injection_video_title: "",
                injection_video_link: "",
            },
        ];
        formik.setValues({ ...formik.values, injections: newInjection });
    };
    const handleRemoveInjection = (injectionId) => {
        const updatedInjections = formik.values.injections.filter((injection, index) => index !== injectionId - 1);
        formik.setValues({ ...formik.values, injections: updatedInjections });
    };

    useEffect(() => {
        if (!formik.isSubmitting) return;

        let firstErrorField = Object.keys(formik.errors)[0];

        console.log(formik.errors.injections);

        if (firstErrorField == "injections") {
            let proError = formik.errors.injections;

            if (proError) {
                for (const [index, row] of Object.entries(proError)) {
                    if (row) {
                        firstErrorField = Object.keys(row)[0] + `${index}`;
                        console.log(row);
                        break;
                    }
                }
            }
        }

        if (firstErrorField) {
            const errorFieldElement = document.getElementById(firstErrorField);

            if (errorFieldElement) {
                const elementRect = errorFieldElement.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const middleOfElement = absoluteElementTop - window.innerHeight / 2;

                window.scrollTo({
                    top: middleOfElement,
                    behavior: "smooth",
                });
            } else {
                console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
        }
    }, [formik.errors, formik.isSubmitting]);

    return (
        <div className="app-content">
            <div className="content-wrapper">
                <div className="container-fluid gx-0 gx-md-4">
                    <div className="section-header d-flex align-items-center justify-content-between">
                        <div className="section-heading">
                            <h4 className="mb-1">Add Clinical</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                            Home
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a role="button" onClick={() => navigate("/Admin/ClinicalList")}>
                                            Clinical
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Add Clinical
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="section-essentials d-flex align-items-center justify-content-between gap-2">
                            <Button className="btn-light btn-md" onClick={() => navigate("/Admin/ClinicalList")}>
                                Back
                            </Button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" />

                    <Row className="gx-0 gx-md-4">
                        <Col md={12} className="mx-auto ">
                            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                <Stack>
                                    <div className="my-card p-4 bg-white">
                                        <div className="section-heading border-bottom pb-3 mb-3">
                                            <h6 className="mb-0">Pathology</h6>
                                        </div>
                                        <Row className="gx-0 gx-md-4 mb-3">
                                            <Col md={4}>
                                                <Form.Group controlId="ultrasound_video_link">
                                                    <Form.Label>
                                                        Ultrasound Video Link<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="url"
                                                        name="ultrasound_video_link"
                                                        value={values.ultrasound_video_link}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.ultrasound_video_link && touched.ultrasound_video_link ? "input-error" : ""}
                                                    />
                                                    {errors.ultrasound_video_link && touched.ultrasound_video_link && (
                                                        <p className="error">{errors.ultrasound_video_link}</p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="title_of_pathology">
                                                    <Form.Label>
                                                        Title of Pathology<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="title_of_pathology"
                                                        value={values.title_of_pathology}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.title_of_pathology && touched.title_of_pathology ? "input-error" : ""}
                                                    />
                                                    {errors.title_of_pathology && touched.title_of_pathology && (
                                                        <p className="error">{errors.title_of_pathology}</p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="grade_of_injury">
                                                    <Form.Label>
                                                        Grade of Pathology<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="grade_of_injury"
                                                        value={values.grade_of_injury}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.grade_of_injury && touched.grade_of_injury ? "input-error" : ""}
                                                    />
                                                    {errors.grade_of_injury && touched.grade_of_injury && <p className="error">{errors.grade_of_injury}</p>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="gx-0 gx-md-4 mb-3">
                                            <Col md={4}>
                                                <Form.Group controlId="description_of_pathology">
                                                    <Form.Label>
                                                        Description of Pathology<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="description_of_pathology"
                                                        value={values.description_of_pathology}
                                                        style={{ height: "100px" }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.description_of_pathology && touched.description_of_pathology ? "input-error" : ""}
                                                    />
                                                    {errors.description_of_pathology && touched.description_of_pathology && (
                                                        <p className="error">{errors.description_of_pathology}</p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            {/* <Col md={4}>
                                                <Form.Group controlId="rehabilitation">
                                                    <Form.Label>
                                                        Rehabilitation<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={4}
                                                        name="rehabilitation"
                                                        value={values.rehabilitation}
                                                        style={{ height: "100px" }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.rehabilitation && touched.rehabilitation ? "input-error" : ""}
                                                    />
                                                    {errors.rehabilitation && touched.rehabilitation && <p className="error">{errors.rehabilitation}</p>}
                                                </Form.Group>
                                            </Col> */}
                                        </Row>
                                    </div>
                                </Stack>

                                <Stack className="mt-4">
                                    <div className="my-card p-4 bg-white">
                                        <div className="section-heading border-bottom pb-3 mb-3">
                                            <h6 className="mb-0">Autologous Biologic</h6>
                                        </div>
                                        {/* <Row className="mb-3">
                                            <Col md={4}>
                                                <Form.Group controlId="adipose_concentrate_dose">
                                                    <Form.Label>Adipose Concentrate Dose</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="adipose_concentrate_dose"
                                                        value={values.adipose_concentrate_dose}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.adipose_concentrate_dose && touched.adipose_concentrate_dose ? "input-error" : ""}
                                                    />
                                                    {errors.adipose_concentrate_dose && touched.adipose_concentrate_dose && (
                                                        <p className="error">{errors.adipose_concentrate_dose}</p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup id="adipose_concentrate">
                                                    <Form.Label>Adipose Concentrate</Form.Label>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="adipose_concentrate"
                                                                checked={formik.values.adipose_concentrate.includes("Adipose concentrate")}
                                                                onChange={(event) => {
                                                                    const isChecked = event.target.checked;
                                                                    formik.setFieldValue(
                                                                        "adipose_concentrate",
                                                                        isChecked
                                                                            ? [...formik.values.adipose_concentrate, "Adipose concentrate"]
                                                                            : formik.values.adipose_concentrate.filter((type) => type !== "Adipose concentrate")
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        }
                                                        label="Adipose concentrate"
                                                    />
                                                    {formik.errors.adipose_concentrate && formik.touched.adipose_concentrate && (
                                                        <p className="error">{formik.errors.adipose_concentrate}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="ac_total_volume">
                                                    <Form.Label>AC Total Volume</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="ac_total_volume"
                                                        value={values.ac_total_volume}
                                                        // min={0}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.ac_total_volume && touched.ac_total_volume ? "input-error" : ""}
                                                    />
                                                    {errors.ac_total_volume && touched.ac_total_volume && <p className="error">{errors.ac_total_volume}</p>}
                                                </Form.Group>
                                            </Col>
                                        </Row> */}
                                        <Row className="gx-0 gx-md-4 mb-3">
                                            <Col md={4}>
                                                <Form.Group controlId="bmc_platelet_dose ">
                                                    <Form.Label>BMC Platelet Dose</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="bmc_platelet_dose"
                                                        value={values.bmc_platelet_dose}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.bmc_platelet_dose && touched.bmc_platelet_dose ? "input-error" : ""}
                                                    />
                                                    {errors.bmc_platelet_dose && touched.bmc_platelet_dose && (
                                                        <p className="error">{errors.bmc_platelet_dose}</p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup id="bmc_wpc_dose">
                                                    <Form.Label>BMC WBC Dose</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="bmc_wpc_dose"
                                                        value={values.bmc_wpc_dose}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.bmc_wpc_dose && touched.bmc_wpc_dose && <p className="error">{errors.bmc_wpc_dose}</p>}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup id="prp_type">
                                                    <Form.Label>PRP Type</Form.Label>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="prp_type"
                                                                checked={formik.values.prp_type.includes("Platelet Rich Plasma")}
                                                                onChange={(event) => {
                                                                    const isChecked = event.target.checked;
                                                                    formik.setFieldValue(
                                                                        "prp_type",
                                                                        isChecked
                                                                            ? [...formik.values.prp_type, "Platelet Rich Plasma"]
                                                                            : formik.values.prp_type.filter((type) => type !== "Platelet Rich Plasma")
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        }
                                                        label="Platelet Rich Plasma"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="prp_type"
                                                                checked={formik.values.prp_type.includes("Protein Rich PRP")}
                                                                onChange={(event) => {
                                                                    const isChecked = event.target.checked;
                                                                    formik.setFieldValue(
                                                                        "prp_type",
                                                                        isChecked
                                                                            ? [...formik.values.prp_type, "Protein Rich PRP"]
                                                                            : formik.values.prp_type.filter((type) => type !== "Protein Rich PRP")
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        }
                                                        label="Protein Rich PRP"
                                                    />
                                                </FormGroup>

                                                {formik.errors.prp_type && formik.touched.prp_type && <p className="error">{formik.errors.prp_type}</p>}
                                            </Col>
                                            {/* <Col md={4}>
                                                <Form.Group controlId="bc_total_volume">
                                                    <Form.Label>BC Total Volume</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="bc_total_volume"
                                                        value={values.bc_total_volume}
                                                        min={0}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.bc_total_volume && touched.bc_total_volume ? "input-error" : ""}
                                                    />
                                                    {errors.bc_total_volume && touched.bc_total_volume && <p className="error">{errors.bc_total_volume}</p>}
                                                </Form.Group>
                                            </Col> */}
                                            <Col md={4}>
                                                <Form.Group controlId="prp_platelet_dose ">
                                                    <Form.Label>PRP Platelet Dose</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="prp_platelet_dose"
                                                        value={values.prp_platelet_dose}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.prp_platelet_dose && touched.prp_platelet_dose ? "input-error" : ""}
                                                    />
                                                    {errors.prp_platelet_dose && touched.prp_platelet_dose && (
                                                        <p className="error">{errors.prp_platelet_dose}</p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup id="leukocyte_type">
                                                    <Form.Label>Leukocyte</Form.Label>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.leukocyte_type.includes("Leukocyte rich")}
                                                                onChange={(event) => {
                                                                    const isChecked = event.target.checked;
                                                                    formik.setFieldValue(
                                                                        "leukocyte_type",
                                                                        isChecked
                                                                            ? [...formik.values.leukocyte_type, "Leukocyte rich"]
                                                                            : formik.values.leukocyte_type.filter((type) => type !== "Leukocyte rich")
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                name="leukocyte_type"
                                                            />
                                                        }
                                                        label="Leukocyte rich"
                                                    />

                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.leukocyte_type.includes("Leukocyte poor")}
                                                                onChange={(event) => {
                                                                    const isChecked = event.target.checked;
                                                                    formik.setFieldValue(
                                                                        "leukocyte_type",
                                                                        isChecked
                                                                            ? [...formik.values.leukocyte_type, "Leukocyte poor"]
                                                                            : formik.values.leukocyte_type.filter((type) => type !== "Leukocyte poor")
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                name="leukocyte_type"
                                                            />
                                                        }
                                                        label="Leukocyte poor"
                                                    />

                                                    {formik.errors.leukocyte_type && formik.touched.leukocyte_type && (
                                                        <p className="error">{formik.errors.leukocyte_type}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="gx-0 gx-md-4 mb-3">
                                            {/* <Col md={4}>
                                                <FormGroup id="platelet_concentrate">
                                                    <Form.Label>Platelet Concentrate</Form.Label>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="platelet_concentrate"
                                                                checked={formik.values.platelet_concentrate.includes("Platelet concentrate")}
                                                                onChange={(event) => {
                                                                    const isChecked = event.target.checked;
                                                                    formik.setFieldValue(
                                                                        "platelet_concentrate",
                                                                        isChecked
                                                                            ? [...formik.values.platelet_concentrate, "Platelet concentrate"]
                                                                            : formik.values.platelet_concentrate.filter(
                                                                                  (type) => type !== "Platelet concentrate"
                                                                              )
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        }
                                                        label="Platelet concentrate"
                                                    />

                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="platelet_concentrate"
                                                                checked={formik.values.platelet_concentrate.includes("Protein rich platelet concentrate")}
                                                                onChange={(event) => {
                                                                    const isChecked = event.target.checked;
                                                                    formik.setFieldValue(
                                                                        "platelet_concentrate",
                                                                        isChecked
                                                                            ? [...formik.values.platelet_concentrate, "Protein rich platelet concentrate"]
                                                                            : formik.values.platelet_concentrate.filter(
                                                                                  (type) => type !== "Protein rich platelet concentrate"
                                                                              )
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        }
                                                        label="Protein rich platelet concentrate"
                                                    />

                                                    {formik.errors.platelet_concentrate && formik.touched.platelet_concentrate && (
                                                        <p className="error">{formik.errors.platelet_concentrate}</p>
                                                    )}
                                                </FormGroup>
                                            </Col> */}
                                            {/* <Col md={4}>
                                                <Form.Group controlId="pc_total_volume">
                                                    <Form.Label>PC Total Volume</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="pc_total_volume"
                                                        value={values.pc_total_volume}
                                                        min={0}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.pc_total_volume && touched.pc_total_volume ? "input-error" : ""}
                                                    />
                                                    {errors.pc_total_volume && touched.pc_total_volume && <p className="error">{errors.pc_total_volume}</p>}
                                                </Form.Group>
                                            </Col> */}
                                        </Row>
                                        {/* <Row className="mb-3">
                                            <Col md={4}>
                                                <Form.Group controlId="leukocyteCheckbox">
                                                    <Form.Label>Leukocyte</Form.Label>
                                                    <Form.Check type="checkbox" id="leukocyteRichCheckbox" label="Leukocyte rich" className="custom-checkbox" />
                                                    <Form.Check type="checkbox" id="leukocytePoorCheckbox" label="Leukocyte poor" className="custom-checkbox" />
                                                </Form.Group>
                                            </Col>
                                        </Row> */}
                                    </div>
                                </Stack>

                                <Stack className="mt-4">
                                    <div className="my-card p-4 bg-white">
                                        <div className="section-heading border-bottom pb-3 mb-3">
                                            <h6 className="mb-0">Anesthetics</h6>
                                        </div>
                                        <Row className="gx-0 gx-md-4 mb-3">
                                            <Col md={4}>
                                                <Form.Group controlId="anesthesia_type">
                                                    <Form.Label>
                                                        Type of Anesthesia<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="anesthesia_type"
                                                        value={values.anesthesia_type}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.anesthesia_type && touched.anesthesia_type ? "input-error" : ""}
                                                    />
                                                    {errors.anesthesia_type && touched.anesthesia_type && <p className="error">{errors.anesthesia_type}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="anesthetic_name">
                                                    <Form.Label>
                                                        Name of Anesthetic<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="anesthetic_name"
                                                        value={values.anesthetic_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.anesthetic_name && touched.anesthetic_name ? "input-error" : ""}
                                                    />
                                                    {errors.anesthetic_name && touched.anesthetic_name && <p className="error">{errors.anesthetic_name}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="anesthetic_dose">
                                                    <Form.Label>
                                                        Dose of Anesthetic<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="anesthetic_dose"
                                                        value={values.anesthetic_dose}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.anesthetic_dose && touched.anesthetic_dose ? "input-error" : ""}
                                                    />
                                                    {errors.anesthetic_dose && touched.anesthetic_dose && <p className="error">{errors.anesthetic_dose}</p>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="gx-0 gx-md-4 mb-3">
                                            <Col md={4}>
                                                <Form.Group controlId="injection_technique">
                                                    <Form.Label>
                                                        Injection Technique<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="injection_technique"
                                                        value={values.injection_technique}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.injection_technique && touched.injection_technique ? "input-error" : ""}
                                                    />
                                                    {errors.injection_technique && touched.injection_technique && (
                                                        <p className="error">{errors.injection_technique}</p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="anesthetics_rationale">
                                                    <Form.Label>
                                                        Anesthetics Rationale<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="anesthetics_rationale"
                                                        value={values.anesthetics_rationale}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.anesthetics_rationale && touched.anesthetics_rationale ? "input-error" : ""}
                                                    />
                                                    {errors.anesthetics_rationale && touched.anesthetics_rationale && (
                                                        <p className="error">{errors.anesthetics_rationale}</p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                </Stack>

                                {/* <Stack className="mt-4">
                                    <div className="my-card p-4 bg-white">
                                        <div className="section-heading border-bottom pb-3 mb-3">
                                            <h6 className="mb-0">
                                                Rehabilitation
                                            </h6>
                                        </div>
                                        <Row className="mb-3"></Row>

                                    </div>
                                </Stack> */}

                                <Stack className="mt-4">
                                    <div className="my-card p-4 bg-white">
                                        {injections.map((injection, index) => (
                                            <div key={index + 1}>
                                                <div className="section-heading border-bottom pb-3 mb-3">
                                                    <h6 className="mb-0">Injection Sites & Volume {index + 1}</h6>
                                                </div>

                                                <Row className="gx-0 gx-md-4 mb-3">
                                                    <Col md={6}>
                                                        <Form.Group controlId={`injection_site${index}`}>
                                                            <Form.Label>
                                                                Site<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name={`injections.${index}.injection_site`}
                                                                value={injection.injection_site}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.injections?.[index]?.injection_site && touched.injections?.[index]?.injection_site && (
                                                                <p className="error">{errors.injections[index].injection_site}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group controlId={`injection_volume${index}`}>
                                                            <Form.Label>
                                                                Volume<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name={`injections.${index}.injection_volume`}
                                                                value={injection.injection_volume}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.injections?.[index]?.injection_volume && touched.injections?.[index]?.injection_volume && (
                                                                <p className="error">{errors.injections[index].injection_volume}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="gx-0 gx-md-4 mb-3">
                                                    {/* <Col md={6}>
                                                        <Form.Group controlId={`injection_video_title${index}`}>
                                                            <Form.Label>
                                                                Video Title<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name={`injections.${index}.injection_video_title`}
                                                                value={injection.injection_video_title}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.injections?.[index]?.injection_video_title &&
                                                                touched.injections?.[index]?.injection_video_title && (
                                                                    <p className="error">{errors.injections[index].injection_video_title}</p>
                                                                )}
                                                        </Form.Group>
                                                    </Col> */}
                                                    <Col md={6}>
                                                        <Form.Group controlId={`injection_video_link${index}`}>
                                                            <Form.Label>
                                                                Video link<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="url"
                                                                name={`injections.${index}.injection_video_link`}
                                                                value={injection.injection_video_link}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.injections?.[index]?.injection_video_link &&
                                                                touched.injections?.[index]?.injection_video_link && (
                                                                    <p className="error">{errors.injections[index].injection_video_link}</p>
                                                                )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                {injections.length > 1 && (
                                                    <Row className="gx-0 gx-md-4 mt-3">
                                                        <Col sm={12} className="text-end">
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                size="small"
                                                                onClick={() => handleRemoveInjection(index + 1)}
                                                            >
                                                                Remove Injection
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        ))}

                                        <Row className="gx-0 gx-md-4 mt-5">
                                            <Col sm={12} className="text-end">
                                                <Button variant="contained" color="success" onClick={handleAddInjection}>
                                                    Additional Injection
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Stack>

                                <Stack>
                                    <Row className="gx-0 gx-md-4 my-4">
                                        <Col sm={12} className="text-end">
                                            <Button variant="contained" type="submit" disabled={isSubmitting}>
                                                <span className="px-4">Submit</span>
                                                {isSubmitting ? <LinearProgress /> : ""}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Stack>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default PathologyForm;
