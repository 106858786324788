import React from "react";

function SearchBar() {
    return (
        <div className="search">
            <form>
                <input className="form-control" type="text" placeholder="Type here..." aria-label="Search" />
            </form>
            <a href="#" className="toggle-search">
                <i className="material-icons">close</i>
            </a>
        </div>
    );
}

export default SearchBar;
