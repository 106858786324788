import React, { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import ListAltIcon from "@mui/icons-material/ListAlt";
import axios from "axios";
import { security_key, BASE_URL } from "../../global";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import PaymentIcon from "@mui/icons-material/Payment";
import Button from "@mui/material/Button";
import PaymentList from "../PaymentList";

const formatPhoneNumber = (rawPhoneNumber) => {
    const phoneNumber = String(rawPhoneNumber);
    const countryCode = phoneNumber.substring(0, 1);
    const restOfNumber = phoneNumber.substring(1);

    // Format the phone number as +1 (XXX) XXX-XXXX
    const formattedPhoneNumber = `+${countryCode} (${restOfNumber.substring(0, 3)}) ${restOfNumber.substring(3, 6)}-${restOfNumber.substring(6)}`;

    return formattedPhoneNumber;
};

function PatientDashboard() {
    const navigate = useNavigate();

    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [loading, setLoading] = useState(true);

    const [activeTab, setActiveTab] = useState("patient"); // 'home' or 'profile' based on default tab

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const [patientData, setPatientData] = useState();

    /**Fetch provider details**/

    const location = useLocation();
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getPatient",
                patient_id: sessionStorage.getItem("loger_id"),
            });

            const output = response.data;

            if (output) {
                setPatientData(output);
                setLoading(false);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <>
            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <div className="app-content" id="patient_view">
                        <div className="content-wrapper">
                            <div className="container-fluid">
                                <div className="section-header d-flex align-items-center justify-content-between">
                                    <div className="section-heading">
                                        <h4 className="mb-1">Dashboard</h4>
                                        {/* <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <a role="button" onClick={() => navigate("/PatientApp/Profile")}>
                                                        Home
                                                    </a>
                                                </li>
                                            </ol>
                                        </nav> */}
                                    </div>
                                </div>
                                <div className="row gx-0 gx-md-4">
                                    <div className="col-xxl-12">
                                        <Card className="my-card px-3 mt-3 bg-white">
                                            <Card.Body className="card-padding-res pt-0">
                                                <Box sx={{ width: "100%", typography: "body1" }}>
                                                    <TabContext value={value}>
                                                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                            <TabList
                                                                onChange={handleChange}
                                                                variant="scrollable"
                                                                // scrollButtons
                                                                scrollButtons="auto"
                                                                allowScrollButtonsMobile
                                                                aria-label="scrollable auto tabs example"
                                                                // sx={{ paddingLeft: 0, marginLeft: 0 }}
                                                            >
                                                                <Tab icon={<PaymentIcon />} iconPosition="start" label="Payment history" value="1" />
                                                                <Tab icon={<LocalHospitalIcon />} iconPosition="start" label="Provider" value="2" />
                                                            </TabList>
                                                            {loading && <LinearProgress />}
                                                        </Box>
                                                        <TabPanel value="1" className="p-0">
                                                            <PaymentList patientId={sessionStorage.getItem("loger_id")} />
                                                        </TabPanel>
                                                        <TabPanel value="2">
                                                            <div
                                                                className="m-0 position-absolute w-100 h-100 bg-white"
                                                                style={
                                                                    patientData && (patientData.provider_first_name || patientData.provider_last_name)
                                                                        ? { display: "none" }
                                                                        : {}
                                                                }
                                                            >
                                                                <div className="d-flex align-items-center justify-content-center h-50">
                                                                    <h4>You can view after payment. Please check your Email!</h4>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Provider First Name</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>
                                                                        {patientData && patientData.provider_first_name
                                                                            ? patientData.provider_first_name
                                                                            : "N/A"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Provider Last Name</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>
                                                                        {patientData && patientData.provider_last_name ? patientData.provider_last_name : "N/A"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Practice Name</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>{patientData && patientData.practice_name ? patientData.practice_name : "N/A"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Practice City</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>{patientData && patientData.practice_city ? patientData.practice_city : "N/A"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Practice State</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>{patientData && patientData.practice_state ? patientData.practice_state : "N/A"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Practice Email</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>{patientData && patientData.practice_email ? patientData.practice_email : "N/A"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Practice Phone</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>
                                                                        {patientData && patientData.practice_phone
                                                                            ? formatPhoneNumber(patientData.practice_phone)
                                                                            : "N/A"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Practice Address1</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>
                                                                        {patientData && patientData.practice_address_1 ? patientData.practice_address_1 : "N/A"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Practice Address2</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>
                                                                        {patientData && patientData.practice_address_2 ? patientData.practice_address_2 : "N/A"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Practice Zip</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>{patientData && patientData.practice_zip ? patientData.practice_zip : "N/A"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Scheduling Email</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>{patientData && patientData.scheduling_email ? patientData.scheduling_email : "N/A"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <h6 className="text-secondary">Scheduling Phone</h6>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <p>
                                                                        {patientData && patientData.scheduling_phone
                                                                            ? formatPhoneNumber(patientData.scheduling_phone)
                                                                            : "N/A"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </TabPanel>
                                                    </TabContext>
                                                </Box>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
export default PatientDashboard;
