/*
Purpose: list details of student with use datatable
Developer: BY PKS
Date:26-OCT-2023 
Project: EMSTYLE
*/

import React, { useState, useCallback, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
import Select from "react-select";

import Box from "@mui/material/Box";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

import Chip from "@mui/material/Chip";

import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import axios from "axios";
import { security_key, BASE_URL, options_provider } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import Badge from "react-bootstrap/Badge";
import TablePagination from "@mui/material/TablePagination";

import CustomNoRowsOverlay from "../../components/NoRow";

import PushMessageModal from "../../components/PushMessageModal";
// import { confirm } from "../components/Confirmation";

import Swal from "sweetalert2";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const formatPhoneNumber = (rawPhoneNumber) => {
    const phoneNumber = String(rawPhoneNumber);
    const countryCode = phoneNumber.substring(0, 1);
    const restOfNumber = phoneNumber.substring(1);

    // Format the phone number as +1 (XXX) XXX-XXXX
    const formattedPhoneNumber = `+${countryCode} (${restOfNumber.substring(0, 3)}) ${restOfNumber.substring(3, 6)}-${restOfNumber.substring(6)}`;

    return formattedPhoneNumber;
};

const options_field = [
    { value: "patient_first_name", label: "Patient First Name" },
    { value: "patient_last_name", label: "Patient Last Name" },
    { value: "patient_city", label: "Patient City" },
    { value: "patient_state", label: "Patient State" },
    { value: "patient_zip", label: "Patient Zip" },
    { value: "patient_email", label: "Patient Email" },
    { value: "patient_mobile_phone", label: "Patient Mobile Phone" },
];

function PatientList() {
    const navigate = useNavigate();

    const handleRowClick = (row, event = false) => {
        if (event && event.target.tagName === "A") {
            return;
        }
        navigate("/Admin/PatientEdit", {
            state: {
                patient_id: row.pk_patient_id,
                preferred_doc: row.fk_provider_ids,
                preferred_ser: row.fk_service_ids,
                patient_name: `${row.patient_first_name} ${row.patient_last_name}`,
                patient_city: row.patient_city,
                patient_state: row.patient_state,
                assignData: {
                    pk_assign_id: row.pk_assign_id,
                    fk_patient_id: row.fk_patient_id,
                    fk_provider_id: row.fk_provider_id,
                },
            },
        });
    };

    /****Grid Pagination functional****/

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    /****Bootstrap Modal functional****/

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const [clickRow, setClickRow] = useState([]);

    const handleClickRow = (row) => {
        handleShowModal();
        setClickRow(row);
    };

    /****Delete Patient row Functional****/

    const deleteRow = async (id) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it",
            });

            if (result.isConfirmed) {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "deletePatient",
                    patient_id: id,
                });

                const output = response.data;

                if (output === 1) {
                    await Swal.fire("Deleted!", "The patient record has been successfully removed.", "success");
                    fetchData();
                }
            }
        } catch (error) {
            // Handle any errors here
            console.error("An error occurred: ", error);
        }
    };

    /****Patient List Functional****/

    const [loading, setLoading] = useState(true);

    const [rows, setRows] = useState([]);

    const [records, setRecords] = useState([]);

    const [field, setField] = useState("");

    const [search, setSearch] = useState("");

    const [selectedButton, setSelectedButton] = useState("all");

    const [view, setView] = useState(0);

    useEffect(() => {
        fetchData();
    }, [selectedButton]);

    useEffect(() => {
        fetchData();
    }, []);

    const handleReset = () => {
        setField("");
        setSearch("");
        fetchData();
    };

    const handleSearch = () => {
        if (field == "") {
            toast.info("Please select a field!", {
                autoClose: 3000,
            });
        } else if (search == "") {
            toast.info("Please enter a search value!", {
                autoClose: 3000,
            });
        } else {
            fetchData(field, search);
        }
    };

    const fetchData = async (field = "", search = "") => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getAllPatient",
                field: field,
                search: search,
                assign_status: selectedButton,
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return {
                        ...row,
                        id: row.pk_patient_id,
                        index: index + 1,
                        assign_status: row.pk_assign_id != null ? "Assigned" : "Not assigned",
                        mobile_number: formatPhoneNumber(row.patient_mobile_phone),
                        patient_zip: row.patient_zip.trim(),
                    };
                });

                setRows(modifiedOutput);
                setRecords(modifiedOutput);
                setLoading(false);
            } else {
                setRows([]);
                setRecords([]);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const columns = [
        // {
        //     headerName: "#ID",
        //     field: "index",
        //     headerClassName: "super-app-theme--header",
        //     width: 50,
        // },
        {
            headerName: "Actions",
            field: "actions",
            type: "actions",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            cellClassName: "actions",
            getActions: ({ id, row }) => {
                return [
                    <div>
                        {/* View action */}
                        {/* <Tooltip title="Assign Patient">
                            <GridActionsCellItem
                                icon={<PersonAddAlt1SharpIcon />}
                                label="Assign"
                                className="textPrimary"
                                onClick={() =>
                                    navigate("/Admin/ProviderSelect", {
                                        state: {
                                            patient_id: id,
                                            patient_name: `${row.patient_first_name} ${row.patient_last_name}`,
                                            patient_city: row.patient_city,
                                            assignData: {
                                                pk_assign_id: row.pk_assign_id,
                                                fk_patient_id: row.fk_patient_id,
                                                fk_provider_id: row.fk_provider_id,
                                            },
                                        },
                                    })
                                }
                                color="inherit"
                            />
                        </Tooltip> */}

                        {/* Notify action */}

                        {/* {row["pk_assign_id"] ? (
                            <Tooltip title="Push Payment Notification">
                                <GridActionsCellItem
                                    icon={<NotificationAddIcon />}
                                    label="Push Payment Notification"
                                    className="textPrimary"
                                    onClick={() => handleClickRow(row)}
                                    color="inherit"
                                />
                            </Tooltip>
                        ) : (
                            ""
                        )} */}

                        {/* View details action */}

                        <Tooltip title="View Details">
                            <GridActionsCellItem
                                icon={<VisibilityIcon />}
                                label="View"
                                className="textPrimary"
                                onClick={() => navigate("/Admin/PatientView", { state: { patient_id: id } })}
                                color="inherit"
                            />
                        </Tooltip>

                        {/* Edit action */}
                        {/* <Tooltip title="Edit">
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                label="Edit"
                                className="textPrimary"
                                onClick={() => navigate("/Admin/PatientEdit", { state: { patient_id: id } })}
                                color="inherit"
                            />
                        </Tooltip> */}

                        {/* Delete action */}
                        <Tooltip title="Delete">
                            <GridActionsCellItem icon={<DeleteIcon />} label="Delete" className="textPrimary" onClick={() => deleteRow(id)} color="inherit" />
                        </Tooltip>
                    </div>,
                ];
            },
        },
        {
            headerName: "Assign Status",
            field: "assign_status",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            renderCell: (cellValues) => {
                const label = cellValues.row.pk_assign_id === null ? "Not assigned" : "Assigned";

                return <Chip label={label} color={cellValues.row.pk_assign_id === null ? "warning" : "success"} variant="outlined" />;
            },
        },
        {
            headerName: "Assigned To",
            field: "provider_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            renderCell: (cellValues) => {
                return cellValues.row.provider_name === null ? "NA" : cellValues.row.provider_name;
            },
        },
        {
            headerName: "Provider Status",
            field: "status",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
        },

        // {
        //     headerName: "Reason",
        //     field: "reason",
        //     headerClassName: "super-app-theme--header",
        //     width: 100,
        //     renderCell: (cellValues) => {
        //         if (cellValues.row.reason === "Other reasons") {
        //             return cellValues.row.other; // Fix: remove the curly braces
        //         } else {
        //             return cellValues.row.reason;
        //         }
        //     },
        // },
        {
            headerName: "Scheduled Date",
            field: "schedule_date",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
        },
        {
            headerName: "Date Assigned",
            field: "assign_date",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            renderCell: function (cellValues) {
                if (cellValues.row.assign_date) {
                    const dateString = cellValues.row.assign_date;
                    const dateParts = dateString.split("-");
                    const formattedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`; // MM/DD/YYYY format
                    return formattedDate;
                } else {
                    return "";
                }
            },
        },

        {
            headerName: "Payment Status",
            field: "payment_status",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            headerAlign: "left",
            align: "left",
            renderCell: (params) => {
                if (params.value) {
                    return (
                        <Badge bg="success" className="p-2">
                            {params.value}
                        </Badge>
                    );
                } else {
                    return (
                        <Badge bg="info" className="p-2">
                            Pending
                        </Badge>
                    );
                }
            },
        },

        {
            headerName: "First Name",
            field: "patient_first_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
        },
        {
            headerName: "Last Name",
            field: "patient_last_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
        },
        {
            headerName: "City",
            field: "patient_city",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
        },
        {
            headerName: "State",
            field: "patient_state",
            headerClassName: "super-app-theme--header",

            minWidth: 150,
        },
        // {
        //     field: "patient_address_1",
        //     headerName: "Address 1",
        //     headerClassName: "super-app-theme--header",
        //     width: 150,
        // },
        {
            headerName: "ZIP Code",
            field: "patient_zip",
            headerClassName: "super-app-theme--header",
            type: "number",
            minWidth: 150,
        },
        // {
        //     headerName: "Email",
        //     field: "patient_email",
        //     headerClassName: "super-app-theme--header",
        //     width: 190,
        // },
        {
            headerName: "Mobile Phone",
            field: "mobile_number",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
        },
    ];

    function handleFilter(event) {
        const inputValue = event.target.value.trim().toLowerCase();
        const newData = records.filter((row) => {
            return (
                row.pk_patient_id.toString().includes(inputValue) ||
                (row.patient_first_name + " " + row.patient_last_name).toLowerCase().includes(inputValue) ||
                (row.patient_address_1?.toLowerCase() || "").includes(inputValue) ||
                // row.patient_address_1.toLowerCase().includes(inputValue) ||
                // row.patient_address_2.toLowerCase().includes(inputValue) ||
                row.patient_city.toLowerCase().includes(inputValue) ||
                row.patient_state.toLowerCase().includes(inputValue) ||
                row.patient_zip.toLowerCase().includes(inputValue) ||
                row.patient_email.toLowerCase().includes(inputValue) ||
                row.patient_mobile_phone.toLowerCase().includes(inputValue) ||
                (row.status?.toLowerCase() || "").includes(inputValue)
                // row.patient_other_phone.toLowerCase().includes(inputValue) ||
                // row.description_of_condition.toLowerCase().includes(inputValue) ||
                // row.urgency.toLowerCase().includes(inputValue) ||
                // row.preferred_contact_method.toLowerCase().includes(inputValue) ||
                // row.preferred_contact_time.toLowerCase().includes(inputValue) ||
                // row.how_did_you_hear_about_us.toLowerCase().includes(inputValue)
            );
        });

        // if (inputValue === "") {
        //     setRows(records);
        // } else {
        // }
        setRows(newData);
        //console.log(newData);
    }

    const themes = sessionStorage.getItem("theme");

    const commonStyles = {
        react_select: "",
        react_tel: "",
    };

    const darkThemeStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "#343541",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "white",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                    // color: "black",
                },
            }),
        },
        react_tel: "",
    };

    const colourStyles = themes == "dark" ? darkThemeStyles : commonStyles;

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="app-content">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Row className="gx-0 gx-md-4">
                            <Col xs="12">
                                <Row className="gx-0 gx-md-4 justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="section-header d-flex align-items-center justify-content-between">
                                            <div className="section-heading ">
                                                {" "}
                                                <h4 className="mb-1">Patients</h4>
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                                                Home
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            Patients
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                            <div>
                                                <Button className="me-2" variant="contained" onClick={() => navigate("/Admin/PatientAdd")}>
                                                    Add Patient
                                                </Button>
                                                <Button variant="outlined" color="error" onClick={handleReset}>
                                                    Reset
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="section-essentials d-flex align-items-center mb-3 gap-2">
                                            <Select
                                                options={options_field}
                                                className="w-auto"
                                                styles={colourStyles.react_select}
                                                value={
                                                    field &&
                                                    options_field.find((option) => {
                                                        return option.value && option.value.toString() == field.toString();
                                                    })
                                                }
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: "field",
                                                            value: selectedOption["value"],
                                                        },
                                                    };

                                                    setField(event.target.value);

                                                    // setState((prevState) => ({
                                                    //     ...prevState,
                                                    //     field: event.target.value,
                                                    // }));
                                                }}
                                            />

                                            <input
                                                type="search"
                                                className="form-control m-0 w-auto"
                                                placeholder="Search"
                                                style={{ height: "39px" }}
                                                value={search}
                                                onChange={(event) => {
                                                    setSearch(event.target.value);
                                                }}
                                            />

                                            <Button className="me-2" variant="contained" onClick={() => handleSearch()}>
                                                Search
                                            </Button>

                                            {/* <div className="search-section position-relative">
                                                    <input
                                                        type="search"
                                                        placeholder="Search"
                                                        // onChange={(e) => {
                                                        //     setSearch(e.target.value);
                                                        //     //handleFilter(e);
                                                        // }}
                                                        value={search}
                                                        onKeyUp={(e) => {
                                                            setSearch(e.target.value);
                                                        }}
                                                        onChange={(event) => {
                                                            setSearch(e.target.value);
                                                        }}
                                                    />
                                                    <div className="icon">
                                                        <i className="fa-solid fa-magnifying-glass" />
                                                    </div>
                                                </div> */}

                                            {/* <a role="button" className="btn btn-primary btn-md" onClick={() => navigate("/Admin/AddPatient")}>
                                                Add New Patient
                                            </a> */}
                                        </div>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <Card className="my-card p-1 bg-white">
                                            <Card.Body className="card-padding-res">
                                                <div className="d-flex  mx-3 btn-align-col gap-3">
                                                    <ButtonGroup aria-label="outlined primary button group" className="">
                                                        <Button size="small" variant={view === 0 ? "contained" : "outlined"} onClick={() => setView(0)}>
                                                            <i class="material-icons icon-16pt me-2" style={{ fontSize: "22px" }}>
                                                                dehaze
                                                            </i>
                                                            List View
                                                        </Button>
                                                        <Button size="small" variant={view === 1 ? "contained" : "outlined"} onClick={() => setView(1)}>
                                                            <i className="material-icons icon-16pt me-2" style={{ fontSize: "22px" }}>
                                                                view_module
                                                            </i>
                                                            Grid View
                                                        </Button>
                                                    </ButtonGroup>
                                                    <ButtonGroup aria-label="outlined primary button group">
                                                        <Button
                                                            size="small"
                                                            variant={selectedButton === "all" ? "contained" : "outlined"}
                                                            onClick={() => setSelectedButton("all")}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant={selectedButton === "assigned" ? "contained" : "outlined"}
                                                            onClick={() => setSelectedButton("assigned")}
                                                        >
                                                            Assigned
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant={selectedButton === "notAssigned" ? "contained" : "outlined"}
                                                            onClick={() => setSelectedButton("notAssigned")}
                                                        >
                                                            Not Assigned
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>
                                                <Box
                                                    sx={{
                                                        height: "fit-content",
                                                        width: "100%",
                                                        "& .actions": {
                                                            color: "text.secondary",
                                                        },
                                                        "& .textPrimary": {
                                                            color: "text.primary",
                                                        },
                                                        "& .super-app-theme--header": {
                                                            backgroundColor: "#e3e6ee",
                                                        },
                                                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                                            {
                                                                outline: "none !important",
                                                            },
                                                    }}
                                                    style={{
                                                        height: "max-content",
                                                        width: "100%",
                                                        display: view === 1 ? "none" : "block",
                                                    }}
                                                    //style={{ height: rows.length == 0 ? 600 : "fit-content", width: "100%" }}
                                                >
                                                    <DataGrid
                                                        autoHeight
                                                        sx={{
                                                            border: "none",
                                                            m: 2,
                                                            "--DataGrid-overlayHeight": "600px",
                                                        }}
                                                        initialState={{
                                                            pagination: { paginationModel: { pageSize: 10 } },
                                                        }}
                                                        pageSizeOptions={[10, 25, 50]}
                                                        loading={loading}
                                                        slots={{
                                                            loadingOverlay: LinearProgress,
                                                            noRowsOverlay: CustomNoRowsOverlay,
                                                            toolbar: GridToolbar,
                                                        }}
                                                        slotProps={{
                                                            toolbar: {
                                                                csvOptions: { fileName: "PatientList" },
                                                                // printOptions: { fileName: "PatientList" },
                                                                printOptions: {
                                                                    disableToolbarButton: true,
                                                                    hideFooter: true,
                                                                    hideToolbar: true,
                                                                    // fileName: "PatientList",
                                                                    // pageStyle: `@media print {
                                                                    //     @page {
                                                                    //         size: A4 landscape;
                                                                    //         margin: 0;
                                                                    //     }
                                                                    // }`,
                                                                },
                                                                rows,
                                                                setRows,
                                                            },
                                                        }}
                                                        // rows={[]}
                                                        rows={rows}
                                                        columns={columns}
                                                        editMode="row"
                                                        onRowClick={(params) => handleRowClick(params.row)}
                                                        // onRowDoubleClick={(params) => handleRowClick(params.row)}
                                                        getRowClassName={() => "cursor-pointer"}
                                                    />
                                                </Box>

                                                <Box style={{ display: view === 0 ? "none" : "block" }}>
                                                    <Row className="mt-3 g-2">
                                                        {rows.length == 0 ? <CustomNoRowsOverlay /> : ""}
                                                        {/* {rows.map((row, index) => ( */}
                                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                            <Col
                                                                sm={4}
                                                                key={index}
                                                                role="button"
                                                                onClick={(event) => handleRowClick(row, event)}
                                                                style={{ aspectRatio: 3 / 2 }}
                                                            >
                                                                <div className="p-3 border rounded" style={{ background: "#f2f2f2" }}>
                                                                    <div className="mb-3">
                                                                        <h6>
                                                                            <PersonIcon className="me-2" />
                                                                            Patient
                                                                        </h6>

                                                                        <span>First Name: {row["patient_first_name"]}</span>
                                                                        <br />
                                                                        <span>Last Name: {row["patient_last_name"]}</span>
                                                                        <br />
                                                                        <span>City: {row["patient_city"]}</span>
                                                                        <br />
                                                                        <span>State: {row["patient_state"]}</span>
                                                                        <br />
                                                                        <span>Zip Code: {row["patient_zip"]}</span>
                                                                        <br />
                                                                        <span>
                                                                            Phone:{" "}
                                                                            <a href={`tel:${row["mobile_number"]}`} className="text-decoration-none">
                                                                                {row["mobile_number"]}
                                                                            </a>
                                                                        </span>
                                                                        <div>Scheduled Date: {row["schedule_date"]}</div>
                                                                    </div>
                                                                    <div>
                                                                        <h6>
                                                                            <i className="fa-solid fa-person-circle-plus me-2" />
                                                                            Status
                                                                        </h6>
                                                                        <span>
                                                                            Date Assigned: {row["formatted_assign_date"] ? row["formatted_assign_date"] : "NA"}
                                                                        </span>
                                                                        <br />
                                                                        <span>
                                                                            Assign Status:{" "}
                                                                            {/* <Chip
                                                                                label={row["assign_status"]}
                                                                                color={row.pk_assign_id === null ? "warning" : "success"}
                                                                                variant="outlined"
                                                                            /> */}
                                                                            {row["pk_assign_id"] ? (
                                                                                <Badge bg="success" className="p-1">
                                                                                    Assigned
                                                                                </Badge>
                                                                            ) : (
                                                                                <Badge bg="warning" className="p-1">
                                                                                    Not assigned
                                                                                </Badge>
                                                                            )}
                                                                        </span>
                                                                        <div>Assigned To: {row["provider_name"] ? row["provider_name"] : "NA"}</div>
                                                                        <span>
                                                                            Payment Status:{" "}
                                                                            {row["payment_status"] ? (
                                                                                <Badge bg="success" className="p-1">
                                                                                    {row["payment_status"]}
                                                                                </Badge>
                                                                            ) : (
                                                                                <Badge bg="info" className="p-1">
                                                                                    Pending
                                                                                </Badge>
                                                                            )}
                                                                        </span>
                                                                        <br />
                                                                        <span>Provider Status: {row["status"] ? row["status"] : "NA"}</span>
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    <TablePagination
                                                        rowsPerPageOptions={[12, 24, 60]}
                                                        component="div"
                                                        count={rows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </Box>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <PushMessageModal clickRow={clickRow} showModal={showModal} handleCloseModal={handleCloseModal} />
        </>
    );
}

export default PatientList;
