import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { security_key, BASE_URL } from "../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Badge from "react-bootstrap/Badge";

function PaymentList({ patientId }) {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, [location]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(BASE_URL + "API/apicall", {
        security_key: security_key,
        mode: "getPatientPayment",
        patient_id: patientId,
      });

      const output = response.data;

      if (output.length > 0) {
        const modifiedOutput = output.map((row, index) => {
          return { ...row, id: row.pk_pay_id, index: index + 1 };
        });
        setRows(modifiedOutput);
      } else {
        // Handle unsuccessful response, e.g., show an alert to the user.
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [
    {
      headerName: "#ID",
      field: "index",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
    },
    {
      headerName: "Paid Amount",
      field: "amount",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "left",
      align: "left",
      minWidth: 100,
    },
    {
      headerName: "Currency",
      field: "currency",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "left",
      align: "left",
      minWidth: 100,
    },
    {
      headerName: "Payer ID",
      field: "payer_id",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "left",
      align: "left",
      minWidth: 150,
    },
    {
      headerName: "Transaction ID",
      field: "transaction_id",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "left",
      align: "left",
      minWidth: 150,
    },
    {
      headerName: "Payment Status",
      field: "payment_status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      renderCell: (params) => (
        <Badge bg="success" className="p-2">
          {params.value}
        </Badge>
      ),
    },
    {
      headerName: "Date Of Payment",
      field: "paid_date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "left",
      align: "left",
      minWidth: 100,
    },
  ];

  return (
    <>
      <ToastContainer position="top-center" />

      <Box
        sx={{
          height: "fit-content",
          width: "100%",
          overflowX: "auto",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
          "& .super-app-theme--header": {
            backgroundColor: "#e3e6ee",
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
            {
              outline: "none !important",
            },
          "@media (max-width: 600px)": {
            "& .MuiDataGrid-columnHeader": {
              fontSize: "0.75rem",
            },
            "& .MuiDataGrid-cell": {
              fontSize: "0.75rem",
            },
          },
        }}
        style={{ height: "max-content", width: "100%" }}
      >
        <DataGrid
          autoHeight
          sx={{ border: "none", m: 2, "--DataGrid-overlayHeight": "600px" }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50]}
          loading={loading}
          rows={rows}
          columns={columns}
          disableColumnMenu // Disable the column menu to always show the header names
        />
      </Box>
    </>
  );
}

export default PaymentList;
