import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { security_key, BASE_URL } from "../../global";
import axios from "axios";

const formatPhoneNumber = (rawPhoneNumber) => {
    const phoneNumber = String(rawPhoneNumber);
    const countryCode = phoneNumber.substring(0, 1);
    const restOfNumber = phoneNumber.substring(1);

    // Format the phone number as +1 (XXX) XXX-XXXX
    const formattedPhoneNumber = `+${countryCode} (${restOfNumber.substring(0, 3)}) ${restOfNumber.substring(3, 6)}-${restOfNumber.substring(6)}`;

    return formattedPhoneNumber;
};

function DashboardAdmin() {
    const navigate = useNavigate();

    useEffect(() => {
        getDashboardAllData();
    }, []);

    const [totalPatient, setTotalPatient] = React.useState(0);
    const [totalProvider, setTotalProvider] = React.useState(0);
    const [patientList, setPatientList] = React.useState([]);
    const [providerList, setProviderList] = React.useState([]);

    const getDashboardAllData = async () => {
        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "getDashboardAllData",
        });
        const output = response.data;

        if (output) {
            setTotalPatient(output["total_patient"]);
            setTotalProvider(output["total_provider"]);
            setPatientList(output["patient_list"]);
            setProviderList(output["provider_list"]);
        }
    };

    return (
        <div className="app-content" id="admin-dashboard">
            <div className="page-description">
                <h4 className="mb-0">Dashboard</h4>
            </div>
            <div className="row gx-0 gx-md-4">
                <div className="col-xxl-6 col-xl-6 col-md-6">
                    <div className="card widget widget-stats mb-3">
                        <div className="card-body">
                            <div className="widget-stats-container d-flex">
                                <div className="widget-stats-icon widget-stats-icon-primary">
                                    <i className="fa-solid fa-users" />
                                </div>
                                <div className="widget-stats-content flex-fill">
                                    <span className="widget-stats-title">Total Patients</span>
                                    <span className="widget-stats-amount">{totalPatient}</span>
                                    {/* <span className="widget-stats-info">Total No. of Patients</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-md-6">
                    <div className="card widget widget-stats mb-3">
                        <div className="card-body">
                            <div className="widget-stats-container d-flex">
                                <div className="widget-stats-icon widget-stats-icon-warning">
                                    <i className="fa-solid fa-window-restore" />
                                </div>
                                <div className="widget-stats-content flex-fill">
                                    <span className="widget-stats-title">Total Providers</span>
                                    <span className="widget-stats-amount">{totalProvider}</span>
                                    {/* <span className="widget-stats-info">Total No. of Providers</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row gx-0 gx-md-4">
                <div className="col-xxl-6">
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            {/* <h5 className="card-title">Patients</h5> */}
                            <h5 className="card-title">Top 10 Latest Patient Entries</h5>
                            <a className="text-primary text-decoration-none" role="button" onClick={() => navigate("/Admin/PatientList")}>
                                View All
                            </a>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive tb-fx-height" style={{ whiteSpace: "nowrap" }}>
                                <table className="table dash_table">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            {/* <th>City</th>
                                                    <th>State</th>
                                                    <th>Address 1</th> */}
                                            <th>Email</th>
                                            <th>Mobile Number</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {patientList.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.patient_first_name}</td>
                                                <td>{row.patient_last_name}</td>
                                                {/*<td>
                                                            {row.patient_first_name} {row.patient_last_name}
                                                        </td>
                                                         <td>{row.patient_city}</td>
                                                        <td>{row.patient_state}</td>
                                                        <td>{row.patient_address_1}</td> */}
                                                <td>{row.patient_email}</td>
                                                <td>{formatPhoneNumber(row.patient_mobile_phone)}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary btn-sm"
                                                        onClick={() =>
                                                            navigate("/Admin/PatientEdit", {
                                                                state: { patient_id: row.pk_patient_id },
                                                            })
                                                        }
                                                    >
                                                        View
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        {/* <tr>
                                                    <td>John Doe</td>
                                                    <td>123 Main St</td>
                                                    <td>CA</td>
                                                    <td>john@example.com</td>
                                                    <td>(555) 555-1234</td>
                                                    <td>
                                                        <button className="btn btn-primary btn-sm">View</button>
                                                    </td>
                                                </tr>*/}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-6">
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            {/* <h5 className="card-title">Providers</h5> */}
                            <h5 className="card-title">Top 10 Latest Provider Entries</h5>
                            <a className="text-primary text-decoration-none" role="button" onClick={() => navigate("/Admin/ProviderList")}>
                                View All
                            </a>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive tb-fx-height" style={{ whiteSpace: "nowrap" }}>
                                <table className="table dash_table">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            {/* <th>City</th>
                                                    <th>State</th>
                                                    <th>Address 1</th>
                                                    <th>ZIP Code</th> */}
                                            <th>Email</th>
                                            <th>Mobile Number</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {providerList.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.provider_first_name}</td>
                                                <td>{row.provider_last_name}</td>
                                                {/* <td>{row.practice_city}</td>
                                                        <td>{row.practice_state}</td>
                                                        <td>{row.practice_address_1}</td>
                                                        <td>{row.practice_zip}</td> */}
                                                <td>{row.provider_email}</td>
                                                <td>{formatPhoneNumber(row.practice_phone)}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary btn-sm"
                                                        onClick={() =>
                                                            navigate("/Admin/ProviderView", {
                                                                state: { provider_id: row.pk_provider_id },
                                                            })
                                                        }
                                                    >
                                                        View
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        {/* <tr>
                                                    <td>John</td>
                                                    <td>Doe</td>
                                                    <td>123 Main St</td>
                                                    <td>Anytown</td>
                                                    <td>CA</td>
                                                    <td>12345</td>
                                                    <td>john@example.com</td>
                                                    <td>(555) 555-1234</td>
                                                    <td>
                                                        <button className="btn btn-primary btn-sm">View</button>
                                                    </td>
                                                </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardAdmin;
