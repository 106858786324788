/*
Purpose: list details of student with use datatable
Developer: BY PKS
Date:26-OCT-2023 
Project: EMSTYLE
*/

// import { useState, useCallback, useEffect } from "react";
import React, { useState, useCallback, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

// import Button from "react-bootstrap/Button";
import Select from "react-select";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import PersonIcon from "@mui/icons-material/Person";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { security_key, BASE_URL, options_patient } from "../../global";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress, TablePagination } from "@mui/material";

import CustomNoRowsOverlay from "../../components/NoRow";

const formatPhoneNumber = (rawPhoneNumber) => {
    const phoneNumber = String(rawPhoneNumber);
    const countryCode = phoneNumber.substring(0, 1);
    const restOfNumber = phoneNumber.substring(1);

    // Format the phone number as +1 (XXX) XXX-XXXX
    const formattedPhoneNumber = `+${countryCode} (${restOfNumber.substring(0, 3)}) ${restOfNumber.substring(3, 6)}-${restOfNumber.substring(6)}`;

    return formattedPhoneNumber;
};

const options_field = [
    { value: "practice_city", label: "Practice City" },
    { value: "practice_state", label: "Practice State" },
    { value: "practice_zip", label: "Practice Zip" },
    { value: "provider_specialty", label: "Provider Specialty" },
];

const handlePageChange = (params) => {
    const { pageSize, page, sortModel } = params;
    console.log(pageSize);
    console.log(page);
    console.log(sortModel);
    // fetchData(pageSize, page, sortModel);
};

const ProviderList = () => {
    const navigate = useNavigate();

    /****Grid Pagination functional****/

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    /****Provider List Functional****/

    const [view, setView] = useState(0);

    const [loading, setLoading] = useState(true);

    const [rows, setRows] = React.useState([]);

    const [records, setRecords] = useState([]);

    const [state, setState] = useState({
        field: "",
        search: "",
    });

    useEffect(() => {
        // document.title = "Your Custom Title";

        fetchData(state.field, state.search);
    }, []);

    const handleSearch = () => {
        if (state.field == "") {
            toast.info("Please select a field!", {
                autoClose: 3000,
            });
        } else if (state.search == "") {
            toast.info("Please enter a search value!", {
                autoClose: 3000,
            });
        } else {
            fetchData(state.field, state.search);
        }
    };

    const fetchData = async (field = "", search = "") => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getAllProviderV2",
                field: field,
                search: search,
            });
            const output = response.data;

            // console.log("Response Data:", response.data);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return {
                        ...row,
                        id: row.pk_provider_id,
                        provider_name: `${row.provider_first_name} ${row.provider_last_name}`,
                        image_url: `https://emstylemsk.net/API/upload/image/${row.provider_image}`,
                        index: index + 1,
                        mobile_number: formatPhoneNumber(row.scheduling_phone),
                    };
                });

                setRows(modifiedOutput);
                setRecords(modifiedOutput);
                setLoading(false);
            } else {
                setRows([]);
                setRecords([]);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleAssignBtnClick = (row, event = false) => {
        if (event && event.target.tagName === "A") {
            return;
        }
        navigate("/Admin/PatientSelect", {
            state: {
                provider_id: row.pk_provider_id,
                provider_name: `${row.provider_first_name} ${row.provider_last_name}`,
                practice_city: row.practice_city,
                practice_state: row.practice_state,
                assignData: {
                    pk_assign_id: row.pk_assign_id,
                    fk_patient_id: row.fk_patient_id,
                    fk_provider_id: row.fk_provider_id,
                },
            },
        });
    };

    const columns = [
        // {
        //     headerName: "#ID",
        //     field: "index",
        //     headerClassName: "super-app-theme--header",
        //     width: 50,
        // },
        // {
        //     headerName: "Provider Image",
        //     field: "image_url",
        //     headerClassName: "super-app-theme--header",
        //     // flex: 1,
        //     width: 100,

        //     renderCell: (params) => {
        //         const imageUrl = "https://emstylemsk.net/API/upload/image/" + params.row.provider_image; // Assuming the URL of the image is stored in provider_image field

        //         return imageUrl ? (
        //             <img src={imageUrl} alt="Provider Image" style={{ width: "100px", height: "auto", aspectRatio: "1/1" }} />
        //         ) : (
        //             <span>No Image Available</span>
        //         );
        //     },
        // },

        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            headerClassName: "super-app-theme--header",
            minWidth: 200,
            flex: 1,
            cellClassName: "actions",
            getActions: ({ id, row }) => {
                return [
                    <div key={id}>
                        <Button
                            variant="contained"
                            size="small"
                            // startIcon={<PersonAddAlt1SharpIcon />}
                            onClick={() => handleAssignBtnClick(row)}
                        >
                            Assign Patient
                        </Button>
                    </div>,
                ];
            },
        },

        {
            headerName: "Practice Name",
            field: "practice_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider First Name",
            field: "provider_first_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider Last Name",
            field: "provider_last_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider Specialty",
            field: "provider_specialty",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Practice City",
            field: "practice_city",
            headerClassName: "super-app-theme--header",
            minWidth: 100,
            flex: 1,
        },
        {
            headerName: "Practice State",
            field: "practice_state",
            headerClassName: "super-app-theme--header",
            minWidth: 100,
            flex: 1,
        },

        {
            headerName: "Practice Zip",
            field: "practice_zip",
            headerClassName: "super-app-theme--header",
            minWidth: 100,
            flex: 1,
        },
        {
            headerName: "Scheduling Phone",
            field: "mobile_number",
            headerClassName: "super-app-theme--header",
            minWidth: 130,
            flex: 1,
        },
        // {
        //     headerName: "Provider Title",
        //     field: "provider_title",
        //     headerClassName: "super-app-theme--header",
        //     minWidth: 100,
        // },

        // {
        //     headerName: "Practice Address 1",
        //     field: "practice_address_1",
        //     headerClassName: "super-app-theme--header",
        //     minWidth: 150,
        // },
        // {
        //     headerName: "Practice Address 2",
        //     field: "practice_address_2",
        //     headerClassName: "super-app-theme--header",
        //     minWidth: 150,
        // },

        // {
        //     headerName: "Scheduling email",
        //     field: "scheduling_email",
        //     headerClassName: "super-app-theme--header",
        //     minWidth: 190,
        // },
    ];

    const customCsvFileName = "customFileName"; // Replace this with your desired custom file name

    const csvOptions = {
        fileName: customCsvFileName,
        delimiter: ";",
        utf8WithBom: true,
    };

    const modalStyle = {
        minWidth: "80%",
    };

    const themes = sessionStorage.getItem("theme");

    const commonStyles = {
        react_select: "",
        react_tel: "",
    };

    const darkThemeStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "#343541",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "white",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                    // color: "black",
                },
            }),
        },
        react_tel: "",
    };

    const colourStyles = themes == "dark" ? darkThemeStyles : commonStyles;

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="app-content">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Row className="gx-0 gx-md-4">
                            <Col xs="12">
                                <Row className="gx-0 gx-md-4 justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="section-header d-flex align-items-center justify-content-between">
                                            <div className="section-heading">
                                                <h4 className="mb-1">Providers</h4>
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                                                Home
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            Assign
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => {
                                                    setState(() => ({
                                                        search: "",
                                                        field: "",
                                                    }));
                                                    fetchData();
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </div>

                                        <div className="section-essentials d-flex align-items-center mb-3 gap-2">
                                            <Select
                                                options={options_field}
                                                className="w-auto"
                                                styles={colourStyles.react_select}
                                                value={
                                                    state &&
                                                    state.field &&
                                                    options_field.find((option) => {
                                                        return option.value && option.value.toString() === state.field.toString();
                                                    })
                                                }
                                                onChange={(selectedOption) => {
                                                    const event = {
                                                        target: {
                                                            name: "field",
                                                            value: selectedOption["value"],
                                                        },
                                                    };

                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        field: event.target.value,
                                                    }));
                                                }}
                                            />

                                            <input
                                                type="search"
                                                className="form-control w-auto"
                                                placeholder="Search"
                                                style={{ height: "39px" }}
                                                value={state.search}
                                                onChange={(event) => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        search: event.target.value,
                                                    }));
                                                }}
                                            />

                                            {/* <div className="search-section position-relative">
                                                    <input type="search" placeholder="Search providers" onChange={handleFilter} />
                                                    <div className="icon">
                                                        <i className="fa-solid fa-magnifying-glass" />
                                                    </div>
                                                </div> */}

                                            <Button variant="contained" onClick={() => handleSearch()}>
                                                Search
                                            </Button>
                                        </div>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <Card className="my-card p-1 bg-white">
                                            <Card.Body className="card-padding-res">
                                                <ButtonGroup aria-label="outlined primary button group" className="mx-3">
                                                    <Button size="small" variant={view === 0 ? "contained" : "outlined"} onClick={() => setView(0)}>
                                                        <i class="material-icons icon-16pt me-2" style={{ fontSize: "22px" }}>
                                                            dehaze
                                                        </i>
                                                        List View
                                                    </Button>
                                                    <Button size="small" variant={view === 1 ? "contained" : "outlined"} onClick={() => setView(1)}>
                                                        <i className="material-icons icon-16pt me-2" style={{ fontSize: "22px" }}>
                                                            view_module
                                                        </i>
                                                        Grid View
                                                    </Button>
                                                </ButtonGroup>
                                                <Box
                                                    sx={{
                                                        height: "fit-content",
                                                        width: "100%",
                                                        "& .actions": {
                                                            color: "text.secondary",
                                                        },
                                                        "& .textPrimary": {
                                                            color: "text.primary",
                                                        },
                                                        "& .super-app-theme--header": {
                                                            backgroundColor: "#e3e6ee",
                                                        },
                                                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                                            {
                                                                outline: "none !important",
                                                            },
                                                    }}
                                                    style={{ height: "max-content", width: "100%", display: view === 1 ? "none" : "block" }}
                                                    // style={{ height: "max-content", width: "100%" }}
                                                    // style={{ height: rows.length == 0 ? 600 : "fit-content", width: "100%" }}
                                                >
                                                    <DataGrid
                                                        autoHeight
                                                        sx={{ border: "none", m: 2, "--DataGrid-overlayHeight": "600px" }}
                                                        initialState={{
                                                            pagination: { paginationModel: { pageSize: 10 } },
                                                        }}
                                                        pageSizeOptions={[10, 25, 50]}
                                                        loading={loading}
                                                        slots={{
                                                            loadingOverlay: LinearProgress,
                                                            noRowsOverlay: CustomNoRowsOverlay,
                                                            toolbar: GridToolbar,
                                                            // toolbar: EditToolbar,
                                                        }}
                                                        slotProps={{
                                                            toolbar: {
                                                                csvOptions: { fileName: "ProviderList" },
                                                                printOptions: {
                                                                    disableToolbarButton: true,
                                                                    hideFooter: true,
                                                                    hideToolbar: true,
                                                                },
                                                                // printOptions: { fileName: "ProviderList" },
                                                                rows,
                                                                setRows,
                                                            },
                                                        }}
                                                        // rows={[]}
                                                        rows={rows}
                                                        columns={columns}
                                                        // initialState={{ pinnedColumns: { right: ["actions"] } }}
                                                        rowHeight={80}
                                                        editMode="row"
                                                        onPageChange={handlePageChange}
                                                    />
                                                </Box>

                                                <Box style={{ display: view === 0 ? "none" : "block" }}>
                                                    <Row className="mt-3 g-2">
                                                        {/* {rows.map((row, index) => ( */}
                                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                            <Col
                                                                sm={4}
                                                                key={index}
                                                                role="button"
                                                                onClick={(event) => handleAssignBtnClick(row, event)}
                                                                style={{ aspectRatio: 3 / 2 }}
                                                            >
                                                                <div className="p-3 border rounded" style={{ background: "#f2f2f2" }}>
                                                                    <div className="mb-3">
                                                                        <h6>
                                                                            <PersonIcon className="me-2" />
                                                                            Provider
                                                                        </h6>
                                                                        <div>First Name: {row["provider_first_name"]}</div>
                                                                        <div>Last Name: {row["provider_last_name"]}</div>
                                                                        <div>Specialty: {row["provider_specialty"]}</div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <h6>
                                                                            <i class="fa-solid fa-hospital me-2"></i>
                                                                            Practice
                                                                        </h6>
                                                                        <div>Name: {row["practice_name"]}</div>
                                                                        <div>City: {row["practice_city"]}</div>
                                                                        <div>State: {row["practice_state"]}</div>
                                                                        <div>Zip: {row["practice_zip"]}</div>
                                                                    </div>
                                                                    <div>
                                                                        <h6>
                                                                            <i class="fa-regular fa-calendar me-2"></i>
                                                                            Schedule
                                                                        </h6>
                                                                        <div>
                                                                            Phone:{" "}
                                                                            <a href={`tel:${row["mobile_number"]}`} className="text-decoration-none">
                                                                                {row["mobile_number"]}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    <TablePagination
                                                        rowsPerPageOptions={[12, 24, 60]}
                                                        component="div"
                                                        count={rows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </Box>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProviderList;
