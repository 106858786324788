import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useHistory, NavLink } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
// import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import MedicationIcon from "@mui/icons-material/Medication";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ReorderIcon from "@mui/icons-material/Reorder";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Select from "react-select";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";

import { security_key, BASE_URL, options_city } from "../../global";
import ProviderService from "../ProviderServiceEditMode";
import ProviderServiceListMode from "../ProviderServiceListMode";
// import "./AdminProfile.css";

import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import Moment from "moment";
import "moment-timezone";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

// const currentDate = new Date();
// const dbFormattedDateTime = currentDate.toISOString().slice(0, 19).replace("T", " ");
// const dbFormattedDate = currentDate.toISOString().split("T")[0];
// const dbFormattedTime = currentDate.toTimeString().split(" ")[0];

// const FILE_SIZE_LIMIT = 1048576; // 1MB in bytes
const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB in bytes

const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "image/gif"]; // Example supported image formats

const basicSchema = Yup.object().shape({
    /*
    provider_image: Yup.mixed().required("Upload Provider Image is required."),
    */
    // .test("fileSize", "File is too large", (value) => value && value.size <= FILE_SIZE_LIMIT)
    // .test("fileFormat", "Unsupported file format", (value) => value && SUPPORTED_FORMATS.includes(value.type)),

    /*
    provider_title: Yup.string()
        .min(2, "Provider Title must be at least 2 characters.")
        .max(250, "Provider Title must be at most 250 characters.")
        // .matches(/^[a-zA-Z ]*$/, "Provider Title can only contain letters and spaces")
        .matches(/^[a-zA-Z. ]*$/, "Provider Title can only contain letters, dots, and spaces.")
        .required("Provider Title is required.")
        .strict(),
    */
    provider_first_name: Yup.string()
        .min(3, "Provider First Name must be at least 3 characters.")
        .max(250, "Provider First Name must be at most 250 characters.")
        .matches(/^[a-zA-Z]*$/, "Provider First Name can only contain letters.")
        .required("Provider First Name is required.")
        .strict(),
    provider_last_name: Yup.string()
        .min(1, "Provider Last Name must be at least 1 character.")
        .max(250, "Provider Last Name must be at most 250 characters.")
        .matches(/^[a-zA-Z]*$/, "Provider Last Name can only contain letters.")
        .required("Provider Last Name is required.")
        .strict(),

    provider_mobile_number: Yup.string()
        .required("Provider Mobile Number is required.")
        .min(11, "Provider Mobile number must be at least 10 digits.")
        .max(16, "Provider Mobile number must not exceed 15 digits.")
        .test("unique-digits.", "Invalid mobile number.", function (value) {
            const cleanedDigits = value.replace(/^\+\D*/, "");
            const digitsWithoutCountryCode = cleanedDigits.slice(1);
            const hasDifferentDigit = Array.from(new Set(digitsWithoutCountryCode)).length !== 1;
            return hasDifferentDigit || this.createError({ message: "Invalid mobile number." });
        })
        .strict(),
    provider_email: Yup.string()
        .email("Invalid email.")
        .required("Provider Email is required.")
        .strict()
        .test("valid-email.", "Invalid email format.", function (value) {
            if (!value) return true; // Allow empty value (let required() handle this)

            // Check for invalid patterns
            const invalidPatterns = [
                /^\./, // Starts with a dot
                /\.$/, // Ends with a dot
                /\.{2,}/, // Multiple dots in sequence
                /\.@/, // Starts with a dot immediately followed by @
                // /\.com.com$/,
                /(\.com){2,}$/, // Ends with .com repeated one or more times
                // /@\.\w+$/,
            ];

            const validPatterns = [/@.*\.[^.].*$/];

            const isInvalid = invalidPatterns.some((pattern) => pattern.test(value));

            const isValid = validPatterns.some((pattern) => pattern.test(value));

            return isValid && !isInvalid;
        }),
    /*
    provider_specialty: Yup.string()
        .min(3, "Provider Specialty must be at least 3 characters.")
        .max(250, "Provider Specialty must be at most 250 characters.")
        .required("Provider Specialty is required.")
        .strict(),
   
    provider_memberships: Yup.string()
        .min(3, "Provider Memberships must be at least 3 characters.")
        .max(250, "Provider Memberships must be at most 250 characters.")
        .required("Provider Memberships is required.")
        .strict(),
    */
    // provider_description_of_expertise: Yup.string()
    //     .min(3, "Provider Description of Expertise must be at least 3 characters.")
    //     .max(250, "Provider Description of Expertise must be at most 250 characters.")
    //     .required("Provider Description of Expertise is required.")
    //     .strict(),
    provider_guidance: Yup.array()
        .required("At least one provider guidance option must be selected.")
        .min(1, "At least one provider guidance option must be selected."),

    practice_name: Yup.string()
        .min(3, "Practice Name must be at least 3 characters.")
        .max(250, "Practice Name must be at most 250 characters.")
        // .matches(/^[a-zA-Z]*$/, "Practice Name can only contain letters")
        .matches(/^[a-zA-Z ]*$/, "Provider Title can only contain letters and spaces.")
        .required("Practice Name is required.")
        .strict(),
    practice_email: Yup.string()
        .email("Invalid email.")
        .required("Practice Email is required.")
        .strict()
        .test("valid-email.", "Invalid email format.", function (value) {
            if (!value) return true; // Allow empty value (let required() handle this)

            // Check for invalid patterns
            const invalidPatterns = [
                /^\./, // Starts with a dot
                /\.$/, // Ends with a dot
                /\.{2,}/, // Multiple dots in sequence
                /\.@/, // Starts with a dot immediately followed by @
                // /\.com.com$/,
                /(\.com){2,}$/, // Ends with .com repeated one or more times
                // /@\.\w+$/,
            ];

            const validPatterns = [/@.*\.[^.].*$/];

            const isInvalid = invalidPatterns.some((pattern) => pattern.test(value));

            const isValid = validPatterns.some((pattern) => pattern.test(value));

            return isValid && !isInvalid;
        }),
    practice_phone: Yup.string()
        .required("Practice Phone is required.")
        .min(11, "Practice Phone must be at least 10 digits.")
        .max(16, "Practice Phone must not exceed 15 digits.")
        .test("unique-digits.", "Invalid mobile number.", function (value) {
            const cleanedDigits = value.replace(/^\+\D*/, "");
            const digitsWithoutCountryCode = cleanedDigits.slice(1);
            const hasDifferentDigit = Array.from(new Set(digitsWithoutCountryCode)).length !== 1;
            return hasDifferentDigit || this.createError({ message: "Invalid mobile number." });
        })
        .strict(),

    practice_city: Yup.string().required("Practice City is required.").strict(),
    practice_state: Yup.string(),
    practice_address_1: Yup.string()
        .min(3, "Practice Address 1 must be at least 3 characters.")
        .max(250, "Practice Address 1 must be at most 250 characters.")
        .required("Practice Address 1 is required.")
        .strict(),

    /*
    practice_address_2: Yup.string()
        .min(3, "Practice Address 2 must be at least 3 characters.")
        .max(250, "Practice Address 2 must be at most 250 characters.")
        .required("Practice Address 2 is required.")
        .strict(),
    */

    practice_zip: Yup.string()
        .min(5, "Practice Zip must be at least 5 digits.")
        .max(10, "Practice Zip must be at most 9 digits.")
        // .matches(/^\d{5}$/, "Invalid ZIP code.")
        .required("Practice Zip is required.")
        // .test("is-valid-zip", "Invalid ZIP code.", (value) => Number(value) >= 1 && Number(value) <= 99950)
        .strict(),
    scheduling_contact_first_name: Yup.string()
        .min(3, "Scheduling Contact First Name must be at least 3 characters.")
        .max(250, "Scheduling Contact First Name must be at most 250 characters.")
        .matches(/^[a-zA-Z ]*$/, "Scheduling Contact First Name can only contain letters and spaces.")
        .required("Scheduling Contact First Name is required.")
        .strict(),
    scheduling_contact_last_name: Yup.string()
        .min(3, "Scheduling Contact Last Name must be at least 3 characters.")
        .max(250, "Scheduling Contact Last Name must be at most 250 characters.")
        .matches(/^[a-zA-Z ]*$/, "Scheduling Contact Last Name can only contain letters and spaces.")
        .required("Scheduling Contact Last Name is required.")
        .strict(),
    scheduling_phone: Yup.string()
        .required("Scheduling Phone is required.")
        .min(11, "Scheduling Phone must be at least 10 digits.")
        .max(16, "Scheduling Phone must not exceed 15 digits.")
        .test("unique-digits.", "Invalid mobile number.", function (value) {
            const cleanedDigits = value.replace(/^\+\D*/, "");
            const digitsWithoutCountryCode = cleanedDigits.slice(1);
            const hasDifferentDigit = Array.from(new Set(digitsWithoutCountryCode)).length !== 1;
            return hasDifferentDigit || this.createError({ message: "Invalid mobile number" });
        })
        .strict(),
    scheduling_email: Yup.string()
        .email("Invalid email.")
        .required("Scheduling Email is required.")
        .strict()
        .test("valid-email.", "Invalid email format.", function (value) {
            if (!value) return true; // Allow empty value (let required() handle this)

            // Check for invalid patterns
            const invalidPatterns = [
                /^\./, // Starts with a dot
                /\.$/, // Ends with a dot
                /\.{2,}/, // Multiple dots in sequence
                /\.@/, // Starts with a dot immediately followed by @
                // /\.com.com$/,
                /(\.com){2,}$/, // Ends with .com repeated one or more times
                // /@\.\w+$/,
            ];

            const validPatterns = [/@.*\.[^.].*$/];

            const isInvalid = invalidPatterns.some((pattern) => pattern.test(value));

            const isValid = validPatterns.some((pattern) => pattern.test(value));

            return isValid && !isInvalid;
        }),

    /*    
    hours_of_operation: Yup.string()
        .min(3, "Hours of Operation must be at least 3 characters.")
        .max(250, "Hours of Operation must be at most 250 characters.")
        .required("Hours of Operation is required.")
        .strict(),
    */

    prov_dir_password: Yup.string()
        .required("Password is required")
        .matches(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,20}$/,
            "Password must contain at least one special character, one number, and be between 8 and 20 characters."
        )
        .strict(),
});

async function checkProviderEmailUniqueness(email) {
    const response = await axios.post(BASE_URL + "API/apicall", {
        security_key: security_key,
        mode: "checkProviderEmailUniqueness",
        provider_email: email,
        prov_auth_id: sessionStorage.getItem("loger_id"),
    });

    const output = response.data;

    return output;
}

async function checkSchedulingEmailUniqueness(email) {
    const response = await axios.post(BASE_URL + "API/apicall", {
        security_key: security_key,
        mode: "checkSchedulingEmailUniqueness",
        scheduling_email: email,
        practice_id: sessionStorage.getItem("fk_practice_id"),
    });

    const output = response.data;

    return output;
}

const readInputImg = (event, id) => {
    const input = event.target;
    const reader = new FileReader();

    reader.onload = function () {
        const imgElement = document.querySelector(id);
        if (imgElement) {
            imgElement.src = reader.result;
        }
    };

    if (input.files && input.files[0]) {
        reader.readAsDataURL(input.files[0]);
    }
};

const formatPhoneNumber = (rawPhoneNumber) => {
    const phoneNumber = String(rawPhoneNumber);
    const countryCode = phoneNumber.substring(0, 1);
    const restOfNumber = phoneNumber.substring(1);

    // Format the phone number as +1 (XXX) XXX-XXXX
    const formattedPhoneNumber = `+${countryCode} (${restOfNumber.substring(0, 3)}) ${restOfNumber.substring(3, 6)}-${restOfNumber.substring(6)}`;

    return formattedPhoneNumber;
};

const Profile = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const formRef = useRef(null);

    const [loading, setLoading] = useState(true);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    /****Password Hide Show Functional****/

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /****MUI Nav Tab****/

    const [value, setValue] = React.useState("1");

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    /****Get Provider Data Functional****/

    const [providerData, setProviderData] = useState();

    useEffect(() => {
        fetchData();
    }, [location]);

    const fetchData = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getProvider",
                provider_id: sessionStorage.getItem("loger_id"),
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output) {
                setProviderData(output);
                setLoading(false);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const initialValues = {
        /*tbl_provider_information*/
        provider_image: providerData && providerData.provider_image ? providerData.provider_image : "default.jfif",
        practice_name: providerData && providerData.practice_name ? providerData.practice_name : "",
        practice_email: providerData && providerData.practice_email ? providerData.practice_email : "",
        provider_first_name: providerData && providerData.provider_first_name ? providerData.provider_first_name : "",
        provider_last_name: providerData && providerData.provider_last_name ? providerData.provider_last_name : "",
        provider_title: providerData && providerData.provider_title ? providerData.provider_title : "",
        provider_specialty: providerData && providerData.provider_specialty ? providerData.provider_specialty : "",
        provider_memberships: providerData && providerData.provider_memberships ? providerData.provider_memberships : "",
        // provider_description_of_expertise: providerData && providerData.provider_description_of_expertise ? providerData.provider_description_of_expertise : "",
        provider_guidance: providerData && providerData.provider_guidance ? providerData.provider_guidance.split(", ") : [],
        practice_address_1: providerData && providerData.practice_address_1 ? providerData.practice_address_1 : "",
        practice_address_2: providerData && providerData.practice_address_2 ? providerData.practice_address_2 : "",
        practice_city: providerData ? `${providerData.fk_city_id} || ${providerData.practice_city.trim()} || ${providerData.practice_state.trim()}` : "",
        // practice_city: providerData && providerData.practice_city ? providerData.practice_city : "",
        practice_state: providerData && providerData.practice_state ? providerData.practice_state : "",
        practice_zip: providerData && providerData.practice_zip ? providerData.practice_zip : "",
        practice_phone: providerData && providerData.practice_phone ? providerData.practice_phone : "",
        provider_mobile_number: providerData && providerData.provider_mobile_number ? providerData.provider_mobile_number : "",
        scheduling_phone: providerData && providerData.scheduling_phone ? providerData.scheduling_phone : "",
        provider_email: providerData && providerData.provider_email ? providerData.provider_email : "",
        scheduling_email: providerData && providerData.scheduling_email ? providerData.scheduling_email : "",
        scheduling_contact_first_name: providerData && providerData.scheduling_contact_first_name ? providerData.scheduling_contact_first_name : "",
        scheduling_contact_last_name: providerData && providerData.scheduling_contact_last_name ? providerData.scheduling_contact_last_name : "",
        hours_of_operation: providerData && providerData.hours_of_operation ? providerData.hours_of_operation : "",
        prov_dir_password: providerData && providerData.prov_dir_password ? providerData.prov_dir_password : "",

        start_date: dbFormattedDate,
        start_time: dbFormattedTime,

        created_by: sessionStorage.getItem("loger_id"),
        created_on: dbFormattedDateTime,
        updated_by: sessionStorage.getItem("loger_id"),
        updated_on: dbFormattedDateTime,
        visibility: "1",
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };

            fileReader.readAsDataURL(file);
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        validate: async (values) => {
            const errors = {};

            // Check provider email uniqueness
            const isProviderEmailUnique = await checkProviderEmailUniqueness(values.provider_email);
            if (!isProviderEmailUnique) {
                errors.provider_email = "Provider Email is already taken";
            }

            // Check scheduling email uniqueness
            const isSchedulingEmailUnique = await checkSchedulingEmailUniqueness(values.scheduling_email);
            if (!isSchedulingEmailUnique) {
                errors.scheduling_email = "Scheduling Email is already taken";
            }

            return errors;
        },

        onSubmit: async (formValues) => {
            toast.dismiss();

            setIsSubmitting(true);

            // formValues.namename = "hello";

            let base64Image = null;
            let fileExtension = null;

            if (typeof formValues["provider_image"] === "object") {
                base64Image = await convertBase64(formValues["provider_image"]);
                let fileName = formValues["provider_image"]["name"];
                fileExtension = fileName.split(".").pop();
                //console.log(base64Image);
            }

            const provider_image = {
                base64Image: base64Image,
                fileExtension: fileExtension,
            };

            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "updateProvider",
                provider_id: sessionStorage.getItem("pk_provider_id"),
                practice_id: sessionStorage.getItem("fk_practice_id"),
                ...formValues,
                provider_image_new: provider_image,
            });
            const output = response.data;
            if (output == 1) {
                // sessionStorage.setItem("image", `${values.provider_image}`);
                toast.success("Updated successful!", {
                    autoClose: 3000,
                });
                fetchData();
                setTimeout(function () {
                    setIsEdit(false);
                }, 3000);
            } else {
                toast.error("Oops! Something went wrong.", {
                    autoClose: 3000,
                });
            }

            setTimeout(function () {
                setIsSubmitting(false);
            }, 3000);
        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;
    const setFieldValue = formik.setFieldValue;

    // Handler function to toggle selected guidance options
    const handleGuidanceChange = (option) => {
        const { provider_guidance } = formik.values;
        const updatedGuidance = provider_guidance.includes(option) ? provider_guidance.filter((item) => item !== option) : [...provider_guidance, option];
        formik.setFieldValue("provider_guidance", updatedGuidance);
    };

    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) {
            const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
            const correspondingTab = getTabForField(firstErrorField);

            setValue(correspondingTab);
            const errorFieldElement = document.getElementById(firstErrorField);

            if (errorFieldElement) {
                const elementRect = errorFieldElement.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const middleOfElement = absoluteElementTop - window.innerHeight / 2;

                window.scrollTo({
                    top: middleOfElement,
                    behavior: "smooth",
                });
            } else {
                // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
            //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
    }, [formik]);

    const tabOneFields = [
        // "provider_title",
        "provider_first_name",
        "provider_last_name",
        "provider_email",
        "provider_mobile_number",
        "provider_specialty",
        "provider_memberships",
        "provider_description_of_expertise",
        "provider_guidance",
    ];
    const tabTwoFields = [
        "practice_name",
        "practice_email",
        "practice_phone",
        "practice_city",
        "practice_state",
        "practice_address_1",
        "practice_address_2",
        "practice_zip",
    ];
    const tabThreeFields = ["scheduling_contact_first_name", "scheduling_contact_last_name", "scheduling_email", "scheduling_phone", "hours_of_operation"];

    const getTabForField = (fieldName) => {
        if (tabOneFields.includes(fieldName)) {
            return "1";
        } else if (tabTwoFields.includes(fieldName)) {
            return "2";
        } else if (tabThreeFields.includes(fieldName)) {
            return "3";
        } else {
            return "1";
        }
    };

    const themes = sessionStorage.getItem("theme");

    const commonStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "white",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "black",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                },
            }),
        },
        react_tel: "",
    };

    const darkThemeStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "#343541",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "white",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                    // color: "black",
                },
            }),
        },
        react_tel: {
            // input: {
            //     backgroundColor: "#343541",
            //     color: "white",
            //     border: "1px solid #2684ff",
            //     // Add more styles as needed
            // },
            // countryListItem: {
            //     backgroundColor: "#343541",
            //     color: "white",
            //     "&:hover": {
            //         backgroundColor: "#2684ff",
            //         color: "black",
            //     },
            // },
        },
    };

    const colourStyles = themes == "dark" ? darkThemeStyles : commonStyles;

    function stringAvatar(name) {
        return {
            // sx: {
            //     bgcolor: stringToColor(name),
            // },
            children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
        };
    }

    return (
        <>
            <ToastContainer position="top-center" />
            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div className="app-content">
                    <div className="content-wrapper">
                        <div className="container-fluid">
                            <div className="section-header d-flex align-items-center justify-content-between">
                                <div className="section-heading">
                                    <h4 className="mb-1">Profile</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a role="button" onClick={() => navigate("/Provider/PatientList")}>
                                                    Home
                                                </a>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                Profile
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                {/* <div className="section-essentials d-flex align-items-center justify-content-between gap-2">
                                <a role="button" className="btn btn-light btn-md" onClick={() => navigate("/Admin/ListPatient")}>
                                    Back
                                </a>
                            </div> */}
                            </div>
                            <Card className="my-card px-3 py-3 bg-white">
                                <Card.Body className="card-padding-res pt-0">
                                    <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                        <div className="row">
                                            <div className="col-12 text-end">
                                                {isEdit ? (
                                                    <input
                                                        type="button"
                                                        className="btn btn-outline-secondary"
                                                        name="btnAddMore"
                                                        value="View Profile"
                                                        onClick={() => setIsEdit(false)}
                                                    />
                                                ) : (
                                                    <input
                                                        type="button"
                                                        className="btn btn-outline-secondary"
                                                        name="btnAddMore"
                                                        value="Edit Profile"
                                                        onClick={() => setIsEdit(true)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="me-5" style={{ position: "relative" }}>
                                                {/* <Avatar
                                                    {...stringAvatar(
                                                        `${providerData && providerData.provider_first_name ? providerData.provider_first_name : "N/A"} ${
                                                            providerData && providerData.provider_last_name ? providerData.provider_last_name : "N/A"
                                                        }`
                                                    )}
                                                    sx={{ width: 72, height: 72 }}
                                                /> */}
                                                <img
                                                    id="user_img_output"
                                                    className="rounded"
                                                    src={`https://emstylemsk.net/API/upload/image/${values.provider_image}`}
                                                    width={100}
                                                    height={100}
                                                />

                                                {isEdit ? (
                                                    <>
                                                        <label for="provider_image" style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}>
                                                            <EditIcon className="text-primary" />
                                                        </label>
                                                        <Form.Control
                                                            type="file"
                                                            className={errors.provider_image && touched.provider_image ? "input-error" : ""}
                                                            id="provider_image"
                                                            name="provider_image"
                                                            accept="image/*"
                                                            style={{ display: "none" }}
                                                            onChange={(event) => {
                                                                setFieldValue("provider_image", event.currentTarget.files[0]);
                                                                readInputImg(event, "#user_img_output");
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.provider_image && touched.provider_image && <p className="error">{errors.provider_image}</p>}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div>
                                                <h1>
                                                    {/* {providerData && providerData.provider_title ? providerData.provider_title.replace(".", "") : "N/A"}. */}
                                                    {providerData && providerData.provider_first_name ? providerData.provider_first_name : "N/A"}{" "}
                                                    {providerData && providerData.provider_last_name ? providerData.provider_last_name : "N/A"}
                                                </h1>
                                                <div>
                                                    {/* <span className="me-3">Single page application</span> */}
                                                    <span className="me-3 text-secondary">Practice Name</span>
                                                    <span className="me-3">
                                                        {providerData && providerData.practice_name ? providerData.practice_name : "N/A"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {isEdit ? (
                                            <>
                                                <Box sx={{ width: "100%", typography: "body1" }}>
                                                    <TabContext value={value}>
                                                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                            <TabList
                                                                onChange={handleTabChange}
                                                                variant="scrollable"
                                                                scrollButtons="auto"
                                                                allowScrollButtonsMobile
                                                                aria-label="scrollable auto tabs example"
                                                            >
                                                                <Tab label="Provider" value="1" icon={<PersonIcon />} iconPosition="start" />
                                                                <Tab label="Practice" value="2" icon={<MedicationIcon />} iconPosition="start" />
                                                                <Tab label="Schedule" value="3" icon={<CalendarTodayIcon />} iconPosition="start" />
                                                                <Tab label="Service" value="4" icon={<MedicalServicesIcon />} iconPosition="start" />
                                                                {/* <Tab label="Credential" value="5" icon={<KeyIcon />} iconPosition="start" /> */}
                                                            </TabList>
                                                        </Box>
                                                        <TabPanel value="1">
                                                            {/* <Form.Group controlId="provider_title" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">Title</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="provider_title"
                                                                        value={values.provider_title}
                                                                        onChange={handleChange}
                                                                        // onBlur={handleBlur}
                                                                        onBlur={(e) => {
                                                                            const trimmedValue = e.target.value.trim();
                                                                            const event = {
                                                                                target: {
                                                                                    name: "provider_title",
                                                                                    value: trimmedValue,
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                            handleBlur(event);
                                                                        }}
                                                                        className={errors.provider_title && touched.provider_title ? "input-error" : ""}
                                                                    />
                                                                    {errors.provider_title && touched.provider_title && (
                                                                        <p className="error">{errors.provider_title}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group> */}

                                                            <Form.Group controlId="provider_first_name" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        First Name<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="provider_first_name"
                                                                        value={values.provider_first_name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={
                                                                            errors.provider_first_name && touched.provider_first_name ? "input-error" : ""
                                                                        }
                                                                    />
                                                                    {errors.provider_first_name && touched.provider_first_name && (
                                                                        <p className="error">{errors.provider_first_name}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="provider_last_name" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Last Name<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="provider_last_name"
                                                                        value={values.provider_last_name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.provider_last_name && touched.provider_last_name ? "input-error" : ""}
                                                                    />
                                                                    {errors.provider_last_name && touched.provider_last_name && (
                                                                        <p className="error">{errors.provider_last_name}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="provider_email" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Email<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="email"
                                                                        name="provider_email"
                                                                        value={values.provider_email}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.provider_email && touched.provider_email ? "input-error" : ""}
                                                                    />
                                                                    {errors.provider_email && touched.provider_email && (
                                                                        <p className="error">{errors.provider_email}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="provider_mobile_number" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Mobile Number<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <PhoneInput
                                                                        name="provider_mobile_number"
                                                                        countryCodeEditable={false}
                                                                        country="us"
                                                                        value={values.provider_mobile_number}
                                                                        onlyCountries={["us"]}
                                                                        onChange={(value) => {
                                                                            const event = {
                                                                                target: {
                                                                                    name: "provider_mobile_number",
                                                                                    value: value,
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const event = {
                                                                                target: {
                                                                                    name: "provider_mobile_number",
                                                                                    value: e.target.value,
                                                                                },
                                                                            };
                                                                            handleBlur(event);
                                                                            setTouched({ ...touched, provider_mobile_number: true });
                                                                        }}
                                                                        id="provider_mobile_number"
                                                                        inputClass={
                                                                            errors.provider_mobile_number && touched.provider_mobile_number
                                                                                ? "input-error form-control"
                                                                                : "form-control"
                                                                        }
                                                                    />
                                                                    {errors.provider_mobile_number && touched.provider_mobile_number && (
                                                                        <p className="error">{errors.provider_mobile_number}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="provider_specialty" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Specialty<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        style={{ height: "100px" }}
                                                                        name="provider_specialty"
                                                                        value={values.provider_specialty}
                                                                        onChange={handleChange}
                                                                        // onBlur={handleBlur}
                                                                        onBlur={(e) => {
                                                                            const trimmedValue = e.target.value.trim();
                                                                            const event = {
                                                                                target: {
                                                                                    name: "provider_specialty",
                                                                                    value: trimmedValue,
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                            handleBlur(event);
                                                                        }}
                                                                        className={errors.provider_specialty && touched.provider_specialty ? "input-error" : ""}
                                                                    />
                                                                    {errors.provider_specialty && touched.provider_specialty && (
                                                                        <p className="error">{errors.provider_specialty}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="provider_memberships" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">Memberships</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        style={{ height: "100px" }}
                                                                        name="provider_memberships"
                                                                        value={values.provider_memberships}
                                                                        onChange={handleChange}
                                                                        onBlur={(e) => {
                                                                            const trimmedValue = e.target.value.trim();
                                                                            const event = {
                                                                                target: {
                                                                                    name: "provider_memberships",
                                                                                    value: trimmedValue,
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                            handleBlur(event);
                                                                        }}
                                                                        className={
                                                                            errors.provider_memberships && touched.provider_memberships ? "input-error" : ""
                                                                        }
                                                                    />
                                                                    {errors.provider_memberships && touched.provider_memberships && (
                                                                        <p className="error">{errors.provider_memberships}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            {/* <Form.Group controlId="provider_description_of_expertise" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    {" "}
                                                                    <h6 className="text-secondary">Description of Expertise</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        style={{ height: "100px" }}
                                                                        name="provider_description_of_expertise"
                                                                        value={values.provider_description_of_expertise}
                                                                        onChange={handleChange}
                                                                        //onBlur={handleBlur}
                                                                        onBlur={(e) => {
                                                                            const trimmedValue = e.target.value.trim();
                                                                            const event = {
                                                                                target: {
                                                                                    name: "provider_description_of_expertise",
                                                                                    value: trimmedValue,
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                            handleBlur(event);
                                                                        }}
                                                                        className={
                                                                            errors.provider_description_of_expertise &&
                                                                            touched.provider_description_of_expertise
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors.provider_description_of_expertise && touched.provider_description_of_expertise && (
                                                                        <p className="error">{errors.provider_description_of_expertise}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group> */}

                                                            <div className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Guidance<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={formik.values.provider_guidance.includes("Visual Guidance")}
                                                                                    onChange={() => handleGuidanceChange("Visual Guidance")}
                                                                                    onBlur={formik.handleBlur}
                                                                                    name="provider_guidance"
                                                                                />
                                                                            }
                                                                            label="Visual Guidance"
                                                                        />
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={formik.values.provider_guidance.includes("Landmark Guidance")}
                                                                                    onChange={() => handleGuidanceChange("Landmark Guidance")}
                                                                                    onBlur={formik.handleBlur}
                                                                                    name="provider_guidance"
                                                                                />
                                                                            }
                                                                            label="Landmark Guidance"
                                                                        />
                                                                        {formik.errors.provider_guidance && formik.touched.provider_guidance && (
                                                                            <p className="error">{formik.errors.provider_guidance}</p>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>
                                                            </div>

                                                            <Row>
                                                                <Col className="text-end">
                                                                    <Button variant="primary" type="submit" className="my-4" disabled={isSubmitting}>
                                                                        <span className="px-4">Save</span>
                                                                        {isSubmitting ? <LinearProgress /> : ""}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </TabPanel>
                                                        <TabPanel value="2">
                                                            <Form.Group controlId="practice_name" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Name<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="practice_name"
                                                                        value={values.practice_name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.practice_name && touched.practice_name ? "input-error" : ""}
                                                                    />
                                                                    {errors.practice_name && touched.practice_name && (
                                                                        <p className="error">{errors.practice_name}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="practice_email" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Email<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="email"
                                                                        name="practice_email"
                                                                        value={values.practice_email}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.practice_email && touched.practice_email ? "input-error" : ""}
                                                                    />
                                                                    {errors.practice_email && touched.practice_email && (
                                                                        <p className="error">{errors.practice_email}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="practice_phone" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Phone<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <PhoneInput
                                                                        name="practice_phone"
                                                                        countryCodeEditable={false}
                                                                        country="us"
                                                                        value={values.practice_phone}
                                                                        onlyCountries={["us"]}
                                                                        onChange={(value) => {
                                                                            const event = {
                                                                                target: {
                                                                                    name: "practice_phone",
                                                                                    value: value,
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const event = {
                                                                                target: {
                                                                                    name: "practice_phone",
                                                                                    value: e.target.value,
                                                                                },
                                                                            };
                                                                            handleBlur(event);
                                                                            setTouched({ ...touched, practice_phone: true });
                                                                        }}
                                                                        id="practice_phone"
                                                                        inputClass={
                                                                            errors.practice_phone && touched.practice_phone
                                                                                ? "input-error form-control"
                                                                                : "form-control"
                                                                        }
                                                                    />
                                                                    {errors.practice_phone && touched.practice_phone && (
                                                                        <p className="error">{errors.practice_phone}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="practice_city" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        City<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Select
                                                                        options={options_city}
                                                                        id="practice_city"
                                                                        styles={colourStyles.react_select}
                                                                        // value={values.find(
                                                                        //     (option) =>
                                                                        //         option.value &&
                                                                        //         values.practice_city &&
                                                                        //         option.value.toString() === values.practice_city.toString()
                                                                        // )}

                                                                        value={
                                                                            values &&
                                                                            values.practice_city &&
                                                                            options_city.find((option) => {
                                                                                // console.log("Value:", values.practice_city);
                                                                                // console.log("Option Value:", option.value);
                                                                                // return (
                                                                                //     option.label &&
                                                                                //     option.label.toString() === "Albany ".trim().toString()
                                                                                // );
                                                                                // return (
                                                                                //     option.label.toString() === values.practice_city.trim().toString()
                                                                                // );
                                                                                return (
                                                                                    option.value && option.value.toString() === values.practice_city.toString()
                                                                                );
                                                                            })
                                                                        }
                                                                        onChange={(selectedOption) => {
                                                                            const event = {
                                                                                target: {
                                                                                    name: "practice_city",
                                                                                    value: selectedOption["value"],
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                        }}
                                                                        onBlur={(event) => {
                                                                            handleBlur(event);
                                                                            setTouched({ ...touched, practice_city: true });
                                                                        }}
                                                                        filterOption={(option, inputValue) => {
                                                                            return option.label.toLowerCase().includes(inputValue.toLowerCase());
                                                                        }}
                                                                        className={errors.practice_city && touched.practice_city ? "input-error" : ""}
                                                                    />
                                                                    {errors.practice_city && touched.practice_city && (
                                                                        <p className="error">{errors.practice_city}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="practice_state" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        State<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        readOnly={true}
                                                                        value={values.practice_city.split(" || ")[2]} // Extract the state from the combined value
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.practice_state && touched.practice_state ? "input-error" : ""}
                                                                    />
                                                                    {errors.practice_state && touched.practice_state && (
                                                                        <p className="error">{errors.practice_state}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="practice_address_1" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Address 1<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="practice_address_1"
                                                                        value={values.practice_address_1}
                                                                        onChange={handleChange}
                                                                        //onBlur={handleBlur}
                                                                        onBlur={(e) => {
                                                                            const trimmedValue = e.target.value.trim();
                                                                            const event = {
                                                                                target: {
                                                                                    name: "practice_address_1",
                                                                                    value: trimmedValue,
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                            handleBlur(event);
                                                                        }}
                                                                        className={errors.practice_address_1 && touched.practice_address_1 ? "input-error" : ""}
                                                                    />
                                                                    {errors.practice_address_1 && touched.practice_address_1 && (
                                                                        <p className="error">{errors.practice_address_1}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="practice_address_2" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">Address 2</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="practice_address_2"
                                                                        value={values.practice_address_2}
                                                                        onChange={handleChange}
                                                                        //onBlur={handleBlur}
                                                                        onBlur={(e) => {
                                                                            const trimmedValue = e.target.value.trim();
                                                                            const event = {
                                                                                target: {
                                                                                    name: "practice_address_2",
                                                                                    value: trimmedValue,
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                            handleBlur(event);
                                                                        }}
                                                                        className={errors.practice_address_2 && touched.practice_address_2 ? "input-error" : ""}
                                                                    />
                                                                    {errors.practice_address_2 && touched.practice_address_2 && (
                                                                        <p className="error">{errors.practice_address_2}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group controlId="practice_zip" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Zip Code<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    {/* <Form.Control
                                                                                type="text"
                                                                                name="practice_zip"
                                                                                value={values.practice_zip}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                className={errors.practice_zip && touched.practice_zip ? "input-error" : ""}
                                                                            /> */}
                                                                    <InputMask
                                                                        mask="99999-9999"
                                                                        maskChar=""
                                                                        type="text"
                                                                        name="practice_zip"
                                                                        value={values.practice_zip}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={`form-control ${
                                                                            errors.practice_zip && touched.practice_zip ? "input-error" : ""
                                                                        }`}
                                                                    />
                                                                    {errors.practice_zip && touched.practice_zip && (
                                                                        <p className="error">{errors.practice_zip}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Row>
                                                                <Col className="text-end">
                                                                    <Button variant="primary" type="submit" className="my-4" disabled={isSubmitting}>
                                                                        <span className="px-4">Save</span>
                                                                        {isSubmitting ? <LinearProgress /> : ""}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </TabPanel>
                                                        <TabPanel value="3">
                                                            <Form.Group controlId="scheduling_contact_first_name" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Scheduling Contact First Name<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="scheduling_contact_first_name"
                                                                        value={values.scheduling_contact_first_name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={
                                                                            errors.scheduling_contact_first_name && touched.scheduling_contact_first_name
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors.scheduling_contact_first_name && touched.scheduling_contact_first_name && (
                                                                        <p className="error">{errors.scheduling_contact_first_name}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="scheduling_contact_last_name" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Scheduling Contact Last Name<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="scheduling_contact_last_name"
                                                                        value={values.scheduling_contact_last_name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={
                                                                            errors.scheduling_contact_last_name && touched.scheduling_contact_last_name
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors.scheduling_contact_last_name && touched.scheduling_contact_last_name && (
                                                                        <p className="error">{errors.scheduling_contact_last_name}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="scheduling_phone" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Scheduling Phone<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <PhoneInput
                                                                        name="scheduling_phone"
                                                                        countryCodeEditable={false}
                                                                        country="us"
                                                                        value={values.scheduling_phone}
                                                                        onlyCountries={["us"]}
                                                                        onChange={(value) => {
                                                                            const event = {
                                                                                target: {
                                                                                    name: "scheduling_phone",
                                                                                    value: value,
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const event = {
                                                                                target: {
                                                                                    name: "scheduling_phone",
                                                                                    value: e.target.value,
                                                                                },
                                                                            };
                                                                            handleBlur(event);
                                                                            setTouched({ ...touched, scheduling_phone: true });
                                                                        }}
                                                                        inputClass={
                                                                            errors.scheduling_phone && touched.scheduling_phone
                                                                                ? "input-error form-control"
                                                                                : "form-control"
                                                                        }
                                                                    />
                                                                    {errors.scheduling_phone && touched.scheduling_phone && (
                                                                        <p className="error">{errors.scheduling_phone}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="scheduling_email" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">
                                                                        Scheduling Email<span className="text-danger">*</span>
                                                                    </h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="email"
                                                                        name="scheduling_email"
                                                                        value={values.scheduling_email}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.scheduling_email && touched.scheduling_email ? "input-error" : ""}
                                                                    />
                                                                    {errors.scheduling_email && touched.scheduling_email && (
                                                                        <p className="error">{errors.scheduling_email}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="hours_of_operation" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">Hours of Operation</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="hours_of_operation"
                                                                        value={values.hours_of_operation}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.hours_of_operation && touched.hours_of_operation ? "input-error" : ""}
                                                                    />
                                                                    {errors.hours_of_operation && touched.hours_of_operation && (
                                                                        <p className="error">{errors.hours_of_operation}</p>
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                            <Row>
                                                                <Col className="text-end">
                                                                    <Button variant="primary" type="submit" className="my-4" disabled={isSubmitting}>
                                                                        <span className="px-4">Save</span>
                                                                        {isSubmitting ? <LinearProgress /> : ""}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </TabPanel>
                                                        <TabPanel value="4">
                                                            <ProviderService providerId={sessionStorage.getItem("pk_provider_id")} />
                                                        </TabPanel>
                                                        {/* <TabPanel value="5">
                                                            <Form.Group controlId="provider_email" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">Login Email</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Control type="email" readOnly={true} value={values.provider_email} />
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group controlId="prov_dir_password" className="row align-items-center mb-3">
                                                                <Col sm={6}>
                                                                    <h6 className="text-secondary">Login Password</h6>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div style={{ position: "relative" }}>
                                                                        <Form.Control
                                                                            type={showPassword ? "text" : "password"}
                                                                            name="prov_dir_password"
                                                                            value={values.prov_dir_password}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            className={errors.prov_dir_password && touched.prov_dir_password ? "input-error" : ""}
                                                                        />
                                                                        {errors.prov_dir_password && touched.prov_dir_password && (
                                                                            <p className="error">{errors.prov_dir_password}</p>
                                                                        )}
                                                                        <span style={{ position: "absolute", top: "10px", right: "10px" }}>
                                                                            <a role="button" onClick={toggleShowPassword}>
                                                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                            </a>
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            </Form.Group>
                                                        </TabPanel> */}
                                                    </TabContext>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box sx={{ width: "100%", typography: "body1" }}>
                                                <TabContext value={value}>
                                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                        <TabList
                                                            onChange={handleTabChange}
                                                            variant="scrollable"
                                                            scrollButtons="auto"
                                                            allowScrollButtonsMobile
                                                            aria-label="scrollable auto tabs example"
                                                        >
                                                            <Tab
                                                                className="pb-0"
                                                                label="Provider"
                                                                value="1"
                                                                icon={<PersonIcon />}
                                                                iconPosition="start"
                                                                style={{ minHeight: "44px" }}
                                                            />
                                                            <Tab
                                                                className="pb-0"
                                                                label="Practice"
                                                                value="2"
                                                                icon={<MedicationIcon />}
                                                                iconPosition="start"
                                                                style={{ minHeight: "44px" }}
                                                            />
                                                            <Tab
                                                                className="pb-0"
                                                                label="Schedule"
                                                                value="3"
                                                                icon={<CalendarTodayIcon />}
                                                                iconPosition="start"
                                                                style={{ minHeight: "44px" }}
                                                            />
                                                            <Tab
                                                                className="pb-0"
                                                                label="Service"
                                                                value="4"
                                                                icon={<MedicalServicesIcon />}
                                                                iconPosition="start"
                                                                style={{ minHeight: "44px" }}
                                                            />
                                                            {/* <Tab label="Credential" value="5" icon={<KeyIcon />} iconPosition="start" /> */}
                                                        </TabList>
                                                    </Box>
                                                    <TabPanel value="1">
                                                        {/* <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Title</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{providerData && providerData.provider_title ? providerData.provider_title : "N/A"}</p>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">First Name</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.provider_first_name
                                                                        ? providerData.provider_first_name
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Last Name</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.provider_last_name ? providerData.provider_last_name : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Email</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{providerData && providerData.provider_email ? providerData.provider_email : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Mobile Number</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.provider_mobile_number
                                                                        ? formatPhoneNumber(providerData.provider_mobile_number)
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Specialty</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.provider_specialty ? providerData.provider_specialty : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Memberships</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.provider_memberships
                                                                        ? providerData.provider_memberships
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Description of Expertise</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.provider_description_of_expertise
                                                                        ? providerData.provider_description_of_expertise
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Provider Guidance</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{providerData && providerData.provider_guidance ? providerData.provider_guidance : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Name</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{providerData && providerData.practice_name ? providerData.practice_name : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Email</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{providerData && providerData.practice_email ? providerData.practice_email : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Phone</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.practice_phone
                                                                        ? formatPhoneNumber(providerData.practice_phone)
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">City</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{providerData && providerData.practice_city ? providerData.practice_city : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">State</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{providerData && providerData.practice_state ? providerData.practice_state : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Address 1</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.practice_address_1 ? providerData.practice_address_1 : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Address 2</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.practice_address_2 ? providerData.practice_address_2 : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">ZIP</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{providerData && providerData.practice_zip ? providerData.practice_zip : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="3">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Scheduling Contact First Name</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.scheduling_contact_first_name
                                                                        ? providerData.scheduling_contact_first_name
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Scheduling Contact Last Name</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.scheduling_contact_last_name
                                                                        ? providerData.scheduling_contact_last_name
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Scheduling Phone</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.scheduling_phone
                                                                        ? formatPhoneNumber(providerData.scheduling_phone)
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Scheduling Email</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{providerData && providerData.scheduling_email ? providerData.scheduling_email : "N/A"}</p>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Hours of Operation</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {providerData && providerData.hours_of_operation ? providerData.hours_of_operation : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="4">
                                                        <ProviderServiceListMode providerId={sessionStorage.getItem("pk_provider_id")} />
                                                    </TabPanel>

                                                    {/* <TabPanel value="5">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h6 className="text-secondary">Login Email</h6>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p>{providerData && providerData.prov_email ? providerData.prov_email : "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h6 className="text-secondary">Login Password</h6>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="d-flex align-items-center justiy-content-center">
                                                                <p className="me-4 mb-0">
                                                                    {providerData && providerData.prov_dir_password
                                                                        ? showPassword
                                                                            ? providerData.prov_dir_password
                                                                            : "*".repeat(providerData.prov_dir_password.length)
                                                                        : "N/A"}
                                                                </p>

                                                                <a role="button" onClick={toggleShowPassword}>
                                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel> */}
                                                </TabContext>
                                            </Box>
                                        )}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Profile;
