import React, { useState } from "react";
import "./Sidebar.css";
import Image from "react-bootstrap/Image";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

function AppSidebar({ isOpen, toggleSidebar }) {
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path ? "active-page" : "";
    };

    return (
        <div className={`app-sidebar ${isOpen ? "open" : ""}`}>
            <div className="sidebar-header">
                <div className={`logo text-center ${isOpen ? "hidden-sidebar-logo" : ""}`}>
                    <Image src="../images/logo-light.png" width={150} className="img-fluid mx-auto d-block" />
                </div>
                <button className="close-btn" onClick={toggleSidebar}>
                    &times;
                </button>
            </div>
            <div className="app-menu">
                <ul className="accordion-menu">
                    <li className="sidebar-title">Menu</li>
                    <li className={isActive("/PatientApp/Dashboard")}>
                        <NavLink to="/PatientApp/Dashboard">
                            <i className="fa-solid fa-gauge" />
                            Dashboard
                        </NavLink>
                    </li>
                    <li className={isActive("/PatientApp/Profile")}>
                        <NavLink to="/PatientApp/Profile">
                            <i className="fa-solid fa-user" />
                            Profile
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AppSidebar;
