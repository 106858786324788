import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { security_key, BASE_URL } from "../../global";
import Loader from "../../components/Loader";

const PayPalRedirect = () => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [amount, setAmount] = useState(0);
    const [patientId, setPatientId] = useState(0);
    const [providerId, setProviderId] = useState(0);

    const getUrlParameter = (parameterName) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(parameterName);
    };

    const assign_id = getUrlParameter("assign_id");

    useEffect(() => {
        const getAssignDetails = async () => {
            try {
                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "getAssignDetails",
                    assign_id: assign_id,
                });

                const output = response.data;

                // if (Object.keys(output).length > 0) {
                if (output) {
                    setAmount(output.amount);
                    setPatientId(output.fk_patient_id);
                    setProviderId(output.fk_provider_id);
                    // alert(`${assign_id}||${patientId}||${providerId}`);
                } else {
                    alert("Empty response from server");
                    console.error("Empty response from server");
                }
            } catch (error) {
                console.error("Error while fetching assign details:", error);
            }
        };

        if (assign_id) {
            getAssignDetails();
        }
    }, [assign_id]);

    useEffect(() => {
        if (amount > 0 && patientId > 0) {
            const submitPayPalForm = () => {
                document.getElementById("casePayment").submit();
            };
            submitPayPalForm();
        }
    }, [amount, providerId]);

    return (
        <>
            {loading && (
                <div style={{ height: "100vh" }}>
                    <Loader />
                </div>
            )}
            {providerId != 0 && (
                // <form action="https://www.sandbox.paypal.com/cgi-bin/webscr" method="post" target="_top" id="casePayment" name="casePayment">
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" id="casePayment">
                    <input type="hidden" name="cmd" value="_xclick" />
                    {/* <input type="hidden" name="business" value="cdsphp1@gmail.com" /> */}
                    <input type="hidden" name="business" value="patrick@emstyle.com" />
                    <input type="hidden" name="item_name" value="Referral Payment" />
                    <input type="hidden" name="currency_code" value="USD" />
                    <input type="hidden" name="amount" id="total_amount" value={amount} />
                    <input type="hidden" name="return" value={`${BASE_URL}PaypalSuccess?assignId=${assign_id}`} />
                    <input type="hidden" name="cancel_return" value={`${BASE_URL}Patient/Login`} />
                    <input type="hidden" name="notify_url" value={`${BASE_URL}API/pay_notify.php`} />
                    <input type="hidden" name="custom" value={`${assign_id}||${patientId}||${providerId}`} />
                    <button
                        type="submit"
                        className="d-none"
                        style={
                            {
                                // backgroundColor: "#007bff",
                                // color: "white",
                                // borderColor: "#007bff",
                                // borderRadius: "0.25rem",
                                // padding: "0.25rem 0.5rem",
                                // fontSize: "0.875rem",
                                // lineHeight: "1.25",
                            }
                        }
                    >
                        Pay Now
                    </button>
                </form>
            )}
        </>
    );
};

export default PayPalRedirect;
