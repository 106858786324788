import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";

import axios from "axios";
import { security_key, BASE_URL } from "../global";
import { Hidden } from "@mui/material";

function AdminNotify({ anchorElNotify, setAnchorElNotify, setTotalPatient }) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [rows, setRows] = React.useState([]);

    const handleClose = () => {
        setAnchorElNotify(null);
    };
    const handlePatientClick = (row) => {
        navigate("/Admin/PatientView", { state: { patient_id: row["pk_patient_id"] } });
    };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    useEffect(() => {
        fetchData();
        // const intervalId = setInterval(fetchData, 3000);

        // return () => {
        //     clearInterval(intervalId);
        // };
    }, []);

    const fetchData = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getNewPatient",
            });

            const output = response.data;

            if (output.length > 0) {
                setTotalPatient(output.length);
                setRows(output);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const clearAllNotify = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "clearAllNotify",
            });

            const output = response.data;

            if (output == 1) {
                setTotalPatient(0);
                setRows([]);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const unDoLastTenDaysNotify = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "unDoLastTenDaysNotify",
            });

            const output = response.data;

            if (output == 1) {
                fetchData();
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // const getTimeDifference = (row) => {
    //     if (row["MinuteDifference"] < 60) {
    //         return `(${row["MinuteDifference"]} minute${row["MinuteDifference"] === 1 ? "" : "s"} ago)`;
    //     }
    //     if (row["HourDifference"] < 24) {
    //         return `(${row["HourDifference"]} hour${row["HourDifference"] === 1 ? "" : "s"} ago)`;
    //     }
    //     return `(${row["DayDifference"]} day${row["DayDifference"] === 1 ? "" : "s"} ago)`;
    // };

    const getTimeDifference = (row) => {
        if (row["MinuteDifference"] < 60) {
            return `(${row["MinuteDifference"]} minute${row["MinuteDifference"] === 1 ? "" : "s"} ago)`;
        }
        if (row["HourDifference"] < 24) {
            return `(${row["HourDifference"]} hour${row["HourDifference"] === 1 ? "" : "s"} ago)`;
        }
        if (row["DayDifference"] === 0 && row["HourDifference"] >= 24) {
            // If DayDifference is 0 but HourDifference is at least 24, it means it's within the same day
            return `(${Math.floor(row["HourDifference"] / 24)} day${Math.floor(row["HourDifference"] / 24) === 1 ? "" : "s"} ago)`;
        }
        return `(${row["DayDifference"]} day${row["DayDifference"] === 1 ? "" : "s"} ago)`;
    };

    return (
        <Menu
            // className="bg-primary"
            anchorEl={anchorElNotify}
            id="account-menu"
            open={Boolean(anchorElNotify)}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
            <div style={{ width: "360px" }}>
                {/* <MenuItem style={{ width: "250px" }}></MenuItem> */}
                <div className="d-flex justify-content-between pt-3 px-3">
                    <h5>Notifications</h5>
                    <span role="button" onClick={unDoLastTenDaysNotify} className="text-primary">
                        UNDO LAST 7 DAYS
                    </span>
                    <span role="button" onClick={clearAllNotify} className="text-primary">
                        Clear All
                    </span>
                </div>
                <hr />
                <div style={{ maxHeight: "265px", overflow: "scroll" }}>
                    {rows.length == 0 ? (
                        <h5 className="text-center my-5">Sorry, No new notification</h5>
                    ) : (
                        rows.map((row, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() => {
                                    handleClose();
                                    handlePatientClick(row);
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar />
                                </ListItemAvatar>

                                <ListItemText
                                    primary={`New Patient Registered ${getTimeDifference(row)}`}
                                    secondary={
                                        <React.Fragment>
                                            <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                                                Name:
                                            </Typography>
                                            {`${row["patient_first_name"]} ${row["patient_last_name"]}`}
                                        </React.Fragment>
                                    }
                                />
                            </ListItemButton>
                        ))
                    )}
                </div>
            </div>
        </Menu>
    );
}

export default AdminNotify;
