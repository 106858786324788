/*
Purpose: list details of student with use datatable
Developer: BY PKS
Date:26-OCT-2023 
Project: EMSTYLE
*/

// import { useState, useCallback, useEffect } from "react";
import React, { useState, useCallback, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { Dialog, Box, TabContext, TabList, Tab, TabPanel } from '@mui/material';

import axios from "axios";
import { security_key, BASE_URL, options_patient } from "../../global";

import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Swal from "sweetalert2";

import CustomNoRowsOverlay from "../../components/NoRow";

const ClinicalList = () => {
    const navigate = useNavigate();

    const handleRowClick = (row) => {
        navigate("/Admin/EmailEdit", { state: { mail_id: row.id } });
    };

    /****Email List Functional****/

    const [loading, setLoading] = useState(true);

    const [rows, setRows] = React.useState([]);

    useEffect(() => {
        // document.title = "Your Custom Title";
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getAllEmailTemplate",
            });
            const output = response.data;

            // console.log("Response Data:", response.data);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return {
                        ...row,
                        id: row.pk_mail_id,
                        index: index + 1,
                    };
                });

                setRows(modifiedOutput);
                setLoading(false);
            } else {
                setRows([]);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleClick = (content) => {
        // Open the content in a new popup tab
        const newWindow = window.open("", "_blank", "toolbar=0,location=0,menubar=0");
        if (newWindow) {
            newWindow.document.write(content);
            newWindow.document.close();
        }
    };

    const columns = [
        {
            headerName: "#ID",
            field: "index",
            headerClassName: "super-app-theme--header",
            width: 50,
        },

        {
            headerName: "Title",
            field: "mail_title",
            headerClassName: "super-app-theme--header",
            minWidth: 250,
            flex: 1,
        },
        {
            headerName: "Subject",
            field: "mail_subject",
            headerClassName: "super-app-theme--header",
            minWidth: 250,
            flex: 1,
        },

        {
            headerName: "Description",
            field: "mail_remarks",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
            // renderCell: (params) => <div style={{ height: "auto", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: params.value }}></div>,
        },
        {
            headerName: "Content",
            field: "mail_content",
            type: "actions",
            headerClassName: "super-app-theme--header",
            minWidth: 250,
            flex: 1,
            getActions: ({ id, row }) => {
                return [
                    <div key={id}>
                        <Button variant="contained" size="small" onClick={() => handleClick(row.mail_content)}>
                            Preview
                        </Button>
                    </div>,
                ];
            },
        },
    ];

    /*
    const handleFilterModelChange = (model) => {
        if (model && model.items && model.items.length > 0) {
            const filteredRowsCount = rows.filter((row) => {
                return model.items.every((filterItem) => {
                    const { field, operator, value } = filterItem;
                    if (operator === "contains") {
                        return row[field].toString().includes(value.toString());
                    }
                    return true;
                });
            }).length;

            setBoxHeight(filteredRowsCount === 0 ? 600 : "max-content");
        } else {
            setBoxHeight(600);
        }
    };
    */

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="app-content">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Row>
                            <Col xs="12">
                                <Row className="justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="section-header d-flex align-items-center justify-content-between">
                                            <div className="section-heading">
                                                <h4 className="mb-1">Email</h4>
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                                                Home
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            Email
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                            {/* <div className="d-flex align-items-center justify-content-end gap-2" style={{ minWidth: "400px" }}>
                                                <Button variant="contained" style={{ width: "180px" }} onClick={() => navigate("/Admin/EmailAdd")}>
                                                    Add Email Template
                                                </Button>
                                            </div> */}
                                        </div>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <Card className="my-card p-1 bg-white">
                                            <Card.Body>
                                                <Box
                                                    sx={{
                                                        height: "fit-content",
                                                        width: "100%",
                                                        "& .actions": {
                                                            color: "text.secondary",
                                                        },
                                                        "& .textPrimary": {
                                                            color: "text.primary",
                                                        },
                                                        "& .super-app-theme--header": {
                                                            backgroundColor: "#e3e6ee",
                                                        },
                                                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                                            {
                                                                outline: "none !important",
                                                            },
                                                    }}
                                                    style={{ height: "max-content", width: "100%" }}
                                                >
                                                    <DataGrid
                                                        autoHeight
                                                        sx={{ border: "none", m: 2, "--DataGrid-overlayHeight": "600px" }}
                                                        initialState={{
                                                            pagination: { paginationModel: { pageSize: 10 } },
                                                        }}
                                                        pageSizeOptions={[10, 25, 50]}
                                                        loading={loading}
                                                        slots={{
                                                            loadingOverlay: LinearProgress,
                                                            noRowsOverlay: CustomNoRowsOverlay,
                                                            toolbar: GridToolbar,
                                                            // toolbar: EditToolbar,
                                                        }}
                                                        slotProps={{
                                                            toolbar: {
                                                                csvOptions: { fileName: "ClinicalList" },
                                                                printOptions: {
                                                                    disableToolbarButton: true,
                                                                    hideFooter: true,
                                                                    hideToolbar: true,
                                                                },
                                                                // printOptions: { fileName: "ClinicalList" },
                                                                rows,
                                                                setRows,
                                                            },
                                                        }}
                                                        // rows={[]}
                                                        rows={rows}
                                                        columns={columns}
                                                        rowHeight={80}
                                                        editMode="row"
                                                        onRowClick={(params) => handleRowClick(params.row)}
                                                        getRowClassName={() => "cursor-pointer"}
                                                    />
                                                </Box>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClinicalList;
