/*
Purpose: list details of student with use datatable
Developer: BY PKS
Date:26-OCT-2023 
Project: Emstyle
*/

import React, { useState, useCallback, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Form from "react-bootstrap/Form";
import Tooltip from "@mui/material/Tooltip";

import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { Dialog, Box, TabContext, TabList, Tab, TabPanel } from '@mui/material';

import axios from "axios";
// import { security_key, BASE_URL, options_patient } from "../global";
import { security_key, BASE_URL } from "../../global";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/NoRow";
import FeesModal from "../../components/FeesModal";
import StarRateIcon from "@mui/icons-material/StarRate";

import Moment from "moment";
import "moment-timezone";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

const formatPhoneNumber = (rawPhoneNumber) => {
    const phoneNumber = String(rawPhoneNumber);
    const countryCode = phoneNumber.substring(0, 1);
    const restOfNumber = phoneNumber.substring(1);

    // Format the phone number as +1 (XXX) XXX-XXXX
    const formattedPhoneNumber = `+${countryCode} (${restOfNumber.substring(0, 3)}) ${restOfNumber.substring(3, 6)}-${restOfNumber.substring(6)}`;

    return formattedPhoneNumber;
};

const AssignPatient = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const { patient_id } = location.state;
    const { patient_name } = location.state;
    const { patient_city } = location.state;
    const { patient_state } = location.state;
    const { assignData } = location.state;
    const { preferred_doc } = location.state;
    const { preferred_ser } = location.state;

    /*
    const preferred_doc_arr = preferred_doc.split(", ");
    const preferred_ser_arr = preferred_ser.split(", ");
    */

    // console.log(preferred_doc_arr);

    // alert(JSON.stringify(preferred_doc));

    const [isLoading, setIsLoading] = useState(true);

    const [selectedButton, setSelectedButton] = useState(0);

    /****Bootstrap Modal functional****/

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    /****Assign Functional****/

    const [loading, setLoading] = useState(true);

    const [providerId, setProviderId] = useState(0);
    const [patientName, setPatientName] = useState(patient_name);
    const [providerName, setProviderName] = useState("N/A");

    const handleAssign = async (provider_id) => {
        setProviderId(parseInt(provider_id));
        setShowModal(true);
    };

    const getAssignData = async () => {
        setLoading(true);

        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "getAssignData",
            patient_id: patient_id,
        });

        const output = response.data;

        if (output) {
            assignData.pk_assign_id = output.pk_assign_id;
            assignData.fk_patient_id = output.fk_patient_id;
            assignData.fk_provider_id = output.fk_provider_id;
            fetchData();
        } else {
            fetchData();
        }
    };

    const handleAfterAssign = async () => {
        getAssignData();
        // assignData.pk_assign_id = assign_id;
        // assignData.fk_patient_id = patient_id;
        // assignData.fk_provider_id = providerId;
    };

    const [rows, setRows] = React.useState([]);

    useEffect(() => {
        getAssignData();
    }, [location, selectedButton]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getProviderAssignList",
                patient_state: patient_state,
                assign_id: assignData["pk_assign_id"] ? assignData["pk_assign_id"] : 0,
            });

            const output = response.data;

            if (output.length > 0) {
                const modifiedOutput = output
                    .map((row) => {
                        // if (selectedButton == 1 && !preferred_doc_arr.includes(`${row["pk_provider_id"]}`)) {
                        //     return null;
                        // }
                        return { ...row, id: row.pk_provider_id };
                    })
                    .filter((row) => row !== null);

                // console.log(modifiedOutput);

                setRows(modifiedOutput);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const columns = [
        // {
        //     headerName: "Preffered Provider",
        //     field: "pk_provider_id",
        //     headerClassName: "super-app-theme--header",
        //     width: 100,
        //     align: "center",
        //     renderCell: (params) => {
        //         if (preferred_doc_arr.includes(`${params.value}`)) {
        //             return <StarRateIcon color="warning" />;
        //         } else {
        //             return "";
        //         }
        //     },
        // },
        {
            headerName: "Practice Name",
            field: "practice_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Practice Address1",
            field: "practice_address_1",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Practice Phone",
            field: "practice_phone",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider First Name",
            field: "provider_first_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider Last Name",
            field: "provider_last_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider Specialty",
            field: "provider_specialty",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            headerName: "Provider Guidance",
            field: "provider_guidance",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
            cellClassName: "actions",
            getActions: ({ id, row }) => {
                const actionButton = () => {
                    if (assignData["pk_assign_id"] == null) {
                        return (
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    handleAssign(row.pk_provider_id);
                                }}
                            >
                                Assign
                            </Button>
                        );
                    } else {
                        if (assignData["fk_provider_id"] === row.fk_provider_id && assignData["fk_patient_id"] === row.fk_patient_id) {
                            setProviderName(`${row.provider_first_name} ${row.provider_last_name}`);
                            return (
                                <Button variant="contained" size="small" disabled>
                                    Assigned
                                </Button>
                            );
                        } else {
                            return (
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                        handleAssign(row.pk_provider_id);
                                    }}
                                >
                                    Re Assign
                                </Button>
                            );
                        }
                    }
                };

                return [<div key={id}>{actionButton()}</div>];
            },
        },
    ];

    const themes = sessionStorage.getItem("theme");

    const commonStyles = {
        react_select: "",
        react_tel: "",
    };

    const darkThemeStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "#343541",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "white",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                    // color: "black",
                },
            }),
        },
        react_tel: "",
    };

    const colourStyles = themes == "dark" ? darkThemeStyles : commonStyles;

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="app-content">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Row>
                            <Col xs="12">
                                <Row className="justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="section-header d-flex align-items-center justify-content-between">
                                            <div className="section-heading">
                                                <h4 className="mb-1">Patient Assign</h4>
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                                                Home
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Admin/PatientList")}>
                                                                Patients
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Admin/PatientEdit", { state: location.state })}>
                                                                Edit Patient
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            Patient Assign
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                            <div className="section-essentials d-flex align-items-center justify-content-between gap-2">
                                                <a
                                                    role="button"
                                                    className="btn btn-light btn-md border"
                                                    onClick={() => navigate("/Admin/PatientEdit", { state: location.state })}
                                                >
                                                    Back
                                                </a>
                                            </div>
                                        </div>
                                    </Col>

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <Card className="my-card p-1 bg-white">
                                            <Card.Body>
                                                <Row className="mb-3">
                                                    <Col sm={12}>
                                                        <div className="d-flex align-items-center mt-2">
                                                            <span>
                                                                Recipient (<Form.Label className="p-0">Provider</Form.Label>): {providerName}
                                                            </span>
                                                        </div>
                                                        <div className="mt-3">
                                                            <span>
                                                                Assignee (<Form.Label className="p-0">Patient</Form.Label>): {patientName}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    {/* <ButtonGroup aria-label="outlined primary button group">
                                                        <Button
                                                            size="small"
                                                            variant={selectedButton === 0 ? "contained" : "outlined"}
                                                            onClick={() => setSelectedButton(0)}
                                                        >
                                                            All Provider
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant={selectedButton === 1 ? "contained" : "outlined"}
                                                            onClick={() => setSelectedButton(1)}
                                                        >
                                                            Preferred Provider
                                                        </Button>
                                                    </ButtonGroup> */}
                                                    <Box
                                                        sx={{
                                                            height: "fit-content",
                                                            width: "100%",
                                                            "& .actions": {
                                                                color: "text.secondary",
                                                            },
                                                            "& .textPrimary": {
                                                                color: "text.primary",
                                                            },
                                                            "& .super-app-theme--header": {
                                                                backgroundColor: "#e3e6ee",
                                                            },
                                                            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                                                {
                                                                    outline: "none !important",
                                                                },
                                                            // "& .row-muted": {
                                                            //     backgroundColor: "#FFF2CA",
                                                            //     color: "#1a3e72",
                                                            //     // filter: "blur(1px)",
                                                            // },
                                                        }}
                                                        style={{ height: "max-content", width: "100%" }}
                                                        // style={{ height: rows.length == 0 ? 600 : "fit-content", width: "100%" }}
                                                    >
                                                        <DataGrid
                                                            autoHeight
                                                            sx={{ border: "none", m: 2, "--DataGrid-overlayHeight": "600px" }}
                                                            initialState={{
                                                                pagination: { paginationModel: { pageSize: 10 } },
                                                                pinnedColumns: { left: ["actions"] },
                                                            }}
                                                            slots={{
                                                                loadingOverlay: LinearProgress,
                                                                noRowsOverlay: CustomNoRowsOverlay,
                                                                // Row: (props: GridRowParams) => {
                                                                //     const { row } = props;
                                                                //     return (
                                                                //         <div className={row.className}>
                                                                //             {props.children}
                                                                //             {row.className === "row-muted" && (
                                                                //                 <Tooltip title="Your tooltip content">
                                                                //                     <div>Hover me</div>
                                                                //                 </Tooltip>
                                                                //             )}
                                                                //         </div>
                                                                //     );
                                                                // },
                                                            }}
                                                            pageSizeOptions={[10, 25, 50]}
                                                            loading={loading}
                                                            rows={rows}
                                                            // rows={[]}
                                                            columns={columns}
                                                            // getRowClassName={(params) => {
                                                            //     if (preferred_doc_arr.includes(`${params.row["pk_provider_id"]}`)) {
                                                            //         return "row-muted";
                                                            //     }
                                                            //     return "";
                                                            // }}
                                                        />
                                                    </Box>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <FeesModal
                patientId={patient_id}
                providerId={providerId}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                handleAfterAssign={handleAfterAssign}
                // preferredSerArr={preferred_ser_arr}
            />
        </>
    );
};

export default AssignPatient;
