export const Config = {
    buttons: [
        "source",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "align",
        "|",
        "ul",
        "ol",
        "|",
        "outdent",
        "indent",
        "|",
        "font",
        "fontsize",
        "brush",
        "paragraph",
        "|",
        "image", // Include the 'image' button for image upload
        "table",
        "link",
        "|",
        "undo",
        "redo",
        "|",
        "hr",
        "eraser",
        "|",
        "copyformat",
        "selectall",
        "|",
        "fullsize",
        "print",
        "|",
        "symbols",
        "about",
    ],
    uploader: {
        insertImageAsBase64URI: false, // Use base64 URI for image insertions
    },
    removeButtons: ["brush", "file"], // Remove unnecessary buttons
    showXPathInStatusbar: false, // Disable showing XPath in status bar
    showCharsCounter: false, // Disable showing character counter
    showWordsCounter: false, // Disable showing words counter
    toolbarAdaptive: false, // Disable adaptive toolbar
};
