import React from "react";
import { useNavigate } from "react-router-dom";

function PaypalPending() {
    const navigate = useNavigate();

    const formSubmit = () => {
        document.getElementById("casePayment").submit();
    };
    return (
        <>
            <form
                action="https://www.sandbox.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top"
                id="casePayment"
                name="casePayment"
                style={{ display: "none" }}
            >
                <input type="hidden" name="item_name" value="Referral Payment" />
                <input type="hidden" name="amount" id="total_amount" value="200" />
                <input type="hidden" name="currency_code" value="USD" />
                <input type="hidden" name="business" value="cdsphp1@gmail.com" />
                <input type="hidden" name="cmd" value="_xclick" />
                <input type="hidden" name="USER" value="cdsphp1-facilitator-1_api1.gmail.com" />
                <input type="hidden" name="PWD" value="N6FL93U3CVE5ASN7" />
                <input type="hidden" name="SIGNATURE" value="Ahs3vWVNjhi-4QscmZnANk3Md3OCAF4lcicq5cpDq9Sibz3bK9FVLkiE" />
                <input type="hidden" name="return" value="https://app.attestaai.com/genindex_merge.php?hnd=121" />
                <input type="hidden" name="cancel_return" value="payment2.0/cancelled.php" />
                <input type="hidden" name="notify_url" value="payment2.0/payment_notify.php" />
                <input type="hidden" name="custom" id="custom" value="{$patient_id}" />
                <button type="submit" name="redirect" id="redirect">
                    Pay Now
                </button>
            </form>

            <div className="row justify-content-center success-card mt-5 mr-0">
                <div className="col-10 col-lg-6 my-auto">
                    <div className="card text-center">
                        <div className="card-body">
                            <img src="images/emcyte/pending.png" alt="Failed" width="240px" />
                            <h2 className="pay-pending">$200{/*?php echo $_SESSION['pay_amount']; ?*/}</h2>
                            <p>Thank you for registering at Emstyle.</p>
                            <h1>Your Payment is Pending</h1>
                            <p className="my-3">
                                <strong>
                                    Please contact{" "}
                                    <a href="tel:18007555775" class="link-underline-light">
                                        1800 755 5775
                                    </a>{" "}
                                </strong>
                                for further instructions.
                            </p>
                            <div className="d-fle flex-wrap justify-content-center my-2">
                                <button onClick={formSubmit} className="btn btn-primary text-white link me-2">
                                    Pay Now
                                </button>
                                <button onClick={() => navigate("/Patient")} className="btn btn-danger my-2 text-white link">
                                    Back To Site
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaypalPending;
