import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./Loader.css";
const Loader = () => {
    return (
        <div className="preloader" id="preloader">
            <div className="status" id="status">
                <div className="d-flex flex-row align-items-center">
                    <div className="me-2">
                        <CircularProgress />
                    </div>
                    <h4>Loading...</h4>
                </div>
            </div>
        </div>
    );
};

export default Loader;
