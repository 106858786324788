/*
Purpose: list details of student with use datatable
Developer: BY PKS
Date:26-OCT-2023 
Project: EMSTYLE
*/

import React, { useState, useCallback, useEffect, useRef } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import DataTable from "react-data-table-component";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import axios from "axios";
import { security_key, BASE_URL, options_status, options_label } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import "./ProviderSettings.css";

const label = { inputProps: { "aria-label": "Switch demo" } };

const options_page = [
    { value: "PatientList", label: "Patients" },
    // { value: "ClinicalList", label: "Clinical" },
    { value: "Settings", label: "Settings" },
    { value: "Profile", label: "Profile" },
    // { value: "ProductList", label: "Product" },
];

document.body.className = `body-container ${sessionStorage.getItem("theme")}`;

const ProviderSettings = () => {
    const navigate = useNavigate();

    const [activeItem, setActiveItem] = useState(1);

    const handleItemClick = (index) => {
        setActiveItem(index);
    };

    const [state, setState] = React.useState({
        theme: sessionStorage.getItem("theme"),
        is_mail_send: sessionStorage.getItem("is_mail_send") == 1 ? true : false,
        is_sms_send: sessionStorage.getItem("is_sms_send") == 1 ? true : false,
        landing_page: sessionStorage.getItem("landing_page"),
    });

    // const [theme, setTheme] = useState("system");

    const handleThemeChange = async (newTheme) => {
        // console.log("Changing theme to:", newTheme);
        // setTheme(newTheme);
        // Apply theme to the body
        document.body.className = `body-container ${newTheme}`;
        setState((prevState) => ({
            ...prevState,
            theme: newTheme,
        }));

        sessionStorage.setItem("theme", newTheme);

        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "changeProviderTheme",
            provider_id: sessionStorage.getItem("pk_provider_id"),
            theme: newTheme,
        });
    };

    const switchMailSend = async (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked,
        });

        const newValue = e.target.checked ? 1 : 0;

        sessionStorage.setItem("is_mail_send", newValue);

        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "switchMailSend",
            provider_id: sessionStorage.getItem("pk_provider_id"),
            status: newValue,
        });

        // const output = response.data;
        // if (output == 1) {
        //     toast.success("updated successful!", {
        //         autoClose: 3000,
        //     });
        // }
    };
    const switchSMSSend = async (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked,
        });

        const newValue = e.target.checked ? 1 : 0;

        sessionStorage.setItem("is_sms_send", newValue);

        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "switchSMSSend",
            provider_id: sessionStorage.getItem("pk_provider_id"),
            status: newValue,
        });

        // const output = response.data;
        // if (output == 1) {
        //     toast.success("updated successful!", {
        //         autoClose: 3000,
        //     });
        // }
    };

    const changeProviderLandingPage = async (e) => {
        setState((prevState) => ({
            ...prevState,
            landing_page: e.target.value,
        }));

        // alert(e.target.value);

        const newValue = e.target.value;

        sessionStorage.setItem("landing_page", newValue);

        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "changeProviderLandingPage",
            provider_id: sessionStorage.getItem("pk_provider_id"),
            landing_page: newValue,
        });

        // const output = response.data;
        // if (output == 1) {
        //     toast.success("updated successful!", {
        //         autoClose: 3000,
        //     });
        // }
    };

    const themes = sessionStorage.getItem("theme");

    const commonStyles = {
        option: (provided, state) => ({
            ...provided,
            //backgroundColor: state.isFocused ? "#ddd" : "white",
            //backgroundColor: state.isSelected ? "red" : "white",
            //color: state.isFocused ? "black" : "black",
            backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "white",
            color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "black",
            ":hover": {
                backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                color: state.isSelected ? "white" : "black",
            },
        }),
    };

    const darkThemeStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "#343541",
            color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "white",
            ":hover": {
                backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                color: state.isSelected ? "white" : "black",
                // color: "black",
            },
        }),
    };

    const colourStyles = themes == "dark" ? darkThemeStyles : commonStyles;

    return (
        <>
            {/* <ToastContainer position="top-center" /> */}

            <div className="app-content" id="provider-settings">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Row className="gx-0 gx-md-4">
                            <Col xs="12">
                                <Row className="gx-0 gx-md-4 justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="section-header d-flex align-items-center justify-content-between">
                                            <div className="section-heading">
                                                <h4 className="mb-1">Settings</h4>
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Provider/PatientList")}>
                                                                Home
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            Settings
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <Card className="my-card p-1 bg-white overflow-y-scroll" style={{ height: "350px" }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col sm={3}>
                                                        <ul className="p-0">
                                                            <li
                                                                className={`m-0 cursor-pointer ${activeItem === 1 ? "active" : ""}`}
                                                                onClick={() => handleItemClick(1)}
                                                            >
                                                                <i className="fa-solid fa-gear me-2"></i>General
                                                            </li>
                                                            <li
                                                                className={`m-0 cursor-pointer ${activeItem === 2 ? "active" : ""}`}
                                                                onClick={() => handleItemClick(2)}
                                                            >
                                                                <i className="fa-solid fa-database me-2"></i>Data Controls
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col sm={8}>
                                                        <div className={activeItem === 1 ? "" : "d-none"}>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <span>Theme</span>

                                                                <div>
                                                                    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                                                                        <Button
                                                                            variant={state.theme === "system" ? "contained" : "outlined"}
                                                                            onClick={() => handleThemeChange("system")}
                                                                        >
                                                                            System
                                                                        </Button>
                                                                        <Button
                                                                            variant={state.theme === "dark" ? "contained" : "outlined"}
                                                                            onClick={() => handleThemeChange("dark")}
                                                                        >
                                                                            Dark
                                                                        </Button>
                                                                        <Button
                                                                            variant={state.theme === "light" ? "contained" : "outlined"}
                                                                            onClick={() => handleThemeChange("light")}
                                                                        >
                                                                            Light
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <span>Landing Page</span>
                                                                <Select
                                                                    options={options_page}
                                                                    className="w-25 react-select"
                                                                    // menuIsOpen={true}
                                                                    styles={colourStyles}
                                                                    value={
                                                                        state &&
                                                                        state.landing_page &&
                                                                        options_page.find((option) => {
                                                                            // console.log("op1:" + option.value);
                                                                            // console.log("op2:" + state.landing_page.toString());
                                                                            return option.value && option.value.toString() === state.landing_page.toString();
                                                                        })
                                                                    }
                                                                    onChange={(selectedOption) => {
                                                                        const event = {
                                                                            target: {
                                                                                name: "landing_page",
                                                                                value: selectedOption["value"],
                                                                            },
                                                                        };
                                                                        changeProviderLandingPage(event);
                                                                    }}
                                                                />
                                                            </div>
                                                            <hr />
                                                        </div>
                                                        <div className={activeItem === 2 ? "" : "d-none"}>
                                                            <div className="d-flex  justify-content-between align-items-center">
                                                                <span>Receive mail</span>
                                                                <Switch {...label} checked={state.is_mail_send} onChange={switchMailSend} name="is_mail_send" />
                                                            </div>
                                                            <div className="description">
                                                                {/* Receive mail from admin for assign patient. Lorem ipsum dolor, sit amet consectetur adipisicing
                                                                elit. Iure expedita culpa illo impedit, facere repudiandae perspiciatis ipsa nisi quae sed
                                                                laudantium explicabo beatae odio, eligendi in modi! Libero, commodi velit. */}
                                                                If you turn off the button, you won't receive mail from admin for patient assignments.
                                                            </div>
                                                            <hr />
                                                            {/* <div className="d-flex  justify-content-between align-items-center">
                                                                <span>Receive message</span>
                                                                <Switch {...label} checked={state.is_sms_send} onChange={switchSMSSend} name="is_sms_send" />
                                                            </div>
                                                            <div className="description">
                                                                Receive message from admin for assign patient. Lorem ipsum dolor, sit amet consectetur
                                                                adipisicing elit. Iure expedita culpa illo impedit, facere repudiandae perspiciatis ipsa nisi
                                                                quae sed laudantium explicabo beatae odio, eligendi in modi! Libero, commodi velit.
                                                            </div> */}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProviderSettings;
