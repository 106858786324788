import React, { useEffect, useState } from "react";

import { Nav, Navbar, NavDropdown, Dropdown, ButtonGroup, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import GreetingComponent from "./Greeting";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { deepOrange, red } from "@mui/material/colors";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Cookies from "js-cookie";
import axios from "axios";
import { BASE_URL } from "../global";

// sessionStorage.setItem("name", "naveen");

function AppHeader({ isOpen, toggleSidebar }) {
    const navigate = useNavigate();

    /*
    const handleLogout = () => {
        const wpLogoutUrl = sessionStorage.getItem("logoutUrl");

        // console.log("WordPress Logout URL:", wpLogoutUrl);

        if (wpLogoutUrl) {
            const newTab = window.open(wpLogoutUrl, "_blank");
            setTimeout(() => {
                sessionStorage.clear();

                navigate("/Provider/Login");

                newTab.close();
            }, 1000);
        } else {
            navigate("/Provider/Login");
        }
    };
    */

    const handleLogout = async () => {
        const userId = sessionStorage.getItem("wp_user_id");

        sessionStorage.clear();
        navigate("/Provider/Login");

        if (!userId) {
            // Handle missing user ID error
            return;
        }

        try {
            const response = await axios.post(BASE_URL + "shop/wp-json/woocommerce/v1/auth/", {
                action: "logout",
                user_id: userId,
            });
        } catch (error) {
            // Handle errors from the API call
            console.error("Error logging out:", error);
        }
    };

    // useEffect(() => {
    //     const handleTabClose = async () => {
    //         const userId = sessionStorage.getItem("wp_user_id");
    //         try {
    //             const response = await axios.post(BASE_URL + "shop/wp-json/woocommerce/v1/auth/", {
    //                 action: "logout",
    //                 user_id: userId,
    //             });
    //         } catch (error) {
    //             console.error("Error logging out:", error);
    //         }
    //     };

    //     window.addEventListener("beforeunload", handleTabClose);

    //     // Cleanup event listener on component unmount
    //     return () => {
    //         window.removeEventListener("beforeunload", handleTabClose);
    //     };
    // });

    /*
    useEffect(() => {
        const handleTabClose = () => {
            // const message = "Are you sure you want to leave?";
            // event.preventDefault(); // Standard in most browsers
            // event.returnValue = message; // Required for most browsers to show the dialog
            // return message; // Some browsers may require returning a string

            Object.keys(Cookies.get()).forEach((cookieName) => {
                Cookies.remove(cookieName);
            });
        };

        window.addEventListener("beforeunload", handleTabClose);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    });
    */

    const [isOpenDrop, setIsOpenDrop] = useState(false);

    const toggleDropDown = () => {
        setIsOpenDrop(!isOpenDrop); // Toggle the current value
    };

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function stringAvatar(name) {
        return {
            children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
        };
    }

    return (
        <div className="app-header">
            <div className="app-header-one">
                <nav className="navbar navbar-light navbar-expand-lg">
                    <div className="container-fluid">
                        <div className="w-100 d-flex flex-row justify-content-between align-items-center overflow-hidden">
                            <div className="navbar-nav flex-row align-items-center" id="navbarNav">
                                <ul className="navbar-nav" onClick={toggleSidebar}>
                                    <li className="nav-item">
                                        <a className="nav-link hide-sidebar-toggle-button" role="button">
                                            {isOpen ? <i className="material-icons">last_page</i> : <i className="material-icons">first_page</i>}
                                        </a>
                                    </li>
                                </ul>
                                {/* <h5 className="mt-3 ms-3">Hi, Good Afternoon !</h5> */}
                                <GreetingComponent />
                            </div>
                            <div className="d-flex">
                                <ul className="navbar-nav">
                                    {/* <li className="nav-item">
                                <a className="nav-link toggle-search" role="button">
                                    <i className="material-icons">search</i>
                                </a>
                            </li> */}
                                    <li className="nav-item hidden-on-mobile d-flex align-items-center gap-1">
                                        <Box sx={{ flexGrow: 0 }}>
                                            <Tooltip title="">
                                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                    {/* <Avatar
                                                    alt="Remy Sharp"
                                                    src={`https://emstylemsk.net/API/upload/image/${sessionStorage.getItem("image")}`}
                                                /> */}
                                                    <Avatar
                                                        {...stringAvatar(sessionStorage.getItem("name"))}
                                                        sx={{ width: 46, height: 46, bgcolor: "#1869bf" }}
                                                        className="me-2"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                sx={{ mt: "45px" }}
                                                id="menu-appbar"
                                                anchorEl={anchorElUser}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                open={Boolean(anchorElUser)}
                                                onClose={handleCloseUserMenu}
                                            >
                                                <MenuItem
                                                    key="profile"
                                                    onClick={() => {
                                                        handleCloseUserMenu();
                                                        navigate("/Provider/Profile");
                                                    }}
                                                >
                                                    <Typography textAlign="center">Profile</Typography>
                                                </MenuItem>
                                                <MenuItem
                                                    key="Settings"
                                                    onClick={() => {
                                                        handleCloseUserMenu();
                                                        navigate("/Provider/Settings");
                                                    }}
                                                >
                                                    <Typography textAlign="center">Settings</Typography>
                                                </MenuItem>
                                                <MenuItem
                                                    key="Logout"
                                                    onClick={() => {
                                                        handleCloseUserMenu();
                                                        handleLogout();
                                                    }}
                                                >
                                                    <Typography textAlign="center">Logout</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>

                                        <div>
                                            <h6 className="mb-0">{sessionStorage.getItem("name")}</h6>
                                            <small>{sessionStorage.getItem("email")}</small>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

            <div className=" app-header-two">
                <nav className="navbar navbar-light navbar-expand-lg app-header-two">
                    <div className="container-fluid">
                        <div className="w-100  overflow-hidden header-main">
                            <div className="navbar-nav flex-row align-items-center justify-content-between" id="navbarNav">
                                <Image src="../images/logo-light.png" width={132} className="img-fluid  d-block" />
                                <ul className="navbar-nav" onClick={toggleSidebar}>
                                    <li className="nav-item">
                                        <a className="nav-link hide-sidebar-toggle-button" role="button">
                                            {isOpen ? (
                                                <MenuOpenIcon className="text-light f-30"></MenuOpenIcon>
                                            ) : (
                                                <MenuIcon className="text-light f-30"></MenuIcon>
                                            )}
                                        </a>
                                    </li>
                                </ul>
                                {/* <h5 className="mt-3 ms-3">Hi, Good Afternoon !</h5> */}
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="mt-4 app-header-two">
                    <div className="mb-3">
                        {" "}
                        <GreetingComponent />
                    </div>
                    <div className="d-flex">
                        <ul className="navbar-nav">
                            {/* <li className="nav-item">
                                <a className="nav-link toggle-search" role="button">
                                    <i className="material-icons">search</i>
                                </a>
                            </li> */}
                            <li className="nav-item hidden-on-mobile d-flex align-items-center gap-1">
                                <Box sx={{ flexGrow: 0 }}>
                                    <Tooltip title="">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            {/* <Avatar
                                                    alt="Remy Sharp"
                                                    src={`https://emstylemsk.net/API/upload/image/${sessionStorage.getItem("image")}`}
                                                /> */}
                                            <Avatar
                                                {...stringAvatar(sessionStorage.getItem("name"))}
                                                sx={{ width: 46, height: 46, bgcolor: "#1869bf" }}
                                                className="me-2"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: "45px" }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        <MenuItem
                                            key="profile"
                                            onClick={() => {
                                                handleCloseUserMenu();
                                                navigate("/Provider/Profile");
                                            }}
                                        >
                                            <Typography textAlign="center">Profile</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            key="Settings"
                                            onClick={() => {
                                                handleCloseUserMenu();
                                                navigate("/Provider/Settings");
                                            }}
                                        >
                                            <Typography textAlign="center">Settings</Typography>
                                        </MenuItem>
                                        <MenuItem
                                            key="Logout"
                                            onClick={() => {
                                                handleCloseUserMenu();
                                                handleLogout();
                                            }}
                                        >
                                            <Typography textAlign="center">Logout</Typography>
                                        </MenuItem>
                                    </Menu>
                                </Box>

                                <div>
                                    <h6 className="mb-0">{sessionStorage.getItem("name")}</h6>
                                    <small>{sessionStorage.getItem("email")}</small>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppHeader;
