import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { CircularProgress, LinearProgress } from "@mui/material";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { security_key, BASE_URL } from "../global";
import { ToastContainer, toast } from "react-toastify";

import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import Moment from "moment";
import "moment-timezone";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

function FeesModal({ patientId, providerId, showModal, handleCloseModal, handleAfterAssign }) {
    const [loading, setLoading] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const initialValues = {
        amount: "",
        description: "",
        active_status: 1,
        consultation_dt: "",
    };
    const basicSchema = Yup.object().shape({
        amount: Yup.string()
            .required("Amount is required.")
            .test("is-not-float", "Amount must be a whole number.", (value) => {
                if (value !== undefined && value !== null && value.trim() !== "") {
                    return !/\./.test(value);
                }
                return true;
            })
            .test("is-positive", "Amount must be a positive number", (value) => {
                if (!value) return true;
                const numericValue = parseFloat(value);
                if (numericValue < 0) {
                    return false;
                }
                return true;
            })
            .test("greater-than-zero", "Amount must be greater than zero", (value) => {
                if (!value) return false;
                const numericValue = parseFloat(value);
                return numericValue > 0;
            }),
        description: Yup.string()
            .min(3, "Description must be at least 3 characters.")
            .max(250, "Description must be at most 250 characters.")
            .required("Description is required.")
            .strict(),
        consultation_dt: Yup.string().required("Consultation date and time are required.").strict(),
        // active_status: Yup.array().min(1, "At least one option should be selected"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        onSubmit: async (formValues) => {
            toast.dismiss();

            setIsSubmitting(true);

            if (patientId != 0 && providerId != 0) {
                setIsSubmitting(true);

                const response = await axios.post(BASE_URL + "API/apicall", {
                    security_key: security_key,
                    mode: "addAssign",
                    fk_patient_id: patientId,
                    fk_provider_id: providerId,
                    assign_date: dbFormattedDate,
                    assign_time: dbFormattedTime,
                    created_by: 0,
                    visibility: 1,
                    ...formValues,
                });
                const output = response.data;

                if (output == 1) {
                    handleAfterAssign();
                    handleCloseModal();
                    toast.success("Success! Patient has been assigned", {
                        autoClose: 3000,
                    });
                } else if (output && output.includes("Fatal error")) {
                    handleAfterAssign();
                    toast.error("Server returned an error.", {
                        autoClose: 3000,
                    });
                } else {
                    handleAfterAssign();
                    toast.error("Oops! Something went wrong.", {
                        autoClose: 3000,
                    });
                }

                setIsSubmitting(false);
            } else {
                toast.error("Please select a provider.", {
                    autoClose: 3000,
                });
            }
        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;

    const today = new Date().toISOString().slice(0, 16);

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Payment Notify</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading ? (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <Form.Group controlId="amount" className="mb-3">
                                    <Form.Label>
                                        Amount<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control type="number" name="amount" value={values.amount} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.amount && touched.amount && <p className="error">{errors.amount}</p>}
                                </Form.Group>

                                <Form.Group controlId="description" className="mb-3">
                                    <Form.Label>
                                        Description<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: "100px" }}
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.description && touched.description && <p className="error">{errors.description}</p>}
                                </Form.Group>

                                <Form.Group controlId="consultation_dt" className="mb-3">
                                    <Form.Label>
                                        Consultation Date & time<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="datetime-local"
                                        name="consultation_dt"
                                        value={values.consultation_dt}
                                        min={today}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.consultation_dt && touched.consultation_dt && <p className="error">{errors.consultation_dt}</p>}
                                </Form.Group>

                                <Form.Group controlId="active_status" className="mb-3">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="active_status"
                                                checked={formik.values.active_status === 1}
                                                onChange={(event) => {
                                                    const isChecked = event.target.checked;
                                                    formik.setFieldValue("active_status", isChecked ? 1 : 0);
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                        label="Assigned After Payment"
                                    />
                                    {formik.errors.active_status && formik.touched.active_status && <p className="error">{formik.errors.active_status}</p>}
                                </Form.Group>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outlined" onClick={handleCloseModal} className="me-2">
                            Close
                        </Button>

                        {isSubmitting ? (
                            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                                Send
                            </LoadingButton>
                        ) : (
                            <Button variant="contained" type="submit" disabled={isSubmitting}>
                                Send
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default FeesModal;
