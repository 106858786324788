import React, { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import ListAltIcon from "@mui/icons-material/ListAlt";
import axios from "axios";
import { security_key, BASE_URL, options_city } from "../../global";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import PaymentIcon from "@mui/icons-material/Payment";

const formatPhoneNumber = (rawPhoneNumber) => {
    const phoneNumber = String(rawPhoneNumber);
    const countryCode = phoneNumber.substring(0, 1);
    const restOfNumber = phoneNumber.substring(1);

    // Format the phone number as +1 (XXX) XXX-XXXX
    const formattedPhoneNumber = `+${countryCode} (${restOfNumber.substring(0, 3)}) ${restOfNumber.substring(3, 6)}-${restOfNumber.substring(6)}`;

    return formattedPhoneNumber;
};

function PatientView() {
    const navigate = useNavigate();

    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [loading, setLoading] = useState(true);

    const [activeTab, setActiveTab] = useState("patient"); // 'home' or 'profile' based on default tab

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const [patientData, setPatientData] = useState();

    /**Fetch provider details**/

    const location = useLocation();
    useEffect(() => {
        const { patient_id } = location.state;
        // alert(provider_id);
        if (patient_id) {
            fetchData(patient_id);
        }
    }, [location]);

    const fetchData = async (id) => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getPatient",
                patient_id: id,
            });

            const output = response.data;

            if (output) {
                setPatientData(output);
                setLoading(false);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const capitalizeFirstLetter = (s) => {
        return s[0].toUpperCase() + s.slice(1);
    };

    return (
        <>
            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div className="app-content" id="patient_view">
                    <div className="content-wrapper">
                        <div className="container-fluid">
                            <div className="section-header d-flex align-items-center justify-content-between">
                                <div className="section-heading">
                                    <h4 className="mb-1">Patients</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a role="button" onClick={() => navigate(-1)}>
                                                    Patients
                                                </a>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                View Patient
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="section-essentials d-flex align-items-center justify-content-between gap-2">
                                    <a role="button" className="btn btn-light btn-md border" onClick={() => navigate(-1)}>
                                        Back
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xxl-12">
                                    <Card className="my-card px-3 py-5 bg-white">
                                        <Card.Body>
                                            <div className="d-flex align-items-center">
                                                <div className="me-3">
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: "#1869bf",
                                                            width: 72, // Adjust the width as needed
                                                            height: 72, // Adjust the height as needed
                                                        }}
                                                        className="me-2"
                                                    >
                                                        {patientData ? patientData.patient_first_name.charAt(0).toUpperCase() : "N/A"}
                                                        {patientData ? patientData.patient_last_name.charAt(0).toUpperCase() : ""}
                                                    </Avatar>
                                                </div>
                                                <div>
                                                    <h1>
                                                        {patientData && patientData.patient_first_name
                                                            ? capitalizeFirstLetter(patientData.patient_first_name)
                                                            : "N/A"}{" "}
                                                        {patientData && patientData.patient_last_name ? patientData.patient_last_name : "N/A"}
                                                    </h1>
                                                    <div>
                                                        <span className="me-2">
                                                            <LocationOnIcon />
                                                        </span>
                                                        <span className="me-1 text-secondary">City:</span>
                                                        <span className="me-2">
                                                            {patientData && patientData.patient_city ? patientData.patient_city : "N/A"}
                                                        </span>
                                                        <span className="me-1 text-secondary">State:</span>
                                                        <span className="me-2">
                                                            {patientData && patientData.patient_state ? patientData.patient_state : "N/A"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="d-flex align-items-center mb-3">
                                        <Avatar
                                            sx={{
                                                bgcolor: "#1869bf",
                                                width: 48,
                                                height: 48, 
                                            }}
                                            className="me-2"
                                        >
                                            {patientData ? patientData.patient_first_name.charAt(0).toUpperCase() : "N/A"}
                                            {patientData ? patientData.patient_last_name.charAt(0).toUpperCase() : ""}
                                        </Avatar>
                                        <h5>{patientData ? `${patientData.patient_first_name}  ${patientData.patient_last_name}` : "N/A"}</h5>
                                    </div> */}

                                            <Box sx={{ width: "100%", typography: "body1" }}>
                                                <TabContext value={value}>
                                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                        <TabList
                                                            onChange={handleChange}
                                                            variant="scrollable"
                                                            // scrollButtons
                                                            scrollButtons="auto"
                                                            allowScrollButtonsMobile
                                                            aria-label="scrollable auto tabs example"
                                                            // sx={{ paddingLeft: 0, marginLeft: 0 }}
                                                        >
                                                            <Tab icon={<PersonIcon />} iconPosition="start" label="Patient" value="1" />
                                                            <Tab icon={<PermContactCalendarIcon />} iconPosition="start" label="Contact" value="2" />
                                                            <Tab icon={<ReadMoreIcon />} iconPosition="start" label="Other" value="3" />
                                                        </TabList>
                                                        {loading && <LinearProgress />}
                                                    </Box>
                                                    <TabPanel value="1">
                                                        {!loading && (
                                                            <div className="row">
                                                                {/* <div className="col-sm-5 mb-4">
                                                            <h6 className="text-secondary">First Name</h6>
                                                            <p>{patientData && patientData.patient_first_name ? patientData.patient_first_name : "N/A"}</p>
                                                        </div>
                                                        <div className="col-sm-5 mb-4">
                                                            <h6 className="text-secondary">Last Name</h6>
                                                            <p>{patientData && patientData.patient_last_name ? patientData.patient_last_name : "N/A"}</p>
                                                        </div>
                                                        <div className="col-sm-5 mb-4">
                                                            <h6 className="text-secondary">City</h6>
                                                            <p>{patientData && patientData.patient_city ? patientData.patient_city : "N/A"}</p>
                                                        </div>
                                                        <div className="col-sm-5 mb-4">
                                                            <h6 className="text-secondary">State</h6>
                                                            <p>{patientData && patientData.patient_state ? patientData.patient_state : "N/A"}</p>
                                                        </div>
                                                        <div className="col-sm-5 mb-4">
                                                            <h6 className="text-secondary">Address 1</h6>
                                                            <p>{patientData && patientData.patient_address_1 ? patientData.patient_address_1 : "N/A"}</p>
                                                        </div>
                                                        <div className="col-sm-5 mb-4">
                                                            <h6 className="text-secondary">Address 2</h6>
                                                            <p>{patientData && patientData.patient_address_2 ? patientData.patient_address_2 : "N/A"}</p>
                                                        </div>
                                                        <div className="col-sm-5 mb-4">
                                                            <h6 className="text-secondary">Zip</h6>
                                                            <p>{patientData && patientData.patient_zip ? patientData.patient_zip : "N/A"}</p>
                                                        </div> */}

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <h6 className="text-secondary">First Name</h6>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>
                                                                            {patientData && patientData.patient_first_name
                                                                                ? patientData.patient_first_name
                                                                                : "N/A"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <h6 className="text-secondary">Last Name</h6>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>
                                                                            {patientData && patientData.patient_last_name
                                                                                ? patientData.patient_last_name
                                                                                : "N/A"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <h6 className="text-secondary">City</h6>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>{patientData && patientData.patient_city ? patientData.patient_city : "N/A"}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <h6 className="text-secondary">State</h6>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>{patientData && patientData.patient_state ? patientData.patient_state : "N/A"}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <h6 className="text-secondary">Address 1</h6>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>
                                                                            {patientData && patientData.patient_address_1
                                                                                ? patientData.patient_address_1
                                                                                : "N/A"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <h6 className="text-secondary">Address 2</h6>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>
                                                                            {patientData && patientData.patient_address_2
                                                                                ? patientData.patient_address_2
                                                                                : "N/A"}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <h6 className="text-secondary">Zip</h6>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>{patientData && patientData.patient_zip ? patientData.patient_zip : "N/A"}</p>
                                                                    </div>
                                                                </div>

                                                                <hr />
                                                            </div>
                                                        )}
                                                    </TabPanel>

                                                    <TabPanel value="2">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Email</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{patientData && patientData.patient_email ? patientData.patient_email : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Mobile Phone</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {patientData && patientData.patient_mobile_phone && patientData.patient_mobile_phone != 1
                                                                        ? formatPhoneNumber(patientData.patient_mobile_phone)
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Alternate Phone</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {patientData && patientData.patient_other_phone && patientData.patient_other_phone != 1
                                                                        ? formatPhoneNumber(patientData.patient_other_phone)
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Urgency</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{patientData && patientData.urgency ? patientData.urgency : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Preferred Contact Method</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {patientData && patientData.preferred_contact_method
                                                                        ? patientData.preferred_contact_method
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Preferred Contact Time</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {patientData && patientData.preferred_contact_time
                                                                        ? patientData.preferred_contact_time
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <hr />
                                                    </TabPanel>
                                                    <TabPanel value="3">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Call Date</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{patientData && patientData.formatted_date ? patientData.formatted_date : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Call Time</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>{patientData && patientData.formatted_time ? patientData.formatted_time : "N/A"}</p>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">Description of Condition</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {patientData && patientData.description_of_condition
                                                                        ? patientData.description_of_condition
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h6 className="text-secondary">How Did You Hear About Us</h6>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>
                                                                    {patientData && patientData.how_did_you_hear_about_us
                                                                        ? patientData.how_did_you_hear_about_us
                                                                        : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </TabPanel>
                                                </TabContext>
                                            </Box>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default PatientView;
