import React, { useState, useRef, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";

import Stack from "react-bootstrap/Stack";
import Select from "react-select";
import { useLocation, useNavigate, useHistory, NavLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";

import "./AddForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

import axios from "axios";
import { security_key, BASE_URL, options_city } from "../../global";
import LinearProgress from "@mui/material/LinearProgress";

import debounce from "lodash/debounce";

import Moment from "moment";
import "moment-timezone";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

const providerSchema = Yup.object().shape({
    /*
    provider_image: Yup.mixed().required("Provider Image is required."),
    provider_title: Yup.string()
        .required("Provider Title is required.")
        .matches(/^[a-zA-Z. ]*$/, "Provider Title can only contain letters, dots and spaces.")
        .min(2, "Provider Title must be at least 2 characters.")
        .max(250, "Provider Title must be at most 250 characters."),
    */
    // .test("no-leading-trailing-spaces", "Provider Title cannot start or end with spaces.", (value) => {
    //     return value.trim() === value; // Check if the trimmed value is the same as the original value
    // }),
    // .matches(/^[a-zA-Z ]*$/, "Provider Title can only contain letters and spaces")
    provider_first_name: Yup.string()
        .required("Provider First Name is required.")
        .matches(/^[a-zA-Z]*$/, "Provider First Name can only contain letters.")
        .test(
            "no-leading-trailing-space",
            "Provider First Name cannot start or end with a space.",
            (value) => !value || (value.trim() === value && value.indexOf(" ") === -1)
        )
        .min(3, "Provider First Name must be at least 3 characters.")
        .max(250, "Provider First Name must be at most 250 characters."),
    provider_last_name: Yup.string()
        .required("Provider Last Name is required")
        .min(1, "Provider Last Name must be at least 1 character.")
        .max(250, "Provider Last Name must be at most 250 characters.")
        .matches(/^[a-zA-Z]*$/, "Provider Last Name can only contain letters."),
    provider_mobile_number: Yup.string()
        .required("Provider Mobile Number is required.")
        .min(11, "Provider Mobile number must be at least 10 digits.")
        .max(16, "Provider Mobile number must not exceed 15 digits.")
        .test("unique-digits", "Invalid mobile number.", function (value) {
            const cleanedDigits = value.replace(/^\+\D*/, "");
            const digitsWithoutCountryCode = cleanedDigits.slice(1);
            const hasDifferentDigit = Array.from(new Set(digitsWithoutCountryCode)).length !== 1;
            return hasDifferentDigit || this.createError({ message: "Invalid mobile number." });
        })
        .strict(),
    provider_email: Yup.string()
        .email("Invalid email.")
        .required("Provider Email is required.")
        .test("unique-email", "Provider Email is already taken", function (value, { path }) {
            const providers = this.options.context.providers || [];

            const allEmails = providers.map((provider) => provider.provider_email);

            const counts = {};

            allEmails.forEach(function (x) {
                counts[x] = (counts[x] || 0) + 1;
            });

            if (counts[`${value}`] > 1) {
                return path.includes("[0]");
            } else {
                return true;
            }
        })

        .test("unique-email", "Provider Email is already existing in the database", async function (value) {
            if (!value) return true;
            const isProviderEmailUnique = await checkProviderEmailUniqueness(value);
            return isProviderEmailUnique;
        })
        .test("valid-email", "Invalid email format.", function (value) {
            if (!value) return true; // Allow empty value (let required() handle this)

            // Check for invalid patterns
            const invalidPatterns = [
                /^\./, // Starts with a dot
                /\.$/, // Ends with a dot
                /\.{2,}/, // Multiple dots in sequence
                /\.@/, // Starts with a dot immediately followed by @
                // /\.com.com$/,
                /(\.com){2,}$/, // Ends with .com repeated one or more times
                // /@\.\w+$/,
            ];

            const validPatterns = [/@.*\.[^.].*$/];

            const isInvalid = invalidPatterns.some((pattern) => pattern.test(value));

            const isValid = validPatterns.some((pattern) => pattern.test(value));

            return isValid && !isInvalid;
        }),

    provider_specialty: Yup.string()
        .required("Provider Specialty is required.")
        .min(3, "Provider Specialty must be at least 3 characters.")
        .max(250, "Provider Specialty must be at most 250 characters."),

    /*
    provider_memberships: Yup.string()
        .required("Provider Memberships is required.")
        .min(3, "Provider Memberships must be at least 3 characters.")
        .max(250, "Provider Memberships must be at most 250 characters."),

    /*
    provider_description_of_expertise: Yup.string()
        .required("Provider Description of Expertise is required.")
        .min(3, "Provider Description of Expertise must be at least 3 characters.")
        .max(250, "Provider Description of Expertise must be at most 250 characters."),
    */

    provider_guidance: Yup.array()
        .required("At least one provider guidance option must be selected.")
        .min(1, "At least one provider guidance option must be selected."),
});

const validationSchema = Yup.object().shape({
    // providers: Yup.array().of(providerSchema).min(1, "At least one provider is required."),
    providers: Yup.array().of(providerSchema),

    practice_name: Yup.string()
        .min(3, "Practice Name must be at least 3 characters.")
        .max(250, "Practice Name must be at most 250 characters.")
        // .matches(/^[a-zA-Z]*$/, "Practice Name can only contain letters")
        .matches(/^[a-zA-Z ]*$/, "Provider Title can only contain letters and spaces.")
        .required("Practice Name is required.")
        .strict(),
    practice_email: Yup.string()
        .email("Invalid email.")
        .required("Practice Email is required.")
        .strict()
        .test("valid-email", "Invalid email format.", function (value) {
            if (!value) return true; // Allow empty value (let required() handle this)

            // Check for invalid patterns
            const invalidPatterns = [
                /^\./, // Starts with a dot
                /\.$/, // Ends with a dot
                /\.{2,}/, // Multiple dots in sequence
                /\.@/, // Starts with a dot immediately followed by @
                // /\.com.com$/,
                /(\.com){2,}$/, // Ends with .com repeated one or more times
                // /@\.\w+$/,
            ];

            const validPatterns = [/@.*\.[^.].*$/];

            const isInvalid = invalidPatterns.some((pattern) => pattern.test(value));

            const isValid = validPatterns.some((pattern) => pattern.test(value));

            return isValid && !isInvalid;
        }),

    practice_phone: Yup.string()
        .required("Practice Phone is required.")
        .min(11, "Practice Phone must be at least 10 digits.")
        .max(16, "Practice Phone must not exceed 15 digits.")
        .test("unique-digits.", "Invalid mobile number.", function (value) {
            const cleanedDigits = value.replace(/^\+\D*/, "");
            const digitsWithoutCountryCode = cleanedDigits.slice(1);
            const hasDifferentDigit = Array.from(new Set(digitsWithoutCountryCode)).length !== 1;
            return hasDifferentDigit || this.createError({ message: "Invalid mobile number." });
        })
        .strict(),

    practice_city: Yup.string().required("Practice City is required.").strict(),
    practice_state: Yup.string(),
    practice_address_1: Yup.string()
        .min(3, "Practice Address 1 must be at least 3 characters.")
        .max(250, "Practice Address 1 must be at most 250 characters.")
        .required("Practice Address 1 is required.")
        .strict(),

    /*    
    practice_address_2: Yup.string()
        .min(3, "Practice Address 2 must be at least 3 characters.")
        .max(250, "Practice Address 2 must be at most 250 characters.")
        .required("Practice Address 2 is required.")
        .strict(),
    */

    practice_zip: Yup.string()
        .min(5, "Practice Zip must be at least 5 digits.")
        .max(10, "Practice Zip must be at most 9 digits.")
        // .matches(/^\d{5}$/, "Invalid ZIP code.")
        .required("Practice Zip is required.")
        // .test("is-valid-zip.", "Invalid ZIP code.", (value) => Number(value) >= 1 && Number(value) <= 99950)
        .strict(),

    scheduling_contact_first_name: Yup.string()
        .min(3, "Scheduling Contact First Name must be at least 3 characters.")
        .max(250, "Scheduling Contact First Name must be at most 250 characters.")
        .matches(/^[a-zA-Z ]*$/, "Scheduling Contact First Name can only contain letters and spaces.")
        .required("Scheduling Contact First Name is required.")
        .strict(),
    scheduling_contact_last_name: Yup.string()
        .min(3, "Scheduling Contact Last Name must be at least 3 characters.")
        .max(250, "Scheduling Contact Last Name must be at most 250 characters.")
        .matches(/^[a-zA-Z ]*$/, "Scheduling Contact Last Name can only contain letters and spaces.")
        .required("Scheduling Contact Last Name is required.")
        .strict(),
    scheduling_phone: Yup.string()
        .required("Scheduling Phone is required.")
        .min(11, "Scheduling Phone must be at least 10 digits.")
        .max(16, "Scheduling Phone must not exceed 15 digits.")
        .test("unique-digits", "Invalid mobile number.", function (value) {
            const cleanedDigits = value.replace(/^\+\D*/, "");
            const digitsWithoutCountryCode = cleanedDigits.slice(1);
            const hasDifferentDigit = Array.from(new Set(digitsWithoutCountryCode)).length !== 1;
            return hasDifferentDigit || this.createError({ message: "Invalid mobile number." });
        })
        .strict(),
    scheduling_email: Yup.string()
        .email("Invalid email.")
        .required("Scheduling Email is required.")
        .strict()
        .test("valid-email.", "Invalid email format.", function (value) {
            if (!value) return true; // Allow empty value (let required() handle this)

            // Check for invalid patterns
            const invalidPatterns = [
                /^\./, // Starts with a dot
                /\.$/, // Ends with a dot
                /\.{2,}/, // Multiple dots in sequence
                /\.@/, // Starts with a dot immediately followed by @
                // /\.com.com$/,
                /(\.com){2,}$/, // Ends with .com repeated one or more times
                // /@\.\w+$/,
            ];

            const validPatterns = [/@.*\.[^.].*$/];

            const isInvalid = invalidPatterns.some((pattern) => pattern.test(value));

            const isValid = validPatterns.some((pattern) => pattern.test(value));

            return isValid && !isInvalid;
        }),

    /*
    hours_of_operation: Yup.string()
        .min(3, "Hours of Operation must be at least 3 characters.")
        .max(250, "Hours of Operation must be at most 250 characters.")
        .required("Hours of Operation is required.")
        .strict(),
    */
});

const initialValues = {
    /*tbl_provider_information*/
    providers: [
        {
            provider_title: "",
            provider_first_name: "",
            provider_last_name: "",
            provider_email: "",
            provider_mobile_number: "",
            provider_image: null,
            provider_specialty: "",
            provider_memberships: "",
            provider_guidance: [],
            //provider_description_of_expertise: "",

            created_by: sessionStorage.getItem("loger_id"),
            created_on: dbFormattedDateTime,
            updated_by: sessionStorage.getItem("loger_id"),
            updated_on: dbFormattedDateTime,
            visibility: "1",
        },
    ],
    practice_name: "",
    practice_email: "",
    practice_phone: "",
    practice_city: "",
    practice_state: "",
    practice_zip: "",
    practice_address_1: "",
    practice_address_2: "",
    scheduling_contact_first_name: "",
    scheduling_contact_last_name: "",
    scheduling_email: "",
    scheduling_phone: "",
    hours_of_operation: "",

    start_date: dbFormattedDate,
    start_time: dbFormattedTime,

    created_by: sessionStorage.getItem("loger_id"),
    created_on: dbFormattedDateTime,
    updated_by: sessionStorage.getItem("loger_id"),
    updated_on: dbFormattedDateTime,
    visibility: "1",
};

async function checkProviderEmailUniqueness(email) {
    const response = await axios.post(BASE_URL + "API/apicall", {
        security_key: security_key,
        mode: "checkProviderEmailUniqueness",
        provider_email: email,
    });

    const output = response.data;

    return output;
}

async function checkSchedulingEmailUniqueness(email) {
    const response = await axios.post(BASE_URL + "API/apicall", {
        security_key: security_key,
        mode: "checkSchedulingEmailUniqueness",
        scheduling_email: email,
    });

    const output = response.data;

    return output;
}

const ProviderForm = () => {
    /****Service Functional */

    const [services, setServices] = useState([{ service_name: "", service_cost: "" }]);

    const addServiceRow = () => {
        setServices([...services, { service_name: "", service_cost: "" }]);
    };

    const handleServiceChange = (index, event) => {
        const { name, value } = event.target;
        const updatedServices = [...services];
        updatedServices[index][name] = value;
        setServices(updatedServices);
    };

    const removeServiceRow = (index) => {
        const updatedServices = [...services];
        updatedServices.splice(index, 1);
        setServices(updatedServices);
    };

    const validateServices = (servicesArray) => {
        const errors = {};

        const seenServiceNames = {};

        servicesArray.forEach((service, index) => {
            if (!service.service_name) {
                errors[index] = { ...errors[index], service_name: "Service Name is required." };
            } else {
                // Check for duplicate service names
                const serviceName = service.service_name.toLowerCase(); // Case-insensitive comparison
                if (seenServiceNames[serviceName]) {
                    errors[index] = { ...errors[index], service_name: "Duplicate Service Name." };
                } else {
                    seenServiceNames[serviceName] = true;
                }
            }
            // if (!/^[a-zA-Z0-9. ]{2,250}$/.test(service.service_name)) {
            //     errors[index] = {
            //         ...errors[index],
            //         service_name: "Service Name must be 2 to 250 characters long and can only contain letters, numbers, dots, and spaces.",
            //     };
            // }

            if (!service.service_cost) {
                errors[index] = { ...errors[index], service_cost: "Service Cost is required." };
            }
            if (service.service_cost == 0) {
                errors[index] = { ...errors[index], service_cost: "Service Cost must be greater than zero." };
            }
            if (service.service_cost < 0) {
                errors[index] = { ...errors[index], service_cost: "Service Cost not accept negative value." };
            }
        });

        return errors;
    };

    // Assuming you have a function to handle changes in the services array
    const handleServiceArrayChange = (index, field, value) => {
        setFieldValue(`services[${index}].${field}`, value);
    };

    // const serviceErrors = validateServices(services);
    const [serviceErrors, setServiceErrors] = useState({});

    const handleSubmitButtonClick = () => {
        setServiceErrors(validateServices(services));
    };

    const navigate = useNavigate();

    const formRef = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [baseImage, setBaseImage] = useState("");

    const handleAddProvider = () => {
        const newProviders = [
            ...formik.values.providers,
            {
                provider_title: "",
                provider_first_name: "",
                provider_last_name: "",
                provider_email: "",
                provider_mobile_number: "",
                provider_image: null,
                provider_specialty: "",
                provider_memberships: "",
                provider_guidance: [],
            },
        ];
        formik.setValues({ ...formik.values, providers: newProviders });
    };
    const handleRemoveProvider = (providerId) => {
        const updatedProviders = formik.values.providers.filter((provider, index) => index !== providerId - 1);
        formik.setValues({ ...formik.values, providers: updatedProviders });
    };

    // const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, setFieldValue } = useFormik({
    const formik = useFormik({
        initialValues,
        validationSchema,
        validate: async (values) => {
            // setServiceErrors(validateServices(services));

            const errors = {};

            // Check provider email uniqueness
            // if (touched.provider_email || touched.submitButton) {
            //     const isProviderEmailUnique = await checkProviderEmailUniqueness(values.providers[0].provider_email);
            //     if (!isProviderEmailUnique) {
            //         errors.providers = [{ provider_email: "Provider Email is already taken" }];
            //     }
            // }

            // Check scheduling email uniqueness
            if (touched.scheduling_email || touched.submitButton) {
                const isSchedulingEmailUnique = await checkSchedulingEmailUniqueness(values.scheduling_email);
                if (!isSchedulingEmailUnique) {
                    errors.scheduling_email = "Scheduling Email is already taken";
                }
            }

            return errors;
        },
        onSubmit: async (formValues) => {
            // console.log(JSON.stringify(formValues, null, 2));
            // alert(JSON.stringify(formValues, null, 2));
            if (Object.keys(serviceErrors).length === 0) {
                toast.dismiss();
                setIsSubmitting(true);
                const convertBase64 = (file) => {
                    return new Promise((resolve, reject) => {
                        const fileReader = new FileReader();
                        fileReader.onload = () => {
                            resolve(fileReader.result);
                        };
                        fileReader.onerror = (error) => {
                            reject(error);
                        };
                        fileReader.readAsDataURL(file);
                    });
                };

                const updatedProviders = await Promise.all(
                    formValues.providers.map(async (provider) => {
                        if (provider.provider_image instanceof File) {
                            const base64Image = await convertBase64(provider.provider_image);
                            const fileName = provider.provider_image.name;
                            const fileExtension = fileName.split(".").pop();

                            return {
                                ...provider,
                                provider_image_new: {
                                    base64Image,
                                    fileExtension,
                                },
                            };
                        } else {
                            return provider;
                        }
                    })
                );

                // Assuming formValues and BASE_URL are defined somewhere in your code
                (async () => {
                    try {
                        const response = await axios.post(BASE_URL + "API/apicall", {
                            security_key: security_key,
                            mode: "addProvider",
                            ...formValues,
                            providers: updatedProviders,
                            services: services,
                        });
                        const output = response.data;
                        if (output && output == 1) {
                            toast.success("Success! Your form was submitted", {
                                autoClose: 3000,
                            });
                            setTimeout(function () {
                                navigate("/Admin/ProviderList");
                            }, 3000);
                            setIsSubmitting(false);
                        } else {
                            toast.error("Oops! Something went wrong.", {
                                autoClose: 3000,
                            });
                        }
                    } catch (error) {
                        // console.error(error);
                        toast.error("Oops! Something went wrong.", {
                            autoClose: 3000,
                        });
                    }
                })();
            }
        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;
    const setFieldValue = formik.setFieldValue;

    const providers = values.providers || []; // Provide default value if users is undefined

    // console.log(errors.providers);

    // Handler function to toggle selected guidance options
    const handleGuidanceChange = (index, option) => {
        const { providers } = formik.values;
        const updatedGuidance = providers[index].provider_guidance.includes(option)
            ? providers[index].provider_guidance.filter((item) => item !== option)
            : [...providers[index].provider_guidance, option];

        formik.setFieldValue(`providers.${index}.provider_guidance`, updatedGuidance);
    };

    useEffect(() => {
        if (!formik.isSubmitting) return;

        let firstErrorField = Object.keys(formik.errors)[0];

        console.log(formik.errors.providers);

        if (firstErrorField == "providers") {
            let proError = formik.errors.providers;

            if (proError) {
                for (const [index, row] of Object.entries(proError)) {
                    if (row) {
                        firstErrorField = Object.keys(row)[0] + `${index}`;
                        console.log(row);
                        break;
                    }
                }
            }
        }

        if (firstErrorField) {
            const errorFieldElement = document.getElementById(firstErrorField);

            if (errorFieldElement) {
                const elementRect = errorFieldElement.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const middleOfElement = absoluteElementTop - window.innerHeight / 2;

                window.scrollTo({
                    top: middleOfElement,
                    behavior: "smooth",
                });
            } else {
                console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
        }
    }, [formik.errors, formik.isSubmitting]);

    // Assuming touched is an object like { field1: true, field2: false, ... }
    // const currentlyTouchedField = Object.keys(touched).find((field) => touched[field]);

    // console.log("Currently Touched Field:", currentlyTouchedField);

    const themes = sessionStorage.getItem("theme");

    const commonStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "white",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "black",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                },
            }),
        },
        react_tel: "",
    };

    const darkThemeStyles = {
        react_select: {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "#343541",
                color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "white",
                ":hover": {
                    backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                    color: state.isSelected ? "white" : "black",
                    // color: "black",
                },
            }),
        },
        react_tel: "",
    };

    const colourStyles = themes == "dark" ? darkThemeStyles : commonStyles;

    return (
        <>
            <div className="app-content">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="section-header d-flex align-items-center justify-content-between">
                            <div className="section-heading">
                                <h4 className="mb-1">Add Provider</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a role="button" onClick={() => navigate("/Admin/Dashboard")}>
                                                Home
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a role="button" onClick={() => navigate("/Admin/ProviderList")}>
                                                Providers
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Add Provider
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="section-essentials d-flex align-items-center justify-content-between gap-2">
                                <a role="button" className="btn btn-light btn-md" onClick={() => navigate("/Admin/ProviderList")}>
                                    Back
                                </a>
                            </div>
                        </div>
                        <ToastContainer position="top-center" />
                        <Row>
                            <Col md={12} className="mx-auto">
                                <div>
                                    {/* <div className="card p-5"> */}
                                    <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                        <Stack>
                                            <div className="my-card p-4 bg-white">
                                                {providers.map((provider, index) => (
                                                    <div key={index + 1}>
                                                        <div className="section-heading border-bottom pb-3 mb-3">
                                                            <h6 className="mb-0">Provider {index + 1} </h6>
                                                        </div>
                                                        <Row className="mb-3">
                                                            {/* <Col md={4}>
                                                                <Form.Group controlId={`provider_title${index}`}>
                                                                    <Form.Label>
                                                                        Title<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name={`providers.${index}.provider_title`}
                                                                        value={provider.provider_title}
                                                                        onChange={(e) =>
                                                                            formik.setFieldValue(`providers.${index}.provider_title`, e.target.value)
                                                                        }
                                                                        onBlur={(e) =>
                                                                            formik.setFieldValue(`providers.${index}.provider_title`, e.target.value.trim())
                                                                        }
                                                                        onFocus={() => formik.setFieldTouched(`providers.${index}.provider_title`, true)}
                                                                        className={
                                                                            errors.providers?.[index]?.provider_title &&
                                                                            touched.providers?.[index]?.provider_title
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors?.providers?.[index]?.provider_title &&
                                                                        touched?.providers?.[index]?.provider_title && (
                                                                            <p className="error">{errors.providers[index].provider_title}</p>
                                                                        )}
                                                                </Form.Group>
                                                            </Col> */}
                                                            <Col md={4}>
                                                                <Form.Group controlId={`provider_first_name${index}`}>
                                                                    <Form.Label>
                                                                        First Name<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name={`providers.${index}.provider_first_name`}
                                                                        value={provider.provider_first_name}
                                                                        onChange={(e) =>
                                                                            formik.setFieldValue(`providers.${index}.provider_first_name`, e.target.value)
                                                                        }
                                                                        onBlur={(e) => {
                                                                            formik.setFieldTouched(`providers.${index}.provider_first_name`, true);
                                                                            formik.setFieldValue(
                                                                                `providers.${index}.provider_first_name`,
                                                                                e.target.value.trim()
                                                                            );
                                                                        }}
                                                                        className={
                                                                            errors?.providers?.[index]?.provider_first_name &&
                                                                            touched?.providers?.[index]?.provider_first_name
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors?.providers?.[index]?.provider_first_name &&
                                                                        touched?.providers?.[index]?.provider_first_name && (
                                                                            <p className="error">{errors.providers[index].provider_first_name}</p>
                                                                        )}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={4}>
                                                                <Form.Group controlId={`provider_last_name${index}`}>
                                                                    <Form.Label>
                                                                        Last Name<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name={`providers.${index}.provider_last_name`}
                                                                        value={provider.provider_last_name}
                                                                        onChange={(e) =>
                                                                            formik.setFieldValue(`providers.${index}.provider_last_name`, e.target.value)
                                                                        }
                                                                        onBlur={(e) => {
                                                                            formik.setFieldTouched(`providers.${index}.provider_last_name`, true);
                                                                            formik.setFieldValue(
                                                                                `providers.${index}.provider_last_name`,
                                                                                e.target.value.trim()
                                                                            );
                                                                        }}
                                                                        className={
                                                                            errors?.providers?.[index]?.provider_last_name &&
                                                                            touched?.providers?.[index]?.provider_last_name
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors?.providers?.[index]?.provider_last_name &&
                                                                        touched?.providers?.[index]?.provider_last_name && (
                                                                            <p className="error">{errors.providers[index].provider_last_name}</p>
                                                                        )}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={4}>
                                                                <Form.Group controlId={`provider_email${index}`}>
                                                                    <Form.Label>
                                                                        Email<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="email"
                                                                        name={`providers.${index}.provider_email`}
                                                                        value={provider.provider_email || ""}
                                                                        onChange={(e) =>
                                                                            formik.setFieldValue(`providers.${index}.provider_email`, e.target.value)
                                                                        }
                                                                        onBlur={(e) => {
                                                                            formik.setFieldTouched(`providers.${index}.provider_email`, true);
                                                                            // formik.setFieldValue(`providers.${index}.provider_email`, e.target.value.trim());
                                                                        }}
                                                                        className={
                                                                            errors?.providers?.[index]?.provider_email &&
                                                                            touched?.providers?.[index]?.provider_email
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors?.providers?.[index]?.provider_email &&
                                                                        touched?.providers?.[index]?.provider_email && (
                                                                            <p className="error">{errors.providers[index].provider_email}</p>
                                                                        )}
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col md={4}>
                                                                <Form.Group controlId={`provider_specialty${index}`}>
                                                                    <Form.Label>
                                                                        Specialty<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        style={{ height: "100px" }}
                                                                        name={`providers.${index}.provider_specialty`}
                                                                        value={provider.provider_specialty}
                                                                        onChange={(e) =>
                                                                            formik.setFieldValue(`providers.${index}.provider_specialty`, e.target.value)
                                                                        }
                                                                        onBlur={(e) => {
                                                                            const trimmedValue = e.target.value.trim();
                                                                            const event = {
                                                                                target: {
                                                                                    name: `providers.${index}.provider_specialty`,
                                                                                    value: trimmedValue,
                                                                                },
                                                                            };
                                                                            formik.handleChange(event);
                                                                            formik.handleBlur(event);
                                                                        }}
                                                                        className={
                                                                            errors?.providers?.[index]?.provider_specialty &&
                                                                            touched?.providers?.[index]?.provider_specialty
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors?.providers?.[index]?.provider_specialty &&
                                                                        touched?.providers?.[index]?.provider_specialty && (
                                                                            <p className="error">{errors.providers[index].provider_specialty}</p>
                                                                        )}
                                                                </Form.Group>
                                                            </Col>
                                                            {/* <Col md={4}>
                                                                <Form.Group controlId={`provider_memberships${index}`}>
                                                                    <Form.Label>
                                                                        Memberships<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        style={{ height: "100px" }}
                                                                        name={`providers.${index}.provider_memberships`}
                                                                        value={provider.provider_memberships}
                                                                        onChange={(e) =>
                                                                            formik.setFieldValue(`providers.${index}.provider_memberships`, e.target.value)
                                                                        }
                                                                        onBlur={(e) => {
                                                                            const trimmedValue = e.target.value.trim();
                                                                            const event = {
                                                                                target: {
                                                                                    name: `providers.${index}.provider_memberships`,
                                                                                    value: trimmedValue,
                                                                                },
                                                                            };
                                                                            formik.handleChange(event);
                                                                            formik.handleBlur(event);
                                                                        }}
                                                                        className={
                                                                            errors?.providers?.[index]?.provider_memberships &&
                                                                            touched?.providers?.[index]?.provider_memberships
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors?.providers?.[index]?.provider_memberships &&
                                                                        touched?.providers?.[index]?.provider_memberships && (
                                                                            <p className="error">{errors.providers[index].provider_memberships}</p>
                                                                        )}
                                                                </Form.Group>
                                                            </Col> */}
                                                            <Col md={4}>
                                                                {/* <Form.Group controlId="provider_description_of_expertise" className="mb-3">
                                                                    <Form.Label>
                                                                        Description of Expertise<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        style={{ height: "100px" }}
                                                                        name="provider_description_of_expertise"
                                                                        value={values.provider_description_of_expertise}
                                                                        onChange={handleChange}
                                                                        // onBlur={handleBlur}
                                                                        onBlur={(e) => {
                                                                            const trimmedValue = e.target.value.trim();
                                                                            const event = {
                                                                                target: {
                                                                                    name: "provider_description_of_expertise",
                                                                                    value: trimmedValue,
                                                                                },
                                                                            };
                                                                            handleChange(event);
                                                                            handleBlur(event);
                                                                        }}
                                                                        className={
                                                                            errors.provider_description_of_expertise &&
                                                                            touched.provider_description_of_expertise
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors.provider_description_of_expertise && touched.provider_description_of_expertise && (
                                                                        <p className="error">{errors.provider_description_of_expertise}</p>
                                                                    )}
                                                                </Form.Group> */}

                                                                <FormGroup className="mb-3" id={`provider_guidance${index}`}>
                                                                    <Form.Label>
                                                                        Guidance<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={formik.values.providers[index].provider_guidance.includes(
                                                                                    "Visual Guidance"
                                                                                )}
                                                                                onChange={() => handleGuidanceChange(index, "Visual Guidance")}
                                                                                onBlur={formik.handleBlur}
                                                                                name={`providers.${index}.provider_guidance`}
                                                                            />
                                                                        }
                                                                        label="Visual Guidance"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={formik.values.providers[index].provider_guidance.includes(
                                                                                    "Landmark Guidance"
                                                                                )}
                                                                                onChange={() => handleGuidanceChange(index, "Landmark Guidance")}
                                                                                onBlur={formik.handleBlur}
                                                                                name={`providers.${index}.provider_guidance`}
                                                                            />
                                                                        }
                                                                        label="Landmark Guidance"
                                                                    />
                                                                    {formik.errors.providers?.[index]?.provider_guidance &&
                                                                        formik.touched.providers?.[index]?.provider_guidance && (
                                                                            <p className="error">{formik.errors.providers[index].provider_guidance}</p>
                                                                        )}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={4}>
                                                                <Form.Group controlId={`provider_mobile_number${index}`}>
                                                                    <Form.Label>
                                                                        Mobile Number<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <PhoneInput
                                                                        name={`providers.${index}.provider_mobile_number`}
                                                                        countryCodeEditable={false}
                                                                        country="us"
                                                                        style={{ width: "100% !important" }}
                                                                        value={provider.provider_mobile_number}
                                                                        onlyCountries={["us"]}
                                                                        onChange={(value) => {
                                                                            const event = {
                                                                                target: {
                                                                                    name: `providers.${index}.provider_mobile_number`,
                                                                                    value: value,
                                                                                },
                                                                            };
                                                                            formik.handleChange(event);
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const event = {
                                                                                target: {
                                                                                    name: `providers.${index}.provider_mobile_number`,
                                                                                    value: e.target.value,
                                                                                },
                                                                            };
                                                                            formik.handleBlur(event);
                                                                            formik.setFieldTouched(`providers.${index}.provider_mobile_number`, true);
                                                                        }}
                                                                        inputClass={
                                                                            errors?.providers?.[index]?.provider_mobile_number &&
                                                                            touched?.providers?.[index]?.provider_mobile_number
                                                                                ? "input-error form-control"
                                                                                : "form-control"
                                                                        }
                                                                    />
                                                                    {errors?.providers?.[index]?.provider_mobile_number &&
                                                                        touched?.providers?.[index]?.provider_mobile_number && (
                                                                            <p className="error">{errors.providers[index].provider_mobile_number}</p>
                                                                        )}
                                                                </Form.Group>
                                                            </Col>
                                                            {/* <Col md={4}>
                                                                <Form.Group controlId={`provider_image${index}`}>
                                                                    <Form.Label>
                                                                        Upload Image<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="file"
                                                                        accept="image/*"
                                                                        name={`providers.${index}.provider_image`}
                                                                        onChange={(event) => {
                                                                            formik.setFieldValue(
                                                                                `providers.${index}.provider_image`,
                                                                                event.currentTarget.files[0]
                                                                            );
                                                                        }}
                                                                        onBlur={formik.handleBlur}
                                                                        className={
                                                                            errors?.providers?.[index]?.provider_image &&
                                                                            touched?.providers?.[index]?.provider_image
                                                                                ? "input-error"
                                                                                : ""
                                                                        }
                                                                    />
                                                                    {errors?.providers?.[index]?.provider_image &&
                                                                        touched?.providers?.[index]?.provider_image && (
                                                                            <p className="error">{errors.providers[index].provider_image}</p>
                                                                        )}
                                                                </Form.Group>
                                                            </Col> */}
                                                        </Row>
                                                        {/* Remove Provider Button */}
                                                        {providers.length > 1 && (
                                                            <Row className="mt-3">
                                                                <Col sm={12} className="text-end">
                                                                    <Button
                                                                        variant="contained"
                                                                        color="error"
                                                                        size="small"
                                                                        onClick={() => handleRemoveProvider(index + 1)}
                                                                    >
                                                                        Remove Provider
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </div>
                                                ))}
                                                <Row className="mt-5">
                                                    <Col sm={12} className="text-end">
                                                        <Button variant="contained" color="success" onClick={handleAddProvider}>
                                                            Additional Provider
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Stack>
                                        {/* <Stack className="mt-4">
                                            <Row className="mt-5">
                                                <Col sm={12} className="text-end">
                                                    <Button variant="success" onClick={handleAddProvider}>
                                                        Additional Provider
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Stack> */}

                                        <Stack className="mt-4">
                                            <div className="my-card p-4 bg-white">
                                                <div className="section-heading border-bottom pb-3 mb-3">
                                                    <h6 className="mb-0">
                                                        Practice<span className="text-danger">*</span>
                                                    </h6>
                                                </div>
                                                <Row className="mb-3">
                                                    <Col md={4}>
                                                        <Form.Group controlId="practice_name">
                                                            <Form.Label>
                                                                Name<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="practice_name"
                                                                value={values.practice_name}
                                                                onChange={handleChange}
                                                                // onBlur={handleBlur}
                                                                onBlur={(e) => {
                                                                    const trimmedValue = e.target.value.trim();
                                                                    const event = {
                                                                        target: {
                                                                            name: "practice_name",
                                                                            value: trimmedValue,
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                    handleBlur(event);
                                                                }}
                                                                className={errors.practice_name && touched.practice_name ? "input-error" : ""}
                                                            />
                                                            {errors.practice_name && touched.practice_name && <p className="error">{errors.practice_name}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group controlId="practice_email">
                                                            <Form.Label>
                                                                Email<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                name="practice_email"
                                                                value={values.practice_email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={errors.practice_email && touched.practice_email ? "input-error" : ""}
                                                            />
                                                            {errors.practice_email && touched.practice_email && (
                                                                <p className="error">{errors.practice_email}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group controlId="practice_phone">
                                                            <Form.Label>
                                                                Phone<span className="text-danger">*</span>
                                                            </Form.Label>
                                                        </Form.Group>
                                                        <PhoneInput
                                                            name="practice_phone"
                                                            countryCodeEditable={false}
                                                            country="us"
                                                            value={values.practice_phone}
                                                            onlyCountries={["us"]}
                                                            onChange={(value) => {
                                                                const event = {
                                                                    target: {
                                                                        name: "practice_phone",
                                                                        value: value,
                                                                    },
                                                                };
                                                                handleChange(event);
                                                            }}
                                                            onBlur={(e) => {
                                                                const event = {
                                                                    target: {
                                                                        name: "practice_phone",
                                                                        value: e.target.value,
                                                                    },
                                                                };
                                                                handleBlur(event);
                                                                setTouched({ ...touched, practice_phone: true });
                                                            }}
                                                            id="practice_phone"
                                                            inputClass={
                                                                errors.practice_phone && touched.practice_phone ? "input-error form-control" : "form-control"
                                                            }
                                                        />
                                                        {errors.practice_phone && touched.practice_phone && <p className="error">{errors.practice_phone}</p>}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={4} className="practice_city">
                                                        <Form.Label>
                                                            City<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Select
                                                            options={options_city}
                                                            name="practice_city"
                                                            styles={colourStyles.react_select}
                                                            // value={values.find(
                                                            //     (option) =>
                                                            //         option.value && values.practice_city && option.value.toString() === values.practice_city.toString()
                                                            // )}
                                                            id="practice_city"
                                                            onChange={(selectedOption) => {
                                                                const event = {
                                                                    target: {
                                                                        name: "practice_city",
                                                                        value: selectedOption["value"],
                                                                    },
                                                                };
                                                                handleChange(event);
                                                            }}
                                                            onBlur={(event) => {
                                                                handleBlur(event);
                                                                setTouched({ ...touched, practice_city: true });
                                                            }}
                                                            filterOption={(option, inputValue) => {
                                                                return option.label.toLowerCase().includes(inputValue.toLowerCase());
                                                            }}
                                                            className={errors.practice_city && touched.practice_city ? "input-error" : ""}
                                                        />
                                                        {errors.practice_city && touched.practice_city && <p className="error">{errors.practice_city}</p>}
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group controlId="practice_state">
                                                            <Form.Label>
                                                                State<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                readOnly={true}
                                                                value={values.practice_city.split(" || ")[2]} // Extract the state from the combined value
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={errors.practice_state && touched.practice_state ? "input-error" : ""}
                                                            />
                                                            {errors.practice_state && touched.practice_state && (
                                                                <p className="error">{errors.practice_state}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group controlId="practice_zip">
                                                            <Form.Label>
                                                                Zip Code<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <InputMask
                                                                mask="99999-9999"
                                                                maskChar=""
                                                                type="text"
                                                                name="practice_zip"
                                                                value={values.practice_zip}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={`form-control ${errors.practice_zip && touched.practice_zip ? "input-error" : ""}`}
                                                            />
                                                            {errors.practice_zip && touched.practice_zip && <p className="error">{errors.practice_zip}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={4}>
                                                        <Form.Group controlId="practice_address_1">
                                                            <Form.Label>
                                                                Address 1<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="practice_address_1"
                                                                value={values.practice_address_1}
                                                                onChange={handleChange}
                                                                //onBlur={handleBlur}
                                                                onBlur={(e) => {
                                                                    const trimmedValue = e.target.value.trim();
                                                                    const event = {
                                                                        target: {
                                                                            name: "practice_address_1",
                                                                            value: trimmedValue,
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                    handleBlur(event);
                                                                }}
                                                                className={errors.practice_address_1 && touched.practice_address_1 ? "input-error" : ""}
                                                            />
                                                            {errors.practice_address_1 && touched.practice_address_1 && (
                                                                <p className="error">{errors.practice_address_1}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    {/* <Col md={4}>
                                                        <Form.Group controlId="practice_address_2">
                                                            <Form.Label>
                                                                Address 2<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="practice_address_2"
                                                                value={values.practice_address_2}
                                                                onChange={handleChange}
                                                                //onBlur={handleBlur}
                                                                onBlur={(e) => {
                                                                    const trimmedValue = e.target.value.trim();
                                                                    const event = {
                                                                        target: {
                                                                            name: "practice_address_2",
                                                                            value: trimmedValue,
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                    handleBlur(event);
                                                                }}
                                                                className={errors.practice_address_2 && touched.practice_address_2 ? "input-error" : ""}
                                                            />
                                                            {errors.practice_address_2 && touched.practice_address_2 && (
                                                                <p className="error">{errors.practice_address_2}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col> */}
                                                </Row>

                                                <Row className="mb-3"></Row>
                                            </div>
                                        </Stack>

                                        <Stack className="mt-4">
                                            <div className="my-card p-4 bg-white">
                                                <div className="section-heading border-bottom pb-3 mb-3">
                                                    <h6 className="mb-0">Schedule </h6>
                                                </div>
                                                <Row className="mb-3">
                                                    <Col md={4}>
                                                        <Form.Group controlId="scheduling_contact_first_name">
                                                            <Form.Label>
                                                                Scheduling Contact First Name<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="scheduling_contact_first_name"
                                                                value={values.scheduling_contact_first_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.scheduling_contact_first_name && touched.scheduling_contact_first_name
                                                                        ? "input-error"
                                                                        : ""
                                                                }
                                                            />
                                                            {errors.scheduling_contact_first_name && touched.scheduling_contact_first_name && (
                                                                <p className="error">{errors.scheduling_contact_first_name}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group controlId="scheduling_contact_last_name">
                                                            <Form.Label>
                                                                Scheduling Contact Last Name<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="scheduling_contact_last_name"
                                                                value={values.scheduling_contact_last_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.scheduling_contact_last_name && touched.scheduling_contact_last_name
                                                                        ? "input-error"
                                                                        : ""
                                                                }
                                                            />
                                                            {errors.scheduling_contact_last_name && touched.scheduling_contact_last_name && (
                                                                <p className="error">{errors.scheduling_contact_last_name}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group controlId="scheduling_phone">
                                                            <Form.Label>
                                                                Scheduling Phone<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <PhoneInput
                                                                name="scheduling_phone"
                                                                countryCodeEditable={false}
                                                                country="us"
                                                                value={values.scheduling_phone}
                                                                onlyCountries={["us"]}
                                                                onChange={(value) => {
                                                                    const event = {
                                                                        target: {
                                                                            name: "scheduling_phone",
                                                                            value: value,
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                }}
                                                                onBlur={(e) => {
                                                                    const event = {
                                                                        target: {
                                                                            name: "scheduling_phone",
                                                                            value: e.target.value,
                                                                        },
                                                                    };
                                                                    handleBlur(event);
                                                                    setTouched({ ...touched, scheduling_phone: true });
                                                                }}
                                                                id="scheduling_phone"
                                                                inputClass={
                                                                    errors.scheduling_phone && touched.scheduling_phone
                                                                        ? "input-error form-control"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {errors.scheduling_phone && touched.scheduling_phone && (
                                                                <p className="error">{errors.scheduling_phone}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={4}>
                                                        <Form.Group controlId="scheduling_email">
                                                            <Form.Label>
                                                                Scheduling Email<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                name="scheduling_email"
                                                                value={values.scheduling_email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={errors.scheduling_email && touched.scheduling_email ? "input-error" : ""}
                                                            />
                                                            {errors.scheduling_email && touched.scheduling_email && (
                                                                <p className="error">{errors.scheduling_email}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    {/* <Col md={4}>
                                                        <Form.Group controlId="hours_of_operation">
                                                            <Form.Label>
                                                                Hours of Operation<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="hours_of_operation"
                                                                value={values.hours_of_operation}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={errors.hours_of_operation && touched.hours_of_operation ? "input-error" : ""}
                                                            />
                                                            {errors.hours_of_operation && touched.hours_of_operation && (
                                                                <p className="error">{errors.hours_of_operation}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col> */}
                                                </Row>
                                            </div>
                                        </Stack>

                                        <Stack className="mt-4">
                                            <div className="my-card p-4 bg-white">
                                                <div className="section-heading border-bottom pb-3 mb-3">
                                                    <h6 className="mb-0">Service</h6>
                                                </div>
                                                {services.map((service, index) => (
                                                    <Row key={index} className="mb-3">
                                                        <Col md={5}>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    Name<span className="text-danger">*</span>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="service_name"
                                                                    value={service.service_name}
                                                                    onBlur={(e) => {
                                                                        const trimmedValue = e.target.value.trim();
                                                                        const event = {
                                                                            target: {
                                                                                name: "service_name",
                                                                                value: trimmedValue,
                                                                            },
                                                                        };
                                                                        handleServiceChange(index, event);

                                                                        setServiceErrors(validateServices(services));
                                                                    }}
                                                                    onChange={(e) => {
                                                                        handleServiceChange(index, e);
                                                                        setServiceErrors(validateServices(services));
                                                                    }}
                                                                    isInvalid={serviceErrors[index]?.service_name}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {serviceErrors[index]?.service_name}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md={5}>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    Cost<span className="text-danger">*</span>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="service_cost"
                                                                    value={service.service_cost}
                                                                    min={0}
                                                                    onBlur={() => {
                                                                        setServiceErrors(validateServices(services));
                                                                    }}
                                                                    onChange={(e) => {
                                                                        handleServiceChange(index, e);
                                                                        setServiceErrors(validateServices(services));
                                                                    }}
                                                                    isInvalid={serviceErrors[index]?.service_cost} // Check if there's an error for this input
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {serviceErrors[index]?.service_cost}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md={2} style={{ margin: "33px 0px" }}>
                                                            {services.length !== 1 && (
                                                                <Button variant="contained" color="error" size="small" onClick={() => removeServiceRow(index)}>
                                                                    Remove
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                ))}

                                                <Row className="mt-5">
                                                    <Col sm={12} className="text-end">
                                                        <Button variant="contained" color="success" onClick={addServiceRow}>
                                                            Add Service
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Stack>
                                        <Stack>
                                            <Row className="my-4">
                                                <Col sm={12} className="text-end">
                                                    <Button variant="contained" type="submit" disabled={isSubmitting} onClick={handleSubmitButtonClick}>
                                                        {/* <Button variant="contained" type="submit" disabled={isSubmitting}> */}
                                                        <span className="px-4">Submit</span>
                                                        {isSubmitting ? <LinearProgress /> : ""}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Stack>
                                        {/* <Stack gap={2} className="mt-4">
                                        <div className="text-end">
                                            <Button variant="contained" className="primary-gray me-2">
                                                Cancel
                                            </Button>
                                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>
                                                Save
                                            </Button>
                                        </div>
                                    </Stack> */}
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProviderForm;
