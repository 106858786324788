import React, { useState } from "react";

import {
  Nav,
  Navbar,
  NavDropdown,
  Dropdown,
  ButtonGroup,
  Image,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GreetingComponent from "./Greeting";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

// sessionStorage.setItem("name", "naveen");

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function AppHeader({ isOpen, toggleSidebar }) {
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/Patient/Login");
  };

  const [isOpenDrop, setIsOpenDrop] = useState(false);

  const toggleDropDown = () => {
    setIsOpenDrop(!isOpenDrop);
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div className="app-header ">
      <div className="app-header-one">
        <nav className="navbar navbar-light navbar-expand-lg ">
          <div className="container-fluid">
            <div className="w-100 d-flex  justify-content-between align-items-center overflow-hidden header-main">
              <div
                className="navbar-nav flex-row align-items-center"
                id="navbarNav"
              >
                <ul className="navbar-nav" onClick={toggleSidebar}>
                  <li className="nav-item">
                    <a
                      className="nav-link hide-sidebar-toggle-button"
                      role="button"
                    >
                      {isOpen ? (
                        <i className="material-icons">last_page</i>
                      ) : (
                        <i className="material-icons">first_page</i>
                      )}
                    </a>
                  </li>
                </ul>
                {/* <h5 className="mt-3 ms-3">Hi, Good Afternoon !</h5> */}
                <GreetingComponent />
              </div>
              <div className="d-flex">
                <ul className="navbar-nav">
                  <li className="nav-item hidden-on-mobile d-flex align-items-center gap-1">
                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title="">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar
                            sx={{ width: 46, height: 46, bgcolor: "#1869bf" }}
                            className="me-2"
                          >
                            {sessionStorage.getItem("name").charAt(0)}
                          </Avatar>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        <MenuItem
                          key="Logout"
                          onClick={() => {
                            handleCloseUserMenu();
                            handleLogout();
                          }}
                        >
                          <Typography textAlign="center">Logout</Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                    <div>
                      <h6 className="mb-0">{sessionStorage.getItem("name")}</h6>
                      <small>{sessionStorage.getItem("email")}</small>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className=" app-header-two">
        <nav className="navbar navbar-light navbar-expand-lg app-header-two">
          <div className="container-fluid">
            <div className="w-100  overflow-hidden header-main">
              <div
                className="navbar-nav flex-row align-items-center justify-content-between"
                id="navbarNav"
              >
                <Image
                  src="../images/logo-light.png"
                  width={132}
                  className="img-fluid  d-block"
                />
                <ul className="navbar-nav" onClick={toggleSidebar}>
                  <li className="nav-item">
                    <a
                      className="nav-link hide-sidebar-toggle-button"
                      role="button"
                    >
                      {isOpen ? (
                        <MenuOpenIcon className="text-light f-30"></MenuOpenIcon>
                      ) : (
                        <MenuIcon className="text-light f-30"></MenuIcon>
                      )}
                    </a>
                  </li>
                </ul>

                {/* <h5 className="mt-3 ms-3">Hi, Good Afternoon !</h5> */}
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="mt-4 app-header-two">
        <div className="mb-3">
          {" "}
          <GreetingComponent />
        </div>
        <div className="d-flex">
          <ul className="navbar-nav">
            <li className="nav-item hidden-on-mobile d-flex align-items-center gap-1">
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      sx={{ width: 46, height: 46, bgcolor: "#1869bf" }}
                      className="me-2"
                    >
                      {sessionStorage.getItem("name").charAt(0)}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    key="Logout"
                    onClick={() => {
                      handleCloseUserMenu();
                      handleLogout();
                    }}
                  >
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <div>
                <h6 className="mb-0">{sessionStorage.getItem("name")}</h6>
                <small>{sessionStorage.getItem("email")}</small>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AppHeader;
