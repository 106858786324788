/*
Purpose: list details of student with use datatable
Developer: BY PKS
Date:26-OCT-2023 
Project: Emstyle
*/

import React, { useState, useCallback, useEffect, useRef } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// import DataTable from "react-data-table-component";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import EditIcon from "@mui/icons-material/Edit";
// import Tooltip from "@mui/material/Tooltip";
import { IconButton, Tooltip } from "@mui/material";

import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// import Button from "@mui/material/Button";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import axios from "axios";
import { security_key, BASE_URL, options_status, options_label } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
    GridRowModes,
    DataGrid,
    GridToolbar,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbarExport,
    gridClasses,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"; // Adjust the import path based on your project structure

import CustomNoRowsOverlay from "../../components/NoRow";

import { useFormik } from "formik";
import * as Yup from "yup";

const formatPhoneNumber = (rawPhoneNumber) => {
    const phoneNumber = String(rawPhoneNumber);
    const countryCode = phoneNumber.substring(0, 1);
    const restOfNumber = phoneNumber.substring(1);

    // Format the phone number as +1 (XXX) XXX-XXXX
    const formattedPhoneNumber = `+${countryCode} (${restOfNumber.substring(0, 3)}) ${restOfNumber.substring(3, 6)}-${restOfNumber.substring(6)}`;

    return formattedPhoneNumber;
};

// toast.hide();

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//     "& .MuiDialogContent-root": {
//         padding: theme.spacing(2),
//     },
//     "& .MuiDialogActions-root": {
//         padding: theme.spacing(1),
//     },
// }));

// function EditToolbar(props) {
//     // const { setRows, setRowModesModel } = props;

//     const { rows, setRows, setRowModesModel } = props;

//     const handleClick = () => {
//         // const id = 1;
//         const maxId = Math.max(...rows.map((row) => row.id)); // Find the maximum ID from existing rows
//         const id = isNaN(maxId) || maxId === -Infinity ? 1 : maxId + 1; // Increment it by 1
//         // alert(id);

//         setRows((oldRows) => [...oldRows, { id, service_name: "", service_cost: "", isNew: true }]);
//         setRowModesModel((oldModel) => ({
//             ...oldModel,
//             [id]: { mode: GridRowModes.Edit, fieldToFocus: "service_name" },
//         }));
//     };

//     // return (
//     //     <GridToolbarContainer>
//     //         <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
//     //             Add service
//     //         </Button>
//     //     </GridToolbarContainer>
//     // );
// }

// const currentDate = new Date();

// const dbFormattedDateTime = currentDate.toISOString().slice(0, 19).replace("T", " ");

// const dbFormattedDate = currentDate.toISOString().split("T")[0];

// const dbFormattedTime = currentDate.toTimeString().split(" ")[0];

const PatientListU2 = () => {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const handleModalClose = () => {
        setShowModal(false);
    };
    const handleModalShow = () => setShowModal(true);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRowClick = (row) => {
        if (row.assign_visibility == 1) {
            navigate("/Provider/PatientView", { state: { patient_id: row.pk_patient_id } });
        }
    };

    /****Patient List Functional****/

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    //Api call start
    const [rows, setRows] = useState([]);

    const [records, setRecords] = useState([]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getAllPatientByProv",
                provider_id: sessionStorage.getItem("loger_id"),
            });

            const output = response.data;

            // console.log("Response Data:", output);

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return {
                        ...row,
                        index: index + 1,
                        id: row.pk_assign_id,
                        mobile_number1: formatPhoneNumber(row.patient_mobile_phone),
                        mobile_number2: row.patient_other_phone && row.patient_other_phone != 1 ? formatPhoneNumber(row.patient_other_phone) : "",
                    };
                });

                setRows(modifiedOutput);
                setRecords(modifiedOutput);
                setLoading(false);
                console.log(modifiedOutput);
            } else {
                //alert message provide
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    //Api call End

    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow) => {
        toast.dismiss();

        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

        const foundOption = options_status.find((option) => option.label === updatedRow["status"]);
        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "updateStatus",
            patient_id: updatedRow["pk_patient_id"],
            provider_id: sessionStorage.getItem("loger_id"),
            status_id: foundOption.value,
        });

        const output = response.data;
        if (output == 1) {
            toast.success("updated successful!", {
                autoClose: 3000,
            });
        }
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const [clickRow, setClickRow] = useState([]);

    const CustomCell = (params) => {
        const isAssignVisibilityZero = params.row.assign_visibility === 0;

        return (
            <React.Fragment>
                {isAssignVisibilityZero ? (
                    <Tooltip title="Admin: Patient has been assigned to another provider.">
                        <span className="csutable-cell-trucate">{params.value}</span>
                    </Tooltip>
                ) : (
                    <span className="csutable-cell-trucate">{params.value}</span>
                )}
            </React.Fragment>
        );
    };

    const columns = [
        // {
        //     headerName: "#ID",
        //     field: "index",
        //     headerClassName: "super-app-theme--header",
        //     width: 50,
        //     renderCell: CustomCell,
        // },
        {
            headerName: "Actions",
            field: "actions",
            type: "actions",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
            cellClassName: "actions",
            getActions: ({ id, row }) => {
                if (row.assign_visibility == 1) {
                    return [
                        <div>
                            <MoreVertIcon
                                onClick={(event) => {
                                    handleClick(event, id);
                                    setClickRow(row);
                                    console.log(row);
                                }}
                            />
                            <Menu
                                id={`basic-menu-${id}`}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                MenuListProps={{
                                    "aria-labelledby": `basic-button-${id}`,
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        handleModalShow();
                                    }}
                                >
                                    Change status
                                </MenuItem>
                            </Menu>
                        </div>,
                    ];
                } else {
                    return [];
                }
                // if (row.assign_visibility == 1) {
                // }
                // else {
                //     return null;
                // }
            },
        },
        {
            headerName: "Date assigned",
            field: "assign_date_mod",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
            // editable: true,
            // renderCell: (params) => (
            //     <Tooltip title="Admin changed patient provider">
            //         <span className="csutable-cell-trucate">{params.value}</span>
            //     </Tooltip>
            // ),
            renderCell: CustomCell,
        },
        {
            headerName: "Scheduled Date",
            field: "schedule_date",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
            renderCell: CustomCell,
        },
        {
            headerName: "Status",
            field: "status",
            headerClassName: "super-app-theme--header",
            // type: "singleSelect",
            minWidth: 200,
            flex: 1,
            valueOptions: options_label,
            renderCell: CustomCell,
        },
        /*
        {
            headerName: "Reason",
            field: "reason",
            headerClassName: "super-app-theme--header",
            type: "text",
            width: 100,
            align: "left",
            headerAlign: "left",
            renderCell: (cellValues) => {
                if (cellValues.row.reason === "Other reasons") {
                    return cellValues.row.other; // Fix: remove the curly braces
                } else {
                    return cellValues.row.reason;
                }
            },
        },
        */
        {
            headerName: "First Name",
            field: "patient_first_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
            align: "left",
            headerAlign: "left",
            renderCell: CustomCell,
        },
        {
            headerName: "Last Name",
            field: "patient_last_name",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
            align: "left",
            headerAlign: "left",
            renderCell: CustomCell,
        },
        {
            headerName: "Description of Condition",
            field: "description_of_condition",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            // width: 150,
            flex: 1,
            align: "left",
            headerAlign: "left",
            renderCell: CustomCell,
        },
        {
            headerName: "Urgency",
            field: "urgency",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
            align: "left",
            headerAlign: "left",
            renderCell: CustomCell,
        },
        /*
        {
            headerName: "Preferred Contact Method",
            field: "preferred_contact_method",
            headerClassName: "super-app-theme--header",
            width: 150,
            align: "left",
            headerAlign: "left",
            renderCell: CustomCell,
        },
        {
            headerName: "Preferred Contact Time",
            field: "preferred_contact_time",
            headerClassName: "super-app-theme--header",
            width: 150,
            align: "left",
            headerAlign: "left",
            renderCell: CustomCell,
        },
        {
            headerName: "Patient Mobile Phone",
            field: "mobile_number1",
            headerClassName: "super-app-theme--header",
            width: 150,
            align: "left",
            headerAlign: "left",
            renderCell: CustomCell,
        },
        {
            headerName: "Patient Other Phone",
            field: "mobile_number2",
            headerClassName: "super-app-theme--header",
            width: 150,
            align: "left",
            headerAlign: "left",
            renderCell: CustomCell,
        },

        {
            headerName: "Email",
            field: "patient_email",
            headerClassName: "super-app-theme--header",
            width: 150,
            renderCell: CustomCell,
        },
        */

        // {
        //     field: "visibility",
        //     // headerName: "Visibility",
        //     hideable: false,
        // },
    ];

    function handleFilter(event) {
        const inputValue = event.target.value.trim().toLowerCase();
        const newData = records.filter((row) => {
            return (
                row.pk_patient_id.toString().includes(inputValue) ||
                (row.patient_first_name + " " + row.patient_last_name).toLowerCase().includes(inputValue) ||
                (row.patient_address_1?.toLowerCase() || "").includes(inputValue) ||
                // row.patient_address_1.toLowerCase().includes(inputValue) ||
                // row.patient_address_2.toLowerCase().includes(inputValue) ||
                row.patient_city.toLowerCase().includes(inputValue) ||
                row.patient_state.toLowerCase().includes(inputValue) ||
                row.patient_zip.toLowerCase().includes(inputValue) ||
                row.patient_email.toLowerCase().includes(inputValue) ||
                row.patient_mobile_phone.toLowerCase().includes(inputValue) ||
                (row.status?.toLowerCase() || "").includes(inputValue) ||
                (row.patient_other_phone?.toLowerCase() || "").includes(inputValue) ||
                // row.description_of_condition.toLowerCase().includes(inputValue) ||
                // row.urgency.toLowerCase().includes(inputValue) ||
                (row.preferred_contact_method?.toLowerCase() || "").includes(inputValue) ||
                (row.preferred_contact_time?.toLowerCase() || "").includes(inputValue) ||
                (row.status?.toLowerCase() || "").includes(inputValue) ||
                (row.assign_date_mod?.toLowerCase() || "").includes(inputValue)
                // row.how_did_you_hear_about_us.toLowerCase().includes(inputValue)
            );
        });
        setRows(newData);
    }

    /* Add this style to your CSS */
    const custom_modal_width = {
        // width: "70%",
        // maxWidth: "50%",
        // margin: "0 auto",
        minWidth: "90%",
    };

    const [isSubmitting, setIsSubmitting] = useState(false);

    const formRef = useRef(null);

    // const resetFormFields = (resetForm, setValues, initialValues) => {
    //     resetForm();
    //     setValues({
    //         ...initialValues, // Reset select fields
    //     });
    // };

    const resetFormFields = (resetForm, initialValues) => {
        // resetForm({
        //     values: {
        //         ...initialValues,
        //     },
        // });
        resetForm();
    };

    const initialValues = {
        assign_id: clickRow && clickRow["pk_assign_id"] ? clickRow["pk_assign_id"] : "",
        status: clickRow && clickRow["pk_status_id"] ? clickRow["pk_status_id"] : "",
        reason: clickRow && clickRow["reason"] ? clickRow["reason"] : "",
        other: clickRow && clickRow["other"] ? clickRow["other"] : "",
    };

    const validationSchema = Yup.object().shape({
        status: Yup.string().required("Status is required"),
        reason: Yup.string().when("status", {
            is: "4",
            then: () => Yup.string().required("Reason is required"),
        }),
        other: Yup.string().when("reason", {
            is: "Other reasons",
            then: () =>
                Yup.string().required("Other is required").min(6, "Other must be at least 6 characters").max(250, "Other must be at most 250 characters"),
        }),
    });

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, setFieldValue, resetForm, setValues } = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (formValues) => {
            toast.dismiss();
            setIsSubmitting(true);

            if (formValues.status !== 4) {
                formValues.reason = "";
                formValues.other = "";
            } else if (formValues.reason == "No show") {
                formValues.other = "";
            }
            console.log(formValues);

            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "updateStatus",
                ...formValues,
            });

            const output = response.data;
            if (output == 1) {
                toast.success("updated successful!", {
                    autoClose: 3000,
                });
                setClickRow("");
                fetchData();
                // resetFormFields(resetForm, setValues, initialValues);
                resetFormFields(resetForm, initialValues);
                handleModalClose();

                // console.log(initialValues);
            }

            setTimeout(function () {
                setIsSubmitting(false);
            }, 3000);
        },
    });

    const themes = sessionStorage.getItem("theme");

    const commonStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "white",
            color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "black",
            ":hover": {
                backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                color: state.isSelected ? "white" : "black",
            },
        }),
    };

    const darkThemeStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused && !state.isSelected ? "#deebff" : state.isSelected ? "#2684ff" : "#343541",
            color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : "white",
            ":hover": {
                backgroundColor: state.isSelected ? "#2684ff" : "#deebff",
                color: state.isSelected ? "white" : "black",
            },
        }),
    };

    const colourStyles = themes == "dark" ? darkThemeStyles : commonStyles;

    <ReactTooltip id="my-tooltip-1" place="bottom" effect="solid" type="dark">
        Hello world! I'm a Tooltip
    </ReactTooltip>;

    const CustomTooltipRow = ({ row }) => (
        <div>
            <p>Name: {row.Urgency}</p>
        </div>
    );

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="app-content">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Row className="gx-0 gx-md-4">
                            <Col xs="12">
                                <Row className="gx-0 gx-md-4 justify-content-center">
                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <div className="section-header d-flex align-items-center justify-content-between mb-3">
                                            <div className="section-heading">
                                                <h4 className="mb-1">Patients</h4>
                                                {/* <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a role="button" onClick={() => navigate("/Provider/PatientList")}>
                                                                Home
                                                            </a>
                                                        </li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            Patients
                                                        </li>
                                                    </ol>
                                                </nav> */}
                                            </div>
                                            <div className="section-essentials d-flex align-items-center justify-content-between gap-2">
                                                <div className="search-section position-relative">
                                                    <input type="search" placeholder="Search patients" onChange={handleFilter} />
                                                    <div className="icon">
                                                        <i className="fa-solid fa-magnifying-glass" />
                                                    </div>
                                                </div>
                                                <div className="dropdown">
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <a className="dropdown-item" href="#">
                                                                Action
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="#">
                                                                Another action
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="#">
                                                                Something else here
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}

                                    {/* --------------Table Content Start--------------- */}
                                    <Col sm={12}>
                                        <Card className="my-card p-1 bg-white">
                                            <Card.Body className="card-padding-res pt-0">
                                                <Box
                                                    sx={{
                                                        height: "fit-content",
                                                        width: "100%",
                                                        "& .actions": {
                                                            color: "text.secondary",
                                                        },
                                                        "& .textPrimary": {
                                                            color: "text.primary",
                                                        },
                                                        "& .super-app-theme--header": {
                                                            backgroundColor: "#e3e6ee",
                                                        },
                                                        "& .hot": {
                                                            backgroundColor: "#ff943975",
                                                            color: "#1a3e72",
                                                        },
                                                        "& .row-muted": {
                                                            //backgroundColor: "#d4cad4",
                                                            color: "#1a3e72",
                                                            filter: "blur(1px)",
                                                        },
                                                        //"& .row-muted:hover": {
                                                        //     backgroundColor: "#d4cad4",
                                                        //     color: "#1a3e72",
                                                        // },

                                                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                                            {
                                                                outline: "none !important",
                                                            },
                                                    }}
                                                    style={{ height: "max-content", width: "100%" }}
                                                    // style={{ height: rows.length == 0 ? 600 : "fit-content", width: "100%" }}
                                                >
                                                    <DataGrid
                                                        autoHeight
                                                        sx={{ border: "none", m: 2, "--DataGrid-overlayHeight": "600px" }}
                                                        // minHeight={700}
                                                        initialState={{
                                                            pagination: { paginationModel: { pageSize: 10 } },
                                                        }}
                                                        pageSizeOptions={[10, 25, 50]}
                                                        loading={loading}
                                                        slots={{
                                                            loadingOverlay: LinearProgress,
                                                            noRowsOverlay: CustomNoRowsOverlay,
                                                            toolbar: GridToolbar,
                                                            // row: (props) => {
                                                            //     // alert(1);
                                                            //     const isTooltipEnabled = props.row.assign_visibility === 0;

                                                            //     return (
                                                            //         <div {...props}>
                                                            //             {isTooltipEnabled ? (
                                                            //                 <Tooltip title="Your Tooltip Text">
                                                            //                     <div className="MuiDataGrid-row">{props.children}</div>
                                                            //                 </Tooltip>
                                                            //             ) : (
                                                            //                 <div className="MuiDataGrid-row">{props.children}</div>
                                                            //             )}
                                                            //         </div>
                                                            //     );
                                                            // },
                                                            // tooltip: { title: "My TEST " },

                                                            // row: (props) => {
                                                            //     const isTooltipEnabled = props.row.assign_visibility === 0;

                                                            //     return isTooltipEnabled ? (
                                                            //         <Tooltip title="Your Tooltip Text">
                                                            //             <div {...props.row} />
                                                            //         </Tooltip>
                                                            //     ) : (
                                                            //         <div {...props.row} />
                                                            //     );
                                                            // },
                                                        }}
                                                        slotProps={{
                                                            toolbar: {
                                                                csvOptions: { fileName: "PatientList" },
                                                                printOptions: {
                                                                    disableToolbarButton: true,
                                                                    hideFooter: true,
                                                                    hideToolbar: true,
                                                                },
                                                                // printOptions: { fileName: "PatientList" },
                                                                rows,
                                                                setRows,
                                                                setRowModesModel,
                                                            },
                                                        }}
                                                        // rows={[]}
                                                        rows={rows}
                                                        columns={columns}
                                                        editMode="row"
                                                        rowModesModel={rowModesModel}
                                                        onRowModesModelChange={handleRowModesModelChange}
                                                        onRowEditStop={handleRowEditStop}
                                                        processRowUpdate={processRowUpdate}
                                                        getRowClassName={(params) => {
                                                            // alert(1);

                                                            // console.log("params:", params.row.assign_visibility);
                                                            if (params.row.assign_visibility == 0) {
                                                                return "row-muted";
                                                            }

                                                            return "cursor-pointer";
                                                        }}
                                                        // getRowProps={(params) => {
                                                        //     alert(1);
                                                        //     if (params.row.assign_visibility === 0) {
                                                        //         return {
                                                        //             title: "Tooltip Text",
                                                        //         };
                                                        //     }
                                                        //     return {};
                                                        // }}

                                                        // getRowProps={(params) => {
                                                        //     if (params.row.assign_visibility === 0) {
                                                        //         // Conditionally add title attribute to associate with the native HTML tooltip
                                                        //         return {
                                                        //             title: "Hello world! I'm a Tooltip",
                                                        //         };
                                                        //     }
                                                        //     return {};
                                                        // }}
                                                        // getCellClassName={(params) => {
                                                        //     console.log("params:" + params.field);

                                                        //     if (params.visibility == 1) {
                                                        //         return "hot";
                                                        //     } else {
                                                        //         return "";
                                                        //     }
                                                        // }}
                                                        onRowClick={(params) => handleRowClick(params.row)}
                                                    />
                                                </Box>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleModalClose} backdrop="static" dialogClassName="custom-modal">
                <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3 gx-0 gx-md-4">
                            <Col md="12">
                                <Form.Label>
                                    Status<span className="text-danger">*</span>
                                </Form.Label>
                                <Select
                                    options={options_status}
                                    id="status"
                                    // className="react-select"
                                    // menuIsOpen={true}
                                    styles={colourStyles}
                                    value={
                                        values &&
                                        values.status &&
                                        options_status.find((option) => {
                                            return option.value && option.value.toString() === values.status.toString();
                                        })
                                    }
                                    onChange={(selectedOption) => {
                                        const event = {
                                            target: {
                                                name: "status",
                                                value: selectedOption["value"],
                                            },
                                        };
                                        handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        setTouched({ ...touched, status: true });
                                    }}
                                    className={errors.status && touched.status ? "input-error" : ""}
                                />
                                {errors.status && touched.status && <p className="error">{errors.status}</p>}
                            </Col>
                        </Row>
                        {values.status === 4 && (
                            <Row className="mb-3 gx-0 gx-md-4">
                                <Col md="12">
                                    <Form.Group>
                                        <Form.Label>
                                            Reason<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Check
                                            type="radio"
                                            id="noShow"
                                            name="reason"
                                            label="No show"
                                            value="No show"
                                            checked={values.reason === "No show"}
                                            onChange={handleChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            id="otherReasons"
                                            name="reason"
                                            label="Other reasons"
                                            value="Other reasons"
                                            checked={values.reason === "Other reasons"}
                                            onChange={handleChange}
                                        />
                                        {errors.reason && touched.reason && <p className="error">{errors.reason}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                        {values.status === 4 && values.reason === "Other reasons" && (
                            <Row className="mb-3 gx-0 gx-md-4">
                                <Col md="12">
                                    <Form.Group controlId="other">
                                        <Form.Label>
                                            Other<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            // id="other"
                                            style={{ height: "100px" }}
                                            value={values.other}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={errors.other && touched.other ? "input-error" : ""}
                                        />
                                        {errors.other && touched.other && <p className="error">{errors.other}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" className="my-3" disabled={isSubmitting}>
                            <span className="px-4">Save</span>
                            {isSubmitting ? <LinearProgress /> : ""}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default PatientListU2;
