import React, { useState, useRef, useEffect } from "react";

import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, gridClasses } from "@mui/x-data-grid";

import axios from "axios";
import { security_key, BASE_URL, options_city } from "../../global";
import "react-toastify/dist/ReactToastify.css";

function AssignPatientList({ providerId }) {
    // alert(providerId);

    const [loading, setLoading] = useState(true);

    const [rows, setRows] = React.useState([]);

    const location = useLocation();

    useEffect(() => {
        fetchData();
    }, [location]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getAllAssignPatient",
                provider_id: providerId,
            });

            const output = response.data;

            if (output.length > 0) {
                const modifiedOutput = output.map((row, index) => {
                    return { ...row, id: row.pk_patient_id };
                });

                setRows(modifiedOutput);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const CustomCell = (params) => {
        const isAssignVisibilityZero = params.row.assign_visibility === 0;

        return (
            <React.Fragment>
                {isAssignVisibilityZero ? (
                    <Tooltip title="Assigned another provider">
                        <span className="csutable-cell-trucate">{params.value}</span>
                    </Tooltip>
                ) : (
                    <span className="csutable-cell-trucate">{params.value}</span>
                )}
            </React.Fragment>
        );
    };

    const columns = [
        {
            headerName: "Date assigned",
            field: "assign_date_mod",
            headerClassName: "super-app-theme--header",
            minWidth: 100,
            flex: 1,
            renderCell: CustomCell,
        },
        {
            headerName: "First Name",
            field: "patient_first_name",
            headerClassName: "super-app-theme--header",
            minWidth: 100,
            flex: 1,
            renderCell: CustomCell,
        },
        {
            headerName: "Last Name",
            field: "patient_last_name",
            headerClassName: "super-app-theme--header",
            minWidth: 100,
            flex: 1,
            renderCell: CustomCell,
        },
        {
            headerName: "Description of Condition",
            field: "description_of_condition",
            headerClassName: "super-app-theme--header",
            minWidth: 170,
            flex: 1,
            renderCell: CustomCell,
        },
        {
            headerName: "Preferred Contact Method",
            field: "preferred_contact_method",
            headerClassName: "super-app-theme--header",
            minWidth: 170,
            flex: 1,
            renderCell: CustomCell,
        },
        {
            headerName: "Preferred Contact Time",
            field: "preferred_contact_time",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
            renderCell: CustomCell,
        },
        {
            headerName: "Other Phone",
            field: "patient_other_phone",
            headerClassName: "super-app-theme--header",
            minWidth: 150,
            flex: 1,
            renderCell: CustomCell,
        },
        {
            headerName: "Email",
            field: "patient_email",
            headerClassName: "super-app-theme--header",
            minWidth: 200,
            flex: 1,
            renderCell: CustomCell,
        },
    ];

    return (
        <Box
            sx={{
                height: "fit-content",
                width: "100%",
                "& .actions": {
                    color: "text.secondary",
                },
                "& .textPrimary": {
                    color: "text.primary",
                },
                "& .super-app-theme--header": {
                    backgroundColor: "#e3e6ee",
                },
                "& .row-muted": {
                    color: "#1a3e72",
                    filter: "blur(1px)",
                },
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
                    outline: "none !important",
                },
            }}
            style={{ height: "max-content", width: "100%" }}
        >
            <DataGrid
                autoHeight
                sx={{ border: "none", m: 2, "--DataGrid-overlayHeight": "600px" }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                loading={loading}
                rows={rows}
                columns={columns}
                getRowClassName={(params) => {
                    if (params.row.assign_visibility == 0) {
                        return "row-muted";
                    }
                    return "";
                }}
            />
        </Box>
    );
}

export default AssignPatientList;
